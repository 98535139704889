import React, { useState, useEffect } from 'react';
import UserStore from '../../stores/UserStore';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import TpeStore from '../../stores/TpeStore';
import TpeView from '../Tpe/TpeView';

const DashboardView = observer((props) => {
  useEffect(() => {
    TpeStore.init();
  }, []);

  //Props
  const urlCommercial = UserStore.props.user.analyticsURLCommercial;
  const urlFinancial = UserStore.props.user.analyticsURLFinancial;
  const urlTpe = UserStore.props.user.analyticsURLTpe;
  const loadingStatus = TpeStore.props.loading.status;
  const loadingMsg = TpeStore.props.loading.msg;
  const canAnalytics = UserStore.canAnalytics();
  const isStation = UserStore.isStation();
  let activeTabulation = '1';

  if (isStation) activeTabulation = '4';
  const [activeTab, setActiveTab] = useState(activeTabulation);

  const toggleTab = (tab) => {
    console.log(tab);
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className='container-fluid mt-3'>
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={loadingStatus}
              message={loadingMsg}
              loader={<BarLoader></BarLoader>}
              className='card'
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                {canAnalytics && (
                  <>
                    <Nav tabs>
                      {!isStation && (
                        <>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === '1',
                              })}
                              onClick={() => {
                                toggleTab('1');
                              }}
                            >
                              <span className='h6'>Commercial</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === '2',
                              })}
                              onClick={() => {
                                toggleTab('2');
                              }}
                            >
                              <span className='h6'>Financier</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === '3',
                              })}
                              onClick={() => {
                                toggleTab('3');
                              }}
                            >
                              <span className='h6'>Monitoring TPE</span>
                            </NavLink>
                          </NavItem>
                        </>
                      )}
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '4' })}
                          onClick={() => {
                            toggleTab('4');
                          }}
                        >
                          <span className='h6'>Gestion des TPE</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent className='p-0 ' activeTab={activeTab}>
                      {!isStation && (
                        <>
                          <TabPane tabId='1' className='animated fadeIn p-0'>
                            <iframe
                              src={urlCommercial}
                              width='100%'
                              display='initial'
                              position='relative'
                              frameBorder={0}
                              style={{ height: '75vh' }}
                              allowFullScreen
                            ></iframe>
                          </TabPane>
                          <TabPane tabId='2' className='animated fadeIn p-0'>
                            <iframe
                              src={urlFinancial}
                              width='100%'
                              display='initial'
                              position='relative'
                              frameBorder={0}
                              style={{ height: '75vh' }}
                              allowFullScreen
                            ></iframe>
                          </TabPane>
                          <TabPane tabId='3' className='animated fadeIn p-0'>
                            <iframe
                              src={urlTpe}
                              width='100%'
                              display='initial'
                              position='relative'
                              frameBorder={0}
                              style={{ height: '75vh' }}
                              allowFullScreen
                            ></iframe>
                          </TabPane>
                        </>
                      )}
                      <TabPane tabId='4' className='animated fadeIn'>
                        <TpeView></TpeView>
                      </TabPane>
                    </TabContent>
                  </>
                )}
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default DashboardView;
