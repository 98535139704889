import React, { useEffect } from 'react'
import UserStore from '../../stores/UserStore'
import { observer } from "mobx-react"
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading'
import { Redirect, withRouter } from 'react-router-dom'

const LogoutView = (props) => {

    useEffect(() => {
        setTimeout(() => {
            UserStore.logout(() =>  props.history.push('/login'))
           
        }, 2000)
    }, [])

    if (!UserStore.props.user.isAuthenticated /*&& process.env['REACT_APP_DEBUG']*/) {
        return (
          <Redirect to="/login"></Redirect>
        )
    }

    return (
        <div className="container-fluid mt-4">
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={true}
                            message={"Déconnexion en cours ..."}
                            loader={<BarLoader></BarLoader>}
                            className="card" style={{ minHeight: 'calc(100vh - 100px)' }}>
                            
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(observer(LogoutView))