import React from 'react';
import Logo from '../../assets/img/logo_black.png';
import UserStore from '../../stores/UserStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { useFormik } from 'formik';
import classNames from 'classnames';
import parse from 'html-react-parser';
import myYup from '../../utils/myYup';
import MyField from '../../reusable/MyField';
import { BarLoader } from '../Loader/Loading';
import { Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';

//Form Field

const LoginView = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const action = query.get('action');

  const history = useHistory();

  const login = async (creds) => {
    // console.log(creds)
    UserStore.props.loading.status = true;
    await UserStore.login(creds, () => {
      history.push('/2fa');
    });
    UserStore.props.loading.status = false;
  };

  const forgotPassword = async (creds, resetForm) => {
    try {
      await UserStore.forgot(creds, resetForm);
      toast.success(
        <>
          <h3>
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: 'white', textAlign: 'center' }}
            ></FontAwesomeIcon>{' '}
            Instructions envoyées !
          </h3>
          <p>
            Si un compte existant est lié à l’adresse e-mail{' '}
            <strong>{creds.email}</strong>, des instructions de réinitialisation
            du mot de passe vous seront envoyées à cette adresse.
          </p>
          <p>
            Cet e-mail devrait vous parvenir dans les cinq prochaines minutes.
          </p>
          <p>Veuillez aussi vérifier votre dossier de courrier indésirable.</p>
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: login,
    validationSchema: myYup.object().shape({
      username: myYup.string().required(),
      password: myYup.string().required(),
    }),
  });

  const forgot = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values, { resetForm }) => {
      forgotPassword(values, resetForm);
    },
    validationSchema: myYup.object().shape({
      email: myYup.string().required(),
    }),
  });

  if (UserStore.props.user.isAuthenticated) {
    let to = null;

    if (UserStore.isSociete()) {
      to = {
        pathname: '/cards',
        state: 'Liste des cartes',
      };
    } else {
      to = {
        pathname: '/dashboard',
        state: 'Tableau de bord',
      };
    }

    // console.log('redirect')

    // to = {
    //     pathname: '/stations',
    //     state: 'CUSTOM'
    // }

    return <Redirect to={to}></Redirect>;
  }

  return (
    <div className='app flex-row align-items-center'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-5'>
            <BlockUi
              tag='div'
              className='card-group rounded login-loader'
              loader={<BarLoader></BarLoader>}
              message={UserStore.props.loading.msg}
              blocking={UserStore.props.loading.status}
            >
              <div className='card p-4'>
                {/* {UserStore.props.action === 'login' && ( */}
                {!action && (
                  <div className='card-body'>
                    <div className='text-center'>
                      <img
                        className='login-image'
                        src={Logo}
                        alt='Fidlink Logo'
                      />
                    </div>
                    <p className='text-muted text-center'>
                      Saisissez vos identifiants de connexion
                    </p>
                    {UserStore.props.fail.status && (
                      <div className='invalid-feedback d-inline-block text-center'>
                        Identifiants incorrects
                      </div>
                    )}
                    <form onSubmit={formik.handleSubmit} method='post'>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text'>
                            <i className='icon-user'></i>
                          </span>
                        </div>
                        <MyField
                          name='username'
                          type='text'
                          className='form-control'
                          placeholder='Login'
                          onChange={formik.handleChange}
                          value={formik.values.username}
                          error={formik.errors.username}
                          highlight={UserStore.props.fail.status}
                          touched={formik.touched.username}
                        ></MyField>
                        {formik.touched.username && formik.errors.username && (
                          <div className='invalid-feedback'>
                            {formik.errors.username}
                          </div>
                        )}
                      </div>
                      <div className='input-group mb-4'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text'>
                            <i className='icon-lock'></i>
                          </span>
                        </div>
                        <MyField
                          name='password'
                          type='password'
                          className='form-control'
                          placeholder='Password'
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          error={formik.errors.password}
                          highlight={UserStore.props.fail.status}
                          touched={formik.touched.password}
                        ></MyField>
                        {formik.touched.password && formik.errors.password && (
                          <div className='invalid-feedback'>
                            {formik.errors.password}
                          </div>
                        )}
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <button type='submit' className='btn btn-fuel px-4'>
                            Se connecter
                          </button>
                        </div>
                        <div className='col-6 text-right'>
                          <button
                            // onClick={() => UserStore.setAction('forgot')}
                            onClick={() =>
                              history.push('/login?action=reset_password')
                            }
                            className='btn btn-link px-0'
                            type='button'
                          >
                            Mot de passe oublié?
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
                {/* {UserStore.props.action === 'forgot' && ( */}
                {action && action === 'reset_password' && (
                  <div className='card-body'>
                    <h2>Réinitialisation de mot de passe</h2>
                    <p className='text-muted'>
                      Veuillez saisir l'email de votre compte afin de recevoir
                      le lien de réinitialisation de votre mot de passe.
                    </p>
                    <form onSubmit={forgot.handleSubmit} method='post'>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text'>
                            <i className='icon-user'></i>
                          </span>
                        </div>
                        <MyField
                          name='email'
                          type='email'
                          className='form-control'
                          placeholder='email'
                          onChange={forgot.handleChange}
                          value={forgot.values.email}
                          error={forgot.errors.email}
                          touched={forgot.touched.email}
                        ></MyField>
                        {forgot.touched.email && forgot.errors.email && (
                          <div className='invalid-feedback'>
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <button type='submit' className='btn btn-fuel px-4'>
                            Valider
                          </button>
                        </div>
                        <div className='col-6 text-right'>
                          <button
                            // onClick={() => UserStore.setAction('login')}
                            onClick={() => history.push('/login')}
                            className='btn btn-link px-0'
                            type='button'
                          >
                            Se connecter
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
              <Modal
                backdrop='static'
                centered={true}
                isOpen={
                  UserStore.props.startupWarningActivated &&
                  !UserStore.props.isLoggedIn
                }
                size='lg'
              >
                <ModalBody>
                  <div className='row'>
                    <div className='col d-flex center-v'>
                      <div className='p-3 text-black rounded'>
                        <div className='col-md-12 px-0 text-center'>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            size='3x'
                            className='text-pass'
                          ></FontAwesomeIcon>
                          <hr />
                          <h1 className='font-italic text-primary text-bold'>
                            Bienvenue sur le nouveau BackOffice FuelPass
                          </h1>
                          <h4 className='font-italic lead my-1 mt-3'>
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: 'green' }}
                            ></FontAwesomeIcon>{' '}
                            Un design moderne et intuitif !
                          </h4>
                          <h4 className='font-italic lead my-1'>
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: 'green' }}
                            ></FontAwesomeIcon>{' '}
                            Un accès à toutes les fonctionnalités habituelles !
                          </h4>
                          <h4 className='font-italic lead my-1'>
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: 'green' }}
                            ></FontAwesomeIcon>{' '}
                            Toute une équipe pour vous assister au mieux !{' '}
                          </h4>
                          <button
                            type='button'
                            onClick={() => {
                              UserStore.props.startupWarningActivated = false;
                            }}
                            className='btn btn-outline-info mt-3'
                            href='#'
                          >
                            Fermer
                          </button>
                          <hr />
                          <a
                            className='btn text-dark pull-right'
                            href={process.env['REACT_APP_OLD_ADMIN_URL']}
                          >
                            Accéder plutôt à l'ancienne interface
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              {/* <div className="card py-5 d-md-down-none">
                                <div className="card-body text-center">
                                    <div>
                                        <img className="login-image" src={Logo} alt="Fidlink Logo" />
                                    </div>
                                </div>
                            </div> */}
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(LoginView);
