import numeral from 'numeral'

// load a locale
numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€'
    }
});

// switch between locales
numeral.locale('fr');

export const score = (value) => {

    if (!value)
        return numeral(String(0).replace('.', ',')).format('0,0,0.00')

    return numeral(String(value).replace('.', ',')).format('0,0,0.00')
}

export const number = (value) => {

    if (!value)
        return numeral(String(0).replace('.', ',')).format('0,0,0.[00]')

    return numeral(String(value).replace('.', ',')).format('0,0,0.[00]')
}

export const currency = (value) => {

    if (!value)
        return numeral(String(0).replace('.', ',')).format('0,0,0.00')

    return numeral(String(value).replace('.', ',')).format('0,0,0.00')
}

export default numeral