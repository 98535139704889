import React from 'react';
import { observable } from 'mobx';
import { entityTypes } from '../utils/constants';
import api from '../api';
import UserStore from './UserStore';
import modalAlert from '../components/Alerts/ModalAlert';
import { ActionsBoUser } from '../components/BoUsers/BoUsersView';

const props = {
  loading: {
    status: false,
    msg: 'Connexion en cours',
  },
  columns: [
    {
      Header: 'NOM / Prénom',
      accessor: 'NP',
      isHidden: false,
      isSortable: true,
    },
    {
      Header: 'Partenaire',
      accessor: 'NomPartenaire',
      isHidden: false,
      isSortable: true,
    },
    {
      Header: 'Réseau',
      accessor: 'NomReseau',
      isHidden: false,
      isSortable: true,
    },
    {
      Header: 'Station',
      readOnly: true,
      accessor: 'NomCommercant',
      isHidden: true,
      isSortable: true,
    },
    {
      Header: 'Société',
      readOnly: true,
      accessor: 'NomSociete',
      isHidden: false,
      isSortable: true,
    },
    {
      Header: 'Login',
      accessor: 'usersLogin',
      isHidden: false,
      isSortable: true,
    },
    {
      Header: "Droit d'accès",
      accessor: 'usersDroit',
      isHidden: false,
      isSortable: true,
    },
    {
      Header: 'Dernière connexion',
      accessor: 'DateDerConnexion',
      isHidden: false,
      isSortable: true,
    },
    {
      Header: 'Rechargement Periodique',
      accessor: 'DroitRechargementPeriodique',
    },
    {
      Header: 'Rechargement Unique',
      accessor: 'DroitRechargementUnique',
    },
    {
      Header: 'Dernier échec',
      accessor: 'DateDerTentative',
    },
    {
      Header: 'Dernier mot de passe',
      accessor: 'DateModification',
    },
    {
      Header: 'Expiration mot de passe',
      accessor: 'days_before_password_expiry',
    },
    {
      Header: 'Email Vérifiée',
      accessor: 'verified',
    },
    {
      Header: 'Sécurité 2 Facteurs',
      accessor: 'two_factors_method',
    },
    {
      Header: 'Sécurité 2FA Vérifiée ',
      accessor: 'two_factors_verified',
    },
    {
      Header: 'Compte Actif',
      accessor: 'compteActif',
      isHidden: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({
        row,
        toggleModalEditBoUser,
        isManager,
        isOperator,
        isReseau,
        deleteUser,
        resendActionMAil,
        resend2FAMail,
      }) => {
        return (
          <ActionsBoUser
            row={row.original}
            toggleModalEditBoUser={toggleModalEditBoUser}
            isManager={isManager}
            isOperator={isOperator}
            isReseau={isReseau}
            deleteUser={deleteUser}
            resendActionMAil={resendActionMAil}
            resend2FAMail={resend2FAMail}
          ></ActionsBoUser>
        );
      },
    },
  ],

  data: [],

  pageStart: 0,
  pageIndex: 0,
  pageLimit: 50,
  pageCount: 0,
  pageTotal: 0,

  sortBy: 'NomReseau,NomCommercant,NP',
  sortOrder: 'ASC',

  typeContent: null,
  listBoUsers: [],
  listGerants: [],
  searchBoUser: { id: null, label: 'Tous' },
  searchBoUserSociete: { id: null, label: 'Toutes' },

  currentBoUser: {},

  isOpenModalLst: false,
  isOpenModalEditBoUser: false,

  initialValuesBoUser: {},
};

const BoUserStore = {
  /***** Props ******/
  props: props,

  /***** Methods ******/
  async init() {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    await this.getBoUsers();
    await this.getBoUsersFilter();
    this.doLoading(false);
  },
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  toggleModalLst(row, typeContent) {
    if (row) this.props.currentBoUser = row;
    if (typeContent) this.props.typeContent = typeContent;
    this.props.isOpenModalLst = !this.props.isOpenModalLst;
  },
  setCurrentBoUser(row) {
    this.props.currentBoUser = {
      id: row.idUsersFid,
      name: row.NP,
      type: entityTypes.boUser,
    };
  },
  toggleModalCreateBoUser() {
    let row = {
      idUsersFid: null,
      NP: '',
      usersLogin: '',
      usersPass: '',
      usersDroit: 5,
      usersIdCom: null,
      usersIdSoc: '',
      usersIdResFid: parseInt(UserStore.props.user.idRes),
      compteActif: true,
      usersMail: '',
      IdPartenaire: parseInt(UserStore.props.user.idPart),
      DroitCreation: false,
      DroitAdministrationCommandes: false,
      DroitValidationCredit: false,
      DroitRechargementPeriodique: false,
      DroitRechargementUnique: false,
      DroitArreterComptable: false,
      DroitExport: false,
      DroitCreationTPE: false,
      DroitCreationSociete: false,
      DroitGestionBonCarburant: false,
      CreerPar: UserStore.props.user.nom,
      ModifierPar: UserStore.props.user.nom,
      two_factors_method: 0,
    };
    this.setCurrentBoUser(row);
    this.toggleModalEditBoUser(row);
  },
  toggleModalEditBoUser(row) {
    if (row) {
      this.props.currentBoUser = row;
      this.props.initialValuesBoUser = {
        idUsersFid: row.idUsersFid,
        NP: row.NP,
        usersLogin: row.usersLogin,
        usersPass: row.usersPass,
        usersDroit: row.usersDroit,
        usersIdCom: row.usersIdCom,
        usersIdSoc: row.usersIdSoc,
        usersIdResFid: row.usersIdResFid,
        compteActif: row.compteActif,
        usersMail: row.usersMail,
        DroitCreation: row.DroitCreation,
        DroitAdministrationCommandes: row.DroitAdministrationCommandes,
        DroitValidationCredit: row.DroitValidationCredit,
        DroitRechargementPeriodique: row.DroitRechargementPeriodique,
        DroitRechargementUnique: row.DroitRechargementUnique,
        DroitArreterComptable: row.DroitArreterComptable,
        DroitExport: row.DroitExport,
        DroitCreationTPE: row.DroitCreationTPE,
        DroitCreationSociete: row.DroitCreationSociete,
        DroitGestionBonCarburant: row.DroitGestionBonCarburant,
        ModifierPar: UserStore.props.user.nom,
        two_factors_method: row.two_factors_method === true ? 1 : 0,
      };
    }
    this.props.isOpenModalEditBoUser = !this.props.isOpenModalEditBoUser;
  },
  async submitEditBoUser(values) {
    if (typeof values.usersDroit === 'object' && values.usersDroit !== null)
      values.usersDroit = values.usersDroit.id;

    if (typeof values.usersIdSoc === 'object' && values.usersIdSoc !== null)
      values.usersDroit = values.usersIdSoc.id;

    if (typeof values.CreerPar === 'undefined' && values.CreerPar !== null)
      values.CreerPar = UserStore.props.user.nom;
    // values.CreerPar = UserStore.props.user.nom;

    if (values.idUsersFid === null) {
      values.newRecord = true;
      values.IdPartenaire = parseInt(UserStore.props.user.idPart);
      values.usersIdResFid = parseInt(UserStore.props.user.idRes);
    }

    if (values.usersIdSoc === '') values.usersIdSoc = null;

    this.doLoading(true, 'Chargement en cours ...');
    this.toggleModalEditBoUser();

    let data = {
      cmd: 'saveRows',
      objName: 'listUsers',
      ref: 'saveUsers',
      userInfo: JSON.stringify([values]),
      data: JSON.stringify([values]),
      version: 'v2',
    };

    const res = await api.post('/scripts/users/users.php', data);
    if (res.data.success) {
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'Les informations ont bien été modifiés',
      });
      if (!parseInt(values.idUsersFid)) {
        await this.getBoUsersFilter();
      }
    } else {
      modalAlert({
        title: 'Erreur',
        typeAlert: 'error',
        text: res.data.error,
      });
    }

    await this.getBoUsers();
    this.doLoading(false);
  },
  async deleteUser(values) {
    try {
      this.doLoading(true, 'Chargement en cours ...');

      let data = {
        cmd: 'DelRows',
        objName: 'listUsers',
        ref: 'deleteData',
        user_id: values.idUsersFid,
      };

      const res = await api.post('/scripts/users/users.php', data);

      if (res.data.success) {
        modalAlert({
          title: 'Succès',
          typeAlert: 'success',
          text: 'Utilisateurs supprimé avec succès',
        });
      } else {
        modalAlert({
          title: 'Erreur',
          typeAlert: 'error',
          text: res.data.error,
        });
      }
      await this.getBoUsers();
      this.doLoading(false);
    } catch (error) {
      console.log(error);
    }
  },
  async setSearchBoUser(val) {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.searchBoUser = val;
    await this.getBoUsers();
    this.doLoading(false);
  },
  async setSearchBoUserSociete(val) {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.searchBoUserSociete = val;
    await this.getBoUsers();
    this.doLoading(false);
  },
  async getBoUsersFilter() {
    let where =
      'IdPartenaire = ' +
      UserStore.props.user.idPart +
      ' and usersIdResFid = ' +
      UserStore.props.user.idRes;

    this.props.sortBy = this.props.sortBy || 'NomReseau,NomCommercant,NP';
    this.props.sortOrder = this.props.sortOrder || 'ASC';

    let data = {
      objName: 'list_Users',
      cmd: 'getRows',
      where: where,
      start: 0,
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/users/users.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.listBoUsers = !res.data.rows
          ? []
          : res.data.rows.map((item) => ({
              id: item.idUsersFid,
              label: item.NP,
            }));
        this.props.listBoUsers.unshift({ id: null, label: 'Tous' });
      }
    }
  },
  async getBoUsers() {
    let where =
      'IdPartenaire = ' +
      UserStore.props.user.idPart +
      ' and usersIdResFid = ' +
      UserStore.props.user.idRes;

    if (this.props.searchBoUser.id)
      where += '  AND idUsersFid = ' + this.props.searchBoUser.id;

    if (this.props.searchBoUserSociete.id)
      where += '  AND usersIdSoc = ' + this.props.searchBoUserSociete.id;

    let data = {
      objName: 'list_Users',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/users/users.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
  },
  //PAGINATION
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getBoUsers();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getBoUsers();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getBoUsers();
    this.doLoading(false);
  },
  async setSort(value) {
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortBy !== value) {
      this.props.sortBy = value;
      this.props.sortOrder = 'ASC';
    } else {
      if (this.props.sortOrder === 'ASC') this.props.sortOrder = 'DESC';
      else if (this.props.sortOrder === 'DESC') {
        this.props.sortOrder = false;
        this.props.sortBy = null;
      }
    }

    await this.getBoUsers();
    this.doLoading(false);
  },
  reset() {
    this.props = props;
  },
  async resendActionMAil(values) {
    try {
      this.doLoading(true, 'Chargement en cours ...');

      let data = {
        cmd: 'resend_verify_email',
        user_id: values.idUsersFid,
      };

      const res = await api.post('/scripts/users/users.php', data);

      if (res.data.success) {
        modalAlert({
          title: 'Succès',
          typeAlert: 'success',
          text: 'Email de vérification envoyé avec succès',
        });
      } else {
        modalAlert({
          title: 'Erreur',
          typeAlert: 'error',
          text: res.data.error,
        });
      }
      this.doLoading(false);
    } catch (error) {
      console.log(error);
      this.doLoading(false);
    }
  },
  async resend2FAMail(values) {
    try {
      this.doLoading(true, 'Chargement en cours ...');

      let data = {
        cmd: 'resend_two_factors_setup_email',
        user_id: values.idUsersFid,
      };

      const res = await api.post('/scripts/users/users.php', data);

      if (res.data.success) {
        modalAlert({
          title: 'Succès',
          typeAlert: 'success',
          text: 'Email de configuration 2FA envoyé avec succès',
        });
      } else {
        modalAlert({
          title: 'Erreur',
          typeAlert: 'error',
          text: res.data.error,
        });
      }
      this.doLoading(false);
    } catch (error) {
      console.log(error);
      this.doLoading(false);
    }
  },
};

export default observable(BoUserStore);
