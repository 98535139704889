import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPowerOff,
  faUser,
  faCaretDown,
  faCogs,
  faLock,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  Modal,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UserStore from '../../stores/UserStore';
import Logo from '../../assets/img/logo_black.png';
import MainStore from '../../stores/MainStore';
import { BarLoader } from '../Loader/Loading';
import BlockUi from 'react-block-ui';
import MyField from '../../reusable/MyField';
import myYup from '../../utils/myYup';
import { useFormik } from 'formik';

const HeaderView = (props) => {
  const { wrapperRef } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [openModalPassword, setOpenModalPassword] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const currentRoute = MainStore.props.currentRoute;

  const toggleSidebar = () => {
    console.log('toggle');
    console.log(wrapperRef);
    const el = wrapperRef.current;
    if (el.classList.contains('sidebar-show')) {
      el.classList.remove('sidebar-show');
    } else {
      el.classList.add('sidebar-show');
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleReset,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: '',
      new_password_1: '',
      new_password_2: '',
    },
    validationSchema: myYup.object().shape({
      old_password: myYup.string().required(),
      new_password_1: myYup
        .string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&*#!@$%^&:;,.?()\[\]{}~_+\-=|<>])[A-Za-z\d@$!%*?&*#!@$%^&:;,.?()\[\]{}~_+\-=|<>]{8,}$/,
          'Ton mot de passe doit contenir au moins un chiffre - au moins une lettre majuscule - au moins une lettre minuscule et un caractère spécial.'
        ),
      new_password_2: myYup
        .string()
        .required()
        .oneOf(
          [myYup.ref('new_password_1')],
          'Confirmation mot de passe incorrecte'
        ),
    }),
    onSubmit: (values) => UserStore.submitChangePassword(values, handleReset),
    validateOnChange: false,
    validateOnBlur: true,
  });

  const closeModalChangePassword = () => {
    setOpenModalPassword(false);
    UserStore.setSuccessChangePassword(false);
    UserStore.logout();
  };

  return (
    <>
      <header className='app-header navbar' style={{ paddingRight: '3vw' }}>
        <button
          className='navbar-toggler sidebar-toggler d-lg-none'
          onClick={toggleSidebar}
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <a className='navbar-brand'>
          <img className='brand-image' src={Logo} alt='Fidlink Logo' />
          <img
            className='navbar-brand-minimized'
            src={Logo}
            width='30'
            height='30'
            alt='Fidlink Logo'
          />
        </a>
        <button
          className='navbar-toggler sidebar-toggler d-md-down-none'
          onClick={toggleSidebar}
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <ul className='navbar-nav ml-3 d-xs-none d-none d-sm-flex'>
          <li className='nav-item'>
            <span id='page-title' className='text-old-light h5'>
              {currentRoute}
            </span>
          </li>
        </ul>
        <ul className='navbar-nav ml-auto'>
          {/* <li className="nav-item">
                    <Dropdown direction="down" isOpen={dropdownNotifOpen} toggle={toggleNotif}>
                        <DropdownToggle
                            tag="span"
                            className="pointer d-flex center-v"
                            data-toggle="dropdown"
                            aria-expanded={dropdownNotifOpen}
                        >
                            <span class="has-badge" data-count="10">
                                <FontAwesomeIcon className="text-fid mr-2 notifications" icon={faBell}></FontAwesomeIcon>
                            </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <div className="dropdown-header bg-light py-2"><strong>Notifications</strong></div>
                            <Link to="/logout" className="dropdown-item  text-old">
                                <FontAwesomeIcon className="pr-2" size="lg" icon={faPowerOff} /> Se déconnecter
                            </Link>
                        </DropdownMenu>
                    </Dropdown>
                </li> */}
          <li className='nav-item'>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                tag='span'
                className='pointer d-flex center-v'
                data-toggle='dropdown'
                aria-expanded={dropdownOpen}
              >
                <FontAwesomeIcon
                  className='text-old-light mr-2'
                  icon={faUser}
                ></FontAwesomeIcon>
                <span className='text-old font-weight-bold'>
                  {UserStore.props.user.nom}
                </span>
                <FontAwesomeIcon
                  className='text-fuel ml-2'
                  icon={faCaretDown}
                ></FontAwesomeIcon>
              </DropdownToggle>
              <DropdownMenu>
                {/* <div className='dropdown-header py-2'>
                  <strong>Paramétrage</strong>
                </div>
                <Link
                  to={{
                    pathname: '/settings',
                    state: 'Paramètres',
                  }}
                  className='dropdown-item  text-old'
                >
                  <FontAwesomeIcon className='pr-2' size='lg' icon={faCogs} />{' '}
                  Paramètres
                </Link> */}
                <div className='dropdown-header py-2'>
                  <strong>Mon compte</strong>
                </div>
                <a
                  onClick={() => setOpenModalPassword(true)}
                  className='dropdown-item  text-old'
                >
                  <FontAwesomeIcon className='pr-2' size='lg' icon={faLock} />{' '}
                  Changer mon mot de passe
                </a>
                <Link to='/logout' className='dropdown-item  text-old'>
                  <FontAwesomeIcon
                    className='pr-2'
                    size='lg'
                    icon={faPowerOff}
                  />{' '}
                  Se déconnecter
                </Link>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
      </header>
      {
        <Modal
          isOpen={openModalPassword}
          backdrop='static'
          centered={true}
          tag='div'
        >
          <div className='profile-header d-flex px-0 center-v center-h py-3'>
            <div class='avatar-first-letter-medium mr-2'>
              <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
            </div>
            <strong className='profile-name'>Modififcation mot de passe</strong>
          </div>
          <ModalBody>
            <BlockUi
              tag='div'
              blocking={UserStore.props.loading.status}
              message={UserStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className='row'
            >
              {!UserStore.props.successChangePassword && (
                <>
                  <div className='form-group col-12'>
                    <label for='oldPhrase'>
                      Tapez votre ancien mot de passe :{' '}
                    </label>
                    <MyField
                      name='old_password'
                      type='password'
                      className='form-control'
                      onChange={handleChange}
                      customOnChange={setFieldValue}
                      value={values['old_password']}
                      error={errors['old_password']}
                      touched={touched['old_password']}
                    ></MyField>
                  </div>
                  <div className='form-group col-12'>
                    <label for='newPhrase1'>
                      Tapez votre nouveau mot de passe :{' '}
                    </label>
                    <MyField
                      name='new_password_1'
                      type='password'
                      className='form-control'
                      onChange={handleChange}
                      customOnChange={setFieldValue}
                      value={values['new_password_1']}
                      error={errors['new_password_1']}
                      touched={touched['new_password_1']}
                    ></MyField>
                  </div>
                  <div className='form-group col-12'>
                    <label for='newPhrase2'>
                      Confirmez nouveau mot de passe :{' '}
                    </label>
                    <MyField
                      name='new_password_2'
                      type='password'
                      className='form-control'
                      onChange={handleChange}
                      customOnChange={setFieldValue}
                      value={values['new_password_2']}
                      error={errors['new_password_2']}
                      touched={touched['new_password_2']}
                    ></MyField>
                  </div>
                  <div className='col-12 d-flex align-items-end justify-content-end'>
                    <button
                      onClick={() => setOpenModalPassword(false)}
                      className='btn btn-danger rounded mr-2'
                    >
                      Annuler
                    </button>
                    <button
                      type='submnit'
                      onClick={handleSubmit}
                      className='btn btn-fuel rounded ml-2'
                    >
                      Valider
                    </button>
                  </div>
                </>
              )}
              {UserStore.props.successChangePassword && (
                <>
                  <div className='d-flex w-100 flex-column px-0 center-v center-h py-3'>
                    <div class='avatar-first-letter-big bg-success mb-3'>
                      <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                    </div>
                    <strong>
                      Modififcation mot de passe effectué. Veillez vous
                      reconnecter.
                    </strong>
                  </div>
                  <div className='w-100 d-flex align-items-end justify-content-end'>
                    <button
                      onClick={() => closeModalChangePassword()}
                      className='btn btn-fuel rounded mr-2'
                    >
                      Fermer
                    </button>
                  </div>
                </>
              )}
            </BlockUi>
          </ModalBody>
        </Modal>
      }
    </>
  );
};

export default withRouter(observer(HeaderView));
