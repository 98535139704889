import React from 'react';

const LoginLayout = ({ component: Component, ...rest }) => {
  return (
    <div className='h-100'>
      <div className='login-page'>
        <Component {...rest}></Component>
      </div>
    </div>
  );
};

export default LoginLayout;
