import React, { useEffect } from 'react';
import UserStore from '../../stores/UserStore';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import MajCardsStore from '../../stores/MajCardsStore';
import { useTable } from 'react-table';
import DataTable, { ColumnsToggle } from '../../reusable/DataTable';
import MajsFilter from '../../reusable/MajsFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilter } from '@fortawesome/free-solid-svg-icons';

const MajCardsView = observer((props) => {
  const { idSta } = props;

  useEffect(() => {
    MajCardsStore.init(idSta);
  }, []);

  const majs = useTable({
    columns: MajCardsStore.props.columns,
    data: MajCardsStore.props.data,
    initialState: {
      hiddenColumns: MajCardsStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: MajCardsStore.props.pageIndex,
      pageSize: MajCardsStore.props.pageLimit,
      pageCount: MajCardsStore.props.pageCount,
      pageTotal: MajCardsStore.props.pageTotal,
    },
  });

  return (
    <div className='container-fluid mt-3'>
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={MajCardsStore.props.loading.status}
              message={MajCardsStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className={classnames({ card: !idSta })}
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <div className='d-flex align-items-end mb-2'>
                  <div className='d-flex'>
                    <MajsFilter
                      onSubmitFilter={() => MajCardsStore.getFilteredMajs()}
                      className='btn btn-old btn-sm  mx-1'
                      innerButton={
                        <>
                          <FontAwesomeIcon
                            icon={faFilter}
                            className='mr-2'
                          ></FontAwesomeIcon>
                          Recherche avancée
                        </>
                      }
                    ></MajsFilter>
                    <ColumnsToggle
                      id={'operations'}
                      columns={majs.allColumns}
                      className='btn btn-fuel btn-sm mx-1 btn-sm-flex-h'
                      toggleColumn={(col) => MajCardsStore.toggleColumn(col)}
                    ></ColumnsToggle>
                    {UserStore.canDroitExport() && (
                      <button
                        onClick={() => MajCardsStore.exportMajs()}
                        className='btn btn-old btn-sm  mx-1 btn-sm-flex-h'
                      >
                        <FontAwesomeIcon
                          icon={faFileExcel}
                          className='mr-2'
                        ></FontAwesomeIcon>
                        Exporter
                      </button>
                    )}
                  </div>
                </div>
                <DataTable
                  headerGroups={majs.headerGroups}
                  getTableBodyProps={majs.getTableBodyProps}
                  // headerClassName="bg-theme-grey"
                  tableClassName='font-xs'
                  // onDoubleClickRow={(id) => TransacFilterStore.onDoubleClickRow(id)}
                  rows={majs.rows}
                  prepareRow={majs.prepareRow}
                  getTableProps={majs.getTableProps}
                  columns={MajCardsStore.props.columns}
                  loadingMsg={MajCardsStore.props.loading.msg}
                  loadingStatus={MajCardsStore.props.loading.status}
                  prevPage={() => MajCardsStore.prevPage()}
                  nextPage={() => MajCardsStore.nextPage()}
                  setSort={(sortBy) => MajCardsStore.setSort(sortBy)}
                  sortOrder={MajCardsStore.props.sortOrder}
                  sortBy={MajCardsStore.props.sortBy}
                  goToPage={(page) => MajCardsStore.goToPage(page)}
                  pageCount={MajCardsStore.props.pageCount}
                  pageIndex={MajCardsStore.props.pageIndex}
                  pageLimit={MajCardsStore.props.pageLimit}
                  pageTotal={MajCardsStore.props.pageTotal}
                  maxHeight='calc(100vh - 260px)'
                  btnClassName='btn btn-outline-fuel btn-sm'
                ></DataTable>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MajCardsView;
