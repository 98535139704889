import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import BonsStore from '../../stores/BonsStore';
import { useTable } from 'react-table';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faTimes,
  faUpload,
  faCheck,
  faPlusCircle,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import 'simplebar/dist/simplebar.min.css';
import UserStore from '../../stores/UserStore';
import MyField from '../../reusable/MyField';
import DataTable, { ColumnsToggle } from '../../reusable/DataTable';
import myYup from '../../utils/myYup';
import { useFormik } from 'formik';
import BonsFilter from '../../reusable/BonsFilter';

const ModalAddBons = observer((props) => {
  const {
    isOpenModalAddBons,
    toggleModalAddBons,
    importLoading,
    importLoadingMsg,
    importBons,
  } = props;

  const fileInput = useRef({});
  const [filename, setFilename] = useState(null);
  const [file, setFile] = useState(null);

  const doImport = async (e) => {
    await importBons(e);
    fileInput.current.value = null;
  };

  return (
    <Modal
      backdrop='static'
      centered={true}
      isOpen={isOpenModalAddBons}
      toggle={() => toggleModalAddBons()}
    >
      <BlockUi
        tag='div'
        blocking={importLoading}
        message={importLoadingMsg}
        loader={<BarLoader></BarLoader>}
      >
        <ModalHeader className='no-border' toggle={() => toggleModalAddBons()}>
          Ajouter des bons carburants
        </ModalHeader>
        <ModalBody className='px-3 py-3'>
          <div className='form-group'>
            <input
              ref={fileInput}
              type='file'
              style={{ display: 'none' }}
              onChange={(e) => {
                setFile(e.target);
                setFilename(e.target.files[0].name);
              }}
              className='form-control'
            />
            {!filename && <span>Aucun fichier à importer</span>}
            {filename && (
              <p>
                <label>Fichier à importer :</label> <b>{filename}</b>
              </p>
            )}
          </div>
          <div className='form-group'>
            <button
              className='btn btn-sm btn-fuel'
              onClick={() => fileInput.current.click()}
            >
              <FontAwesomeIcon
                icon={faUpload}
                className='mr-2'
              ></FontAwesomeIcon>
              Importer un fichier client
            </button>
          </div>
        </ModalBody>
        <ModalFooter className='d-flex center-h'>
          <button className='btn-success btn' onClick={() => doImport(file)}>
            <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
            Valider
          </button>
          <button
            className='btn-danger btn'
            onClick={() => toggleModalAddBons()}
          >
            <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
            Annuler
          </button>
        </ModalFooter>
      </BlockUi>
    </Modal>
  );
});

const ModalSetBons = observer((props) => {
  const {
    isOpenModalSetBons,
    toggleModalSetBons,
    importLoading,
    importLoadingMsg,
    listSocietes,
    submitSetBons,
  } = props;

  const bonEnd = useRef();

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        societe: null,
        numBonCarburantStart: null,
        numBonCarburantEnd: null,
      },
      validationSchema: myYup.object().shape({
        societe: myYup.string().ensure().required(),
        numBonCarburantStart: myYup.string().ensure().required(),
        numBonCarburantEnd: myYup.string().ensure().required(),
      }),
      onSubmit: submitSetBons,
      validateOnChange: false,
      validateOnBlur: false,
    });

  return (
    <Modal
      backdrop='static'
      centered={true}
      isOpen={isOpenModalSetBons}
      toggle={() => toggleModalSetBons()}
    >
      <BlockUi
        tag='div'
        blocking={importLoading}
        message={importLoadingMsg}
        loader={<BarLoader></BarLoader>}
      >
        <ModalHeader className='no-border' toggle={() => toggleModalSetBons()}>
          Attribuer des bons carburants
        </ModalHeader>
        <ModalBody className='px-3 py-3'>
          <div className='row form-group col-md-12 mb-3'>
            <label className='col-md-4' for=''>
              Société:
            </label>
            <div className='col-md-8'>
              <MyField
                type='reactSelect'
                list={listSocietes}
                className='async-select'
                customOnChange={setFieldValue}
                name='societe'
                value={values['societe']}
                error={errors['societe']}
                touched={touched['societe']}
              ></MyField>
            </div>
          </div>
          <div className='row form-group col-md-12 mb-3'>
            <label className='col-md-4' for=''>
              Premier Bon:
            </label>
            <div className='col-md-8'>
              <MyField
                type='text'
                placeholder='Numéro du 1er bon carburant du lot'
                className='form-control form-control-sm'
                onChange={handleChange}
                name='numBonCarburantStart'
                value={values['numBonCarburantStart']}
                error={errors['numBonCarburantStart']}
                touched={touched['numBonCarburantStart']}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (bonEnd.current) bonEnd.current.focus();
                  }
                }}
              ></MyField>
            </div>
          </div>
          <div className='row form-group col-md-12 mb-3'>
            <label className='col-md-4' for=''>
              Dernier Bon:
            </label>
            <div className='col-md-8'>
              <MyField
                refer={bonEnd}
                type='text'
                placeholder='Numéro du dernier bon carburant du lot'
                className='form-control form-control-sm'
                onChange={handleChange}
                name='numBonCarburantEnd'
                value={values['numBonCarburantEnd']}
                error={errors['numBonCarburantEnd']}
                touched={touched['numBonCarburantEnd']}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              ></MyField>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='d-flex center-h'>
          <button className='btn-success btn' onClick={handleSubmit}>
            <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
            Valider
          </button>
          <button
            className='btn-danger btn'
            onClick={() => toggleModalSetBons()}
          >
            <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
            Annuler
          </button>
        </ModalFooter>
      </BlockUi>
    </Modal>
  );
});

const BonsView = observer((props) => {
  useEffect(() => {
    BonsStore.init();
  }, []);

  // const cols = toJS(BonsStore.props.columns).filter(item => !item.isNotDisplay)
  const cltTable = useTable({
    columns: BonsStore.props.columns,
    data: BonsStore.props.data,
    initialState: {
      hiddenColumns: BonsStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: BonsStore.props.pageIndex,
      pageSize: BonsStore.props.pageLimit,
      pageCount: BonsStore.props.pageCount,
      pageTotal: BonsStore.props.pageTotal,
    },
    nomUnitePME2: UserStore.props.user.unitesPME2court,
  });

  return (
    <div className='container-fluid mt-3'>
      {/* <h2 className="card-title">Liste des cartes</h2> */}
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={BonsStore.props.loading.status}
              message={BonsStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className='card'
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between mb-3 flex-column flex-sm-row'>
                  <div className='d-flex'>
                    <ColumnsToggle
                      columns={cltTable.allColumns}
                      className='btn btn-old btn-sm mx-1'
                      toggleColumn={(col) => BonsStore.toggleColumn(col)}
                      // columnsFromTable={BonsStore.props.columnsFromTable}
                    ></ColumnsToggle>
                    <BonsFilter
                      onSubmitFilter={() => BonsStore.getFilteredBons()}
                      className='btn btn-old btn-sm  mx-1'
                      innerButton={
                        <>
                          <FontAwesomeIcon
                            icon={faFilter}
                            className='mr-2'
                          ></FontAwesomeIcon>
                          Recherche avancée
                        </>
                      }
                    ></BonsFilter>
                  </div>
                  <div>
                    {/* <QuickSearch
                                            onSubmitFilter={() => BonsStore.getFilteredBons()}
                                            className="btn btn-old btn-sm"
                                        ></QuickSearch> */}
                  </div>
                  {UserStore.isReseau() && (
                    <div className='d-flex'>
                      <button
                        onClick={() => BonsStore.toggleModalAddBons()}
                        className='btn btn-success btn-sm  mx-1 btn-sm-flex-h'
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className='mr-2'
                        ></FontAwesomeIcon>
                        Ajouter des bons carburants
                      </button>
                      <button
                        onClick={() => BonsStore.toggleModalSetBons()}
                        className='btn btn-old btn-sm  mx-1 btn-sm-flex-h'
                      >
                        <FontAwesomeIcon
                          icon={faLongArrowAltRight}
                          className='mr-2'
                        ></FontAwesomeIcon>
                        Attribuer des bons carburants
                      </button>
                    </div>
                  )}
                </div>
                <DataTable
                  tableClassName='font-xs'
                  headerGroups={cltTable.headerGroups}
                  getTableBodyProps={cltTable.getTableBodyProps}
                  rows={cltTable.rows}
                  prepareRow={cltTable.prepareRow}
                  getTableProps={cltTable.getTableProps}
                  columns={BonsStore.props.columns}
                  loadingMsg={BonsStore.props.loading.msg}
                  loadingStatus={BonsStore.props.loading.status}
                  prevPage={() => BonsStore.prevPage()}
                  nextPage={() => BonsStore.nextPage()}
                  setSort={(sortBy) => BonsStore.setSort(sortBy)}
                  sortOrder={BonsStore.props.sortOrder}
                  sortBy={BonsStore.props.sortBy}
                  goToPage={(page) => BonsStore.goToPage(page)}
                  pageCount={BonsStore.props.pageCount}
                  pageIndex={BonsStore.props.pageIndex}
                  pageLimit={BonsStore.props.pageLimit}
                  pageTotal={BonsStore.props.pageTotal}
                  maxHeight='calc(100vh - 260px)'
                  btnClassName='btn btn-outline-success btn-sm'
                ></DataTable>
                <ModalSetBons
                  isOpenModalSetBons={BonsStore.props.isOpenModalSetBons}
                  toggleModalSetBons={() => BonsStore.toggleModalSetBons()}
                  importLoading={BonsStore.props.loading.status}
                  importLoadingMsg={BonsStore.props.loading.msg}
                  listSocietes={BonsStore.props.listSocietes}
                  submitSetBons={(values) => BonsStore.submitSetBons(values)}
                ></ModalSetBons>
                <ModalAddBons
                  isOpenModalAddBons={BonsStore.props.isOpenModalAddBons}
                  toggleModalAddBons={() => BonsStore.toggleModalAddBons()}
                  importLoading={BonsStore.props.loading.status}
                  importLoadingMsg={BonsStore.props.loading.msg}
                  listSocietes={BonsStore.props.listSocietes}
                  importBons={(e) => BonsStore.importBons(e)}
                ></ModalAddBons>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BonsView;
