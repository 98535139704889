import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import StationsStore from '../../stores/StationsStore';
import { useTable } from 'react-table';
import { DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faRedo,
  faPen,
  faUsers,
  faCodeBranch,
  faExpandArrowsAlt,
  faLock,
  faPrint,
  faCalculator,
} from '@fortawesome/free-solid-svg-icons';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import 'simplebar/dist/simplebar.min.css';
import UserStore from '../../stores/UserStore';
import MyField from '../../reusable/MyField';
import DataTable, {
  ColumnsToggle,
  DropdownPortal,
  Portal,
} from '../../reusable/DataTable';
import { entityTypes } from '../../utils/constants';
import TpeView from '../Tpe/TpeView';
import {
  ModalLst,
  ModalLstUsers,
  ModalEditUsers,
  ModalEditStation,
} from '../Common/Modals';

export const ActionsStation = observer((props) => {
  const {
    row,
    toggleModalEditStation,
    toggleModalLst,
    toggleModalLstUsers,
    printCarteID,
    generateParamsTpe,
    isManager,
    isOperator,
    isReseau,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const rootSelector = document.getElementById('root');
  const div = document.createElement('div');
  div.className = 'h-0';
  let portalContainer = useRef(div).current;

  useEffect(() => {
    // let portalContainer = document.createElement("div");
    rootSelector.appendChild(portalContainer);
    return () => {
      rootSelector.removeChild(portalContainer);
    };
  }, []);

  return (
    <>
      <DropdownPortal
        id={'edit-drop'}
        isOpen={dropdownOpen}
        toggle={toggle}
        portalContainer={portalContainer}
      >
        <DropdownToggle
          tag='button'
          className='btn btn-xs btn-outline-fuel btn-action'
          data-toggle='dropdown'
          aria-expanded={dropdownOpen}
        >
          {' '}
          Actions
          <FontAwesomeIcon
            className='ml-2'
            icon={faCaretDown}
          ></FontAwesomeIcon>
        </DropdownToggle>
        <Portal target={portalContainer}>
          <DropdownMenu right className='p-0'>
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => toggleModalEditStation(row)}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Modifier cette station
              </DropdownItem>
            )}
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() =>
                toggleModalLst(
                  {
                    id: row.idcommercantFid,
                    name: row.NomCommercant,
                    type: entityTypes.station,
                  },
                  'tpe'
                )
              }
            >
              <FontAwesomeIcon
                icon={faCalculator}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Liste des TPE
            </DropdownItem>
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() =>
                toggleModalLst(
                  {
                    id: row.idcommercantFid,
                    name: row.NomCommercant,
                    type: entityTypes.station,
                  },
                  'clients'
                )
              }
            >
              <FontAwesomeIcon
                icon={faUsers}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Liste des clients
            </DropdownItem>
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => generateParamsTpe(row.idcommercantFid)}
              >
                <FontAwesomeIcon
                  icon={faCodeBranch}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Créer les fichiers .PAR
              </DropdownItem>
            )}
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() =>
                toggleModalLst(
                  {
                    id: row.idcommercantFid,
                    name: row.NomCommercant,
                    type: entityTypes.station,
                  },
                  'transacs'
                )
              }
            >
              <FontAwesomeIcon
                icon={faExpandArrowsAlt}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Liste des transactions
            </DropdownItem>
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() =>
                toggleModalLst(
                  {
                    id: row.idcommercantFid,
                    name: row.NomCommercant,
                    type: entityTypes.station,
                  },
                  'majs'
                )
              }
            >
              <FontAwesomeIcon
                icon={faRedo}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Liste des mises à jour
            </DropdownItem>
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() =>
                  toggleModalLstUsers({
                    idSta: row.idcommercantFid,
                    nomSta: row.NomCommercant,
                  })
                }
              >
                <FontAwesomeIcon
                  icon={faLock}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Droit d'accès utilisateurs
              </DropdownItem>
            )}
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() =>
                  printCarteID(row.idcommercantFid, row.NomCommercant)
                }
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Imprimer carte ID
              </DropdownItem>
            )}
          </DropdownMenu>
        </Portal>
      </DropdownPortal>
    </>
  );
});

const StationsView = observer((props) => {
  useEffect(() => {
    StationsStore.init();
  }, []);

  // const cols = toJS(StationsStore.props.columns).filter(item => !item.isNotDisplay)
  const stationTable = useTable({
    columns: StationsStore.props.columns,
    data: StationsStore.props.data,
    initialState: {
      hiddenColumns: StationsStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: StationsStore.props.pageIndex,
      pageSize: StationsStore.props.pageLimit,
      pageCount: StationsStore.props.pageCount,
      pageTotal: StationsStore.props.pageTotal,
    },
    delete: (row) => StationsStore.delete(row),
    toggleModalEditStation: (row) => StationsStore.toggleModalEditStation(row),
    toggleModalLst: (row, typeContent) =>
      StationsStore.toggleModalLst(row, typeContent),
    toggleModalLstUsers: (row) => StationsStore.toggleModalLstUsers(row),
    printCarteID: (idSta, nomStation) =>
      StationsStore.printCarteID(idSta, nomStation),
    generateParamsTpe: (idSta) => StationsStore.generateParamsTpe(idSta),
    isOperator: UserStore.isOperator(),
    isManager: UserStore.isManager(),
    isReseau: UserStore.isReseau(),
  });

  return (
    <div className='container-fluid mt-3'>
      {/* <h2 className="card-title">Liste des cartes</h2> */}
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={StationsStore.props.loading.status}
              message={StationsStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className='card'
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col-md-4 mb-3 d-flex align-items-end'>
                    <div className='row flex-grow-1'>
                      <div className='col-auto'>
                        <ColumnsToggle
                          columns={stationTable.allColumns}
                          className='btn btn-old btn-sm mx-1'
                          toggleColumn={(col) =>
                            StationsStore.toggleColumn(col)
                          }
                          // columnsFromTable={StationsStore.props.columnsFromTable}
                        ></ColumnsToggle>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 mb-3 d-flex align-items-end'>
                    <div className='row flex-grow-1'>
                      <label className='col-md-5' for=''>
                        Rechercher station :
                      </label>
                      <div className='col-md-7'>
                        <MyField
                          type='reactSelect'
                          // defaultValue={values['idSociete']}
                          list={StationsStore.props.listCommerces}
                          className='async-select'
                          onChange={(e) => StationsStore.setSearchStation(e)}
                          name='societe'
                          value={StationsStore.props.searchStation}
                        ></MyField>
                      </div>
                    </div>
                  </div>
                  {/* {UserStore.canDroitExport() && (
                    <div className="col-md-4 mb-3 align-items-end d-flex justify-content-end">
                      <Dropdown
                        isOpen={dropdownOpen}
                        className=" ml-auto"
                        toggle={toggle}
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-sm btn-old"
                          data-toggle="dropdown"
                          aria-expanded={dropdownOpen}
                        >
                          Actions
                          <FontAwesomeIcon
                            className="ml-2"
                            icon={faCaretDown}
                          ></FontAwesomeIcon>
                        </DropdownToggle>
                        <DropdownMenu right className="p-0">
                          <DropdownItem
                            className="dropdown-item btn"
                            onClick={() => StationsStore.exportClients()}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="mr-2 text-info"
                            ></FontAwesomeIcon>
                            Exporter
                          </DropdownItem>

                          <DropdownItem
                            className="dropdown-item"
                            onClick={() => {
                              StationsStore.editBlackList();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faList}
                              className="mr-2 text-secondary"
                            ></FontAwesomeIcon>
                            Générer la blacklist
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )} */}
                </div>
                <div className='row'>
                  <div className='col'>
                    <fieldset className='p-3 border rounded'>
                      <legend className='text-fid w-auto px-3 text-old'>
                        Liste des Stations
                      </legend>
                      <DataTable
                        headerGroups={stationTable.headerGroups}
                        getTableBodyProps={stationTable.getTableBodyProps}
                        onDoubleClickRow={(index, row) =>
                          StationsStore.toggleModalEditStation(row)
                        }
                        onClickRow={(index, row) =>
                          StationsStore.setCurrentStation(row)
                        }
                        rows={stationTable.rows}
                        prepareRow={stationTable.prepareRow}
                        getTableProps={stationTable.getTableProps}
                        columns={StationsStore.props.columns}
                        loadingMsg={StationsStore.props.loading.msg}
                        loadingStatus={StationsStore.props.loading.status}
                        prevPage={() => StationsStore.prevPage()}
                        nextPage={() => StationsStore.nextPage()}
                        setSort={(sortBy) => StationsStore.setSort(sortBy)}
                        sortOrder={StationsStore.props.sortOrder}
                        sortBy={StationsStore.props.sortBy}
                        goToPage={(page) => StationsStore.goToPage(page)}
                        pageCount={StationsStore.props.pageCount}
                        pageIndex={StationsStore.props.pageIndex}
                        pageLimit={StationsStore.props.pageLimit}
                        pageTotal={StationsStore.props.pageTotal}
                        maxHeight='30vh'
                        btnClassName='btn btn-outline-fuel btn-sm'
                      ></DataTable>
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <fieldset className='p-3 border rounded'>
                      <legend className='text-fid w-auto px-3 text-old'>
                        Liste des TPE de la station{' '}
                        {StationsStore.props.currentStation.name}
                      </legend>
                      <TpeView
                        embeded={true}
                        maxHeight={'30vh'}
                        idSta={StationsStore.props.currentStation.id}
                      ></TpeView>
                    </fieldset>
                  </div>
                </div>
                <ModalLst
                  currentEntity={StationsStore.props.currentStation}
                  typeContent={StationsStore.props.typeContent}
                  toggleModalLst={() => StationsStore.toggleModalLst()}
                  isOpenModalLst={StationsStore.props.isOpenModalLst}
                ></ModalLst>
                <ModalEditStation
                  initialValuesStation={
                    StationsStore.props.initialValuesStation
                  }
                  currentStation={StationsStore.props.currentStation}
                  toggleModalEditStation={() =>
                    StationsStore.toggleModalEditStation()
                  }
                  submitEditStation={(values) =>
                    StationsStore.submitEditStation(values)
                  }
                  isOpenModalEditStation={
                    StationsStore.props.isOpenModalEditStation
                  }
                ></ModalEditStation>
                <ModalLstUsers
                  loadingStatus={StationsStore.props.loading.status}
                  loadingMsg={StationsStore.props.loading.msg}
                  currentStation={StationsStore.props.currentStation}
                  isOpenModalLstUsers={StationsStore.props.isOpenModalLstUsers}
                  deleteUser={(id, sta) => StationsStore.deleteUser(id, sta)}
                  toggleModalLstUsers={() =>
                    StationsStore.toggleModalLstUsers()
                  }
                  columnsUsers={StationsStore.props.columnsUsers}
                  dataUsers={StationsStore.props.dataUsers}
                  pageIndexUsers={StationsStore.props.pageIndexUsers}
                  pageLimitUsers={StationsStore.props.pageLimitUsers}
                  pageCountUsers={StationsStore.props.pageCountUsers}
                  pageTotalUsers={StationsStore.props.pageTotalUsers}
                  nextPageUsers={() => StationsStore.nextPageUsers()}
                  prevPageUsers={() => StationsStore.prevPageUsers()}
                  goToPageUsers={(page) => StationsStore.goToPageUsers(page)}
                  setSortUsers={(val) => StationsStore.setSortUsers(val)}
                  toggleModalEditUsers={(row, mode) =>
                    StationsStore.toggleModalEditUsers(row, mode)
                  }
                  deleteUsers={(row) => StationsStore.deleteUsers(row)}
                ></ModalLstUsers>
                <ModalEditUsers
                  initialValuesUsers={StationsStore.props.initialValuesUsers}
                  loadingStatus={StationsStore.props.loading.status}
                  loadingMsg={StationsStore.props.loading.msg}
                  editModeUsers={StationsStore.props.editModeUsers}
                  currentUsers={StationsStore.props.currentUsers}
                  currentStation={StationsStore.props.currentStation}
                  isOpenModalEditUsers={
                    StationsStore.props.isOpenModalEditUsers
                  }
                  submitEditUsers={(values, idSta, mode, resetForm) =>
                    StationsStore.submitEditUsers(
                      values,
                      idSta,
                      mode,
                      resetForm
                    )
                  }
                  toggleModalEditUsers={(row) =>
                    StationsStore.toggleModalEditUsers(row)
                  }
                ></ModalEditUsers>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default StationsView;
