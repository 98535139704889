import React from 'react';
import { observable } from 'mobx';
import { entityTypes } from '../utils/constants';
import api from '../api';
import UserStore from './UserStore';
import modalAlert from '../components/Alerts/ModalAlert';
import { ActionsSociete } from '../components/Societes/SocietesView';

const props = {
  loading: {
    status: false,
    msg: 'Connexion en cours',
  },
  columns: [
    {
      Header: 'Partenaire',
      isSortable: true,
      accessor: 'NomPartenaire',
      isHidden: true,
    },
    {
      Header: 'ID',
      isSortable: true,
      accessor: 'id',
      isHidden: true,
    },
    {
      Header: 'Réseau',
      isSortable: true,
      accessor: 'NomReseau',
      isHidden: true,
    },
    {
      Header: 'Nom société',
      isSortable: true,
      accessor: 'nomSociete',
      field: 'nomSociete',
      isHidden: false,
    },
    {
      Header: 'Raison sociale',
      isSortable: true,
      accessor: 'raisonSociale',
      field: 'raisonSociale',
      isHidden: false,
    },
    {
      Header: 'Référence Interne',
      isSortable: true,
      accessor: 'refInterne',
      field: 'refInterne',
      isHidden: false,
    },
    {
      Header: 'Crédit',
      isSortable: true,
      accessor: 'Credit',
      field: 'Credit',
      isHidden: false,
    },
    {
      Header: 'Réf. Gouv. Local',
      isSortable: true,
      accessor: 'gvtRefNumber',
      field: 'gvtRefNumber',
      isHidden: false,
    },
    {
      Header: 'Prénom personne contact',
      isSortable: true,
      accessor: 'firstname_contact',
      field: 'firstname_contact',
      isHidden: false,
    },
    {
      Header: 'Nom personne contact',
      isSortable: true,
      accessor: 'lastname_contact',
      field: 'lastname_contact',
      isHidden: false,
    },
    {
      Header: 'Téléphone',
      isSortable: true,
      accessor: 'tel',
      field: 'tel',
      isHidden: false,
    },
    {
      Header: 'Email contact',
      isSortable: true,
      accessor: 'email',
      field: 'email',
      isHidden: false,
    },
    {
      Header: 'Adresse Société',
      isSortable: true,
      accessor: 'adresse',
      field: 'adresse',
      isHidden: false,
    },
    {
      Header: 'Ville',
      isSortable: true,
      accessor: 'ville',
      field: 'ville',
      isHidden: false,
    },
    {
      Header: 'Code Postale',
      isSortable: true,
      accessor: 'cp',
      isHidden: true,
    },
    {
      Header: 'Pays',
      isSortable: true,
      accessor: 'pays',
      isHidden: true,
    },
    {
      Header: 'Supprimée',
      isSortable: true,
      accessor: 'deleted',
      isHidden: false,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({
        row,
        toggleModalEditSociete,
        toggleModalLst,
        generateParamsTpe,
        isManager,
        isOperator,
        isReseau,
      }) => {
        return (
          <ActionsSociete
            row={row.original}
            toggleModalEditSociete={toggleModalEditSociete}
            toggleModalLst={toggleModalLst}
            generateParamsTpe={generateParamsTpe}
            isManager={isManager}
            isOperator={isOperator}
            isReseau={isReseau}
          ></ActionsSociete>
        );
      },
    },
  ],

  data: [],

  pageStart: 0,
  pageIndex: 0,
  pageLimit: 50,
  pageCount: 0,
  pageTotal: 0,

  sortBy: 'nomSociete,raisonSociale,Credit',
  sortOrder: 'ASC',

  typeContent: null,
  listSocietes: [],
  listGerants: [],
  searchSociete: { id: null, label: 'Toutes' },
  searchSocieteByGerant: { firstname: null, lastname: null, label: 'Tous' },

  currentSociete: {},

  isOpenModalLst: false,
  isOpenModalEditSociete: false,

  initialValuesSociete: {},
};

const SocietesStore = {
  /***** Props ******/
  props: props,

  /***** Methods ******/
  async init() {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    await this.getSocietes();
    await this.getSocietesFilter();
    this.doLoading(false);
  },
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  toggleModalLst(row, typeContent) {
    if (row) this.props.currentSociete = row;
    if (typeContent) this.props.typeContent = typeContent;
    this.props.isOpenModalLst = !this.props.isOpenModalLst;
  },
  setCurrentSociete(row) {
    this.props.currentSociete = {
      id: row.idsociete,
      name: row.nomSociete,
      type: entityTypes.societe,
    };
  },
  toggleModalCreateSociete() {
    let row = {
      idsociete: null,
      refInterne: '',
      Credit: '',
      nomSociete: '',
      raisonSociale: '',
      firstname_contact: '',
      lastname_contact: '',
      gvtRefNumber: '',
      tel: '',
      email: '',
      deleted: '',
      adresse: '',
      ville: '',
      cp: '',
      pays: '',
    };
    this.setCurrentSociete(row);
    this.toggleModalEditSociete(row);
  },
  toggleModalEditSociete(row) {
    if (row) {
      this.props.currentSociete = row;
      this.props.initialValuesSociete = {
        idsociete: row.idsociete,
        refInterne: row.refInterne,
        Credit: row.Credit,
        nomSociete: row.nomSociete,
        raisonSociale: row.raisonSociale,
        firstname_contact: row.firstname_contact,
        lastname_contact: row.lastname_contact,
        gvtRefNumber: row.gvtRefNumber,
        tel: row.tel,
        email: row.email,
        deleted: row.deleted,
        adresse: row.adresse,
        ville: row.ville,
        cp: row.cp,
        pays: row.pays,
      };
    }
    this.props.isOpenModalEditSociete = !this.props.isOpenModalEditSociete;
  },
  async submitEditSociete(values) {
    if (values.idsociete === null) {
      values.newRecord = true;
      values.idPartenaire = UserStore.props.user.idPart;
      values.idReseau = UserStore.props.user.idRes;
    }
    this.doLoading(true, 'Chargement en cours ...');
    this.toggleModalEditSociete();

    let datAdr = {};
    datAdr.adresse = values['adresse'];
    delete values['adresse'];
    datAdr.ville = values['ville'];
    delete values['ville'];
    datAdr.id_societe = values['idsociete'];
    datAdr.id = parseInt(values['id']);
    if (typeof datAdr.id === 'undefined') {
      datAdr.newRecord = true;
    }
    delete values['id'];
    datAdr.pays = values['pays'];
    delete values['pays'];
    datAdr.cp = values['cp'];
    delete values['cp'];

    let data = {
      cmd: 'saveRows',
      objName: 'listSociete',
      data: JSON.stringify([values]),
    };

    const res = await api.post('/scripts/societe/societe.php', data);
    if (res.data.success) {
      const resDatAdr = await api.post('/scripts/societe/societe.php', {
        cmd: 'saveRows',
        objName: 'adressesSociete',
        data: JSON.stringify([datAdr]),
      });
      if (resDatAdr.data.success) {
        modalAlert({
          title: 'Succès',
          typeAlert: 'success',
          text: 'Les informations ont bien été modifié',
        });
      } else {
        modalAlert({
          title: 'Erreur',
          typeAlert: 'error',
          text: 'Une erreur est survenue',
        });
      }
    } else {
      modalAlert({
        title: 'Erreur',
        typeAlert: 'error',
        text: 'Une erreur est survenue',
      });
    }

    await this.getSocietes();
    this.doLoading(false);
  },
  async setSearchSociete(val) {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.searchSociete = val;
    await this.getSocietes();
    this.doLoading(false);
  },
  async setSearchSocieteByGerant(val) {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.searchSocieteByGerant = val;
    await this.getSocietes();
    this.doLoading(false);
  },
  async getSocietesFilter() {
    const idRes = UserStore.props.user.idRes;
    let where = 's.idReseau = ' + idRes;

    this.props.sortBy = this.props.sortBy || 'nomSociete';
    this.props.sortOrder = this.props.sortOrder || 'ASC';

    let data = {
      objName: 'listeSocieteDuReseau',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/societe/societe.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.listSocietes = !res.data.rows
          ? []
          : res.data.rows.map((item) => ({
              id: item.idsociete,
              label: item.nomSociete,
            }));
        this.props.listSocietes.unshift({ id: null, label: 'Toutes' });
        this.props.listGerants = !res.data.rows
          ? []
          : res.data.rows.map((item, x) => ({
              id: x + 1,
              firstname: item.firstname_contact,
              lastname: item.lastname_contact,
              label:
                item.firstname_contact +
                ' ' +
                item.lastname_contact.toString().toUpperCase(),
            }));
        this.props.listGerants.unshift({
          firstname: null,
          lastname: null,
          label: 'Tous',
        });
      }
    }
  },
  async getSocietes() {
    const idRes = UserStore.props.user.idRes;
    let where = 's.idReseau = ' + idRes;

    this.props.sortBy = this.props.sortBy || 'nomSociete';
    this.props.sortOrder = this.props.sortOrder || 'ASC';
    if (this.props.searchSociete.id)
      where += '  AND s.idsociete = ' + this.props.searchSociete.id;

    if (this.props.searchSocieteByGerant.firstname) {
      where +=
        "  AND s.firstname_contact = '" +
        this.props.searchSocieteByGerant.firstname +
        "'";
      where +=
        "  AND s.lastname_contact = '" +
        this.props.searchSocieteByGerant.lastname +
        "'";
    }

    let data = {
      objName: 'listeSocieteDuReseau',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/societe/societe.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
  },
  //PAGINATION
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getSocietes();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getSocietes();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getSocietes();
    this.doLoading(false);
  },
  async setSort(value) {
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortBy != value) {
      this.props.sortBy = value;
      this.props.sortOrder = 'ASC';
    } else {
      if (this.props.sortOrder == 'ASC') this.props.sortOrder = 'DESC';
      else if (this.props.sortOrder == 'DESC') {
        this.props.sortOrder = false;
        this.props.sortBy = null;
      }
    }

    await this.getSocietes();
    this.doLoading(false);
  },
  reset() {
    this.props = props;
  },
};

export default observable(SocietesStore);
