import React from 'react';
import { observable, toJS } from 'mobx';
import { PME } from '../utils/constants';
import { Operators } from '../utils/constants';
import moment from 'moment';
import api from '../api';
import UserStore from './UserStore';
import { saveAs } from 'file-saver';

const props = {
  loading: {
    status: false,
    msg: 'Connexion en cours',
  },
  columns: [
    {
      Header: 'Date Insertion',
      isSortable: true,
      accessor: 'dateInsertion',
      // accessor: 'field',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Date Consommation',
      isSortable: true,
      accessor: 'dateTransaction',
      field: 'dateTransaction',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'N°',
      isSortable: true,
      accessor: 'NumeroIncrement',
      field: 'NumeroIncrement',
    },
    {
      Header: 'Type',
      isSortable: true,
      accessor: 'libelle_type_card_update',
      field: 'libelle_type_card_update',
      Cell: ({ cell: { value } }) => {
        return <b>{value}</b>;
      },
    },
    {
      Header: 'Statut',
      isSortable: true,
      accessor: 'statut',
      field: 'statut',
      Cell: ({ cell: { value } }) => {
        if (value == 0) return <span className='text-warning'>En attente</span>;
        else if (value == 1)
          return <span className='text-success'>Consommée</span>;
        else if (value == 2)
          return <span className='text-danger'>Périmée</span>;
        else return value;
      },
    },
    {
      Header: 'PME',
      accessor: 'NumeroPME',
      field: 'NumeroPME',
      isHidden: true,
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        return PME[value];
      },
    },
    {
      Header: 'Utilisateur',
      isSortable: true,
      accessor: 'NP',
      field: 'NP',
    },
    {
      Header: 'Origine',
      isSortable: true,
      accessor: 'libelle_origine',
      field: 'libelle_origine',
      isHidden: true,
    },
    {
      Header: 'Categorie',
      isSortable: true,
      accessor: 'libelle_type',
      field: 'libelle_type',
      isHidden: true,
    },
    {
      Header: 'Réseau',
      isSortable: true,
      accessor: 'NomReseau',
      field: 'NomReseau',
    },
    {
      Header: 'N° carte',
      isSortable: true,
      accessor: 'NumeroCarte',
      field: 'NumeroCarte',
      isHidden: false,
    },
    {
      Header: 'Station',
      isSortable: true,
      accessor: 'NomCommercant',
      field: 'NomCommercant',
      isHidden: false,
    },
    {
      Header: 'Montant',
      accessor: 'MontantRecharge',
      field: 'MontantRecharge',
      isSortable: false,
      Cell: ({ row, cell: { value } }) => {
        if (value != 0) {
          if (row.values.NumeroPME == 1) return value / 100 + ' PTS';
          else return value / 100 + ' ';
        } else {
          return '';
        }
      },
    },
    {
      Header: 'Nouveau PIN',
      isSortable: true,
      accessor: 'NouveauPin',
      field: 'NouveauPin',
    },
    {
      Header: ' Ticket à Annuler Identifiant ',
      isSortable: true,
      accessor: 'NumTicketAnnule',
      field: 'NumTicketAnnule',
    },
    {
      Header: " Ticket à Annuler / TPE d'origine ",
      isSortable: true,
      accessor: 'NumSerieTPEDuTicketAnnule',
      field: 'NumSerieTPEDuTicketAnnule',
    },
    {
      Header: " Ticket à Annuler / N° Station d'origine ",
      isSortable: true,
      accessor: 'NumStationTicketAnnule',
      field: 'NumStationTicketAnnule',
    },
    {
      Header: 'Date Début',
      isSortable: true,
      accessor: 'DateDebutRecharge',
      field: 'DateDebutRecharge',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Date Expiration',
      isSortable: true,
      accessor: 'DateFinRecharge',
      field: 'DateFinRecharge',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: ' Motif ',
      isSortable: true,
      accessor: 'motif',
      field: 'motif',
    },
  ],
  pattern: {
    idcommercantFid: {
      active: false,
      title: 'Stations',
      name: 'idcommercantFid',
      operator: {
        type: 'select',
        list: ['='].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'idcommercantFid',
        type: 'reactSelect',
        list: [
          { id: 1, label: '__--FILTRE NON CHARGE--__' },
          { id: 2, label: '__--FILTRE NON CHARGE--__' },
          { id: 3, label: '__--FILTRE NON CHARGE--__' },
          { id: 4, label: '__--FILTRE NON CHARGE--__' },
          { id: 5, label: '__--FILTRE NON CHARGE--__' },
          { id: 6, label: '__--FILTRE NON CHARGE--__' },
        ],
      },
      value: [
        {
          name: 'idcommercantFid',
          field: 'idcommercantFid',
          operator: '=',
          type: 'text',
          value: null,
        },
      ],
      defaultValue: {
        name: 'idcommercantFid',
        field: 'idcommercantFid',
        type: 'text',
        operator: '=',
        value: null,
      },
    },
    NumCarte: {
      active: false,
      quickSearch: true,
      title: 'N° de carte',
      name: 'NumCarte',
      operator: {
        type: 'select',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'NumCarte',
        type: 'text',
      },
      value: [
        {
          name: 'NumCarte',
          field: 'NumCarte',
          campField: 'NumCarte',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'NumCarte',
        field: 'NumCarte',
        campField: 'NumCarte',
        operator: 'like',
        type: 'text',
        value: '',
      },
    },
    statut: {
      active: false,
      quickSearch: true,
      title: 'Statut',
      name: 'statut',
      operator: {
        type: 'multiple',
        list: ['in'],
      },
      field: {
        name: 'statut',
        type: 'multiple',
        list: [
          { id: 0, label: 'En Attente' },
          { id: 1, label: 'Consommée' },
          { id: 2, label: 'Périmée' },
        ],
      },
      value: [
        {
          name: 'statut',
          field: 'statut',
          type: null,
          operator: 'in',
          value: [],
        },
      ],
      defaultValue: {
        name: 'statut',
        field: 'statut',
        type: null,
        operator: 'in',
        value: [],
      },
    },
    dateInsertion: {
      active: true,
      title: 'Date de transaction',
      canAddOr: false,
      name: 'dateInsertion',
      operator: {
        type: 'select',
        list: ['between'].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'dateInsertion',
        type: 'date',
      },
      value: [
        {
          name: 'dateInsertion',
          field: 'DATE(dateInsertion)',
          type: 'date',
          // custom: true,
          operator: 'between',
          // value: [
          //   moment().subtract('1', 'months').format('DD-MM-YYYY'),
          //   moment().format('DD-MM-YYYY'),
          // ],
          value: [
            moment().subtract('1', 'months').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ],
        },
      ],
      defaultValue: {
        name: 'dateInsertion',
        field: 'DATE(dateInsertion)',
        type: 'date',
        // custom: true,
        operator: 'between',
        value: [
          moment().subtract('1', 'months').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        // value: [
        //   moment().subtract('1', 'months').format('DD-MM-YYYY'),
        //   moment().format('DD-MM-YYYY'),
        // ],
      },
    },
  },
  data: [],
  listCommerces: [],
  savedPattern: {},

  pageStart: 0,
  pageIndex: 0,
  pageLimit: 50,
  pageCount: 0,
  pageTotal: 0,
  sortBy: 'NumeroIncrement',
  sortOrder: 'DESC',

  initialWhere: null,
};

const MajCardsStore = {
  /***** Props ******/
  props: props,

  /***** Methods ******/
  async init(idSta = null) {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    console.log('init');
    if (idSta) {
      this.props.initialWhere = ' IdCommercant=' + idSta + ' ';
      delete this.props.pattern['idcommercantFid'];
    } else {
      await this.getCommerces();
    }
    await this.initFilterMajs();
    await this.getMajs();
    this.doLoading(false);
  },
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  async getFilteredMajs() {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.pageStart = 0;
    this.props.pageIndex = 0;
    this.props.pageLimit = 30;
    await this.getMajs();
    this.doLoading(false);
  },
  async initFilterMajs(pattern) {
    if (pattern) this.props.pattern = pattern;
    this.props.savedPattern = toJS(this.props.pattern);
  },
  async getMajs() {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    this.props.sortBy = this.props.sortBy || 'NumeroIncrement';
    this.props.sortOrder = this.props.sortOrder || 'DESC';

    let where = '';

    if (this.props.initialWhere) where = this.getWhere(this.props.initialWhere);
    else if (UserStore.isReseau()) where = this.getWhere('idreseau = ' + idRes);
    else where = this.getWhere('IdCommercant =' + idCom);

    let data = {
      objName: 'listeRecharge',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrderLot) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/recharge/listeRecharge.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
  },
  async getCommerces() {
    const idRes = UserStore.props.user.idRes;
    let data = {
      sort: 'NomCommercant',
      dir: 'DESC',
      cmd: 'getRows',
      objName: 'listCommerce',
      where: 'idreseau=' + idRes,
    };

    const res = await api.post('/scripts/commerce/commercant.php', data);
    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;
      this.props.pattern.idcommercantFid.field.list = data.map((item) => ({
        id: item.id,
        label: item.NomCommercant,
      }));
      // this.props.pattern.idcommercantFid.field.list.unshift({ id: '', label: 'Tous' })

      this.props.listCommerces = this.props.pattern.idcommercantFid.field.list;

      if (!this.props.pattern.idcommercantFid.value.length)
        this.props.pattern.idcommercantFid.value.forEach((item) => {
          item.value = data.map((item) => item.idcommercantFid);
        });
    }
  },
  /************* EXPORT *************/
  async exportMajs() {
    this.doLoading(true, "Génération de l'export en cours ...");

    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';
    if (UserStore.isReseau())
      where = this.getWhere(
        'IdReseau = ' + idRes + ' AND carteTest not in (1)'
      );
    else
      where = this.getWhere(
        'idCommercant = ' + idCom + ' AND carteTest not in (1)'
      );

    const data = {
      cmd: 'exportCsv',
      wheres: JSON.stringify({
        objName: 'expCsvLstRecharges',
        where: where,
        modeF: 2,
      }),
      isCsv: 'true',
      data: undefined,
    };

    const res = await api.post('/scripts/export/exportscsv.php', data, {
      responseType: 'arraybuffer',
    });

    const file = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
    saveAs(file, 'export_majs.csv');

    this.doLoading(false);
  },
  //FILTRES
  toggleItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = !this.props.pattern[key].active;
  },
  activateItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = true;
  },
  changeOperator(key, val, index) {
    console.log(val);
    this.props.pattern[key].value[index].operator = val;
    if (val == 'between') {
      this.props.pattern[key].value[index].value = [null, null];
    } else {
      this.props.pattern[key].value[index].value = '';
    }
  },
  changeValue(key, val, index, order) {
    if (this.props.pattern[key].value[index].operator == 'between') {
      this.props.pattern[key].value[index].value[order] = val;
    } else if (
      this.props.pattern[key].value[index].operator == 'in' ||
      this.props.pattern[key].value[index].operator == 'inConcat'
    ) {
      if (val != 'all') {
        let position = this.props.pattern[key].value[index].value.findIndex(
          (o) => o == val
        );
        console.log('position :', position);
        if (position === -1)
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value,
            val,
          ];
        else
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value.filter(
              (item) => item != val
            ),
          ];
      } else {
        if (
          this.props.pattern[key].field.list.length !=
          this.props.pattern[key].value[index].value.length
        ) {
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].field.list.map((item) => item.id),
          ];
        } else {
          this.props.pattern[key].value[index].value = [];
        }
      }
    } else {
      this.props.pattern[key].value[index].value = val;
    }
    console.log(toJS(this.props.pattern[key].value[index].value));
  },
  addItemFilter(key, type) {
    let newVal = { ...this.props.pattern[key].defaultValue };
    newVal.logic = type;
    this.props.pattern[key].value = [...this.props.pattern[key].value, newVal];
  },
  removeItemFilter(key, idx) {
    this.props.pattern[key].value = [
      ...this.props.pattern[key].value.filter((item, index) => index != idx),
    ];
  },
  submitFilter() {
    this.props.savedPattern = toJS(this.props.pattern);
    // this.getCountActiveFilter()
  },
  resetFilter() {
    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].value = props.pattern[item].value;
      this.props.savedPattern[item].value = props.pattern[item].value;
      this.props.pattern[item].active = false;
      this.props.savedPattern[item].active = false;
    });
  },
  undoPattern() {
    this.props.pattern = toJS(this.props.savedPattern);
  },
  //PAGINATION
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getMajs();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getMajs();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getMajs();
    this.doLoading(false);
  },
  async setSort(value) {
    console.log('TEST', value);
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortBy != value) {
      this.props.sortBy = value;
      this.props.sortOrder = 'ASC';
    } else {
      if (this.props.sortOrder == 'ASC') this.props.sortOrder = 'DESC';
      else if (this.props.sortOrder == 'DESC') {
        this.props.sortOrder = false;
        this.props.sortBy = null;
      }
    }

    await this.getMajs();
    this.doLoading(false);
  },
  //
  getWhere(where = '', type = null) {
    const main = [
      ...Object.keys(this.props.pattern)
        .filter((item) => this.props.pattern[item].active)
        .map((item) => toJS(this.props.pattern[item].value)),
    ];

    console.log(where);
    for (let key in main) {
      if (main[key].isHidden) continue;
      if (where.length) {
        if (main[key].length) where += ' AND  ';
      } else where += ' ';
      let pass = 0;
      for (let id in main[key]) {
        if (pass) where += ' ' + main[key][id].logic + ' ';
        pass = 1;

        if (type == 'campaign')
          where += this.getClauseCampaign(main[key][id], main[key][id].custom);
        else if (type == 'export')
          where += this.getClauseExport(main[key][id], main[key][id].custom);
        else where += this.getClause(main[key][id], main[key][id].custom);
      }

      if (where.length) {
        if (main[key].length) where += '';
      }
    }

    return where;
  },
  getClause(clause, isCustom = false) {
    let where = '-+--+-+ ERR -++-+-+';
    if (clause.operator == 'in') {
      where = clause.field + ' IN (' + clause.value.join(',') + ') ';
      console.log(where);
    } else if (clause.operator == 'inConcat') {
      console.log(clause);
      where = '';
      for (let i = 0; i < clause.value.length; i++) {
        where += clause.value[i];
        if (i + 1 != clause.value.length) where += ' OR ';
      }
      console.log(where);
    } else if (clause.operator == 'between') {
      if (clause.type == 'date') {
        where =
          clause.field +
          " BETWEEN '" +
          clause.value[0] +
          "' AND '" +
          clause.value[1] +
          "' ";
      } else if (clause.type == 'number') {
        where =
          clause.field +
          ' BETWEEN ' +
          clause.value[0] +
          ' AND ' +
          clause.value[1] +
          ' ';
      } else {
        where = '__ERROR__1';
      }
    } else if (clause.operator == 'like' || clause.operator == 'not like') {
      if (clause.type == 'text') {
        where =
          clause.field + ' ' + clause.operator + " '%" + clause.value + "%' ";
      } else {
        where = '__ERROR__2';
      }
    } else if (
      clause.operator == '>' ||
      clause.operator == '<' ||
      clause.operator == '>=' ||
      clause.operator == '<=' ||
      clause.operator == '<>' ||
      clause.operator == '='
    ) {
      if (
        clause.type == 'date' ||
        clause.type == 'text' ||
        clause.type == 'select'
      ) {
        where =
          clause.field + ' ' + clause.operator + " '" + clause.value + "' ";
      } else if (clause.type == 'number') {
        where = clause.field + ' ' + clause.operator + ' ' + clause.value + ' ';
      } else {
        where = '__ERROR__3';
      }
    } else {
      where = '__ERROR__4';
    }
    if (isCustom)
      return clause.field.replace('_$$$_', where.replace(clause.field, ''));
    return where;
  },
  reset() {
    this.props = props;
  },
};

export default observable(MajCardsStore);
