import React from 'react'

export const BarLoader = () => {
    return (
        <div className="sk-wave">
            <div className="sk-rect sk-rect1"></div>
            <div className="sk-rect sk-rect2"></div>
            <div className="sk-rect sk-rect3"></div>
            <div className="sk-rect sk-rect4"></div>
            <div className="sk-rect sk-rect5"></div>
        </div>
    )
}

export const RingLoader = (props) => (
    <div class="lds-ring d-block mx-auto">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
)