import React, { useState, useRef, useEffect } from 'react';

const OPTInput = ({ length = 6, onComplete, isLoadingInputs }) => {
  const inputRef = useRef(Array(length).fill(null));
  const [OPT, setOPT] = useState(Array(length).fill(''));

  useEffect(() => {
    inputRef.current[0].focus();
  }, []);

  const handleChange = (input, index) => {
    const newPin = [...OPT];
    newPin[index] = input;
    setOPT(newPin);

    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1].focus();
    }

    if (input.length === 0 && index > 0) {
      inputRef.current[index - 1].focus();
    }

    if (newPin.every((pin) => pin !== '')) {
      onComplete(newPin.join(''));
    }
  };

  return (
    <div className='row row-cols-4 g-5 justify-content-center'>
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          ref={(ref) => (inputRef.current[index] = ref)}
          type='text'
          value={OPT[index]}
          onChange={(e) => handleChange(e.target.value, index)}
          maxLength='1'
          style={{
            width: '30px',
            height: '30px',
            textAlign: 'center',
            // margin: '0 5px',
            fontSize: '20px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            marginRight: index === length - 1 ? '0' : '10px',
            color: isLoadingInputs ? '#6c757d' : '#000',
            backgroundColor: isLoadingInputs ? '#e9ecef' : '#fff',
          }}
          disabled={isLoadingInputs}
        />
      ))}
    </div>
  );
};

export default OPTInput;
