import { observable } from 'mobx'

const props = {

    currentRoute: '',
    loading: {
        status: false,
        msg: 'Connexion en cours'
    },
}

const MainStore = {
    /***** Props ******/
    props : props,

    /***** Methods ******/
    setCurrentRoute(route){
        this.props.currentRoute = route
    },
    reset(){
        this.props = props
    }

}


export default observable(MainStore)