import React from 'react';
import { observable, toJS } from 'mobx';
import {
  Civilite,
  Operators,
  Segments,
  labelTransac,
} from '../utils/constants';
import moment from 'moment';
import api from '../api';
import UserStore from './UserStore';
import CardsStore from './CardsStore';
import { toast } from 'react-toastify';
import { number } from '../utils/numeral';
import ReactTooltip from 'react-tooltip';
import { saveAs } from 'file-saver';
import { ActionsTransac } from '../components/Transacs/TransacView';
import confirmation from '../components/Alerts/Confirmation';
import modalAlert from '../components/Alerts/ModalAlert';

const props = {
  loading: {
    status: false,
    msg: 'Chargement en cours',
  },
  columns: [
    {
      Header: 'Mode',
      accessor: 'ModeTransmission',
      Cell: ({ cell: { value } }) =>
        // console.log(value)
        parseInt(value) ? (
          <span
            style={{ fontSize: '0.7rem' }}
            className='text-success font-weight-bold'
          >
            Online
          </span>
        ) : (
          <span
            style={{ fontSize: '0.7rem', color: '#229046' }}
            className='font-weight-bold'
          >
            Offline
          </span>
        ),
    },
    {
      Header: 'Date/Heure',
      isSortable: true,
      accessor: 'DateTransaction',
      Cell: ({ cell: { value } }) => {
        // console.log(value)
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Client',
      isSortable: true,
      accessor: 'NP',
      field: 'NP',
      // isHidden: true
    },
    {
      Header: 'Catégorie',
      accessor: 'Label',
      field: 'Label',
      // isHidden: true
    },
    {
      Header: 'Type transaction',
      accessor: 'TypeTransac',
      field: 'TypeTransac',
      Cell: ({ cell: { value } }) => {
        // NumTransac = 16 AND NumTpe = 1 AND NumCarte = '00000000000000000'
        switch (parseInt(value)) {
          case 17:
            return <div class='bg-light   back-bon'>{labelTransac[value]}</div>;
          case 18:
            return <div class='bg-danger  back-bon'>{labelTransac[value]}</div>;
          case 19:
            return <div class='bg-danger  back-bon'>{labelTransac[value]}</div>;
          case 20:
            return <div class='bg-danger  back-bon'>{labelTransac[value]}</div>;
          case 21:
            return <div class='bg-danger  back-bon'>{labelTransac[value]}</div>;
          case 22:
            return <div class='bg-success back-bon'>{labelTransac[value]}</div>;
          case 23:
            return (
              <div class='bg-warning  back-bon'>{labelTransac[value]}</div>
            );
          case 24:
            return (
              <div class='bg-is-done  back-bon'>{labelTransac[value]}</div>
            );
          default:
            return labelTransac[value];
        }
      },
    },
    {
      Header: 'Utilisateur',
      accessor: 'NomUser',
      isSortable: true,
      field: 'NomUser',
      // isHidden: true
    },

    {
      Header: 'Société',
      accessor: 'nomSociete',
      isSortable: true,
      field: 'nomSociete',
    },
    {
      Header: 'Station',
      accessor: 'NomCommercant',
      isSortable: true,
      field: 'NomCommercant',
    },
    {
      Header: 'N° TPE',
      accessor: 'NumTpe',
      field: 'NumTpe',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'N° carte / Bon carburant',
      accessor: 'NumCarte',
      isSortable: true,
      field: 'NumCarte',
    },
    {
      Header: 'Immatriculation',
      accessor: 'Immatriculation',
      isSortable: true,
      field: 'Immatriculation',
    },
    {
      Header: 'N° Ticket',
      accessor: 'NumTicket',
      field: 'NumTicket',
      isHidden: false,
      Cell: ({ row, cell: { value } }) => {
        if (parseInt(row.original.NumTicketAnnule))
          return (
            <>
              <div
                data-tip={
                  'Ceci est une annulation du ticket #' +
                  row.original.NumTicketAnnule
                }
                className='is-ticket-annule'
              >
                {row.original.NumTicketAnnule}
              </div>
              <ReactTooltip />
            </>
          );
        if (parseInt(row.original.TransactionAnnulee) == 1)
          return (
            <>
              <div
                data-tip="Ticket en cours d'annulation"
                className='is-ticket-annule-in-progress'
              >
                {value}
              </div>
              <ReactTooltip />
            </>
          );
        if (parseInt(row.original.TransactionAnnulee) == 2)
          return (
            <>
              <div data-tip='Ticket annulé' className='is-ticket-annule-2'>
                {value}
              </div>
              <ReactTooltip />
            </>
          );

        return value;
      },
    },
    {
      Header: ({ column, setUnitsHeader }) => {
        return setUnitsHeader(column.id);
      },
      accessor: 'creditPme2',
      field: 'creditPme2',
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: ({ column, setUnitsHeader }) => {
        return setUnitsHeader(column.id);
      },
      accessor: 'debitPme2',
      field: 'debitPme2',
      Cell: ({ cell: { value } }) => number(value),
    },

    {
      Header: 'Transaction test',
      accessor: 'carteTest',
      field: 'carteTest',
      isHidden: true,
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        if (parseInt(value)) return 'Oui';

        return 'Non';
      },
    },
    {
      Header: '',
      accessor: 'annuler',
      field: '',
      Cell: ({
        toggleModalCancelTransac,
        toggleModalTreatFraude,
        row,
        isManager,
      }) => {
        return (
          <ActionsTransac
            row={row.original}
            isManager={isManager}
            toggleModalCancelTransac={toggleModalCancelTransac}
            toggleModalTreatFraude={toggleModalTreatFraude}
          ></ActionsTransac>
        );
      },
    },
  ],
  pattern: {
    idsociete: {
      active: false,
      title: 'Société',
      name: 'idsociete',
      operator: {
        type: 'select',
        list: ['='].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'idsociete',
        type: 'asyncSelect',
        list: [],
      },
      value: [
        {
          name: 'idsociete',
          field: 'idsociete',
          operator: '=',
          type: 'number',
          value: '',
        },
      ],
      defaultValue: {
        name: 'idsociete',
        field: 'idsociete',
        operator: '=',
        type: 'number',
        value: '',
      },
    },
    typeSupport: {
      active: false,
      quickSearch: true,
      title: 'Type support',
      name: 'typeSupport',
      operator: {
        type: 'multiple',
        list: ['inConcat'],
      },
      field: {
        name: 'typeSupport',
        type: 'multiple',
        list: [
          {
            id: 'TypeTransac  NOT IN(17,18,19,20,21,22,23,24)',
            label: 'Cartes',
          },
          {
            id: 'TypeTransac  IN(17,18,19,20,21,22,23,24)',
            label: 'Bons carburants',
          },
        ],
      },
      value: [
        {
          name: 'typeSupport',
          field: null,
          type: null,
          operator: 'inConcat',
          value: [],
        },
      ],
      defaultValue: {
        name: 'typeSupport',
        field: null,
        type: null,
        operator: 'inConcat',
        value: [],
      },
    },
    ModeTransmission: {
      active: false,
      quickSearch: true,
      title: 'Mode de transmission',
      name: 'ModeTransmission',
      operator: {
        type: 'multiple',
        list: ['inConcat'],
      },
      field: {
        name: 'ModeTransmission',
        type: 'multiple',
        list: [
          { id: 'ModeTransmission=0', label: 'Offline' },
          { id: 'ModeTransmission=1', label: 'Online' },
        ],
      },
      value: [
        {
          name: 'ModeTransmission',
          field: null,
          type: null,
          operator: 'inConcat',
          value: [],
        },
      ],
      defaultValue: {
        name: 'ModeTransmission',
        field: null,
        type: null,
        operator: 'inConcat',
        value: [],
      },
    },
    NumCarte: {
      active: false,
      quickSearch: true,
      title: 'N° de carte',
      name: 'NumCarte',
      operator: {
        type: 'select',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'NumCarte',
        type: 'text',
      },
      value: [
        {
          name: 'NumCarte',
          field: 'NumCarte',
          campField: 'NumCarte',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'NumCarte',
        field: 'NumCarte',
        campField: 'NumCarte',
        operator: 'like',
        type: 'text',
        value: '',
      },
    },
    NP: {
      active: false,
      quickSearch: true,
      title: 'Nom/Prénom',
      name: 'NP',
      operator: {
        type: 'select',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'NP',
        type: 'text',
      },
      value: [
        {
          name: 'NP',
          field: 'NP',
          campField: 'NP',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'NP',
        field: 'NP',
        campField: 'NP',
        operator: 'like',
        type: 'text',
        value: '',
      },
    },
    DateTransaction: {
      active: true,
      title: 'Date de transaction',
      canAddOr: false,
      name: 'DateTransaction',
      operator: {
        type: 'select',
        list: ['between'].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'DateTransaction',
        type: 'date',
      },
      value: [
        {
          name: 'DateTransaction',
          field: 'DATE(DateTransaction)',
          type: 'date',
          // custom: true,
          operator: 'between',
          value: [
            moment().subtract('1', 'months').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ],
        },
      ],
      defaultValue: {
        name: 'DateTransaction',
        field: 'DATE(DateTransaction)',
        type: 'date',
        // custom: true,
        operator: 'between',
        value: [
          moment().subtract('1', 'months').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
      },
    },
  },

  ticket: null,
  selectedRow: {},
  selectedRowFraude: {},
  data: [],
  pageStart: 0,
  pageIndex: 0,
  pageLimit: 30,
  pageTotal: 0,
  pageCount: 0,
  sortBy: 'DateTransaction',
  sortOrder: 'DESC',

  total_credit_pme1: 0,
  total_debit_pme1: 0,
  total_credit_pme2: 0,
  total_debit_pme2: 0,
  total_gain: null,
  countActiveFilter: 0,
  savedPattern: {},
  savedFilters: [],
  loadedFilter: null,
  isOpenModalTreatFraude: false,
  isOpenModalCancelTransac: false,

  initialWhere: null,
};

const TransacFilterStore = {
  props: props,
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },

  async init(filterCriteria = null) {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');

    if (
      typeof filterCriteria !== undefined &&
      filterCriteria !== '' &&
      filterCriteria != null
    ) {
      this.props.initialWhere = filterCriteria;
      if (filterCriteria.includes('idclientFid')) {
        console.log('TRERRE');
        delete this.props.pattern.NumCarte;
      }
    }

    if (UserStore.isReseau()) {
      this.props.pattern.idsociete.fetch = (value) => this.fetchSociete(value);
      this.props.pattern.idsociete.field.list = await this.fetchSociete();
      this.props.pattern.idsociete.field.list.unshift({
        id: '',
        label: 'Aucune',
      });
    } else {
      delete this.props.pattern.idcommercantFid;
    }

    await this.getTransacs();
    // await this.getSavedFilters()

    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].title = this.props.pattern[item].title.replace(
        '$$point$$',
        UserStore.props.user.unitesPME1
      );
      this.props.pattern[item].title = this.props.pattern[item].title.replace(
        '$$euro$$',
        UserStore.props.user.unitesPME2
      );
    });
    // await CardsStore.getClients()
    this.props.savedPattern = toJS(this.props.pattern);

    this.doLoading(false);
  },
  async getTransacs() {
    this.props.sortBy = this.props.sortBy;
    this.props.sortOrder = this.props.sortOrder;
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;
    const idSoc = UserStore.props.user.idSoc;

    let where = '';

    if (this.props.initialWhere)
      where = this.getWhere(
        this.props.initialWhere + ' AND TypeTransac not in (6,7) '
      );
    else if (UserStore.isReseau())
      where = this.getWhere(
        'iDreseauFid = ' + idRes + ' AND TypeTransac not in (6,7) '
      );
    else if (UserStore.isSociete())
      where = this.getWhere(
        'idSociete = ' + idSoc + ' AND TypeTransac not in (6,7) '
      );
    else
      where = this.getWhere(
        'iDreseauFid = ' +
          idRes +
          ' AND idcommercantFid = ' +
          idCom +
          ' AND TypeTransac not in (6,7) '
      );

    // let where = this.getWhere(mainWhere)
    let data = {
      objName: 'listeTransac',
      cmd: 'getRowsP',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/transac/transac.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );

        if (res.data.totSolde.length) {
          this.props.total_credit_pme1 = res.data.totSolde[0].c1.length
            ? res.data.totSolde[0].c1
            : 0;
          this.props.total_debit_pme1 = res.data.totSolde[0].d1.length
            ? res.data.totSolde[0].d1
            : 0;
          this.props.total_credit_pme2 = res.data.totSolde[0].c2.length
            ? res.data.totSolde[0].c2
            : 0;
          this.props.total_debit_pme2 = res.data.totSolde[0].d2.length
            ? res.data.totSolde[0].d2
            : 0;
          this.props.total_gain = res.data.totSolde[0].Gain.length
            ? res.data.totSolde[0].Gain
            : 0;
        }
      } else {
        this.props.data = [];
      }
    }
    // this.props.loading.status = false
  },
  async fetchSociete(value = '') {
    const idRes = UserStore.props.user.idRes;
    // const idPart = UserStore.props.user.idPart

    let data = {
      sort: 'nomSociete',
      json: true,
      dir: 'ASC',
      cmd: 'GetByCriteria',
      callFromClient: true,
      objName: 'listCommerce',
      where: ' idReseau =' + idRes + ' and deleted = 0',
      sort: 'nomSociete',
      fields: JSON.stringify(['nomSociete']),
      query: value,
    };

    const res = await api.post('/scripts/societe/Services.Societes.php', data);
    let listSocietes = !res.data.rows ? [] : res.data.rows;
    return new Promise((resolve) =>
      resolve(
        listSocietes.map((item) => ({
          value: item.idsociete,
          label: item.nomSociete,
        }))
      )
    );
  },
  async getFilteredTransacs() {
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTransacs();
    this.doLoading(false);
  },
  getQuickSerachItems() {
    let results = [];
    Object.keys(this.props.pattern)
      .filter((item) => this.props.pattern[item].quickSearch)
      .map((item) => {
        results.push({
          id: this.props.pattern[item].name,
          label: this.props.pattern[item].title,
        });
      });

    return results;
  },
  async exportCsvListTransac() {
    this.doLoading(true, "Génération de l'export en cours ...");

    // const modeFid = UserStore.props.user.mode
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';
    if (this.props.initialWhere)
      where = this.getWhere(
        this.props.initialWhere + ' AND TypeTransac not in (6,7) '
      );
    else if (UserStore.isReseau())
      where = this.getWhere(
        'iDreseauFid = ' + idRes + ' AND TypeTransac not in (6,7) '
      );
    else
      where = this.getWhere(
        'iDreseauFid = ' +
          idRes +
          ' AND idcommercantFid = ' +
          idCom +
          ' AND TypeTransac not in (6,7) '
      );

    const data = {
      cmd: 'exportCsvLstTransac',
      wheres: JSON.stringify({
        objName: 'expCsvLsttransac',
        where: where,
        modeF: 2,
        typeBonusPME1: 0,
        typeBonusPME2: 0,
        NomUnitePME1: UserStore.props.user.unitesPME1court || '',
        NomUnitePME2: UserStore.props.user.unitesPME2court || '',
      }),
      isCsv: true,
      data: undefined,
    };

    const res = await api.post('/scripts/export/exportscsv.php', data, {
      responseType: 'arraybuffer',
    });

    const file = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
    saveAs(file, 'export_transac.csv');

    this.doLoading(false);
  },
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTransacs();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTransacs();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTransacs();
    this.doLoading(false);
  },
  toggleModalCancelTransac(row = false) {
    if (row) {
      this.props.selectedRow = row;
    }
    this.props.isOpenModalCancelTransac = !this.props.isOpenModalCancelTransac;
  },
  toggleModalTreatFraude(row = false) {
    if (row) {
      this.props.selectedRowFraude = row;
    }
    this.props.isOpenModalTreatFraude = !this.props.isOpenModalTreatFraude;
  },
  async cancelTransac(row, motif) {
    this.doLoading(true, 'Chargement en cours ...');
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Annulation ticket ' + row.NumTicket,
      text: 'Cette opération est irréversible,êtes-vous certain de vouloir annuler le ticket # 200620073406?',
    });

    if (!next) return;

    const idUser = UserStore.props.user.idUser;

    let data = {
      cmd: 'cancelTransaction',
      data: JSON.stringify([
        {
          NumTicket: row.NumTicket,
          NumReseau: row.NumReseau,
          NumCommercant: row.NumCommercant,
          NumTpe: row.NumTpe,
          DateTransac: row.DateTransaction,
          NumCarte: row.NumCarte,
          motif: motif,
          id: idUser,
        },
      ]),
    };

    const res = await api.post('/scripts/transac/transac.php', data);

    if (res.data.success) {
      // toast.success('La modification a bien été effectué')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La modification a bien été effectué',
      });
    } else {
      toast.error(<>{'Une erreur est survenue'}</>);
    }
    await this.getTransacs();
    this.doLoading(false);
  },
  async changeBonStatut(action, row) {
    let next = false;
    let data = {};
    const idUser = UserStore.props.user.idUser;

    if (action == 'validate') {
      next = await confirmation({
        type: 'confirmNoPass',
        title: 'Valider la transaction suspecte',
        text:
          '<br>Ticket: ' +
          row.NumTicket +
          '<br><br> Bon Carburant:' +
          row.NumCarte +
          " <br><br><p> En cas de suspicion de fraude, la responsabilité financière relève de la 1ère station qui était en charge d'invalider le bon utilisé.<br><br> <p><b>La règle qui va s'applique est la suivante: <p><br> La station (" +
          row.NomCommercant +
          ') qui a scanné ce bon carburant sera remboursée.<br><br><p>La 1ère station (' +
          row.BonCarburantNomCommercant +
          ') qui avait validé ce bon carburant sera prélevée.</b> <br><br> Cette opération est irréversible,<br> êtes-vous certain de vouloir appliquer cette règle ? ',
      });

      data = {
        cmd: 'validateTransaction',
        data: JSON.stringify([
          {
            NumTicket: row.NumTicket,
            NumReseau: row.NumReseau,
            NumCommercant: row.NumCommercant,
            NumTpe: row.NumTpe,
            DateTransac: row.DateTransaction,
            NumBonCarburant: row.NumCarte,
            BonCarburantNumCommercant: row.BonCarburantNumCommercant,
            //motif: Ext.getCmp('processTrans_motif').getValue(),
            UserId: idUser,
            TypeTransac: row.TypeTransac,
          },
        ]),
      };
    } else if (action == 'reject') {
      next = await confirmation({
        type: 'confirmNoPass',
        title: 'Rejeter la transaction suspecte',
        text:
          '<br>Ticket: ' +
          row.NumTicket +
          '<br><br> Bon Carburant:' +
          row.NumCarte +
          ' <br><br><p> <b> La station  (' +
          row.NomCommercant +
          ') qui a scanné ce bon carburant ne sera pas remboursée.</b> <br><br> Cette opération est irréversible, voulez vous continuer ? ',
      });

      data = {
        cmd: 'rejectTransaction',
        data: JSON.stringify([
          {
            NumTicket: row.NumTicket,
            NumReseau: row.NumReseau,
            NumCommercant: row.NumCommercant,
            NumTpe: row.NumTpe,
            DateTransac: row.DateTransaction,
            NumBonCarburant: row.NumCarte,
            //motif: Ext.getCmp('processTrans_motif').getValue(),
            UserId: idUser,
            TypeTransac: row.TypeTransac,
          },
        ]),
      };
    } else return toast.danger('Action impossible');

    if (!next) return;

    this.toggleModalTreatFraude();

    this.doLoading(true, 'Chargement en cours ...');

    const res = await api.post('/scripts/transac/transac.php', data);

    if (res.data.success) {
      // toast.success('La modification a bien été effectué')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La modification a bien été effectué',
      });
    } else {
      toast.error(<>{'Une erreur est survenue'}</>);
    }

    await this.getTransacs();
    this.doLoading(false);
  },
  toggleItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = !this.props.pattern[key].active;
  },
  activateItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = true;
  },
  changeOperator(key, val, index) {
    console.log(val);
    this.props.pattern[key].value[index].operator = val;
    if (val == 'between') {
      this.props.pattern[key].value[index].value = [null, null];
    } else {
      this.props.pattern[key].value[index].value = '';
    }
  },
  changeValue(key, val, index, order) {
    if (this.props.pattern[key].value[index].operator == 'between') {
      this.props.pattern[key].value[index].value[order] = val;
    } else if (
      this.props.pattern[key].value[index].operator == 'in' ||
      this.props.pattern[key].value[index].operator == 'inConcat'
    ) {
      if (val != 'all') {
        let position = this.props.pattern[key].value[index].value.findIndex(
          (o) => o == val
        );
        console.log('position :', position);
        if (position === -1)
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value,
            val,
          ];
        else
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value.filter(
              (item) => item != val
            ),
          ];
      } else {
        if (
          this.props.pattern[key].field.list.length !=
          this.props.pattern[key].value[index].value.length
        ) {
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].field.list.map((item) => item.id),
          ];
        } else {
          this.props.pattern[key].value[index].value = [];
        }
      }
    } else {
      this.props.pattern[key].value[index].value = val;
    }
    console.log(toJS(this.props.pattern[key].value[index].value));
  },
  addItemFilter(key, type) {
    let newVal = { ...this.props.pattern[key].defaultValue };
    newVal.logic = type;
    this.props.pattern[key].value = [...this.props.pattern[key].value, newVal];
  },
  removeItemFilter(key, idx) {
    this.props.pattern[key].value = [
      ...this.props.pattern[key].value.filter((item, index) => index != idx),
    ];
  },
  submitFilter() {
    this.props.savedPattern = toJS(this.props.pattern);
    this.getCountActiveFilter();
  },
  setUnitsHeader(colId) {
    if (colId == 'Pme1')
      return 'Solde (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'Pme2')
      return 'Solde (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'cumDebPme2')
      return 'Debits (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'cumRech2')
      return 'Cumul Recharge (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'cumCredPme1')
      return 'Crédits (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'cumCredPme2')
      return 'Crédits (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'cumRech1')
      return 'Cumul Recharge (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'cumSpot')
      return 'Gains (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'cumBonus2')
      return 'Bonus (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'cumBonus1')
      return 'Bonus (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'bonus1')
      return 'Bonus (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'bonus2')
      return 'Bonus (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'cumDebPme1')
      return 'Debits (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'SPOT')
      return 'Gains (' + UserStore.props.user.unitesPME2court + ')';
    //-----//
    if (colId == 'NouveauPME1')
      return 'Solde (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'NouveauPME2')
      return 'Solde (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'creditPme1')
      return 'Crédit (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'debitPme1')
      return 'Débit (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'creditPme2')
      return 'Crédit (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'debitPme2')
      return 'Débit (' + UserStore.props.user.unitesPME2court + ')';
    if (colId == 'Expiration_Pme1')
      return 'Expiration (' + UserStore.props.user.unitesPME1court + ')';
    if (colId == 'Expiration_Pme2')
      return 'Expiration (' + UserStore.props.user.unitesPME2court + ')';

    return 'Vide';
  },
  resetFilter() {
    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].value = props.pattern[item].value;
      this.props.savedPattern[item].value = props.pattern[item].value;
      this.props.pattern[item].active = false;
      this.props.savedPattern[item].active = false;
    });

    this.activateItemFilter('DateTransaction');
  },
  undoPattern() {
    this.props.pattern = toJS(this.props.savedPattern);
  },
  getWhere(where = '') {
    const main = [
      ...Object.keys(this.props.pattern)
        .filter((item) => this.props.pattern[item].active)
        .map((item) => toJS(this.props.pattern[item].value)),
    ];

    console.log(main);
    for (let key in main) {
      if (main[key].isHidden) continue;
      if (where.length) {
        if (main[key].length) where += ' AND ( ';
      } else where += '( ';
      let pass = 0;
      for (let id in main[key]) {
        if (pass) where += ' ' + main[key][id].logic + ' ';
        pass = 1;

        where += this.getClause(main[key][id], main[key][id].custom);
      }

      if (where.length) {
        if (main[key].length) where += ')';
      }
    }

    return where;
  },
  getClause(clause, isCustom = false) {
    let where = '-+--+-+ ERR -++-+-+';
    if (clause.operator == 'in') {
      where = clause.field + ' IN (' + clause.value.join(',') + ') ';
      console.log(where);
    } else if (clause.operator == 'inConcat') {
      console.log(clause);
      where = '';
      for (let i = 0; i < clause.value.length; i++) {
        where += clause.value[i];
        if (i + 1 != clause.value.length) where += ' OR ';
      }
      console.log(where);
    } else if (clause.operator == 'between') {
      if (clause.type == 'date') {
        where =
          clause.field +
          " BETWEEN '" +
          clause.value[0] +
          "' AND '" +
          clause.value[1] +
          "' ";
      } else if (clause.type == 'number') {
        where =
          clause.field +
          ' BETWEEN ' +
          clause.value[0] +
          ' AND ' +
          clause.value[1] +
          ' ';
      } else {
        where = '__ERROR__1';
      }
    } else if (clause.operator == 'like' || clause.operator == 'not like') {
      if (clause.type == 'text') {
        where =
          clause.field + ' ' + clause.operator + " '%" + clause.value + "%' ";
      } else {
        where = '__ERROR__2';
      }
    } else if (
      clause.operator == '>' ||
      clause.operator == '<' ||
      clause.operator == '>=' ||
      clause.operator == '<=' ||
      clause.operator == '<>' ||
      clause.operator == '='
    ) {
      if (
        clause.type == 'date' ||
        clause.type == 'text' ||
        clause.type == 'select'
      ) {
        where =
          clause.field + ' ' + clause.operator + " '" + clause.value + "' ";
      } else if (clause.type == 'number') {
        where = clause.field + ' ' + clause.operator + ' ' + clause.value + ' ';
      } else {
        where = '__ERROR__3';
      }
    } else {
      where = '__ERROR__4';
    }
    if (isCustom)
      return clause.field.replace('_$$$_', where.replace(clause.field, ''));
    return where;
  },
  async getEnseignes() {
    const idRes = UserStore.props.user.idRes;
    let data = {
      sort: 'NomCommercant',
      dir: 'DESC',
      cmd: 'getRows',
      objName: 'listeCommercants',
      where: 'iDreseauFid=' + idRes,
    };

    const res = await api.post('/scripts/commerce/commercant.php', data);
    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;
      this.props.pattern.idcommercantFid.field.list = data.map((item) => ({
        id: item.idcommercantFid,
        label: item.NomCommercant,
      }));

      if (!this.props.pattern.idcommercantFid.value.length)
        this.props.pattern.idcommercantFid.value.forEach((item) => {
          item.value = data.map((item) => item.idcommercantFid);
        });
    }
  },
  getCountActiveFilter() {
    this.props.countActiveFilter = Object.keys(this.props.pattern).filter(
      (item) => this.props.pattern[item].active
    ).length;
  },
  //FILTER ACTIONS
  getCurrentFilterJson() {
    let filter = {};

    Object.keys(this.props.pattern)
      .filter((item) => this.props.pattern[item].active)
      .map((item) => {
        filter[item] = this.props.pattern[item].value;
      });

    return toJS(filter);
  },
  async setSort(value) {
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortBy != value) {
      this.props.sortBy = value;
      this.props.sortOrder = 'ASC';
    } else {
      if (this.props.sortOrder == 'ASC') this.props.sortOrder = 'DESC';
      else if (this.props.sortOrder == 'DESC') {
        this.props.sortOrder = false;
        this.props.sortBy = null;
      }
    }

    await this.getTransacs();
    this.doLoading(false);
    // console.log(value, this.props.sortOrder)
  },
  reset() {
    this.props = props;
  },
};

export default observable(TransacFilterStore);
