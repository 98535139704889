import React from 'react';
import { observable } from 'mobx';
import { entityTypes } from '../utils/constants';
import api from '../api';
import UserStore from './UserStore';
import confirmation from '../components/Alerts/Confirmation';
import { toast } from 'react-toastify';
import modalAlert from '../components/Alerts/ModalAlert';
import { ActionsStation } from '../components/Stations/StationsView';

const props = {
  loading: {
    status: false,
    msg: 'Connexion en cours',
  },
  columns: [
    {
      Header: 'N° ',
      isSortable: true,
      accessor: 'NumCommercant',
    },
    {
      Header: 'Nom de la station',
      isSortable: true,
      accessor: 'NomCommercant',
    },
    {
      Header: 'Statut',
      isSortable: true,
      accessor: 'statutStation',
      Cell: ({ row, cell: { value } }) =>
        parseInt(value) == 1 ? (
          <span
            style={{ fontSize: '0.8rem' }}
            className='text-success font-weight-bold'
          >
            Active
          </span>
        ) : (
          <span
            style={{ fontSize: '0.8rem' }}
            className='text-danger font-weight-bold'
          >
            Fermée
          </span>
        ),
    },
    {
      Header: 'Adresse station',
      accessor: 'ComAdresse1',
    },
    {
      Header: 'Ville Station',
      accessor: 'ComVille',
      isHidden: true,
    },
    {
      Header: 'NOM du Gérant',
      accessor: 'ComNomContact',
    },
    {
      Header: 'Prénom du Gérant',
      accessor: 'ComPrenomContact',
    },
    {
      Header: 'Tél Gérant',
      accessor: 'ComTelContact',
      isHidden: true,
    },
    {
      Header: 'Cell Gérant',
      accessor: 'ComGsmContact',
      isHidden: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({
        row,
        canCreationTPE,
        toggleModalEditStation,
        toggleModalLst,
        toggleModalLstTransac,
        toggleModalLstMajs,
        toggleModalLstUsers,
        printCarteID,
        generateParamsTpe,
        isManager,
        isOperator,
        isReseau,
      }) => {
        return (
          <ActionsStation
            row={row.original}
            toggleModalEditStation={toggleModalEditStation}
            toggleModalLst={toggleModalLst}
            toggleModalLstUsers={toggleModalLstUsers}
            printCarteID={printCarteID}
            generateParamsTpe={generateParamsTpe}
            isManager={isManager}
            isOperator={isOperator}
            isReseau={isReseau}
          ></ActionsStation>
        );
      },
    },
  ],
  columnsUsers: [
    {
      Header: 'Nom utilisateur',
      field: 'userName',
      isSortable: true,
      accessor: 'userName',
    },
    {
      Header: 'code utilisateur ',
      field: 'userCode',
      isSortable: true,
      accessor: 'userCode',
    },
    {
      Header: '',
      accessor: 'edit',
      Cell: ({ toggleModalEditUsers, deleteUsers, cell: { value }, row }) => (
        <>
          <button
            className='btn btn-xs btn-fuel mx-1'
            onClick={() => toggleModalEditUsers(row.original, 'edit')}
          >
            Modifier
          </button>
          <button
            className='btn btn-xs btn-danger mx-1'
            onClick={() => deleteUsers(row.original)}
          >
            Supprimer
          </button>
        </>
      ),
    },
  ],

  data: [],
  dataUsers: [],

  pageStart: 0,
  pageIndex: 0,
  pageLimit: 50,
  pageCount: 0,
  pageTotal: 0,

  sortBy: 'NomCommercant',
  sortOrder: 'DESC',

  //Users
  pageStartUsers: 0,
  pageIndexUsers: 0,
  pageLimitUsers: 50,
  pageCountUsers: 0,
  pageTotalUsers: 0,

  sortByUsers: 'userName',
  sortOrderUsers: 'DESC',

  typeContent: null,
  listCommerces: [],
  searchStation: { id: null, label: 'Toutes' },

  currentSoc: {},
  currentStation: {},
  currentUsers: {},

  isOpenModalLst: false,
  isOpenModalEditStation: false,
  isOpenModalEditUsers: false,

  initialValuesStation: {},
  initialValuesUsers: {},

  editModeUsers: 'new',
};

const StationsStore = {
  /***** Props ******/
  props: props,

  /***** Methods ******/
  async init() {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    console.log('init');
    await this.getCommerces();
    await this.getStations();
    this.doLoading(false);
  },
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  toggleModalLst(row, typeContent) {
    // console.log(typeContent, row)
    if (row) this.props.currentStation = row;
    if (typeContent) this.props.typeContent = typeContent;
    this.props.isOpenModalLst = !this.props.isOpenModalLst;
  },
  setCurrentStation(row) {
    console.log(row);
    this.props.currentStation = {
      id: row.idcommercantFid,
      name: row.NomCommercant,
      type: entityTypes.station,
    };
  },
  async deleteUsers(row) {
    const next = await confirmation({
      type: 'ouiNon',
      title: 'Confirmation suppression',
      typeAlert: 'question',
      text:
        'Voulez-vous supprimer cet utilisateur : (<b>' +
        row.userName +
        '</b>),<br> Confirmez-vous cette suppression ?',
    });

    if (!next) return;

    this.doLoading(true, 'Chargement en cours ...');

    let data = {
      cmd: 'DelRows',
      ref: 'delUsers',
      objName: 'usersListe',
      where: 'userLstId =' + row.userLstId,
      data: JSON.stringify([{ userLstId: row.userLstId }]),
    };

    const res = await api.post('/scripts/commerce/users.php', data);
    if (res.data.success) {
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: "L'utilisateur a bien été supprimé",
      });
    } else {
      modalAlert({
        title: 'Erreur',
        typeAlert: 'error',
        text: 'Une erreur est survenue',
      });
    }

    await this.getTpetUsers();
    this.doLoading(false);
  },
  async toggleModalLstUsers(row) {
    // console.log(row)
    if (row) {
      this.props.currentStation = row;
    }
    this.props.isOpenModalLstUsers = !this.props.isOpenModalLstUsers;
    await this.getTpetUsers();
  },
  async toggleModalEditUsers(row, mode) {
    // console.log(row)
    this.props.editModeUsers = mode;
    if (row) {
      this.props.currentUsers = row;
      this.props.initialValuesUsers = row;
    } else {
      this.props.currentUsers = {};
      this.props.initialValuesUsers = {};
    }
    this.props.isOpenModalEditUsers = !this.props.isOpenModalEditUsers;
    await this.getTpetUsers();
  },
  toggleModalEditStation(row, mode) {
    console.log(row);
    if (row) {
      this.props.currentStation = row;
      this.props.initialValuesStation = {
        idcommercantFid: row.idcommercantFid,
        //Civilite
        ComContactCivilite: row.ComContactCivilite,
        statutStation: row.statutStation,
        ComNomContact: row.ComNomContact,
        ComPrenomContact: row.ComPrenomContact,
        ComFonctionContact: row.ComFonctionContact,
        ComGsmContact: row.ComGsmContact,
        ComTelContact: row.ComTelContact,
        ComEmailContact: row.ComEmailContact,
        //Infos Station
        NomCommercant: row.NomCommercant,
        ComNomExpediteur: row.ComNomExpediteur,
        NumCommercant: row.NumCommercant,
        refInterneStation: row.refInterneStation,
        ComAdresse1: row.ComAdresse1,
        ComAdresse2: row.ComAdresse2,
        ComCodePostal: row.ComCodePostal,
        ComVille: row.ComVille,
        //Entete ticket
        comEnteteTicket1: row.comEnteteTicket1,
        comEnteteTicket2: row.comEnteteTicket2,
        comEnteteTicket3: row.comEnteteTicket3,
        comEnteteTicket4: row.comEnteteTicket4,
        comEnteteTicket5: row.comEnteteTicket5,
        comEnteteTicket6: row.comEnteteTicket6,
        comEnteteTicket7: row.comEnteteTicket7,
        comEnteteTicket8: row.comEnteteTicket8,
        //Bas de ticket
        comBasTicket1: row.comBasTicket1,
        comBasTicket2: row.comBasTicket2,
        comBasTicket3: row.comBasTicket3,
        comBasTicket4: row.comBasTicket4,
        comBasTicket5: row.comBasTicket5,
        comBasTicket6: row.comBasTicket6,
        comBasTicket7: row.comBasTicket7,
      };
    }
    this.props.isOpenModalEditStation = !this.props.isOpenModalEditStation;
  },
  async submitEditStation(values) {
    this.doLoading(true, 'Chargement en cours ...');
    this.toggleModalEditStation();

    let nomExpediteur = values['ComNomExpediteur'];
    delete values['ComNomExpediteur'];

    let data = {
      cmd: 'saveRows',
      objName: 'lesCommercants',
      ref: 'SaveEnseigne',
      data: JSON.stringify([values]),
      comptesms: JSON.stringify([{ nomExpediteur: nomExpediteur }]),
    };

    const res = await api.post('/scripts/commerce/commercant.php', data);

    if (res.data.success) {
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'Les informations ont bien été modifié',
      });
    } else {
      modalAlert({
        title: 'Erreur',
        typeAlert: 'error',
        text: 'Une erreur est survenue',
      });
    }

    await this.getStations();
    this.doLoading(false);
  },
  async setSearchStation(val) {
    this.doLoading(true, 'Chargement en cours ...');
    console.log(val);
    this.props.searchStation = val;
    await this.getStations();
    this.doLoading(false);
  },
  async getStations() {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;
    let where = '';

    this.props.sortBy = this.props.sortBy || 'NomCommercant';
    this.props.sortOrder = this.props.sortOrder || 'DESC';
    if (UserStore.isReseau()) where = 'iDreseauFid=' + idRes;
    else where = 'idcommercantFid=' + idCom;

    if (this.props.searchStation.id)
      where += '  AND idcommercantFid = ' + this.props.searchStation.id;

    let data = {
      objName: 'listeCommercants',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/commerce/commercant.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
  },
  async getTpetUsers() {
    // const idRes = UserStore.props.user.idRes
    // const idCom = UserStore.props.user.idCom

    this.props.sortByUsers = this.props.sortByUsers || 'userName';
    this.props.sortOrderUsers = this.props.sortOrderUsers || 'DESC';

    let data = {
      start: this.props.pageStartUsers,
      limit: this.props.pageLimitUsers,
      dir: 'DESC',
      cmd: 'getRows',
      objName: 'usersListe',
      where: 'idCom=' + this.props.currentStation.id,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortByUsers) data['sort'] = this.props.sortByUsers;

    if (this.props.sortOrderUsers) data['dir'] = this.props.sortOrderUsers;

    const res = await api.post('/scripts/commerce/users.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.dataUsers = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotalUsers = res.data.totalCount;
        this.props.pageCountUsers = Math.ceil(
          res.data.totalCount / this.props.pageLimitUsers
        );
      } else {
        this.props.dataUsers = [];
      }
    }
  },
  async submitEditUsers(values, idSta, mode, resetForm) {
    this.toggleModalEditUsers();
    this.doLoading(true, 'Chargement en cours ...');

    if (mode == 'new') {
      values['newRecord'] = true;
      values['idCom'] = idSta;
    }

    let data = {
      cmd: 'saveRows',
      objName: 'usersListe',
      ref: 'saveUsers',
      data: JSON.stringify([values]),
    };

    const res = await api.post('/scripts/commerce/users.php', data);
    if (res.data.success) {
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text:
          mode == 'new'
            ? "L'utilisateur a bien été créée"
            : "L'utilisateur a bien été modifié",
      });

      await this.generateParamsUsersTpe(idSta);
    } else {
      modalAlert({
        title: 'Erreur',
        typeAlert: 'error',
        text: 'Une erreur est survenue',
      });
    }

    resetForm();

    await this.getTpetUsers();
    this.doLoading(false);
  },
  async generateParamsTpe(idSta) {
    this.doLoading(true, 'Chargement en cours ...');
    let data = {
      cmd: 'exportParams',
      ref: 'expParams',
      idS: idSta,
      idx: 1,
    };

    const res = await api.post('/scripts/export/ExportParamsTPE.php', data);
    if (res.data.success) {
      // toast.success(<>Export FUELPASS.PAR<br />{res.data.error}</>);
      modalAlert({
        title: 'Export FUELPASS.PAR',
        typeAlert: 'success',
        text: res.data.error,
      });
    }
    // toast.error("Une erreur est survenue lors de la génération du fichier .PAR");
    else
      modalAlert({
        title: 'Export FUELPASS.PAR',
        typeAlert: 'error',
        text: 'Une erreur est survenue lors de la génération du fichier .PAR',
      });
    this.doLoading(false);
  },
  async getCommerces() {
    const idRes = UserStore.props.user.idRes;
    let data = {
      sort: 'NomCommercant',
      dir: 'ASC',
      cmd: 'getRows',
      objName: 'listCommerce',
      where: 'iDreseauFid=' + idRes,
    };

    const res = await api.post('/scripts/commerce/commercant.php', data);
    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;

      this.props.listCommerces = data.map((item) => ({
        id: item.id,
        label: item.NomCommercant,
      }));
      this.props.listCommerces.unshift({ id: null, label: 'Toutes' });
    }
  },
  async printCarteID(idSta, nomStation) {
    const idRes = UserStore.props.user.idRes;
    let data = {
      type: 'IdCard',
      idStation: idSta,
      station: nomStation,
    };

    const res = await api.get('/printCard.php', { params: data });
    console.log(res);
    if (res.request.status == 200) {
      const access_token = UserStore.props.access_token;
      window.open(
        process.env['REACT_APP_API_URL'] +
          '/cards/station_' +
          idSta +
          '.pdf?access_token=' +
          access_token,
        'mywindow',
        'menubar=1,resizable=1,width=440,height=290,toolbar=0,scrollbars=0,resizable=0,status=0,location=0,menubar=0'
      );
    } else {
      modalAlert({
        typeAlert: 'error',
        title: 'Erreur',
        text: 'Echec Impression',
      });
    }
  },
  async generateParamsUsersTpe(idSta) {
    // this.doLoading(true,'Chargement en cours ...')
    let data = {
      cmd: 'exportUserLst',
      ref: 'expUserLst',
      idS: idSta,
      idx: 1,
    };

    const res = await api.post('/scripts/export/ExportUserList.php', data);
    if (res.data.success) {
      toast.success(<>{res.data.error}</>);
    } else
      toast.error(
        'Une erreur est survenue lors de la génération du fichier .USER'
      );
    // this.doLoading(false)
  },
  //PAGINATION
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getStations();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getStations();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getStations();
    this.doLoading(false);
  },
  async setSort(value) {
    console.log('TEST', value);
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortBy != value) {
      this.props.sortBy = value;
      this.props.sortOrder = 'ASC';
    } else {
      if (this.props.sortOrder == 'ASC') this.props.sortOrder = 'DESC';
      else if (this.props.sortOrder == 'DESC') {
        this.props.sortOrder = false;
        this.props.sortBy = null;
      }
    }

    await this.getStations();
    this.doLoading(false);
  },
  //PAGINATION USERS
  async nextPageUsers() {
    this.props.pageIndexUsers = this.props.pageIndexUsers + 1;
    this.props.pageStartUsers =
      this.props.pageIndexUsers * this.props.pageLimitUsers;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTpetUsers();
    this.doLoading(false);
  },
  async prevPageUsers() {
    this.props.pageIndexUsers = this.props.pageIndexUsers - 1;
    this.props.pageStartUsers =
      this.props.pageIndexUsers * this.props.pageLimitUsers;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTpetUsers();
    this.doLoading(false);
  },
  async goToPageUsers(page) {
    this.props.pageIndexUsers = page;
    this.props.pageStartUsers =
      this.props.pageIndexUsers * this.props.pageLimitUsers;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTpetUsers();
    this.doLoading(false);
  },
  async setSortUsers(value) {
    console.log('TEST', value);
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortByUsers != value) {
      this.props.sortByUsers = value;
      this.props.sortOrderUsers = 'ASC';
    } else {
      if (this.props.sortOrderUsers == 'ASC')
        this.props.sortOrderUsers = 'DESC';
      else if (this.props.sortOrderUsers == 'DESC') {
        this.props.sortOrderUsers = false;
        this.props.sortByUsers = null;
      }
    }

    await this.getTpetUsers();
    this.doLoading(false);
  },
  reset() {
    this.props = props;
  },
};

export default observable(StationsStore);
