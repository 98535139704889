import * as yup from 'yup';
import moment from 'moment';

const myYup = yup;

myYup.setLocale({
  mixed: {
    default: 'Champ incorrect',
    required: 'Ce champ est requis',
  },
  number: {
    default: 'La valeur doit être un nombre',
    min: 'La valeur doit être supérieur ou égale à ${min}',
    max: 'La valeur doit être inférieur ou égale à ${max}',
    integer: 'La valeur doit être un nombre entier',
  },
  string: {
    default: 'La valeur doit être une cahîne de caratère',
    min: '${min} caractères minimum',
    max: '${max} caractères maximum',
    email: 'Email non valide',
  },
});

myYup.addMethod(myYup.string, 'expiration', function test(value) {
  return this.test('expiration', 'Date invalide', function (value) {
    return moment(value, 'MM/YY').isValid();
  });
});

myYup.addMethod(myYup.number, 'notZero', function test(value) {
  return this.test(
    'notZero',
    'Le nombre doit être différent de 0',
    function (value) {
      return value != 0;
    }
  );
});

myYup.addMethod(myYup.string, 'date', function test(value) {
  return this.test('date', 'Date invalide', function (value) {
    return moment(value).isValid();
  });
});

myYup.addMethod(myYup.string, 'recurrent', function test(value) {
  return this.test('recurrent', 'Ce champs est requis', function (value) {
    console.log(value);
    return value != 'Non';
  });
});

myYup.addMethod(myYup.string, 'dateIsGreater', function test(value) {
  return this.test(
    'dateIsGreater',
    'La date de fin doit être supérieur à la date de début',
    function (value) {
      return moment(value).isSameOrAfter(moment(this.parent.DateDebutRecharge));
    }
  );
});

myYup.addMethod(myYup.string, 'maxDaysDiff', function test(diff) {
  return this.test(
    'maxDaysDiff',
    'Le nombre de jours entre la date de début et la date de fin ne doit pas être supérieur à ' +
      diff,
    function (value) {
      return moment(value).diff(moment(this.parent.startDate), 'days') <= diff;
    }
  );
});

myYup.addMethod(myYup.string, 'rib', function test() {
  return this.test('rib', 'N° de RIB invalide', function (rib) {
    if (rib != null) {
      if (rib.length > 0) {
        if (rib.length < 21 || rib.length > 23) {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }

    var ribRegEx = '';
    if (rib.length == 21) {
      ribRegEx = /*new RegExp*/ /(\d{5})(\d{5})(\w{11})/;
    } else if (rib.length == 23) {
      ribRegEx = /*new RegExp*/ /(\d{5})(\d{5})(\w{11})(\d{2})/;
    } else {
      return false;
    }

    var ribSplit = ribRegEx.exec(rib);
    if (ribSplit == null) {
      return false;
    }

    let cle = null;

    let code_banque = ribSplit[1];
    let code_guichet = ribSplit[2];
    let numero_compte = ribSplit[3];
    if (ribSplit.length > 4) {
      cle = ribSplit[4];
    }

    let bqe = code_banque;
    let gui = code_guichet;
    let cpt = numero_compte.toUpperCase();
    let tab = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let tab1 = '123456789123456789234567890123456789'.split('');
    while (cpt.match(/\D/) != null)
      cpt = cpt.replace(/\D/, tab1[tab.indexOf(cpt.match(/\D/))]);
    let cp = parseInt(cpt, 10);

    let valCle = bqe % 97;
    valCle = valCle * 100000 + parseInt(gui, 10);
    valCle = valCle % 97;
    valCle = valCle * Math.pow(10, 11) + cp;
    valCle = valCle % 97;
    valCle = valCle * 100;
    valCle = valCle % 97;
    valCle = 97 - valCle;
    if (ribSplit.length > 4) {
      //        console.log("clé: " + valCle);
      if (cle == valCle) {
        return true;
      } else {
        return false;
      }
    } else {
      //        console.log("valeur clé: " + valCle);
      return true;
    }
  });
});

export default myYup;
