import React, { useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faFilter,
  faColumns,
  faCaretUp,
  faCheck,
  faSync,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import {
  Collapse,
  PopoverHeader,
  PopoverBody,
  Popover,
  Dropdown,
} from 'reactstrap';
import { observer } from 'mobx-react';
import { createPortal } from 'react-dom';

const DataTable = (props) => {
  const inputPageRef = useRef();

  const {
    selectedRow,
    onDoubleClickRow,
    onClickRow,
    tableClassName,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    hidePaginationLabel,
    prevPage,
    nextPage,
    goToPage,
    pageCount,
    pageIndex,
    pageLimit,
    pageTotal,
    sortBy,
    sortOrder,
    setSort,
    maxHeight,
    btnClassName,
    headerClassName,
    hidePagination,
  } = props;

  const doDoubleClick = (index, row) => {
    if (onDoubleClickRow) onDoubleClickRow(index, row);
  };

  const doClick = (index, row) => {
    if (onClickRow) onClickRow(index, row);
  };

  useEffect(() => {
    if (inputPageRef.current) inputPageRef.current.value = pageIndex + 1;
  }, [pageIndex]);

  const handleSort = (column) =>
    column.isSortable ? setSort(column.field) : null;

  return (
    <>
      {rows.length == 0 && (
        <div className='row'>
          <div
            className='col d-flex flex-column center-v center-h'
            style={{ minHeight: maxHeight }}
          >
            {/* <FontAwesomeIcon icon={faFrown} size="7x" className="no-data-color"></FontAwesomeIcon> */}
            <h5 className='no-data-color mt-3'>
              Il n'y a aucune donnée à afficher
            </h5>
          </div>
        </div>
      )}
      {rows.length > 0 && (
        <SimpleBar style={{ maxHeight: maxHeight }} className='border rounded'>
          <table
            className={
              'datatable table font-sm table-sm table-hover text-nowrap w-100 ' +
              tableClassName
            }
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className={headerClassName}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        onClick={() => handleSort(column)}
                        sortby={column.field}
                        className={classnames('text-wrap', {
                          'c-pointer': column.isSortable,
                        })}
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                        <span>
                          {column.field &&
                            column.field == sortBy &&
                            sortOrder == 'DESC' && (
                              <FontAwesomeIcon
                                className='ml-2 text-old-light'
                                icon={faCaretDown}
                              ></FontAwesomeIcon>
                            )}
                          {column.field &&
                            column.field == sortBy &&
                            sortOrder == 'ASC' && (
                              <FontAwesomeIcon
                                className='ml-2 text-old-light'
                                icon={faCaretUp}
                              ></FontAwesomeIcon>
                            )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);

                return (
                  <tr
                    {...row.getRowProps()}
                    onDoubleClick={() => {
                      doDoubleClick(row.id, row.original);
                    }}
                    onClick={() => {
                      doClick(row.id, row.original);
                    }}
                    // onClick={() => {setSelectedRow(row.id)}}
                    className={classnames('d-cursor', {
                      selected: selectedRow == row.id,
                    })}
                  >
                    {row.cells.map((cell) => {
                      return typeof cell.value === 'boolean' ? (
                        <td>
                          <div className='w-100 text-center'>
                            <FontAwesomeIcon
                              icon={cell.value ? faCheck : faTimes}
                            ></FontAwesomeIcon>
                          </div>
                        </td>
                      ) : typeof cell.value === 'object' ? (
                        <td>{cell.value.label}</td>
                      ) : (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SimpleBar>
      )}
      {!hidePagination && (
        <div className='row mt-3 font-sm'>
          <div className='col-md-7 col-sm-12'>
            <div className='row col align-items-center'>
              <div className='col-auto px-0'>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    prevPage();
                  }}
                  disabled={pageIndex + 1 <= 1}
                  className={btnClassName}
                >
                  <FontAwesomeIcon
                    className='mr-2'
                    icon={faChevronLeft}
                  ></FontAwesomeIcon>
                  <span
                    className={classnames({ 'd-none': hidePaginationLabel })}
                  >
                    Préc.
                  </span>
                </button>
              </div>
              <div className='col-auto d-flex align-items-center justify-content-center'>
                <span className='mr-2'>Page </span>
                <input
                  type='number'
                  ref={inputPageRef}
                  className='form-control form-control-sm'
                  style={{ width: '50px' }}
                  defaultValue={pageIndex + 1}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      goToPage(page);
                    }
                  }}
                />
                <span className='ml-2'>sur {pageCount}</span>
              </div>
              <div className='col-auto px-0'>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    nextPage();
                  }}
                  disabled={pageIndex + 1 == pageCount}
                  className={btnClassName}
                >
                  <span
                    className={classnames({ 'd-none': hidePaginationLabel })}
                  >
                    Suiv.
                  </span>
                  <FontAwesomeIcon
                    className='ml-2'
                    icon={faChevronRight}
                  ></FontAwesomeIcon>
                </button>
              </div>
              <div className='col-auto ml-1'>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    goToPage(0);
                  }}
                  className={btnClassName}
                >
                  <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                </button>
              </div>
            </div>
          </div>
          <div className='col-md-5 col-sm-12 d-flex justify-content-end'>
            <div className=''>
              {/* Résultats {pageIndex * pageLimit + 1}-
              {pageIndex * pageLimit + pageLimit}
              {' sur '} {pageTotal} {' élément(s)'} */}
              Résultats{' '}
              {pageTotal > 0
                ? pageIndex * (pageTotal < pageLimit ? pageTotal : pageLimit) +
                  1
                : pageTotal}
              -
              {pageTotal < pageLimit ||
              pageTotal < pageIndex * pageLimit + pageLimit
                ? pageTotal
                : pageIndex * pageLimit + pageLimit}{' '}
              sur {pageTotal} élément(s)
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const FilterItem = observer((props) => {
  const {
    item,
    close,
    updateList,
    setFilterValue,
    toggleItemFilter,
    setFilterOperator,
    toggleSubItem,
    index,
  } = props;
  const toggle = () => toggleItemFilter(index);
  const toggleMultiple = (e) =>
    toggleSubItem(index, e.target.getAttribute('index'));
  const handleChange = (e) => setFilterValue(index, e.target.value);
  const handleChangeOperator = (e) => setFilterOperator(index, e.target.value);
  const onPressEnter = (e) => {
    if (e.key === 'Enter') {
      handleChange(e);
      updateList();
      close();
    }
  };
  return (
    <>
      <div className='col-12 p-0'>
        <hr className='m-0' />
      </div>
      <div className='col-12 pb-0 pt-0 pr-0 my-2'>
        <div className='pl-0 form-check abc-checkbox abc-checkbox-fid col-12 pr-0'>
          <input
            id={item.id}
            checked={item.active}
            onClick={toggle}
            className='form-check-input d-none'
            type='checkbox'
          />
          <label for={item.id} className='form-check-label d-flex w-100'>
            {item.name}
          </label>
        </div>
        <Collapse isOpen={item.active}>
          <div className='form-group row mb-1'>
            {item.typeCompare == 'text' && (
              <>
                <label className='col-4 col-form-label'>{item.label} : </label>
                <div className='col'>
                  <input
                    type='text'
                    onChange={handleChange}
                    onKeyDown={onPressEnter}
                    value={item.value}
                    className='form-control form-control-sm'
                  ></input>
                </div>
              </>
            )}
            {item.typeCompare == 'number' && (
              <>
                <label className='col-4 col-form-label'>{item.label} : </label>
                <div className='col'>
                  <select
                    className='form-control  form-control-sm'
                    value={item.operator}
                    onChange={handleChangeOperator}
                  >
                    <option value='='>égale à</option>
                    <option value='>'>supérieur à</option>
                    <option value='<'>inférieur à</option>
                  </select>
                </div>
                <div className='col-4'>
                  <input
                    type='number'
                    onChange={handleChange}
                    onKeyDown={onPressEnter}
                    value={item.value}
                    className='form-control form-control-sm'
                  ></input>
                </div>
              </>
            )}
            {item.typeCompare == 'select' && (
              <>
                <label className='col-4 col-form-label'>{item.label} : </label>
                <div className='col'>
                  <select
                    className='form-control  form-control-sm'
                    value={item.value}
                    onChange={handleChange}
                  >
                    {item.options.map((obj) => (
                      <option value={obj.id}>{obj.label}</option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {item.typeCompare == 'multiple' && (
              <div className='row pl-4 mt-3'>
                {item.choices.map((obj, subIndex) => (
                  <div className='form-check abc-checkbox abc-checkbox-primary col-6 mb-2'>
                    <input
                      id={obj.id}
                      checked={obj.checked}
                      onClick={toggleMultiple}
                      index={subIndex}
                      className='form-check-input d-none'
                      type='checkbox'
                    />
                    <label
                      for={obj.id}
                      className='form-check-label d-flex w-100'
                    >
                      {obj.label}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </>
  );
});

export const Filter = observer((props) => {
  const {
    getFilteredClients,
    columnsToFilter,
    setFilterValue,
    setFilterOperator,
    toggleItemFilter,
    resetFilter,
    toggleSubItem,
    className,
  } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <>
      {popoverOpen && (
        <Portal container={document.body}>
          <div class='modal-backdrop fade show'></div>
        </Portal>
      )}
      <div className='ml-2'>
        <button id='btn-filter' className={className}>
          <FontAwesomeIcon icon={faFilter} className='mr-2'></FontAwesomeIcon>
          Filtre
        </button>
        <Popover
          popperClassName='popover-filter'
          placement='bottom'
          trigger='legacy'
          target='btn-filter'
          isOpen={popoverOpen}
          toggle={toggle}
        >
          <PopoverHeader className='d-flex w-100 align-items-center justify-content-center'>
            <div className='col'>
              <button
                onClick={() => {
                  resetFilter();
                }}
                className='py-0 pl-2 btn-sm btn btn-fuel float-left'
              >
                Effacer
              </button>
            </div>
            <div className='col text-center'>
              <span>Filtre</span>
            </div>
            <div className='col'>
              <button
                onClick={() => {
                  getFilteredClients();
                  toggle();
                }}
                className='py-0 pl-2  btn-sm btn btn-fuel float-right'
              >
                Valider
              </button>
            </div>
          </PopoverHeader>
          <PopoverBody>
            <div className='row m-0'>
              {columnsToFilter.map(
                (item, index) =>
                  !item.isHidden && (
                    <FilterItem
                      item={item}
                      index={index}
                      setFilterOperator={setFilterOperator}
                      toggleItemFilter={toggleItemFilter}
                      setFilterValue={setFilterValue}
                      toggleSubItem={toggleSubItem}
                      updateList={getFilteredClients}
                      close={toggle}
                    ></FilterItem>
                  )
              )}
            </div>
          </PopoverBody>
        </Popover>
      </div>
    </>
  );
});

export const ColumnsToggle = observer((props) => {
  const { columns, className, id } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  return (
    <div className=''>
      <button id={'btn-column' + id} className={className}>
        <FontAwesomeIcon icon={faColumns} className='mr-2'></FontAwesomeIcon>
        Colonnes
      </button>
      <Popover
        popperClassName='popover-columns'
        trigger='legacy'
        placement='bottom'
        target={'btn-column' + id}
        isOpen={popoverOpen}
        toggle={toggle}
      >
        <PopoverHeader>Affichage des colonnes</PopoverHeader>
        <PopoverBody>
          <div className='row m-0'>
            {columns
              .filter((item) => !item.isNotDisplay)
              .map(
                (column) =>
                  column.id != 'actions' && (
                    <div
                      key={column.id}
                      className='form-check abc-checkbox abc-checkbox-primary col-6'
                    >
                      <input
                        id={column.id}
                        {...column.getToggleHiddenProps()}
                        className='form-check-input'
                        type='checkbox'
                      />
                      <label for={column.id} className='form-check-label'>
                        {column.render('Header')}
                      </label>
                    </div>
                  )
              )}
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
});

export class DropdownPortal extends Dropdown {
  handleDocumentClick(e) {
    if (e && (e.which === 3 || (e.type === 'keyup' && e.which !== 'Tab')))
      return;
    const container = this.getContainer();

    const { portalContainer } = this.props;

    if (
      portalContainer.contains(e.target) &&
      portalContainer !== e.target &&
      (e.type !== 'keyup' || e.which === 'Tab')
    ) {
      return;
    }

    if (
      container.contains(e.target) &&
      container !== e.target &&
      (e.type !== 'keyup' || e.which === 'Tab')
    ) {
      return;
    }

    this.toggle(e);
  }
}

export class Portal extends React.Component {
  render() {
    const { children, target = document.body } = this.props;

    return createPortal(children, target);
  }
}

export const TemplateSet = observer((props) => {
  const {
    rows,
    prepareRow,
    setPreviewSaved,
    editModele,
    prevPage,
    nextPage,
    goToPage,
    pageCount,
    pageIndex,
    maxHeight,
    hidePaginationLabel,
    btnClassName,
  } = props;

  const inputPageRef = useRef();
  useEffect(() => {
    if (inputPageRef.current) inputPageRef.current.value = pageIndex + 1;
  }, [pageIndex]);

  return (
    <>
      <SimpleBar style={{ maxHeight: maxHeight }} className='mt-2  rounded'>
        <div className='row'>
          {rows.map((row, i) => {
            prepareRow(row);

            let preview =
              row.values.preview && row.values.preview.includes('https://')
                ? row.values.preview
                : process.env['REACT_APP_API_URL'] +
                  'img/thumb_modeles/' +
                  row.values.preview;

            return (
              <div className='col-md-4 mb-2'>
                <div class='rounded border'>
                  <img
                    alt={process.env['REACT_APP_API_URL']}
                    class='card-img-top'
                    src={preview}
                    onError={(e) =>
                      (e.target.src =
                        process.env['REACT_APP_API_URL'] +
                        '/img/no_image_campaign.png')
                    }
                  />
                  <div class='card-body p-2 d-flex flex-column'>
                    <h5 class='card-title'>{row.values.nom_modele}</h5>
                    <div className='d-flex center-h'>
                      <button
                        onClick={() =>
                          setPreviewSaved(row.values.json, row.values.contenu)
                        }
                        className='btn btn-sm btn-fuel mr-1'
                      >
                        Aperçu
                      </button>
                      {/* <button
                                                onClick={() => useModele(row.values.json, row.values.contenu)}
                                                class="btn btn-sm btn-fuel mx-2">
                                                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                                            </button> */}
                      <button
                        onClick={() =>
                          editModele(
                            row.values.json,
                            row.values.contenu,
                            row.values.editorType
                          )
                        }
                        className='btn btn-sm btn-fuel ml-1'
                      >
                        Utiliser
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </SimpleBar>
      <div className='d-flex center-h pt-2'>
        <div className='col-auto px-0'>
          <button
            onClick={(e) => {
              e.preventDefault();
              prevPage();
            }}
            disabled={pageIndex + 1 <= 1}
            className={btnClassName}
          >
            <FontAwesomeIcon
              className='mr-2'
              icon={faChevronLeft}
            ></FontAwesomeIcon>
            <span className={classnames({ 'd-none': hidePaginationLabel })}>
              Préc.
            </span>
          </button>
        </div>
        <div className='col-auto d-flex align-items-center justify-content-center'>
          <span className='mr-2'>Page </span>
          <input
            type='number'
            ref={inputPageRef}
            className='form-control form-control-sm'
            style={{ width: '50px' }}
            defaultValue={pageIndex + 1}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                goToPage(page);
              }
            }}
          />
          <span className='ml-2'>sur {pageCount}</span>
        </div>
        <div className='col-auto px-0'>
          <button
            onClick={(e) => {
              e.preventDefault();
              nextPage();
            }}
            disabled={pageIndex + 1 == pageCount}
            className={btnClassName}
          >
            <span className={classnames({ 'd-none': hidePaginationLabel })}>
              Suiv.
            </span>
            <FontAwesomeIcon
              className='ml-2'
              icon={faChevronRight}
            ></FontAwesomeIcon>
          </button>
        </div>
        <div className='col-auto ml-1'>
          <button
            onClick={(e) => {
              e.preventDefault();
              goToPage(0);
            }}
            className={btnClassName}
          >
            <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </>
  );
});

export default DataTable;
