import React, { useState, useEffect } from 'react';
import MultiFactorImg from '../../assets/img/two-factor-authentication-2fa-icon.svg';
import UserStore from '../../stores/UserStore';
import { useHistory } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { observer } from 'mobx-react';

import { BarLoader } from '../Loader/Loading';
import { toast } from 'react-toastify';
import OPTInput from '../../reusable/OPTInput';

const MultiFactorView = () => {
  const history = useHistory();

  const activated2FA = localStorage.getItem('fuelpass_2fa');
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoadingInputs, setIsLoadingInputs] = useState(false);

  async function onComplete(OPT) {
    if (!OPT) return toast.error('Veuillez saisir le code de sécurité');

    if (UserStore.props.user.user_id) {
      setIsLoadingInputs(true);
      await UserStore.checkOTP(
        { one_time_password: OPT, user_id: UserStore.props.user.user_id },
        () => {
          UserStore.add2FAToLocalStorage();
          history.push('/dashboard');
        }
      );
      setIsLoadingInputs(false);
    } else {
      toast.error('Une erreur est survenue, veuillez vous reconnecter.');
      return history.push('/login');
    }
  }

  useEffect(() => {
    if (activated2FA && activated2FA === '1') {
      return history.push('/dashboard');
    }

    UserStore.verifyIf2FAIsActivated(() => {
      UserStore.add2FAToLocalStorage();
      history.push('/dashboard');
    });
  }, [history, activated2FA]);

  return (
    <div className='app flex-row align-items-center'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-5'>
            {UserStore.props.check2FAOPT && (
              <BlockUi
                tag='div'
                className='card-group rounded login-loader'
                loader={<BarLoader></BarLoader>}
                message={UserStore.props.loading.msg}
                blocking={UserStore.props.loading.status}
              >
                <div className='card p-4'>
                  <div className='card-body'>
                    <div className='text-center'>
                      <img
                        className='login-image'
                        style={{ width: '75px', marginBottom: '20px' }}
                        src={MultiFactorImg}
                        alt='2FA Icon'
                      />
                    </div>
                    <p className='text-muted text-center'>
                      Pour continuer vous devez saisir le code de sécurité de
                      votre application d'authentification à 2 facteurs.
                    </p>
                    {UserStore.props.twoFactorsVerified === '0' && (
                      <>
                        <hr />
                        <p style={{ color: 'orangered' }}>
                          <span style={{ fontSize: '20px' }}>&#9888;</span>
                          L'authentification à 2 facteurs n'a pas encore été
                          vérifiée pour votre compte, les instructions se
                          trouvent dans l'un de vos emails avec l'objet "Votre
                          compte est activé" OU "Authentification à 2 facteurs".
                          <br /> Pensez également à vérifier dans vos spams.
                        </p>
                        <hr />
                      </>
                    )}

                    {UserStore.props.twoFactorsVerified !== '0' && (
                      <OPTInput
                        length={6}
                        onComplete={onComplete}
                        isLoadingInputs={isLoadingInputs}
                      />
                    )}
                  </div>
                </div>
              </BlockUi>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(MultiFactorView);
