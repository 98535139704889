import React from 'react';
import { observable, toJS } from 'mobx';
import moment from 'moment';
import api from '../api';
import UserStore from './UserStore';
import { ActionsProducts } from '../components/Produits/Prices/ActivePrice';

const props = {
  loading: {
    status: false,
    msg: 'Chargement en cours',
  },
  columns: [
    {
      Header: 'Produit',
      accessor: 'id',
      field: 'id',
      // isHidden: true,
    },
    {
      Header: 'Nom Produit',
      accessor: 'label',
      field: 'label',
      // isHidden: true,
    },
    {
      Header: 'Statut',
      accessor: 'status',
      field: 'status',
      // isHidden: true,
      Cell: ({ cell: { value } }) => {
        const dico = {
          0: 'Inactif',
          1: 'Actif',
        };
        return dico[value];
      },
    },
    {
      Header: 'Unité',
      accessor: 'unite',
      field: 'unite',
      // isHidden: true,
      Cell: ({ cell: { value } }) => {
        const dico = {
          1: 'Litre',
          2: 'Gallon',
        };
        return dico[parseInt(value)];
      },
    },
    {
      Header: 'Controle de volume requis ?',
      accessor: 'gestionVolume',
      field: 'gestionVolume',
      Cell: ({ cell: { value } }) => {
        const dico = { 0: 'Non', 1: 'Oui' };
        return dico[value];
      },
    },
    {
      Header: 'Prix de référence',
      accessor: 'prixReference',
      field: 'prixReference',
      Cell: ({ cell: { value, row } }) => {
        const dico = {
          1: 'Litre',
          2: 'Gallon',
        };
        if (row.values.gestionVolume === '1') {
          if (parseInt(value) > 0) {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  backgroundColor: '#88C21B',
                  color: 'black',
                }}
              >
                {value}{' '}
                <span
                  style={{
                    fontWeight: 'regular',
                    fontSize: '8px',
                  }}
                >{`${row.values.NomPME2}/${dico[row.values.unite]}`}</span>
              </div>
            );
          }
          return (
            <div
              style={{
                cursor: 'pointer',
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: 'red',
                color: 'white',
              }}
            >
              0
            </div>
          );
        } else {
          if (parseInt(value) > 0) {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {value}{' '}
                <span
                  style={{
                    fontWeight: 'regular',
                    fontSize: '8px',
                  }}
                >{`${row.values.NomPME2}/${dico[row.values.unite]}`}</span>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  color: 'black',
                }}
              >
                0
              </div>
            );
          }
        }
      },
    },
    {
      Header: 'Prix actuel du Marché',
      accessor: 'prixCourant',
      field: 'prixCourant',
      Cell: ({ cell: { value, row } }) => {
        const dico = {
          1: 'Litre',
          2: 'Gallon',
        };
        if (row.values.gestionVolume === '1') {
          if (parseInt(value) > 0) {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  backgroundColor: '#88C21B',
                  color: 'black',
                }}
              >
                {value}{' '}
                <span
                  style={{
                    fontWeight: 'regular',
                    fontSize: '8px',
                  }}
                >{`${row.values.NomPME2}/${dico[row.values.unite]}`}</span>
              </div>
            );
          }
          return (
            <div
              style={{
                cursor: 'pointer',
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: 'red',
                color: 'white',
              }}
            >
              0
            </div>
          );
        } else {
          if (parseInt(value) > 0) {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {value}{' '}
                <span
                  style={{
                    fontWeight: 'regular',
                    fontSize: '8px',
                  }}
                >{`${row.values.NomPME2}/${dico[row.values.unite]}`}</span>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  color: 'black',
                }}
              >
                0
              </div>
            );
          }
        }
      },
    },
    {
      Header: 'Debut validité prix référence',
      accessor: 'dateDebutValiditePrixRef',
      Cell: ({ cell: { value } }) => {
        // console.log(value)
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Fin Validité Prix référence',
      accessor: 'dateFinValiditePrixRef',
      Cell: ({ cell: { value } }) => {
        // console.log(value)
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Debut validité Prix du marché',
      accessor: 'dateDebutValiditePrixMarche',
      Cell: ({ cell: { value } }) => {
        // console.log(value)
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Fin Validité prix du marché',
      accessor: 'dateFinValiditePrixMarche',
      Cell: ({ cell: { value } }) => {
        // console.log(value)
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Actions',
      accessor: 'edited',
      Cell: ({ row, toggleModalEdit }) => {
        return (
          <ActionsProducts row={row.values} toggleModalEdit={toggleModalEdit} />
        );
      },
    },
    {
      Header: 'PME',
      accessor: 'NomPME2',
      isHidden: true,
    },
  ],
  pattern: {},
  data: [],
  pageStart: 0,
  pageIndex: 0,
  pageLimit: 30,
  pageTotal: 0,
  pageCount: 0,
  total_gain: null,
  savedPattern: {},
  savedFilters: [],
  loadedFilter: null,
  isOpenModalEdit: false,
  isOpenModalCreate: false,
  currentProduct: {
    id: '',
    status: 0,
    unite: 1,
    gestionVolume: 0,
  },
  productsData: [],
  isLoadingEdit: false,
  isLoadingCreate: false,
};

const ActivePriceFilterStore = {
  props: props,
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },

  async init(pattern) {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    if (pattern) this.props.pattern = pattern;
    console.log('initfilter');
    await this.initColumnsActivePrices();

    await this.getActivePrices();

    this.doLoading(false);
  },
  async initColumnsActivePrices() {
    return new Promise((resolve) => {
      this.props.columns = this.props.columns.map((item) => {
        return item;
      });

      resolve(true);
    });
  },

  async getActivePrices() {
    this.props.sortBy = this.props.sortBy || 'id,label,status';
    this.props.sortOrder = this.props.sortOrder || 'DESC';
    const idRes = UserStore.props.user.idRes;

    let where = '';

    if (UserStore.isReseau()) where = this.getWhere('idres = ' + idRes);
    else where = this.getWhere('idres = ' + idRes);

    // let where = this.getWhere(mainWhere)
    let data = {
      objName: 'PrixproduitsDuJour',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      sort: this.props.sortBy,
      dir: this.props.sortOrder,
      // 'query': JSON.stringify(where)
    };

    const res = await api.post('/scripts/reseau/reseau.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
    // this.props.loading.status = false
  },
  async getProducts() {
    const idRes = UserStore.props.user.idRes;

    let data = {
      objName: 'listeProduitsAutorises',
      cmd: 'getRows',
      where: `status=1 and id not in (SELECT id from produitsautorises_reseau WHERE produitsautorises_reseau.idRes=${idRes})`,
      fields: JSON.stringify(['label']),
    };

    const res = await api.post('/scripts/reseau/reseau.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.productsData = !res.data.rows ? [] : res.data.rows;
      } else {
        this.props.productsData = [];
      }
    }
  },
  async editProduct(values) {
    this.props.isLoadingEdit = true;
    const idRes = UserStore.props.user.idRes;

    const dataValues = [
      {
        id: values.id,
        status: parseInt(values.status),
        gestionVolume: parseInt(values.gestionVolume),
        unite: parseInt(values.unite),
        idres: idRes,
        edited: true,
      },
    ];

    let data = {
      cmd: 'updateProducts',
      data: JSON.stringify(dataValues),
    };
    const res = await api.post('/scripts/reseau/reseau.php', data);
    if (res.data.success) {
      this.init();
      this.props.isOpenModalEdit = false;
    }

    this.props.isLoadingEdit = false;
  },
  async createProduct(values) {
    this.props.isLoadingCreate = true;
    const idRes = UserStore.props.user.idRes;

    const dataValues = [
      {
        id: values.id,
        status: parseInt(values.status),
        gestionVolume: parseInt(values.gestionVolume),
        unite: parseInt(values.unite),
        idres: idRes,
        edited: false,
      },
    ];

    let data = {
      cmd: 'updateProducts',
      data: JSON.stringify(dataValues),
    };
    const res = await api.post('/scripts/reseau/reseau.php', data);
    if (res.data.success) {
      this.init();
      this.props.isOpenModalCreate = false;
    }

    this.props.isLoadingCreate = false;
  },
  toggleModalEdit(values) {
    if (values) {
      this.props.currentProduct = values;
    }
    console.log(this.props.currentProduct);
    this.props.isOpenModalCreate = false;
    this.props.isOpenModalEdit = !this.props.isOpenModalEdit;
  },
  toggleModalCreate() {
    this.props.currentProduct = {
      id: '',
      status: 0,
      unite: 1,
      gestionVolume: 0,
    };
    this.props.isOpenModalEdit = false;
    this.props.isOpenModalCreate = !this.props.isOpenModalCreate;
  },
  async getFilteredActivePrices() {
    this.doLoading(true, 'Chargement en cours ...');
    await this.getActivePrices();
    this.doLoading(false);
  },
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getActivePrices();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getActivePrices();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getActivePrices();
    this.doLoading(false);
  },
  toggleItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = !this.props.pattern[key].active;
  },
  activateItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = true;
  },
  changeOperator(key, val, index) {
    console.log(val);
    this.props.pattern[key].value[index].operator = val;
    if (val === 'between') {
      this.props.pattern[key].value[index].value = [null, null];
    } else {
      this.props.pattern[key].value[index].value = '';
    }
  },
  changeValue(key, val, index, order) {
    console.log('changeValue key', key);
    console.log('changeValue val', val);
    console.log('changeValue pattern', this.props.pattern[key]);
    if (this.props.pattern[key].value[index].operator === 'between') {
      this.props.pattern[key].value[index].value[order] = val;
    } else if (this.props.pattern[key].value[index].operator === 'in') {
      if (val !== 'all') {
        let position = this.props.pattern[key].value[index].value.findIndex(
          (o) => o === val
        );
        console.log('position :', position);
        if (position === -1)
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value,
            val,
          ];
        else
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value.filter(
              (item) => item !== val
            ),
          ];
      } else {
        if (
          this.props.pattern[key].field.list.length !==
          this.props.pattern[key].value[index].value.length
        ) {
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].field.list.map((item) => item.id),
          ];
        } else {
          this.props.pattern[key].value[index].value = [];
        }
      }
    } else {
      this.props.pattern[key].value[index].value = val;
    }
    console.log(toJS(this.props.pattern[key].value[index].value));
  },
  addItemFilter(key, type) {
    let newVal = { ...this.props.pattern[key].defaultValue };
    newVal.logic = type;
    this.props.pattern[key].value = [...this.props.pattern[key].value, newVal];
  },
  removeItemFilter(key, idx) {
    this.props.pattern[key].value = [
      ...this.props.pattern[key].value.filter((item, index) => index !== idx),
    ];
  },
  submitFilter() {
    this.props.savedPattern = toJS(this.props.pattern);
    this.getCountActiveFilter();
  },

  resetFilter() {
    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].value = props.pattern[item].value;
      this.props.savedPattern[item].value = props.pattern[item].value;
      this.props.pattern[item].active = false;
      this.props.savedPattern[item].active = false;
    });

    // this.activateItemFilter('dateAction');
  },
  undoPattern() {
    this.props.pattern = toJS(this.props.savedPattern);
  },
  getWhere(where = '') {
    const main = [
      ...Object.keys(this.props.pattern)
        .filter((item) => this.props.pattern[item].active)
        .map((item) => toJS(this.props.pattern[item].value)),
    ];

    console.log(main);
    for (let key in main) {
      if (main[key].isHidden) continue;
      if (where.length) {
        if (main[key].length) where += ' AND ( ';
      } else where += '( ';
      let pass = 0;
      for (let id in main[key]) {
        if (pass) where += ' ' + main[key][id].logic + ' ';
        pass = 1;

        where += this.getClause(main[key][id], main[key][id].custom);
      }

      if (where.length) {
        if (main[key].length) where += ')';
      }
    }

    return where;
  },
  getClause(clause, isCustom = false) {
    let where = '-+--+-+ ERR -++-+-+';
    if (clause.operator === 'in') {
      where = clause.field + ' IN (' + clause.value.join(',') + ') ';
      console.log(where);
    } else if (clause.operator === 'between') {
      if (clause.type === 'date') {
        where =
          clause.field +
          " BETWEEN '" +
          clause.value[0] +
          "' AND '" +
          clause.value[1] +
          "' ";
      } else if (clause.type === 'number') {
        where =
          clause.field +
          ' BETWEEN ' +
          clause.value[0] +
          ' AND ' +
          clause.value[1] +
          ' ';
      } else {
        where = '__ERROR__1';
      }
    } else if (clause.operator === 'like' || clause.operator === 'not like') {
      if (clause.type === 'text') {
        where =
          clause.field + ' ' + clause.operator + " '%" + clause.value + "%' ";
      } else {
        where = '__ERROR__2';
      }
    } else if (
      clause.operator === '>' ||
      clause.operator === '<' ||
      clause.operator === '>=' ||
      clause.operator === '<=' ||
      clause.operator === '<>' ||
      clause.operator === '='
    ) {
      if (clause.type === 'date' || clause.type === 'text') {
        where =
          clause.field + ' ' + clause.operator + " '" + clause.value + "' ";
      } else if (clause.type === 'number') {
        where = clause.field + ' ' + clause.operator + ' ' + clause.value + ' ';
      } else {
        where = '__ERROR__3';
      }
    } else {
      where = '__ERROR__4';
    }
    if (isCustom)
      return clause.field.replace('_$$$_', where.replace(clause.field, ''));
    return where;
  },
  getCountActiveFilter() {
    this.props.countActiveFilter = Object.keys(this.props.pattern).filter(
      (item) => this.props.pattern[item].active
    ).length;
  },
  //FILTER ACTIONS
  getCurrentFilterJson() {
    let filter = {};

    Object.keys(this.props.pattern)
      .filter((item) => this.props.pattern[item].active)
      .map((item) => {
        filter[item] = this.props.pattern[item].value;
      });

    return toJS(filter);
  },
  reset() {
    this.props = props;
  },
};

export default observable(ActivePriceFilterStore);
