import React, { useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import UserStore from '../../stores/UserStore';
import { observer } from 'mobx-react';
import MainStore from '../../stores/MainStore';

const IsLogged = (props) => {
  const { location } = props;

  const activated2FA = localStorage.getItem('fuelpass_2fa')
    ? localStorage.getItem('fuelpass_2fa')
    : null;

  useEffect(() => {
    console.log('INIT');
    // console.log(location)
    UserStore.init();
  }, []);

  useEffect(() => {
    MainStore.setCurrentRoute(location.state);
  }, [location.state]);

  if (!UserStore.props.user.isAuthenticated && location.pathname !== '/login')
    return (
      <>
        {/* {console.log('Not Auth ', location)} */}
        <Redirect to='/login'></Redirect>
      </>
    );

  if (!UserStore.props.user.isAuthenticated)
    return (
      <>
        {/* {console.log('Not Auth ', location)} */}
        {props.children}
      </>
    );
  if (
    UserStore.props.user.isAuthenticated &&
    !activated2FA &&
    activated2FA !== '1'
  ) {
    UserStore.logout();
    return (
      <>
        {/* {console.log('Not Auth ', location)} */}
        <Redirect to='/login'></Redirect>
      </>
    );
  }

  if (UserStore.props.user.isAuthenticated)
    return (
      <>
        {/* {console.log('Is Auth', location)} */}
        {props.children}
      </>
    );
};

export default withRouter(observer(IsLogged));
