import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import { unmountComponentAtNode, render } from 'react-dom';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import SimpleBar from 'simplebar-react';

const ConfirmModal = (props) => {
  const { title, password, info, close } = props;

  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    if (value === password) {
      setOpen(false);
      close(true);
    } else setError(true);
  };

  const handleChange = (e) => {
    setError(false);
    setValue(e.target.value);
  };

  return (
    <Modal isOpen={open} backdrop='static' toggle={() => setOpen(!open)}>
      <ModalHeader>Confirmation {title}</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col'>
            <div className='form-group'>
              <p>{info}</p>
              <p>
                Veuillez saisir le mot : <strong>{password}</strong>
              </p>
            </div>
            <div className='form-group col'>
              <input
                type='text'
                onChange={handleChange}
                className={classnames('form-control', { 'is-invalid': error })}
              />
              {error && (
                <div className='invalid-feedback'>
                  La valeur saisie est incorrecte
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn-danger btn' onClick={() => setOpen(!open)}>
          Fermer
        </button>
        <button className='btn-success btn' onClick={handleClick}>
          Confirmer
        </button>
      </ModalFooter>
    </Modal>
  );
};

const OuiNonModal = (props) => {
  const { title, typeAlert, text, close } = props;

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(false);
    close(true);
  };

  return (
    <Modal
      isOpen={open}
      backdrop='static'
      centered={true}
      toggle={() => setOpen(!open)}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col-auto d-flex center-v center-h'>
            <p>
              {typeAlert === 'info' && (
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  size='3x'
                  className='text-pass'
                ></FontAwesomeIcon>
              )}
              {typeAlert === 'success' && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size='3x'
                  className='text-success'
                ></FontAwesomeIcon>
              )}
              {typeAlert === 'error' && (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size='3x'
                  className='text-danger'
                ></FontAwesomeIcon>
              )}
              {typeAlert === 'warning' && (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size='3x'
                  className='text-warning'
                ></FontAwesomeIcon>
              )}
              {typeAlert === 'question' && (
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  size='3x'
                  className='text-pass'
                ></FontAwesomeIcon>
              )}
            </p>
          </div>
          <div className='col d-flex center-v'>
            <p>{parse(text)}</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className='btn-danger btn'
          onClick={() => {
            setOpen(!open);
            close(false);
          }}
        >
          Non
        </button>
        <button className='btn-success btn' onClick={handleClick}>
          Oui
        </button>
      </ModalFooter>
    </Modal>
  );
};

const ConfirmAffectModal = (props) => {
  const { selectedRow, choice, typeAffect, close } = props;

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    close(true);
  };

  return (
    <Modal isOpen={open} backdrop='static' toggle={() => setOpen(!open)}>
      <ModalHeader>Confirmation affectation</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col'>
            {typeAffect === 'cardToFiche' && (
              <>
                <p>Êtes vous sûr associer cette carte : </p>
                <p>
                  <strong>{choice.clientNumCard}</strong>
                </p>
                <p>A cette fiche : </p>

                <table className='table border rounded table-borderless table-sm'>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>Nom</td>
                      <td>
                        <strong>{selectedRow.cltNom}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Prénom</td>
                      <td>
                        <strong>{selectedRow.cltPrenom}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Date de naissance</td>
                      <td>
                        <strong>{selectedRow.cltNeLe}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>
                        <strong>{selectedRow.cltEmail}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>N° de tél mobile</td>
                      <td>
                        <strong>{selectedRow.cltGsm}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {typeAffect === 'ficheToCard' && (
              <>
                <p>Êtes vous sûr associer cette fiche : </p>
                <table className='table border rounded  table-sm table-borderless'>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>Nom</td>
                      <td>
                        <strong>{choice.clientNom}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Prénom</td>
                      <td>
                        <strong>{choice.clientPrenom}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Date de naissance</td>
                      <td>
                        <strong>{choice.clientNeLe}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>
                        <strong>{choice.clientEmail}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>N° de tél mobile</td>
                      <td>
                        <strong>{choice.clientGsm}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>A cette carte : </p>
                <p>
                  <strong>{selectedRow.cltNumCard}</strong>
                </p>
              </>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className='btn-danger btn'
          onClick={() => {
            setOpen(!open);
            close(false);
          }}
        >
          Fermer
        </button>
        <button className='btn-success btn' onClick={handleClick}>
          Confirmer
        </button>
      </ModalFooter>
    </Modal>
  );
};

const ConfirmModalNoPassword = (props) => {
  const { title, text, close } = props;

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(false);
    close(true);
  };

  return (
    <Modal
      isOpen={open}
      backdrop='static'
      centered={true}
      toggle={() => setOpen(!open)}
    >
      <ModalHeader>Confirmation : {title}</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col'>
            <div className='form-group'>
              <p>{parse(text)}</p>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className='btn-danger btn'
          onClick={() => {
            setOpen(!open);
            close(false);
          }}
        >
          Annuler
        </button>
        <button className='btn-success btn' onClick={handleClick}>
          Confirmer
        </button>
      </ModalFooter>
    </Modal>
  );
};

const ConfirmAffectBons = (props) => {
  const { listeErreurs, close } = props;

  const [open, setOpen] = useState(true);

  return (
    <Modal
      backdrop='static'
      centered={true}
      isOpen={open}
      toggle={() => setOpen(!open)}
    >
      <ModalHeader className='no-border'>Warnings</ModalHeader>
      <ModalBody className='px-3 py-3'>
        <div className='form-group text-center'>
          <p>Certains bons ont déjà été attribués</p>
          <p>
            <b>Souhaitez vous continuer ?</b>
          </p>
        </div>
        <div className='form-group d-flex center-h'>
          <button
            className='btn-success btn-sm btn mx-1'
            onClick={() => close(true)}
          >
            <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
            Oui, attribuer quand même
          </button>
          <button
            className='btn-danger btn-sm btn mx-1'
            onClick={() => close(false)}
          >
            <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
            Annuler
          </button>
        </div>
        <SimpleBar
          style={{ maxHeight: '50vh' }}
          className='mt-3 border rounded'
        >
          <table className='table table-sm'>
            <thead>
              <th>Bon carburant</th>
              <th>Société</th>
            </thead>
            <tbody>
              {listeErreurs.map((item) => (
                <tr>
                  <td>{item[0]}</td>
                  <td>{item[1]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </SimpleBar>
      </ModalBody>
    </Modal>
  );
};

export const confirmation = (props) => {
  return new Promise((resolve) => {
    let el = document.createElement('div');

    const handleResolve = (result) => {
      unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };
    if (props.type === 'confirmNoPass')
      render(<ConfirmModalNoPassword {...props} close={handleResolve} />, el);
    else if (props.type === 'confirmAffect')
      render(<ConfirmAffectModal {...props} close={handleResolve} />, el);
    else if (props.type === 'ouiNon')
      render(<OuiNonModal {...props} close={handleResolve} />, el);
    else if (props.type === 'confirmAffectBons')
      render(<ConfirmAffectBons {...props} close={handleResolve} />, el);
    else render(<ConfirmModal {...props} close={handleResolve} />, el);
  });
};

export default confirmation;
