import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import CardsStore from '../../stores/CardsStore';
import { useTable } from 'react-table';
import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faDownload,
  faCaretDown,
  faPlus,
  faTimes,
  faTrashAlt,
  faList,
  faInfoCircle,
  faIdCard,
  faClipboardList,
  faSearch,
  faCheck,
  faSyncAlt,
  faPen,
  faExpandArrowsAlt,
} from '@fortawesome/free-solid-svg-icons';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import 'simplebar/dist/simplebar.min.css';
import UserStore from '../../stores/UserStore';
import MyField from '../../reusable/MyField';
import DataTable, { ColumnsToggle } from '../../reusable/DataTable';
import ClientFilter, { QuickSearch } from '../../reusable/ClientFilter';
import { useFormik } from 'formik';
import classnames from 'classnames';
import { Civilite, entityTypes } from '../../utils/constants';
import AnimateHeight from 'react-animate-height';
import parse from 'html-react-parser';
import SimpleBar from 'simplebar-react';
import { ModalLst } from '../Common/Modals';

export const ActionsCards = observer((props) => {
  const {
    row,
    toggleModalEditCards,
    deleteCards,
    toggleModalAffect,
    isSociete,
    isReseau,
    toggleModalLst,
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} className=' ml-auto' toggle={toggle}>
      <DropdownToggle
        tag='button'
        className='btn btn-xs btn-outline-fuel btn-fuel'
        data-toggle='dropdown'
        aria-expanded={dropdownOpen}
      >
        Actions
        <FontAwesomeIcon className='ml-2' icon={faCaretDown}></FontAwesomeIcon>
      </DropdownToggle>
      <DropdownMenu right className='p-0'>
        {!isSociete && (
          <DropdownItem
            tag='a'
            className='dropdown-item'
            onClick={() =>
              toggleModalLst(
                {
                  id: row.idcli,
                  name: row.clientNP,
                  type: entityTypes.transacClient,
                },
                'Client'
              )
            }
          >
            <FontAwesomeIcon
              icon={faExpandArrowsAlt}
              className='mr-2 text-fuel'
            ></FontAwesomeIcon>
            Liste des transactions
          </DropdownItem>
        )}
        {!isSociete &&
          row.cltNumCard != '' &&
          row.cltNom == '' &&
          row.cltPrenom == '' &&
          row.cltNeLe == '' &&
          row.cltGsm == '' &&
          row.cltEmail == '' && (
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() => toggleModalAffect('ficheToCard', row)}
            >
              <FontAwesomeIcon
                icon={faClipboardList}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Affecter fiche client à la carte
            </DropdownItem>
          )}
        {!isSociete &&
          row.cltNumCard == '' &&
          row.Pme2 == 0 &&
          row.Pme1 == 0 &&
          row.cumRech2 == 0 &&
          row.cumRech1 == 0 &&
          row.cumBonus2 == 0 &&
          row.cumBonus1 == 0 &&
          row.cumSpot == 0 &&
          row.cumDebPme2 == 0 &&
          row.cumDebPme1 == 0 &&
          row.cumCredPme1 == 0 &&
          row.cumCredPme2 == 0 && (
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() => toggleModalAffect('cardToFiche', row)}
            >
              <FontAwesomeIcon
                icon={faIdCard}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Affecter une carte à la fiche client
            </DropdownItem>
          )}
        {isReseau && (
          <DropdownItem
            tag='a'
            className='dropdown-item'
            onClick={() => toggleModalEditCards(row)}
          >
            <FontAwesomeIcon
              icon={faPen}
              className='mr-2 text-fuel'
            ></FontAwesomeIcon>
            Modifier
          </DropdownItem>
        )}
        {!isSociete && (
          <DropdownItem
            tag='a'
            className='dropdown-item'
            onClick={() => {
              deleteCards(row);
            }}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              className='mr-2 text-danger'
            ></FontAwesomeIcon>
            Supprimer
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
});

const ModalEditCards = observer((props) => {
  const {
    canRechargementUnique,
    listCardsUpdate,
    initialValuesCards,
    currentCard,
    fetchSociete,
    loadingState,
    loadingMsg,
    isOpenModalEditCards,
    toggleModalEditCards,
    dataMaj,
    columnMaj,
    ajouterRecharge,
    askChangePin,
    regeneratePin,
    changeValidite,
    disableCard,
    enableCard,
    validationAction,
    editInfosClient,
    validationClient,
  } = props;

  const disabled = true;

  const [activeTab, setActiveTab] = useState('1');
  const [action, setAction] = useState();

  // console.log('action', toJS(listCardsUpdate[0]))

  const toggleTab = (tab) => {
    console.log(tab);
    if (activeTab !== tab) setActiveTab(tab);
  };

  const majTable = useTable({
    columns: columnMaj,
    data: dataMaj,
  });

  useEffect(() => {
    if (listCardsUpdate && listCardsUpdate.length)
      setAction(listCardsUpdate[0].id);
  }, [listCardsUpdate]);

  const submit = (values, { resetForm }) => {
    console.log(activeTab);
    if (activeTab == '3') {
      if (action == 1) ajouterRecharge(values);
      else if (action == 2) disableCard(values);
      else if (action == 3) enableCard(values);
      else if (action == 4) regeneratePin(values);
      else if (action == 5) askChangePin(values);
      else if (action == 7) changeValidite(values);

      resetForm();
    } else if (activeTab == '1') editInfosClient(values);
  };

  const getValidation = () => {
    if (activeTab == '3') return validationAction[action];
    else return validationClient;
  };

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValuesCards,
      validationSchema: getValidation(),
      onSubmit: submit,
      validateOnChange: false,
      validateOnBlur: false,
    });

  return (
    <Modal
      isOpen={isOpenModalEditCards}
      backdrop='static'
      size='lg'
      toggle={() => toggleModalEditCards()}
    >
      <BlockUi
        tag='div'
        blocking={loadingState}
        message={loadingMsg}
        loader={<BarLoader></BarLoader>}
      >
        <ModalHeader toggle={() => toggleModalEditCards()}>
          Détails client
        </ModalHeader>
        <ModalBody>
          <div className='row'>
            <div className='col-12'>
              <div className='border rounded mb-3' style={{ height: '130px' }}>
                <div className='card-body p-0 d-flex align-items-center h-100'>
                  <div className='d-flex center-v bg-gradient-info p-4 mr-3 text-white rounded-left h-100'>
                    <i className='icon-user fa-2x'></i>
                  </div>
                  <div className='h-100'>
                    <div className='text-value text-primary'>
                      {currentCard.cltNom} {currentCard.cltPrenom}
                    </div>
                    <div className='h6 text-muted'>
                      N° de carte : <b>{currentCard.cltNumCard}</b>
                    </div>
                    <div className='h6 text-muted'>
                      Statut carte :{' '}
                      <b>
                        {currentCard.cltCardOFF == 1 ? (
                          <span className='text-danger font-weight-bold'>
                            Désactivé
                          </span>
                        ) : currentCard.cltCardOFF !== '' ? (
                          <span className='text-success font-weight-bold'>
                            Active
                          </span>
                        ) : (
                          <span className='text-secondary font-weight-bold'>
                            Inexistante
                          </span>
                        )}
                      </b>
                    </div>
                    <div className='h6 text-muted'>
                      Catégorie : <b>{currentCard.Label}</b>
                    </div>
                    <div className='h6 text-muted'>
                      Type carte : <b>{currentCard.clientTypeCarte}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggleTab('1');
                    }}
                  >
                    <span className='h6'>Général</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggleTab('2');
                    }}
                  >
                    <span className='h6'>Contrôle</span>
                  </NavLink>
                </NavItem>
                {canRechargementUnique && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        toggleTab('3');
                      }}
                    >
                      <span className='h6'>Mise à jour</span>
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => {
                      toggleTab('4');
                    }}
                  >
                    <span className='h6'>Statistiques</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent className='' activeTab={activeTab}>
                <TabPane tabId='1' className='animated fadeIn'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label>Société </label>
                        <MyField
                          name='idSociete'
                          fetch={fetchSociete}
                          type={'asyncSelect'}
                          className='async-select'
                          defaultOptions={true}
                          customOnChange={setFieldValue}
                          defaultValue={values['idSociete']}
                          value={values['idSociete']}
                          error={errors['idSociete']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Civilité </label>
                        <MyField
                          name='clientCivilite'
                          type='select'
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          customOnChange={setFieldValue}
                          list={Civilite}
                          value={values['clientCivilite']}
                          error={errors['clientCivilite']}
                          touched={touched['clientCivilite']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Nom </label>
                        <MyField
                          name='clientNom'
                          type={'text'}
                          className='form-control form-control-sm'
                          defaultOptions={true}
                          onChange={handleChange}
                          value={values['clientNom']}
                          error={errors['clientNom']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Prénom </label>
                        <MyField
                          name='clientPrenom'
                          type={'text'}
                          className='form-control form-control-sm'
                          defaultOptions={true}
                          onChange={handleChange}
                          value={values['clientPrenom']}
                          error={errors['clientPrenom']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <MyField
                          name='clientAdresseIncrorrect'
                          type={'checkbox'}
                          checkboxLabel='Ne souhaite pas recevoir de campagne de communication '
                          className='abc-checkbox-primary'
                          defaultOptions={true}
                          customOnChange={setFieldValue}
                          value={values['clientAdresseIncrorrect']}
                          error={errors['clientAdresseIncrorrect']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <MyField
                          name='clientCarteTest'
                          type={'checkbox'}
                          checkboxLabel='Est une carte test'
                          className='abc-checkbox-primary'
                          defaultOptions={true}
                          customOnChange={setFieldValue}
                          value={values['clientCarteTest']}
                          error={errors['clientCarteTest']}
                        ></MyField>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label>Né(e) le:</label>

                        <MyField
                          name='clientNeLe'
                          type={'date'}
                          className='form-control form-control-sm'
                          customOnChange={setFieldValue}
                          value={values['clientNeLe']}
                          error={errors['clientNeLe']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Tél. portable:</label>

                        <MyField
                          name='clientGsm'
                          type={'text'}
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          value={values['clientGsm']}
                          error={errors['clientGsm']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Tél. Fixe:</label>

                        <MyField
                          name='clientTel'
                          type={'text'}
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          value={values['clientTel']}
                          error={errors['clientTel']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Email</label>

                        <MyField
                          name='clientEmail'
                          type={'text'}
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          value={values['clientEmail']}
                          error={errors['clientEmail']}
                        ></MyField>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label>Adresse 1</label>

                        <MyField
                          name='clientAdresse1'
                          type={'text'}
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          value={values['clientAdresse1']}
                          error={errors['clientAdresse1']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Adresse 2</label>

                        <MyField
                          name='clientAdress2'
                          type={'text'}
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          value={values['clientAdress2']}
                          error={errors['clientAdress2']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Code postal</label>

                        <MyField
                          name='clientCP'
                          type={'text'}
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          value={values['clientCP']}
                          error={errors['clientCP']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>Ville</label>

                        <MyField
                          name='clientVille'
                          type={'text'}
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          value={values['clientVille']}
                          error={errors['clientVille']}
                        ></MyField>
                      </div>
                      <div className='form-group'>
                        <label>N° RIB:</label>

                        <MyField
                          name='clientRIB'
                          type={'text'}
                          className='form-control form-control-sm'
                          onChange={handleChange}
                          value={values['clientRIB']}
                          error={errors['clientRIB']}
                        ></MyField>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 d-flex justify-content-end'>
                      <button
                        className='btn btn-sm btn-success'
                        onClick={handleSubmit}
                      >
                        Enregistrer les modifications
                      </button>
                    </div>
                  </div>
                </TabPane>

                <TabPane tabId='2' className='animated fadeIn p-0'>
                  <div className='row'>
                    <div className='col-12'>
                      <fieldset className='p-3 border rounded'>
                        <legend className='text-fid w-auto px-3 text-old'>
                          Contrôle Jour Semaine
                        </legend>
                        <div className='row'>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Oui'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-12'
                            customOnChange={setFieldValue}
                            name='ctrlList_joursem'
                            value={currentCard['ctrlList_joursem']}
                          ></MyField>
                        </div>
                        <div className='row'>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Lundi autorisé'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-6'
                            customOnChange={setFieldValue}
                            name='ctrlList_joursem1'
                            value={currentCard['ctrlList_joursem1']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Mardi autorisé'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-6'
                            customOnChange={setFieldValue}
                            name='ctrlList_joursem2'
                            value={currentCard['ctrlList_joursem2']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Mercredi autorisé'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-6'
                            customOnChange={setFieldValue}
                            name='ctrlList_joursem3'
                            value={currentCard['ctrlList_joursem3']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Jeudi autorisé'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-6'
                            customOnChange={setFieldValue}
                            name='ctrlList_joursem4'
                            value={currentCard['ctrlList_joursem4']}
                            error={errors['ctrlList_joursem4']}
                            touched={touched['ctrlList_joursem4']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Vendredi autorisé'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-6'
                            customOnChange={setFieldValue}
                            name='ctrlList_joursem5'
                            value={currentCard['ctrlList_joursem5']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Samedi autorisé'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-6'
                            customOnChange={setFieldValue}
                            name='ctrlList_joursem6'
                            value={currentCard['ctrlList_joursem6']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Dimanche autorisé'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-6'
                            customOnChange={setFieldValue}
                            name='ctrlList_joursem7'
                            value={currentCard['ctrlList_joursem7']}
                          ></MyField>
                        </div>
                      </fieldset>
                      <fieldset className='p-3 border rounded'>
                        <legend className='text-fid w-auto px-3 text-old'>
                          Contrôle Kilométrage
                        </legend>
                        <div className='row'>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Oui'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-12'
                            customOnChange={setFieldValue}
                            name='ctrlList_km'
                            value={currentCard['ctrlList_km']}
                          ></MyField>
                        </div>
                      </fieldset>
                      <fieldset className='p-3 border rounded'>
                        <legend className='text-fid w-auto px-3 text-old'>
                          Contrôle Produits
                        </legend>
                        <div className='row'>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Oui'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-12'
                            customOnChange={setFieldValue}
                            name='ctrlList_pdt'
                            value={currentCard['ctrlList_pdt']}
                          ></MyField>
                        </div>

                        <div className='row'>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Gazoline'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-4'
                            customOnChange={setFieldValue}
                            name='prdctList_0'
                            value={currentCard['prdctList_0']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Diésel'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-4'
                            customOnChange={setFieldValue}
                            name='prdctList_1'
                            value={currentCard['prdctList_1']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Kérosène'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-4'
                            customOnChange={setFieldValue}
                            name='prdctList_2'
                            value={currentCard['prdctList_2']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='GPL'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-4'
                            customOnChange={setFieldValue}
                            name='prdctList_3'
                            value={currentCard['prdctList_3']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Lubrifiants'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-4'
                            customOnChange={setFieldValue}
                            name='prdctList_4'
                            value={currentCard['prdctList_4']}
                          ></MyField>
                          <MyField
                            type='checkbox'
                            checkboxLabel='Autres'
                            disabled={disabled}
                            className='abc-checkbox-primary col-md-4'
                            customOnChange={setFieldValue}
                            name='prdctList_5'
                            value={currentCard['prdctList_5']}
                          ></MyField>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </TabPane>
                {canRechargementUnique && (
                  <TabPane tabId='3' className='animated fadeIn p-0'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label>Actions</label>
                          <MyField
                            name='action'
                            type={'select'}
                            list={listCardsUpdate}
                            className='form-control form-control-sm'
                            onChange={(e) => setAction(e.target.value)}
                            value={action}
                          ></MyField>
                        </div>
                      </div>
                    </div>
                    <AnimateHeight
                      duration={500}
                      className='px-0'
                      height={parseInt(action) == 1 ? 'auto' : 0}
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Chargement autorisé
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6'>
                                <label>Du : </label>
                                <MyField
                                  name='DateDebutRecharge'
                                  type={'date'}
                                  className='form-control form-control-sm'
                                  customOnChange={setFieldValue}
                                  defaultValue={values['DateDebutRecharge']}
                                  value={values['DateDebutRecharge']}
                                  error={errors['DateDebutRecharge']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6'>
                                <label>Au :</label>
                                <MyField
                                  name='DateFinRecharge'
                                  type={'date'}
                                  className='form-control form-control-sm'
                                  customOnChange={setFieldValue}
                                  defaultValue={values['DateFinRecharge']}
                                  value={values['DateFinRecharge']}
                                  error={errors['DateFinRecharge']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6'>
                                <label>Montant : </label>
                                <MyField
                                  name='MontantRecharge'
                                  type={'number'}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  defaultValue={values['MontantRecharge']}
                                  value={values['MontantRecharge']}
                                  error={errors['MontantRecharge']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6'>
                                <label>Motif :</label>
                                <MyField
                                  name='motifRecharge'
                                  type={'text'}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  value={values['motifRecharge']}
                                  error={errors['motifRecharge']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-12 d-flex justify-content-end'>
                                <button
                                  className='btn btn-sm btn-success'
                                  onClick={handleSubmit}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className='mr-2'
                                  ></FontAwesomeIcon>
                                  Ajouter la recharge
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </AnimateHeight>
                    <AnimateHeight
                      duration={500}
                      className='px-0'
                      height={parseInt(action) == 2 ? 'auto' : 0}
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Désactiver une carte{' '}
                            </legend>
                            <div className='form-group col-md-12'>
                              <label>Motif :</label>
                              <MyField
                                name='motifBlocage'
                                type={'text'}
                                className='form-control form-control-sm'
                                onChange={handleChange}
                                value={values['motifBlocage']}
                                error={errors['motifBlocage']}
                              ></MyField>
                            </div>
                            <div className='form-group col-md-12 d-flex justify-content-end'>
                              <button
                                className='btn btn-sm btn-danger'
                                onClick={handleSubmit}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className='mr-2'
                                ></FontAwesomeIcon>
                                Mettre la carte en opposition
                              </button>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </AnimateHeight>
                    <AnimateHeight
                      duration={500}
                      className='px-0'
                      height={parseInt(action) == 3 ? 'auto' : 0}
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Activer une carte{' '}
                            </legend>
                            <div className='form-group col-md-6'>
                              <label>Motif :</label>
                              <MyField
                                name='motifDeblocage'
                                type={'text'}
                                className='form-control form-control-sm'
                                onChange={handleChange}
                                value={values['motifDeblocage']}
                                error={errors['motifDeblocage']}
                              ></MyField>
                            </div>
                            <div className='form-group col-md-12 d-flex justify-content-end'>
                              <button
                                className='btn btn-sm btn-success'
                                onClick={handleSubmit}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className='mr-2'
                                ></FontAwesomeIcon>
                                Débloquer la carte
                              </button>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </AnimateHeight>
                    <AnimateHeight
                      duration={500}
                      className='px-0'
                      height={parseInt(action) == 4 ? 'auto' : 0}
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Code de sécurité{' '}
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6'>
                                <label>Code pin :</label>
                                <MyField
                                  name='clientPassword'
                                  disabled={true}
                                  type={'text'}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  value={values['clientPassword']}
                                  error={errors['clientPassword']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6'>
                                <label>Motif :</label>
                                <MyField
                                  name='MotifRegenerate'
                                  type={'text'}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  value={values['MotifRegenerate']}
                                  error={errors['MotifRegenerate']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-12'>
                                <div class='alert alert-info'>
                                  <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className='mr-2'
                                  ></FontAwesomeIcon>
                                  Lors de la prochaine transaction, un nouveau
                                  code pin généré automatiquement sera affecté à
                                  cette carte
                                </div>
                              </div>
                              <div className='form-group col-md-12 d-flex justify-content-end'>
                                <button
                                  className='btn btn-sm btn-success'
                                  onClick={handleSubmit}
                                >
                                  <FontAwesomeIcon
                                    icon={faSyncAlt}
                                    className='mr-2'
                                  ></FontAwesomeIcon>
                                  Générer nouveau PIN
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </AnimateHeight>
                    <AnimateHeight
                      duration={500}
                      className='px-0'
                      height={parseInt(action) == 5 ? 'auto' : 0}
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Code de sécurité{' '}
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6'>
                                <label>Code pin :</label>
                                <MyField
                                  name='clientPassword'
                                  type={'text'}
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  value={values['clientPassword']}
                                  error={errors['clientPassword']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6'>
                                <label>Motif :</label>
                                <MyField
                                  name='MotifAskChangePin'
                                  type={'text'}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  value={values['MotifAskChangePin']}
                                  error={errors['MotifAskChangePin']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-12'>
                                <div class='alert alert-info'>
                                  <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className='mr-2'
                                  ></FontAwesomeIcon>
                                  Lors de la prochaine transaction, le tpe
                                  demandera à l'utilisateur de saisir le code
                                  PIN de son choix
                                </div>
                              </div>
                              <div className='form-group col-md-12 d-flex justify-content-end'>
                                <button
                                  className='btn btn-sm btn-success'
                                  onClick={handleSubmit}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className='mr-2'
                                  ></FontAwesomeIcon>
                                  Demander à l'utilisateur le changement de son
                                  PIN
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </AnimateHeight>
                    <AnimateHeight
                      duration={500}
                      className='px-0'
                      height={parseInt(action) == 7 ? 'auto' : 0}
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Date d'expiration de la carte
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6'>
                                <label>Du : </label>
                                <MyField
                                  name='DateDebutRecharge'
                                  type={'date'}
                                  className='form-control form-control-sm'
                                  customOnChange={setFieldValue}
                                  defaultValue={values['DateDebutRecharge']}
                                  value={values['DateDebutRecharge']}
                                  error={errors['DateDebutRecharge']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6'>
                                <label>Au :</label>
                                <MyField
                                  name='DateFinRecharge'
                                  type={'date'}
                                  className='form-control form-control-sm'
                                  customOnChange={setFieldValue}
                                  defaultValue={values['DateFinRecharge']}
                                  value={values['DateFinRecharge']}
                                  error={errors['DateFinRecharge']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6'>
                                <label>Motif :</label>
                                <MyField
                                  name='motifRecharge'
                                  type={'text'}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  value={values['motifRecharge']}
                                  error={errors['motifRecharge']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-12 d-flex justify-content-end'>
                                <button
                                  className='btn btn-sm btn-success'
                                  onClick={handleSubmit}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className='mr-2'
                                  ></FontAwesomeIcon>
                                  Modifier la date d'expiration
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </AnimateHeight>
                    <div className='row'>
                      <div className='col-12'>
                        <fieldset className='p-3 border rounded'>
                          <legend className='text-fid w-auto px-3 text-old'>
                            Liste mise à jour carte
                          </legend>
                          <DataTable
                            headerGroups={majTable.headerGroups}
                            getTableBodyProps={majTable.getTableBodyProps}
                            onDoubleClickRow={(index, row) =>
                              CardsStore.onDoubleClickRow(index, row)
                            }
                            rows={majTable.rows}
                            prepareRow={majTable.prepareRow}
                            getTableProps={majTable.getTableProps}
                            columns={CardsStore.props.columnsCards}
                            maxHeight='calc(100vh - 260px)'
                            hidePagination={true}
                          ></DataTable>
                        </fieldset>
                      </div>
                    </div>
                  </TabPane>
                )}
                <TabPane tabId='4' className='animated fadeIn p-0'>
                  <div className='row'>
                    <div className='col-12'>
                      {currentCard.stats && (
                        <div className='client-stats'>
                          {parse(currentCard.stats)}
                        </div>
                      )}
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </BlockUi>
    </Modal>
  );
});

const ModalAffectation = observer((props) => {
  const {
    ModalAffectIsOpen,
    AffectLoading,
    AffectLoadingMsg,
    titleAffect,
    toggleModalAffect,
    typeAffect,
    selectedRow,
    fichesToAffect,
    affect,
    searchCard,
    cardsToAffect,
    searchFiche,
  } = props;

  const [search, setSearch] = useState('');
  const [type, setType] = useState('clientNom');

  return (
    <Modal
      isOpen={ModalAffectIsOpen}
      backdrop='static'
      size='lg'
      toggle={() => toggleModalAffect()}
    >
      <BlockUi
        tag='div'
        blocking={AffectLoading}
        message={AffectLoadingMsg}
        loader={<BarLoader></BarLoader>}
      >
        <ModalHeader toggle={() => toggleModalAffect()}>
          {titleAffect}
        </ModalHeader>
        <ModalBody>
          {typeAffect == 'cardToFiche' && (
            <>
              <div className='row'>
                <div className='col-12'>
                  <h6>Fiche client :</h6>
                  <table className='table table-borderless table-sm'>
                    <tbody>
                      <tr>
                        <td>Nom : </td>
                        <td>
                          <strong>{selectedRow.cltNom}</strong>
                        </td>

                        <td>Prénom : </td>
                        <td>
                          <strong>{selectedRow.cltprenom}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Email : </td>
                        <td>
                          <strong>{selectedRow.cltEmail}</strong>
                        </td>

                        <td>N° Tél mobile : </td>
                        <td>
                          <strong>{selectedRow.cltGsm}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Ville : </td>
                        <td>
                          <strong>{selectedRow.cltVille}</strong>
                        </td>
                        <td>Code postal : </td>
                        <td>
                          <strong>{selectedRow.cltCP}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <hr />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <h6>
                    Ci-dessous, la liste des cartes disponibles pour affectation
                    :
                  </h6>
                </div>
              </div>
              <div className='row'>
                <div className='col-auto form-group'>
                  <label>Rechercher un numéro de carte :</label>

                  <div className='input-group'>
                    <input
                      className='form-control'
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          searchCard(search);
                        }
                      }}
                      onChange={(e) => setSearch(e.target.value)}
                      type='text'
                    />
                    <div className='input-group-append'>
                      <button
                        className='btn btn-success'
                        onClick={() => searchCard(search)}
                        type='button'
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          className='mr-2'
                        ></FontAwesomeIcon>
                        Rechercher
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <SimpleBar
                    style={{ maxHeight: '400px' }}
                    className='mt-3 border rounded'
                  >
                    <table className='table table-sm'>
                      <thead>
                        <th>Numéro de carte</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {cardsToAffect.map((item) => (
                          <tr>
                            <td>{item.clientNumCard}</td>
                            <td>
                              <button
                                onClick={() => affect(item, 'cardToFiche')}
                                className='btn btn-sm btn-success'
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className='mr-2'
                                ></FontAwesomeIcon>
                                Affecter à cette carte
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </SimpleBar>
                </div>
              </div>
            </>
          )}

          {typeAffect == 'ficheToCard' && (
            <>
              <div className='row'>
                <div className='col-12'>
                  <h6>N° de carte :</h6>
                  <p>
                    <strong>{selectedRow.cltNumCard}</strong>
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <hr />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <h6>
                    Ci-dessous, la liste des fiches disponibles pour affectation
                    :
                  </h6>
                </div>
              </div>
              <div className='row'>
                <div className='col-auto form-group'>
                  <label>Rechercher par :</label>
                </div>
                <div className='col-auto form-group'>
                  <select
                    onChange={(e) => setType(e.target.value)}
                    className='form-control'
                  >
                    <option value='clientNom'>Nom</option>
                    <option value='clientPrenom'>Prénom</option>
                    <option value='clientEmail'>Email</option>
                    <option value='clientGsm'>Tél mobile</option>
                  </select>
                </div>
                <div className='col-auto form-group'>
                  <input
                    className='form-control form-control'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        searchFiche(search, type);
                      }
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                    type='text'
                  />
                </div>
                <div className='col-auto'>
                  <button
                    className='btn btn-success'
                    onClick={() => searchFiche(search, type)}
                    type='button'
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      className='mr-2'
                    ></FontAwesomeIcon>
                    Rechercher
                  </button>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <SimpleBar
                    style={{ maxHeight: '400px' }}
                    className='mt-3 border rounded'
                  >
                    <table className='table table-sm'>
                      <thead>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Date de naissance</th>
                        <th>Email</th>
                        <th>N° Tél mobile</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {fichesToAffect.map((item) => (
                          <tr>
                            <td>{item.clientNom}</td>
                            <td>{item.clientPrenom}</td>
                            <td>{item.clientNeLe}</td>
                            <td>{item.clientEmail}</td>
                            <td>{item.clientGsm}</td>
                            <td>
                              <button
                                onClick={() => affect(item, 'ficheToCard')}
                                className='btn btn-sm btn-success'
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className='mr-2'
                                ></FontAwesomeIcon>
                                Affecter à cette fiche
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </SimpleBar>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </BlockUi>
    </Modal>
  );
});

const CardsView = observer((props) => {
  const { filterCriteria } = props;

  useEffect(() => {
    CardsStore.init(filterCriteria);
  }, []);

  // const cols = toJS(CardsStore.props.columns).filter(item => !item.isNotDisplay)
  const cltTable = useTable({
    columns: CardsStore.props.columnsCards,
    data: CardsStore.props.dataCards,
    initialState: {
      hiddenColumns: CardsStore.props.columnsCards
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: CardsStore.props.pageIndexCards,
      pageSize: CardsStore.props.pageLimitCards,
      pageCount: CardsStore.props.pageCountCards,
      pageTotal: CardsStore.props.pageTotalCards,
    },
    deleteCards: (row) => CardsStore.deleteCards(row),
    toggleModalEditCards: (row) => CardsStore.toggleModalEditCards(row),
    toggleModalAffect: (title, row) => CardsStore.toggleModalAffect(title, row),
    toggleModalLst: (row, typeContent) =>
      CardsStore.toggleModalLst(row, typeContent),
    isSociete: UserStore.isSociete(),
    isReseau: UserStore.isReseau(),
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className='container-fluid mt-3'>
      {/* <h2 className="card-title">Liste des cartes</h2> */}
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={CardsStore.props.loading.status}
              message={CardsStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className={classnames({ card: !filterCriteria })}
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between mb-3 flex-column flex-sm-row'>
                  <div className='d-flex'>
                    <ColumnsToggle
                      columns={cltTable.allColumns}
                      className='btn btn-old btn-sm mx-1'
                      toggleColumn={(col) => CardsStore.toggleColumn(col)}
                      // columnsFromTable={CardsStore.props.columnsFromTable}
                    ></ColumnsToggle>
                    <ClientFilter
                      onSubmitFilter={() => CardsStore.getFilteredCards()}
                      className='btn btn-old btn-sm  mx-1'
                      innerButton={
                        <>
                          <FontAwesomeIcon
                            icon={faFilter}
                            className='mr-2'
                          ></FontAwesomeIcon>
                          Recherche avancée
                        </>
                      }
                    ></ClientFilter>
                  </div>
                  <div>
                    <QuickSearch
                      onSubmitFilter={() => CardsStore.getFilteredCards()}
                      fetchSociete={(value) => CardsStore.fetchSociete(value)}
                      className='btn btn-old btn-sm'
                    ></QuickSearch>
                  </div>
                  {UserStore.canDroitExport() && (
                    <div>
                      <Dropdown
                        isOpen={dropdownOpen}
                        className=' ml-auto'
                        toggle={toggle}
                      >
                        <DropdownToggle
                          tag='button'
                          className='btn btn-sm btn-old'
                          data-toggle='dropdown'
                          aria-expanded={dropdownOpen}
                        >
                          Actions
                          <FontAwesomeIcon
                            className='ml-2'
                            icon={faCaretDown}
                          ></FontAwesomeIcon>
                        </DropdownToggle>
                        <DropdownMenu right className='p-0'>
                          <DropdownItem
                            className='dropdown-item btn'
                            onClick={() => CardsStore.exportClients()}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className='mr-2 text-info'
                            ></FontAwesomeIcon>
                            Exporter
                          </DropdownItem>

                          <DropdownItem
                            className='dropdown-item'
                            onClick={() => {
                              CardsStore.editBlackList();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faList}
                              className='mr-2 text-secondary'
                            ></FontAwesomeIcon>
                            Générer la blacklist
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                </div>
                <DataTable
                  headerGroups={cltTable.headerGroups}
                  getTableBodyProps={cltTable.getTableBodyProps}
                  onDoubleClickRow={(index, row) =>
                    CardsStore.toggleModalEditCards(row)
                  }
                  rows={cltTable.rows}
                  prepareRow={cltTable.prepareRow}
                  getTableProps={cltTable.getTableProps}
                  columns={CardsStore.props.columnsCards}
                  loadingMsg={CardsStore.props.loading.msg}
                  loadingStatus={CardsStore.props.loading.status}
                  prevPage={() => CardsStore.prevPageCards()}
                  nextPage={() => CardsStore.nextPageCards()}
                  setSort={(sortBy) => CardsStore.setSortCards(sortBy)}
                  sortOrder={CardsStore.props.sortOrderCards}
                  sortBy={CardsStore.props.sortByCards}
                  goToPage={(page) => CardsStore.goToPageCards(page)}
                  pageCount={CardsStore.props.pageCountCards}
                  pageIndex={CardsStore.props.pageIndexCards}
                  pageLimit={CardsStore.props.pageLimitCards}
                  pageTotal={CardsStore.props.pageTotalCards}
                  maxHeight='calc(100vh - 260px)'
                  btnClassName='btn btn-outline-success btn-sm'
                ></DataTable>
              </div>
              <ModalEditCards
                canRechargementUnique={UserStore.canRechargementUnique()}
                fetchSociete={(value) => CardsStore.fetchSociete(value)}
                currentCard={CardsStore.props.currentCard}
                listCardsUpdate={CardsStore.props.listCardsUpdate}
                initialValuesCards={CardsStore.props.initialValuesCards}
                loadingState={CardsStore.props.loading.status}
                loadingMsg={CardsStore.props.loading.msg}
                isOpenModalEditCards={CardsStore.props.isOpenModalEditCards}
                dataMaj={CardsStore.props.dataMaj}
                columnMaj={CardsStore.props.columnsMaj}
                toggleModalEditCards={(row) =>
                  CardsStore.toggleModalEditCards(row)
                }
                ajouterRecharge={(values) => CardsStore.ajouterRecharge(values)}
                askChangePin={(values) => CardsStore.askChangePin(values)}
                regeneratePin={(values) => CardsStore.regeneratePin(values)}
                disableCard={(values) => CardsStore.disableCard(values)}
                enableCard={(values) => CardsStore.enableCard(values)}
                changeValidite={(values) => CardsStore.changeValidite(values)}
                editInfosClient={(values) => CardsStore.editInfosClient(values)}
                validationAction={CardsStore.props.validationAction}
                validationClient={CardsStore.props.validationClient}
              ></ModalEditCards>
              <ModalAffectation
                toggleModalAffect={(title) =>
                  CardsStore.toggleModalAffect(title)
                }
                typeAffect={CardsStore.props.typeAffect}
                ModalAffectIsOpen={CardsStore.props.ModalAffectIsOpen}
                AffectLoading={CardsStore.props.loading.status}
                AffectLoadingMsg={CardsStore.props.loading.msg}
                titleAffect={CardsStore.props.titleAffect}
                selectedRow={CardsStore.props.selectedRow}
                cardsToAffect={CardsStore.props.cardsToAffect}
                fichesToAffect={CardsStore.props.fichesToAffect}
                searchCard={(value) => CardsStore.searchCard(value)}
                searchFiche={(value, type) =>
                  CardsStore.searchFiche(value, type)
                }
                affect={(item, type) => CardsStore.affect(item, type)}
              ></ModalAffectation>
              <ModalLst
                currentEntity={CardsStore.props.currentClient}
                typeContent={CardsStore.props.typeContent}
                toggleModalLst={() => CardsStore.toggleModalLst()}
                isOpenModalLst={CardsStore.props.isOpenModalLst}
              ></ModalLst>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CardsView;
