import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { unmountComponentAtNode, render } from 'react-dom';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

const ModalInfo = (props) => {
  const { title, typeAlert, text, close } = props;

  const [open, setOpen] = useState(true);

  return (
    <Modal
      backdrop='static'
      centered={true}
      isOpen={open}
      toggle={() => setOpen(!open)}
    >
      <ModalHeader className='no-border'>{title}</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col-auto d-flex center-v center-h'>
            <p>
              {typeAlert === 'info' && (
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  size='3x'
                  className='text-pass'
                ></FontAwesomeIcon>
              )}
              {typeAlert === 'success' && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size='3x'
                  className='text-success'
                ></FontAwesomeIcon>
              )}
              {typeAlert === 'error' && (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size='3x'
                  className='text-danger'
                ></FontAwesomeIcon>
              )}
              {typeAlert === 'warning' && (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size='3x'
                  className='text-warning'
                ></FontAwesomeIcon>
              )}
              {typeAlert === 'question' && (
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  size='3x'
                  className='text-pass'
                ></FontAwesomeIcon>
              )}
            </p>
          </div>
          <div className='col d-flex center-v'>
            <p>{parse(text)}</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='no-border'>
        <button
          className='btn-fuel btn'
          onClick={() => {
            setOpen(!open);
            close(false);
          }}
        >
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
};

const ModalAlertDoublons = (props) => {
  const { title, close, erreursLignes } = props;

  const [open, setOpen] = useState(true);

  const abort = () => {
    setOpen(false);
    close(false);
  };

  return (
    <Modal
      backdrop='static'
      isOpen={open}
      size='lg'
      toggle={() => setOpen(!open)}
    >
      <ModalHeader className='no-border'>{title}</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col-12'>
            <div className='form-group'>
              <p>
                Votre fichier comporte des doublons.
                <br />
                <b>Veuillez modifier votre fichier s'il vous plaît.</b>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className='row d-flex center-h mb-2'>
          <div className='col-auto'>
            <button className='btn-danger btn btn-sm' onClick={abort}>
              <FontAwesomeIcon
                icon={faTimes}
                className='mr-2'
              ></FontAwesomeIcon>
              Fermer
            </button>
          </div>
        </div>
        <div className='row d-flex center-h'>
          <div className='col-12 overflow-auto'>
            <p>
              <b>Listes des erreurs</b>
            </p>
            <div className='overflow-auto' style={{ maxHeight: '400px' }}>
              <table className='table table-sm'>
                <thead>
                  <th>Bon Carburant</th>
                  <th>Ligne</th>
                  <th>Détail</th>
                  <th></th>
                </thead>
                {erreursLignes.map((item) => {
                  return (
                    <tr>
                      <td>{item[0]}</td>
                      <td>{item[1]}</td>
                      <td>{item[2].replace('<br/>', '')}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          size='lg'
                          className='text-danger
                                                '
                        ></FontAwesomeIcon>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='no-border'>
        <button className='btn-danger btn btn-sm' onClick={abort}>
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
};

const modalAlert = (props) => {
  return new Promise((resolve) => {
    let el = document.createElement('div');

    const handleResolve = (result) => {
      unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };

    if (props.type === 'doublons')
      render(<ModalAlertDoublons {...props} close={handleResolve} />, el);
    else render(<ModalInfo {...props} close={handleResolve} />, el);
  });
};

export default modalAlert;
