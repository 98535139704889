import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTable } from 'react-table';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCheck,
  faPen,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import ActivePriceStore from '../../../stores/ActivePriceStore';
import DataTable, {
  ColumnsToggle,
  DropdownPortal,
  Portal,
} from '../../../reusable/DataTable';
import MarchePriceStore from '../../../stores/MarchePriceStore';
import ReferencePriceStore from '../../../stores/ReferencePriceStore';
import { useFormik } from 'formik';
import myYup from '../../../utils/myYup';
import MyField from '../../../reusable/MyField';
import { BarLoader } from '../../Loader/Loading';

// actions bars
export const ActionsProducts = observer((props) => {
  const { row, toggleModalEdit } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const rootSelector = document.getElementById('root');
  const div = document.createElement('div');
  div.className = 'h-0';
  let portalContainer = useRef(div).current;

  useEffect(() => {
    rootSelector.appendChild(portalContainer);
    return () => {
      rootSelector.removeChild(portalContainer);
    };
  }, []);

  return (
    <>
      <DropdownPortal
        id={'edit-drop'}
        isOpen={dropdownOpen}
        toggle={toggle}
        portalContainer={portalContainer}
      >
        <DropdownToggle
          tag='button'
          className='btn btn-xs btn-outline-fuel btn-action'
          data-toggle='dropdown'
          aria-expanded={dropdownOpen}
        >
          {' '}
          Actions
          <FontAwesomeIcon
            className='ml-2'
            icon={faCaretDown}
          ></FontAwesomeIcon>
        </DropdownToggle>
        <Portal target={portalContainer}>
          <DropdownMenu right className='p-0'>
            {/* {(isOperator || isManager) && isReseau && ( */}
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() => toggleModalEdit(row)}
            >
              <FontAwesomeIcon
                icon={faPen}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Modifier
            </DropdownItem>
            {/* )} */}
          </DropdownMenu>
        </Portal>
      </DropdownPortal>
    </>
  );
});

// edit Modal
export const ModalEdit = observer((props) => {
  const {
    isOpenModal,
    toggleModal,
    initialValues,
    onSubmit: formSubmit,
  } = props;

  const { values, errors, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: myYup.object().shape({
      id: myYup.string().required(),
      status: myYup.number().required(),
      unite: myYup.string().date().required(),
      gestionVolume: myYup.string().date().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      formSubmit(values);
      resetForm();
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Modal
      backdrop='static'
      className='modal-sm'
      isOpen={isOpenModal}
      toggle={() => toggleModal()}
    >
      <ModalHeader className='no-border' toggle={() => toggleModal()}>
        Ajuster / Modifier Produit
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col-md-12 form-group'>
            <label>Produit:</label>
            <MyField
              name='id'
              type='number'
              className='form-control form-control-sm'
              onChange={handleChange}
              value={values['id']}
              error={errors['id']}
              disabled
            ></MyField>
          </div>

          <div className='col-md-12 form-group'>
            <label>Statut</label>
            <MyField
              name='status'
              type='select'
              className='form-control form-control-sm'
              onChange={handleChange}
              list={[
                { id: 0, label: 'Inactif' },
                { id: 1, label: 'Actif' },
              ]}
              value={values['status']}
              error={errors['status']}
            ></MyField>
          </div>
          <div className='col-md-12 form-group'>
            <label>Unité Volume:</label>
            <MyField
              name='unite'
              type='select'
              className='form-control form-control-sm'
              onChange={handleChange}
              list={[
                { id: 1, label: 'Litre' },
                { id: 2, label: 'Gallon' },
              ]}
              value={values['unite']}
              error={errors['unite']}
            ></MyField>
          </div>
          <div className='col-md-12 form-group'>
            <label>Controle de volume requis?:</label>
            <MyField
              name='gestionVolume'
              type='select'
              className='form-control form-control-sm'
              onChange={handleChange}
              list={[
                { id: 0, label: 'Non' },
                { id: 1, label: 'Oui' },
              ]}
              value={values['gestionVolume']}
              error={errors['gestionVolume']}
            ></MyField>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        <button
          className='btn-success btn'
          onClick={handleSubmit}
          type='button'
          disabled={ActivePriceStore.props.isLoadingEdit}
        >
          <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
          Enregistrer
        </button>
        <button className='btn-danger btn' onClick={() => toggleModal()}>
          <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});

// Create Modal
export const ModalCreate = observer((props) => {
  const {
    isOpenModal,
    toggleModal,
    initialValues,
    onSubmit: formSubmit,
  } = props;

  useEffect(() => {
    ActivePriceStore.getProducts();
  }, [isOpenModal]);

  const products = ActivePriceStore.props.productsData;

  const { values, errors, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: myYup.object().shape({
      id: myYup.string().required(),
      status: myYup.number().required(),
      unite: myYup.string().date().required(),
      gestionVolume: myYup.string().date().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      formSubmit(values);
      resetForm();
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Modal
      backdrop='static'
      className='modal-sm'
      isOpen={isOpenModal}
      toggle={() => toggleModal()}
    >
      <ModalHeader className='no-border' toggle={() => toggleModal()}>
        Ajouter nouveau produit
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col-md-12 form-group'>
            <label>Produit:</label>
            <MyField
              name='id'
              type='select'
              className='form-control form-control-sm'
              onChange={handleChange}
              list={[{ id: '', label: 'Selectionnez un produit' }, ...products]}
              value={values['id']}
              error={errors['id']}
            ></MyField>
          </div>

          <div className='col-md-12 form-group'>
            <label>Statut</label>
            <MyField
              name='status'
              type='select'
              className='form-control form-control-sm'
              onChange={handleChange}
              list={[
                { id: '', label: 'Selectionnez statut' },
                { id: 0, label: 'Inactif' },
                { id: 1, label: 'Actif' },
              ]}
              value={values['status']}
              error={errors['status']}
            ></MyField>
          </div>
          <div className='col-md-12 form-group'>
            <label>Unité Volume:</label>
            <MyField
              name='unite'
              type='select'
              className='form-control form-control-sm'
              onChange={handleChange}
              list={[
                { id: '', label: 'Selectionnez volume' },
                { id: 1, label: 'Litre' },
                { id: 2, label: 'Gallon' },
              ]}
              value={values['unite']}
              error={errors['unite']}
            ></MyField>
          </div>
          <div className='col-md-12 form-group'>
            <label>Controle de volume requis?:</label>
            <MyField
              name='gestionVolume'
              type='select'
              className='form-control form-control-sm'
              onChange={handleChange}
              list={[
                { id: '', label: '' },
                { id: 0, label: 'Non' },
                { id: 1, label: 'Oui' },
              ]}
              value={values['gestionVolume']}
              error={errors['gestionVolume']}
            ></MyField>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        <button
          className='btn-success btn'
          onClick={handleSubmit}
          type='button'
          disabled={ActivePriceStore.props.isLoadingEdit}
        >
          <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
          Enregistrer
        </button>
        <button className='btn-danger btn' onClick={() => toggleModal()}>
          <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});

// view
const ActivePrice = observer((props) => {
  const prixMarcheSuccess = MarchePriceStore.props.success;
  const prixReferenceSuccess = ReferencePriceStore.props.success;

  useEffect(() => {
    ActivePriceStore.init();
  }, [prixMarcheSuccess, prixReferenceSuccess]);

  const activePriceTable = useTable({
    columns: ActivePriceStore.props.columns,
    data: ActivePriceStore.props.data,
    initialState: {
      hiddenColumns: ActivePriceStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: ActivePriceStore.props.pageIndex,
      pageSize: ActivePriceStore.props.pageLimit,
      pageCount: ActivePriceStore.props.pageCount,
      pageTotal: ActivePriceStore.props.pageTotal,
    },
    setUnitsHeader: ActivePriceStore.setUnitsHeader,
    toggleModalEdit: (row) => ActivePriceStore.toggleModalEdit(row),
  });

  return ActivePriceStore.props.loading.status ? (
    <BarLoader />
  ) : (
    <>
      <div className='d-flex  align-items-end mb-2 '>
        <div className='d-flex ml-auto '>
          <ColumnsToggle
            columns={activePriceTable.allColumns}
            className='btn btn-old btn-sm mx-1 btn-sm-flex-h'
            toggleColumn={(col) => ActivePriceStore.toggleColumn(col)}
          />
          <button
            className='btn btn-old btn-sm mx-1 btn-sm-flex-h ml-4'
            onClick={() => ActivePriceStore.toggleModalCreate()}
          >
            Autoriser nouveau Produit
          </button>
        </div>
      </div>
      <DataTable
        headerGroups={activePriceTable.headerGroups}
        getTableBodyProps={activePriceTable.getTableBodyProps}
        rows={activePriceTable.rows}
        prepareRow={activePriceTable.prepareRow}
        getTableProps={activePriceTable.getTableProps}
        columns={ActivePriceStore.props.columns}
        loadingMsg={ActivePriceStore.props.loading.msg}
        loadingStatus={ActivePriceStore.props.loading.status}
        prevPage={() => ActivePriceStore.prevPage()}
        nextPage={() => ActivePriceStore.nextPage()}
        setSort={(sortBy) => ActivePriceStore.setSort(sortBy)}
        sortOrder={ActivePriceStore.props.sortOrder}
        sortBy={ActivePriceStore.props.sortBy}
        goToPage={(page) => ActivePriceStore.goToPage(page)}
        pageCount={ActivePriceStore.props.pageCount}
        pageIndex={ActivePriceStore.props.pageIndex}
        pageLimit={ActivePriceStore.props.pageLimit}
        pageTotal={ActivePriceStore.props.pageTotal}
        maxHeight='calc(100vh - 260px)'
        btnClassName='btn btn-outline-success btn-sm'
        hidePagination
      />

      <ModalEdit
        isOpenModal={ActivePriceStore.props.isOpenModalEdit}
        toggleModal={() => ActivePriceStore.toggleModalEdit()}
        initialValues={ActivePriceStore.props.currentProduct}
        onSubmit={(values) => ActivePriceStore.editProduct(values)}
      />

      <ModalCreate
        isOpenModal={ActivePriceStore.props.isOpenModalCreate}
        toggleModal={() => ActivePriceStore.toggleModalCreate()}
        initialValues={ActivePriceStore.props.currentProduct}
        onSubmit={(values) => ActivePriceStore.createProduct(values)}
      />
    </>
  );
});

export default ActivePrice;
