import React, { useState, useEffect } from 'react';
import UserStore from '../../stores/UserStore';
import { useLocation } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import { observer } from 'mobx-react';

const ActivateEmailView = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(true);

  const token = query.get('token');
  const selector = query.get('selector');

  useEffect(() => {
    if (token && selector) {
      UserStore.verifyEmail({ token, selector });
      setIsLoading(false);
    }
  }, [selector, token]);

  return (
    <div className='app flex-row align-items-center'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-5'>
            <BlockUi
              tag='div'
              className='card-group rounded login-loader'
              loader={<BarLoader></BarLoader>}
              message={UserStore.props.loading.msg}
              blocking={UserStore.props.loading.status}
            ></BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ActivateEmailView);
