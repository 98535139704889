import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTable } from 'react-table';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import 'simplebar/dist/simplebar.min.css';
import MyField from '../../reusable/MyField';
import DataTable from '../../reusable/DataTable';
import myYup from '../../utils/myYup';
import { useFormik } from 'formik';
import {
  Civilite,
  entityTypes,
  Roles_With_Id_Label,
  statutStationList,
} from '../../utils/constants';
import CardsView from '../Cards/CardsView';
import TransacView from '../Transacs/TransacView';
import MajCardsView from '../MajCards/MajCardsView';
import TpeView from '../Tpe/TpeView';

export function getListTitleBasedOnEntity(prefix, entity) {
  let title = prefix;
  switch (entity.type) {
    case entityTypes.societe:
      title += ' de la société : ';
      break;
    case entityTypes.transacClient:
      title += ' du client : ';
      break;
    case entityTypes.station:
      title += ' de la station : ';
      break;
    default:
      title += ' de : ';
  }
  return title + entity.name;
}

export const ModalLst = observer((props) => {
  const { currentEntity, typeContent, toggleModalLst, isOpenModalLst } = props;
  console.log(props);
  return (
    <Modal
      backdrop='static'
      className='modal-xxxl'
      isOpen={isOpenModalLst}
      toggle={() => toggleModalLst()}
    >
      <ModalHeader className='no-border' toggle={() => toggleModalLst()}>
        {typeContent == 'tpe' && (
          <>{getListTitleBasedOnEntity('Liste des tpe', currentEntity)}</>
        )}
        {typeContent == 'clients' && (
          <>{getListTitleBasedOnEntity('Liste des clients', currentEntity)}</>
        )}
        {(typeContent == 'transacs' || typeContent == 'Client') && (
          <>
            {getListTitleBasedOnEntity('Liste des transactions', currentEntity)}
          </>
        )}
        {typeContent == 'majs' && (
          <>
            {getListTitleBasedOnEntity('Liste des mises à jour', currentEntity)}
          </>
        )}
      </ModalHeader>
      <ModalBody className='p-0'>
        {typeContent == 'tpe' && <TpeView idSta={currentEntity.id}></TpeView>}
        {typeContent == 'clients' && (
          <CardsView
            filterCriteria={
              currentEntity.type === entityTypes.station
                ? 'commercantfid.idcommercantFid=' + currentEntity.id + ' '
                : currentEntity.type === entityTypes.societe
                ? 'clientfid.idSociete=' + currentEntity.id
                : '0'
            }
          ></CardsView>
        )}
        {typeContent == 'transacs' && (
          <TransacView
            filterCriteria={
              currentEntity.type === entityTypes.station
                ? 'idcommercantFid=' + currentEntity.id + ' '
                : currentEntity.type === entityTypes.societe
                ? 'idSociete=' + currentEntity.id
                : '0'
            }
          ></TransacView>
        )}
        {typeContent == 'Client' && (
          <TransacView
            filterCriteria={
              currentEntity.type === entityTypes.transacClient
                ? 'idclientFid=' + currentEntity.id
                : '0'
            }
          ></TransacView>
        )}
        {typeContent == 'majs' && (
          <MajCardsView idSta={currentEntity.id}></MajCardsView>
        )}
      </ModalBody>
    </Modal>
  );
});

export const ModalLstUsers = observer((props) => {
  const {
    loadingStatus,
    loadingMsg,

    currentStation,
    toggleModalLstUsers,
    isOpenModalLstUsers,
    deleteUsers,
    columnsUsers,
    dataUsers,
    pageIndexUsers,
    pageLimitUsers,
    pageCountUsers,
    pageTotalUsers,

    sortOrderUsers,
    sortByUsers,

    nextPageUsers,
    prevPageUsers,
    goToPageUsers,
    setSortUsers,

    toggleModalEditUsers,
  } = props;

  const usersTable = useTable({
    columns: columnsUsers,
    data: dataUsers,
    initialState: {
      hiddenColumns: columnsUsers
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: pageIndexUsers,
      pageSize: pageLimitUsers,
      pageCount: pageCountUsers,
      pageTotal: pageTotalUsers,
    },
    deleteUsers: (row) => deleteUsers(row),
    toggleModalEditUsers: (row, mode) => toggleModalEditUsers(row, mode),
  });

  return (
    <Modal
      backdrop='static'
      className='modal-lg'
      isOpen={isOpenModalLstUsers}
      toggle={() => toggleModalLstUsers()}
    >
      <BlockUi
        tag='div'
        blocking={loadingStatus}
        message={loadingMsg}
        loader={<BarLoader></BarLoader>}
        style={{ minHeight: 'calc(100vh - 150px)' }}
      >
        <ModalHeader className='no-border' toggle={() => toggleModalLstUsers()}>
          Liste utilisateurs TPE de la station :{' '}
          <span className='text-pass'>{currentStation.nomSta}</span>
        </ModalHeader>
        <ModalBody className=''>
          <div className='row mb-3'>
            <div className='col-md-4'>
              <button
                className='btn btn-sm btn-success'
                onClick={() => toggleModalEditUsers(null, 'new')}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className='mr-1'
                ></FontAwesomeIcon>
                Créer un nouvel utilisateur
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <DataTable
                headerGroups={usersTable.headerGroups}
                getTableBodyProps={usersTable.getTableBodyProps}
                onDoubleClickRow={(index, row) =>
                  toggleModalEditUsers(row, 'edit')
                }
                rows={usersTable.rows}
                prepareRow={usersTable.prepareRow}
                getTableProps={usersTable.getTableProps}
                columns={columnsUsers}
                prevPage={() => prevPageUsers()}
                nextPage={() => nextPageUsers()}
                setSort={(sortBy) => setSortUsers(sortBy)}
                sortOrder={sortOrderUsers}
                sortBy={sortByUsers}
                goToPage={(page) => goToPageUsers(page)}
                pageCount={pageCountUsers}
                pageIndex={pageIndexUsers}
                pageLimit={pageLimitUsers}
                pageTotal={pageTotalUsers}
                maxHeight='calc(100vh - 260px)'
                btnClassName='btn btn-outline-success btn-sm'
              ></DataTable>
            </div>
          </div>
        </ModalBody>
      </BlockUi>
    </Modal>
  );
});

export const ModalEditUsers = observer((props) => {
  const {
    loadingStatus,
    loadingMsg,
    submitEditUsers,
    initialValuesUsers,
    editModeUsers,
    isOpenModalEditUsers,
    toggleModalEditUsers,
    currentStation,
  } = props;

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleReset,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesUsers,
    validationSchema: myYup.object().shape({
      userName: myYup.string().required(),
    }),
    onSubmit: (values) =>
      submitEditUsers(values, currentStation.idSta, editModeUsers, handleReset),
    validateOnChange: false,
    validateOnBlur: false,
  });

  const items = [
    'menuCreditPme1',
    'menuDebitPme1',
    'menuCreditPme2',
    'menuDebitPme2',
    'menuInitstd',
    'menuInitstdFilleule',
    'menuInitcec',
    'menuInitID',
    'menuInitCopieCarte',
    'menuMajDateValidite',
    'menuRechargeWeb',
    'menuDuplicata',
    'menuJournalTransac',
    'menuParamTPE',
    'menuEtatCarte',
    'menuTelecollecte',
    'menuAnnulTransac',
    'menuImprimattelec',
  ];

  const [allCheckbox, setAllCheckbox] = useState(false);

  const allIsSelected = () => {
    let all = true;

    for (let key in items) {
      if (values[items[key]]) all = all && true;
      else all = all && false;
    }

    return all;
  };

  useEffect(() => {
    let all = allIsSelected();

    setAllCheckbox(all);
  }, [values]);

  const selectAll = () => {
    let all = allIsSelected();

    if (all)
      for (let key in items) {
        setFieldValue(items[key], 0);
      }
    else
      for (let key in items) {
        setFieldValue(items[key], 1);
      }
  };

  return (
    <Modal
      backdrop='static'
      className='modal-xl'
      isOpen={isOpenModalEditUsers}
      toggle={() => toggleModalEditUsers()}
    >
      <BlockUi
        tag='div'
        blocking={loadingStatus}
        message={loadingMsg}
        loader={<BarLoader></BarLoader>}
        style={{ minHeight: 'calc(100vh - 150px)' }}
      >
        <ModalHeader
          className='no-border'
          toggle={() => toggleModalEditUsers()}
        >
          {editModeUsers == 'new' && <>Nouvel utilisateur TPE</>}
          {editModeUsers == 'edit' && <>Edition utilisateur TPE</>}
        </ModalHeader>
        <ModalBody className=''>
          <fieldset className='p-3 border rounded'>
            <legend className='text-fid w-auto px-3 text-old'>
              Informations utilisateur
            </legend>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Nom utilisateur :</label>
                  <MyField
                    name='userName'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['userName']}
                    error={errors['userName']}
                  ></MyField>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Code utilisateur :</label>
                  <MyField
                    name='userCode'
                    disabled={
                      initialValuesUsers.userCode &&
                      initialValuesUsers.userCode.length == 17
                        ? true
                        : false
                    }
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['userCode']}
                    error={errors['userCode']}
                  ></MyField>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className='p-3 border rounded'>
            <legend className='text-fid w-auto px-3 text-old'>
              Droit d'accès aux Menus
            </legend>
            <div className='row mb-3'>
              <div className='col'>
                <div className='form-group'>
                  <MyField
                    name='allMenu'
                    type={'checkbox'}
                    checkboxLabel='Tout sélectionnner'
                    className='abc-checkbox-primary'
                    customOnChange={() => selectAll()}
                    value={allCheckbox}
                  ></MyField>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <MyField
                    name='menuCreditPme1'
                    type={'checkbox'}
                    checkboxLabel='Menu crédit PME1'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuCreditPme1']}
                    error={errors['menuCreditPme1']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuDebitPme1'
                    type={'checkbox'}
                    checkboxLabel='Menu débit PME1'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuDebitPme1']}
                    error={errors['menuDebitPme1']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuCreditPme2'
                    type={'checkbox'}
                    checkboxLabel='Menu crédit PME2'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuCreditPme2']}
                    error={errors['menuCreditPme2']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuDebitPme2'
                    type={'checkbox'}
                    checkboxLabel='Menu débit PME2'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuDebitPme2']}
                    error={errors['menuDebitPme2']}
                  ></MyField>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <MyField
                    name='menuInitstd'
                    type={'checkbox'}
                    checkboxLabel='Menu init carte standard'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuInitstd']}
                    error={errors['menuInitstd']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuInitstdFilleule'
                    type={'checkbox'}
                    checkboxLabel='Menu init carte standard filleule'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuInitstdFilleule']}
                    error={errors['menuInitstdFilleule']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuInitcec'
                    type={'checkbox'}
                    checkboxLabel='Menu init carte CEC'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuInitcec']}
                    error={errors['menuInitcec']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuInitID'
                    type={'checkbox'}
                    checkboxLabel='Menu init carte ID'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuInitID']}
                    error={errors['menuInitID']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuInitCopieCarte'
                    type={'checkbox'}
                    checkboxLabel='Menu init copie carte'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuInitCopieCarte']}
                    error={errors['menuInitCopieCarte']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuMajDateValidite'
                    type={'checkbox'}
                    checkboxLabel='Menu MAJ date validité'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuMajDateValidite']}
                    error={errors['menuMajDateValidite']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuRechargeWeb'
                    type={'checkbox'}
                    checkboxLabel='Menu Mise à jour Carte'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuRechargeWeb']}
                    error={errors['menuRechargeWeb']}
                  ></MyField>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <MyField
                    name='menuDuplicata'
                    type={'checkbox'}
                    checkboxLabel='Menu duplicata'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuDuplicata']}
                    error={errors['menuDuplicata']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuJournalTransac'
                    type={'checkbox'}
                    checkboxLabel='Menu Journal transactions'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuJournalTransac']}
                    error={errors['menuJournalTransac']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuParamTPE'
                    type={'checkbox'}
                    checkboxLabel='Menu Paramètrage TPE'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuParamTPE']}
                    error={errors['menuParamTPE']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuEtatCarte'
                    type={'checkbox'}
                    checkboxLabel='Menu Etat carte'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuEtatCarte']}
                    error={errors['menuEtatCarte']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuTelecollecte'
                    type={'checkbox'}
                    checkboxLabel='Menu Télécollecte'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuTelecollecte']}
                    error={errors['menuTelecollecte']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuAnnulTransac'
                    type={'checkbox'}
                    checkboxLabel='Menu Annulation dernière transaction'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuAnnulTransac']}
                    error={errors['menuAnnulTransac']}
                  ></MyField>
                </div>
                <div className='form-group'>
                  <MyField
                    name='menuImprimattelec'
                    type={'checkbox'}
                    checkboxLabel='Menu Impression télécollecte'
                    className='abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['menuImprimattelec']}
                    error={errors['menuImprimattelec']}
                  ></MyField>
                </div>
              </div>
            </div>
          </fieldset>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-end'>
          <button className='btn-success btn' onClick={handleSubmit}>
            <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
            Enregistrer
          </button>
          <button
            className='btn-danger btn'
            onClick={() => toggleModalEditUsers()}
          >
            <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
            Fermer
          </button>
        </ModalFooter>
      </BlockUi>
    </Modal>
  );
});

export const ModalEditStation = observer((props) => {
  const {
    currentStation,
    toggleModalEditStation,
    isOpenModalEditStation,
    initialValuesStation,
    submitEditStation,
  } = props;

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValuesStation,
      validationSchema: myYup.object().shape({
        NomCommercant: myYup.string().required(),
        ComEmailContact: myYup.string().email(),
        comEnteteTicket1: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comEnteteTicket2: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comEnteteTicket3: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comEnteteTicket4: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comEnteteTicket5: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comEnteteTicket6: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comEnteteTicket7: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comEnteteTicket8: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comBasTicket1: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comBasTicket2: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comBasTicket3: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comBasTicket4: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comBasTicket5: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comBasTicket6: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comBasTicket7: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
        comBasTicket8: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          })
          .max(24),
      }),
      onSubmit: submitEditStation,
      validateOnChange: false,
      validateOnBlur: false,
    });

  return (
    <Modal
      backdrop='static'
      className='modal-xxxl'
      isOpen={isOpenModalEditStation}
      toggle={() => toggleModalEditStation()}
    >
      <ModalHeader
        className='no-border'
        toggle={() => toggleModalEditStation()}
      >
        Paramétrage station : {currentStation.NomCommercant}
      </ModalHeader>
      <ModalBody className=''>
        <div className='row'>
          <div className='col-md-3'>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Informations station
              </legend>
              <div className='form-group'>
                <label>Civilité </label>
                <MyField
                  name='ComContactCivilite'
                  type='select'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  list={Civilite}
                  value={values['ComContactCivilite']}
                  error={errors['ComContactCivilite']}
                  touched={touched['ComContactCivilite']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Statut station {values['statutStation']}</label>
                <MyField
                  name='statutStation'
                  type='select'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  list={statutStationList}
                  value={values['statutStation']}
                  error={errors['statutStation']}
                  touched={touched['statutStation']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Nom du gérant </label>
                <MyField
                  name='ComNomContact'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComNomContact']}
                  error={errors['ComNomContact']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Prénom du gérant </label>
                <MyField
                  name='ComPrenomContact'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComPrenomContact']}
                  error={errors['ComPrenomContact']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Fonction du gérant </label>
                <MyField
                  name='ComFonctionContact'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComFonctionContact']}
                  error={errors['ComFonctionContact']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Tél. Portable</label>
                <MyField
                  name='ComGsmContact'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComGsmContact']}
                  error={errors['ComGsmContact']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Tél. Fixe</label>
                <MyField
                  name='ComTelContact'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComTelContact']}
                  error={errors['ComTelContact']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Email</label>
                <MyField
                  name='ComEmailContact'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComEmailContact']}
                  error={errors['ComEmailContact']}
                ></MyField>
              </div>
            </fieldset>
          </div>
          <div className='col-md-3'>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Informations gérant
              </legend>
              <div className='form-group'>
                <label>Nom Station </label>
                <MyField
                  name='NomCommercant'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['NomCommercant']}
                  error={errors['NomCommercant']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Nom Expéditeur (campagnes sms)</label>
                <MyField
                  name='ComNomExpediteur'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComNomExpediteur']}
                  error={errors['ComNomExpediteur']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>N° Station</label>
                <MyField
                  name='NumCommercant'
                  disabled={true}
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['NumCommercant']}
                  error={errors['NumCommercant']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>N° de Rérérence interne</label>
                <MyField
                  name='refInterneStation'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['refInterneStation']}
                  error={errors['refInterneStation']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Adresse 1</label>
                <MyField
                  name='ComAdresse1'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComAdresse1']}
                  error={errors['ComAdresse1']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Adresse 2</label>
                <MyField
                  name='ComAdresse2'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComAdresse2']}
                  error={errors['ComAdresse2']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Code Postal</label>
                <MyField
                  name='ComCodePostal'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComCodePostal']}
                  error={errors['ComCodePostal']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Ville</label>
                <MyField
                  name='ComVille'
                  type={'text'}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  value={values['ComVille']}
                  error={errors['ComVille']}
                ></MyField>
              </div>
            </fieldset>
          </div>
          <div className='col-md-3'>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Entête de ticket
              </legend>
              <div className='form-group'>
                <label>Entête 1 : </label>
                <MyField
                  name='comEnteteTicket1'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comEnteteTicket1']}
                  error={errors['comEnteteTicket1']}
                  touched={touched['comEnteteTicket1']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Entête 2 : </label>
                <MyField
                  name='comEnteteTicket2'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comEnteteTicket2']}
                  error={errors['comEnteteTicket2']}
                  touched={touched['comEnteteTicket2']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Entête 3 : </label>
                <MyField
                  name='comEnteteTicket3'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comEnteteTicket3']}
                  error={errors['comEnteteTicket3']}
                  touched={touched['comEnteteTicket3']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Entête 4 : </label>
                <MyField
                  name='comEnteteTicket4'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comEnteteTicket4']}
                  error={errors['comEnteteTicket4']}
                  touched={touched['comEnteteTicket4']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Entête 5 : </label>
                <MyField
                  name='comEnteteTicket5'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comEnteteTicket5']}
                  error={errors['comEnteteTicket5']}
                  touched={touched['comEnteteTicket5']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Entête 6 : </label>
                <MyField
                  name='comEnteteTicket6'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comEnteteTicket6']}
                  error={errors['comEnteteTicket6']}
                  touched={touched['comEnteteTicket6']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Entête 7 : </label>
                <MyField
                  name='comEnteteTicket7'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comEnteteTicket7']}
                  error={errors['comEnteteTicket7']}
                  touched={touched['comEnteteTicket7']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Entête 8 : </label>
                <MyField
                  name='comEnteteTicket8'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comEnteteTicket8']}
                  error={errors['comEnteteTicket8']}
                  touched={touched['comEnteteTicket8']}
                ></MyField>
              </div>
            </fieldset>
          </div>
          <div className='col-md-3'>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Bas de ticket
              </legend>
              <div className='form-group'>
                <label>Bas de ticket 1 : </label>
                <MyField
                  name='comBasTicket1'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comBasTicket1']}
                  error={errors['comBasTicket1']}
                  touched={touched['comBasTicket1']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Bas de ticket 2 : </label>
                <MyField
                  name='comBasTicket2'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comBasTicket2']}
                  error={errors['comBasTicket2']}
                  touched={touched['comBasTicket2']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Bas de ticket 3 : </label>
                <MyField
                  name='comBasTicket3'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comBasTicket3']}
                  error={errors['comBasTicket3']}
                  touched={touched['comBasTicket3']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Bas de ticket 4 : </label>
                <MyField
                  name='comBasTicket4'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comBasTicket4']}
                  error={errors['comBasTicket4']}
                  touched={touched['comBasTicket4']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Bas de ticket 5 : </label>
                <MyField
                  name='comBasTicket5'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comBasTicket5']}
                  error={errors['comBasTicket5']}
                  touched={touched['comBasTicket5']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Bas de ticket 6 : </label>
                <MyField
                  name='comBasTicket6'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comBasTicket6']}
                  error={errors['comBasTicket6']}
                  touched={touched['comBasTicket6']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Bas de ticket 7 : </label>
                <MyField
                  name='comBasTicket7'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comBasTicket7']}
                  error={errors['comBasTicket7']}
                  touched={touched['comBasTicket7']}
                ></MyField>
              </div>
              <div className='form-group'>
                <label>Bas de ticket 8 : </label>
                <MyField
                  name='comBasTicket8'
                  type='text'
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  customOnChange={setFieldValue}
                  value={values['comBasTicket8']}
                  error={errors['comBasTicket8']}
                  touched={touched['comBasTicket8']}
                ></MyField>
              </div>
            </fieldset>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        <button className='btn-success btn' onClick={handleSubmit}>
          <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
          Enregistrer
        </button>
        <button
          className='btn-danger btn'
          onClick={() => toggleModalEditStation()}
        >
          <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});

export const ModalEditSociete = observer((props) => {
  const {
    currentSociete,
    toggleModalEditSociete,
    isOpenModalEditSociete,
    initialValuesSociete,
    submitEditSociete,
  } = props;

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValuesSociete,
      validationSchema: myYup.object().shape({
        refInterne: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
          excludeEmptyString: true,
          message: 'Les caractères spéciaux ne sont pas autorisés',
        }),
        nomSociete: myYup
          .string()
          .required()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          }),
        raisonSociale: myYup
          .string()
          .required()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          }),
        Credit: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
          excludeEmptyString: true,
          message: 'Les caractères spéciaux ne sont pas autorisés',
        }),
        gvtRefNumber: myYup
          .string()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          }),
        firstname_contact: myYup
          .string()
          .required()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          }),
        lastname_contact: myYup
          .string()
          .required()
          .matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères spéciaux ne sont pas autorisés',
          }),
        tel: myYup
          .string()
          .required()
          .matches(/^[a-z A-Z0-9\-]+$/, {
            excludeEmptyString: true,
            message: 'Les caractères non numériques ne sont pas autorisés',
          }),
        email: myYup.string(),
        deleted: myYup.bool(),
        adresse: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
          excludeEmptyString: true,
          message: 'Les caractères spéciaux ne sont pas autorisés',
        }),
        ville: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
          excludeEmptyString: true,
          message: 'Les caractères spéciaux ne sont pas autorisés',
        }),
        cp: myYup.string().matches(/^[a-z A-Z0-9]+$/, {
          excludeEmptyString: true,
          message: 'Les caractères spéciaux ne sont pas autorisés',
        }),
        pays: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, {
          excludeEmptyString: true,
          message: 'Les caractères spéciaux ne sont pas autorisés',
        }),
      }),
      onSubmit: submitEditSociete,
      validateOnChange: false,
      validateOnBlur: false,
    });

  return (
    <Modal
      backdrop='static'
      className='modal-xl'
      isOpen={isOpenModalEditSociete}
      toggle={() => toggleModalEditSociete()}
    >
      <ModalHeader
        className='no-border'
        toggle={() => toggleModalEditSociete()}
      >
        Paramétrage société : {currentSociete.nomSociete}
      </ModalHeader>
      <ModalBody className=''>
        <div className='row'>
          <div className='col-md-12'>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Informations société
              </legend>

              <div className='row'>
                <div className='col-md-4 form-group'>
                  <label>Référence Interne</label>
                  <MyField
                    name='refInterne'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['refInterne']}
                    error={errors['refInterne']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Nom société</label>
                  <MyField
                    name='nomSociete'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['nomSociete']}
                    error={errors['nomSociete']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Raison Sociale</label>
                  <MyField
                    name='raisonSociale'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['raisonSociale']}
                    error={errors['raisonSociale']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Crédit</label>
                  <MyField
                    name='Credit'
                    type={'number'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['Credit']}
                    error={errors['Credit']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Réf. Gouv. Local</label>
                  <MyField
                    name='gvtRefNumber'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['gvtRefNumber']}
                    error={errors['gvtRefNumber']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Prénom personne contact</label>
                  <MyField
                    name='firstname_contact'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['firstname_contact']}
                    error={errors['firstname_contact']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Nom personne contact</label>
                  <MyField
                    name='lastname_contact'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['lastname_contact']}
                    error={errors['lastname_contact']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Téléphone</label>
                  <MyField
                    name='tel'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['tel']}
                    error={errors['tel']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Email contact</label>
                  <MyField
                    name='email'
                    type={'email'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['email']}
                    error={errors['email']}
                  ></MyField>
                </div>
                <div className='col-md-4 border-0 pt-3'>
                  <MyField
                    name='deleted'
                    type={'checkbox'}
                    customOnChange={setFieldValue}
                    value={values['deleted']}
                    error={errors['deleted']}
                    checkboxLabel='Supprimée'
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Adresse</label>
                  <MyField
                    name='adresse'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['adresse']}
                    error={errors['adresse']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Ville</label>
                  <MyField
                    name='ville'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['ville']}
                    error={errors['ville']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Code Postal</label>
                  <MyField
                    name='cp'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['cp']}
                    error={errors['cp']}
                  ></MyField>
                </div>
                <div className='col-md-4 form-group'>
                  <label>Pays</label>
                  <MyField
                    name='pays'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['pays']}
                    error={errors['pays']}
                  ></MyField>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        <button
          className='btn-success btn'
          onClick={handleSubmit}
          type='button'
        >
          <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
          Enregistrer
        </button>
        <button
          className='btn-danger btn'
          onClick={() => toggleModalEditSociete()}
        >
          <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});

export const ModalEditBoUser = observer((props) => {
  const {
    currentBoUser,
    toggleModalEditBoUser,
    isOpenModalEditBoUser,
    initialValuesBoUser,
    submitEditBoUser,
    listSocietes,
    listCommerces,
  } = props;

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValuesBoUser,
      validationSchema: myYup.object().shape({
        usersLogin: myYup.string().required(),
        // usersPass: myYup.string().required(),
        usersDroit: myYup.string(),
        usersIdResFid: myYup.string().required(),
        NP: myYup.string().required(),
        compteActif: myYup.bool(),
        usersMail: myYup.string(),
        two_factors_method: myYup.string().required(),
      }),
      onSubmit: submitEditBoUser,
      validateOnChange: false,
      validateOnBlur: false,
    });

  return (
    <Modal
      backdrop='static'
      className='modal-xl'
      isOpen={isOpenModalEditBoUser}
      toggle={() => toggleModalEditBoUser()}
    >
      <ModalHeader className='no-border' toggle={() => toggleModalEditBoUser()}>
        Création / Mise à jour d'utilisateur :
      </ModalHeader>
      <ModalBody className=''>
        <div className='row'>
          <div className='col-md-12'>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Informations Utilisateurs
              </legend>

              <div className='row'>
                <div className='col-md-6 form-group'>
                  <label for='nom-prenom'>Nom/Prénom</label>
                  <MyField
                    id='nom-prenom'
                    name='NP'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['NP']}
                    error={errors['NP']}
                  ></MyField>
                </div>
                <div className='col-md-6 form-group'>
                  <label for='login'>Login</label>
                  <MyField
                    id='login'
                    name='usersLogin'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['usersLogin']}
                    error={errors['usersLogin']}
                  ></MyField>
                </div>
                {/* <div className='col-md-6 form-group'>
                  <label for='pass'>Mot de passe</label>
                  <MyField
                    id='pass'
                    name='usersPass'
                    type={'password'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['usersPass']}
                    error={errors['usersPass']}
                  ></MyField>
                </div> */}
                <div className='col-md-6 form-group'>
                  <label for='role'>Droit d'accès</label>
                  <MyField
                    id='role'
                    name='usersDroit'
                    type={'select'}
                    list={Roles_With_Id_Label}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['usersDroit']}
                    error={errors['usersDroit']}
                  ></MyField>
                </div>
                {values['usersDroit'] === '5' && (
                  <div className='col-md-6 form-group'>
                    <label for='station'>Station liée</label>
                    <MyField
                      id='station'
                      name='usersIdCom'
                      type={'select'}
                      list={listCommerces}
                      className='form-control form-control-sm'
                      onChange={handleChange}
                      value={values['usersIdCom']}
                      error={errors['usersIdCom']}
                    ></MyField>
                  </div>
                )}
                <div className='col-md-6 form-group'>
                  <label for='societe'>Société associée</label>
                  <MyField
                    id='societe'
                    name='usersIdSoc'
                    type={'select'}
                    list={listSocietes}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['usersIdSoc']}
                    error={errors['usersIdSoc']}
                  ></MyField>
                </div>
                <div className='col-md-6 form-group'>
                  <label for='email'>Email</label>
                  <MyField
                    id='email'
                    name='usersMail'
                    type={'text'}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['usersMail']}
                    error={errors['usersMail']}
                  ></MyField>
                </div>
                {/* ############# */}
                <div className='col-md-6 form-group'>
                  <label for='two_factors_method'>Sécurité 2 Facteurs</label>
                  <MyField
                    id='two_factors_method'
                    name='two_factors_method'
                    type='select'
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    value={values['two_factors_method']}
                    error={errors['two_factors_method']}
                    list={[
                      { id: '0', label: 'Désactivé' },
                      { id: '1', label: 'Google Authenticator' },
                    ]}
                  ></MyField>
                </div>
                <div className='col-md-6 form-group d-flex align-items-center'>
                  <MyField
                    id='actif'
                    name='compteActif'
                    type={'checkbox'}
                    className='abc-checkbox abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['compteActif']}
                    error={errors['compteActif']}
                    checkboxLabel='Compte actif'
                  ></MyField>
                </div>
                {values['usersDroit'] === '4' && (
                  <div className='col-md-6 form-group d-flex align-items-center'>
                    <MyField
                      id='creation'
                      name='DroitCreation'
                      type={'checkbox'}
                      className='abc-checkbox abc-checkbox-primary'
                      customOnChange={setFieldValue}
                      value={values['DroitCreation']}
                      error={errors['DroitCreation']}
                      checkboxLabel='Création utilisateur ?'
                    ></MyField>
                  </div>
                )}
                <div className='col-md-6 form-group d-flex align-items-center'>
                  <MyField
                    id='gestion-commande'
                    name='DroitAdministrationCommandes'
                    type={'checkbox'}
                    className='abc-checkbox abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['DroitAdministrationCommandes']}
                    error={errors['DroitAdministrationCommandes']}
                    checkboxLabel='Production Cartes'
                  ></MyField>
                </div>
                <div className='col-md-6 form-group d-flex align-items-center'>
                  <MyField
                    id='gestion-arrete-comptable'
                    name='DroitArreterComptable'
                    type={'checkbox'}
                    className='abc-checkbox abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['DroitArreterComptable']}
                    error={errors['DroitArreterComptable']}
                    checkboxLabel='Droit sur les arrêtés comptables'
                  ></MyField>
                </div>
                <div className='col-md-6 form-group d-flex align-items-center'>
                  <MyField
                    id='can-export'
                    name='DroitExport'
                    type={'checkbox'}
                    className='abc-checkbox abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['DroitExport']}
                    error={errors['DroitExport']}
                    checkboxLabel="Droit d'exporter des fichiers?"
                  ></MyField>
                </div>
                <div className='col-md-6 form-group d-flex align-items-center'>
                  <MyField
                    id='can-create-societe'
                    name='DroitCreationSociete'
                    type={'checkbox'}
                    className='abc-checkbox abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['DroitCreationSociete']}
                    error={errors['DroitCreationSociete']}
                    checkboxLabel='Droit création société?'
                  ></MyField>
                </div>
                <div className='col-md-6 form-group d-flex align-items-center'>
                  <MyField
                    id='can-create-tpe'
                    name='DroitCreationTPE'
                    type={'checkbox'}
                    className='abc-checkbox abc-checkbox-primary'
                    customOnChange={setFieldValue}
                    value={values['DroitCreationTPE']}
                    error={errors['DroitCreationTPE']}
                    checkboxLabel='Peut ajouter/supprimer un TPE?'
                  ></MyField>
                </div>
                {values['usersDroit'] === '5' && (
                  <div className='col-md-6 form-group d-flex align-items-center'>
                    <MyField
                      id='gestion-bon-carburant'
                      name='DroitGestionBonCarburant'
                      type={'checkbox'}
                      className='abc-checkbox abc-checkbox-primary'
                      customOnChange={setFieldValue}
                      value={values['DroitGestionBonCarburant']}
                      error={errors['DroitGestionBonCarburant']}
                      checkboxLabel='Droit Gestion bon carburant?'
                    ></MyField>
                  </div>
                )}
              </div>
            </fieldset>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end'>
        <button
          className='btn-success btn'
          onClick={handleSubmit}
          type='button'
        >
          <FontAwesomeIcon icon={faCheck} className='mr-2'></FontAwesomeIcon>
          Enregistrer
        </button>
        <button
          className='btn-danger btn'
          onClick={() => toggleModalEditBoUser()}
        >
          <FontAwesomeIcon icon={faTimes} className='mr-2'></FontAwesomeIcon>
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});
