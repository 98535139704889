import React, { useState, useRef } from 'react'
import SidebarView from '../Sidebar/SidebarView';
import HeaderView from '../Header/HeaderView';
import { observer } from 'mobx-react';

const MainLayout = ({ component: Component, ...rest }) => {

  const wrapperRef = useRef()

  useState(() => {
    window.addEventListener('resize', () => {
      // console.log('width : ' + window.innerWidth + ' || ' + window.innerHeight)

      const el = wrapperRef.current

      if (el) {
        if (window.innerWidth <= 992) {
          if (el.classList.contains('sidebar-show')) {
            el.classList.remove('sidebar-show')
          }
        } else {
          if (!el.classList.contains('sidebar-show')) {
            el.classList.add('sidebar-show')
          }
        }
      }

    })
    return () => {
      window.removeEventListener('resize')
    }
  }, [])

  return (
    <div ref={wrapperRef} className="app header-fixed sidebar-fixed sidebar-show">
      <HeaderView wrapperRef={wrapperRef}></HeaderView>
      <div className="app-body">
        <SidebarView></SidebarView>
        <main className="main">
          <Component {...rest}></Component>
        </main>
      </div>
    </div>

  )


}


export default observer(MainLayout)