import React from 'react';
import { observable, toJS } from 'mobx';
import moment from 'moment';
import api from '../api';
import UserStore from './UserStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Operators, valeurBit } from '../utils/constants';
import { number } from '../utils/numeral';
import { ActionsTpe } from '../components/Tpe/TpeView';
import myYup from '../utils/myYup';
import { toast } from 'react-toastify';
import confirmation from '../components/Alerts/Confirmation';
import modalAlert from '../components/Alerts/ModalAlert';

const props = {
  loading: {
    status: false,
    msg: 'Chargement en cours',
  },
  columns: [
    {
      Header: '',
      sortable: true,
      accessor: 'nbDay',
      Cell: ({ cell: { value } }) => {
        // console.log(value)
        if (value == -1)
          return <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>;
        else if (value == 0)
          return (
            <FontAwesomeIcon
              icon={faCircle}
              className='text-success'
            ></FontAwesomeIcon>
          );
        else if (value == 1)
          return (
            <FontAwesomeIcon
              icon={faCircle}
              className='text-orange'
            ></FontAwesomeIcon>
          );
        else if (value > 1)
          return (
            <FontAwesomeIcon
              icon={faCircle}
              className='text-danger'
            ></FontAwesomeIcon>
          );
      },
    },
    {
      Header: 'Version',
      accessor: 'etat_version',
      sortable: true,
      isHidden: false,
    },
    {
      Header: 'Dernière télécollecte',
      accessor: 'etat_dt_hr',
      sortable: true,
      isHidden: false,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Fuseau Horaire',
      accessor: 'time_zone',
      sortable: true,
      isHidden: false,
    },
    {
      Header: 'N° TPE',
      accessor: 'NumTpe',
      sortable: true,
      isHidden: false,
    },
    {
      Header: 'N° série',
      accessor: 'num_serie_tpe',
      sortable: true,
      isHidden: false,
    },
    {
      Header: 'N° de SIM',
      accessor: 'simID',
      sortable: true,
      isHidden: false,
    },
    {
      Header: 'Heure télé.',
      accessor: 'HeureTelecollecte',
      sortable: true,
      isHidden: true,
    },
    {
      Header: 'Commentaires',
      accessor: 'commentaires',
      sortable: true,
      isHidden: true,
    },
    {
      Header: 'Station',
      accessor: 'NomCommercant',
      sortable: true,
      isHidden: false,
    },
    {
      Header: 'Désactivé ?',
      accessor: 'DesactiveTpe',
      sortable: true,
      isHidden: false,
    },
    {
      Header: 'Sans contact?',
      accessor: 'AvecContactless',
      sortable: true,
      isHidden: true,
    },
    {
      Header: 'libellé',
      accessor: 'etat_lib_terminal',
      sortable: true,
      isHidden: true,
    },
    {
      Header: 'Os vers.',
      accessor: 'etat_os_version',
      sortable: true,
      isHidden: false,
    },
    {
      Header: 'Boost. vers.',
      accessor: 'etat_booster_v',
      sortable: true,
      isHidden: true,
    },
    {
      Header: 'Tot RAM',
      accessor: 'etat_tot_ram',
      sortable: true,
      isHidden: false,
      Cell: ({ cell: { value } }) => {
        return number(value);
      },
    },
    {
      Header: 'RAM libre',
      accessor: 'etat_free_ram',
      sortable: true,
      isHidden: false,
      Cell: ({ cell: { value } }) => {
        return number(value);
      },
    },
    {
      Header: 'Tot. flash',
      accessor: 'etat_tot_flash',
      sortable: true,
      isHidden: false,
      Cell: ({ cell: { value } }) => {
        return number(value);
      },
    },
    {
      Header: 'Flash libre',
      accessor: 'etat_free_flash',
      sortable: true,
      isHidden: false,
      Cell: ({ cell: { value } }) => {
        return number(value);
      },
    },
    {
      Header: 'Réseau',
      accessor: 'NomReseau',
      sortable: true,
      isHidden: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({
        row,
        canCreationTPE,
        toggleModalEditTpe,
        updateParamsTpe,
        updateVersionTpe,
      }) => {
        return (
          <ActionsTpe
            row={row.original}
            toggleModalEditTpe={toggleModalEditTpe}
            updateParamsTpe={updateParamsTpe}
            updateVersionTpe={updateVersionTpe}
            canCreationTPE={canCreationTPE}
          ></ActionsTpe>
        );
      },
    },
  ],
  pattern: {
    idcommercantFid: {
      active: false,
      title: 'Stations',
      name: 'idcommercantFid',
      operator: {
        type: 'select',
      },
      field: {
        name: 'idcommercantFid',
        type: 'select',
        list: [
          { id: 1, label: '__--FILTRE NON CHARGE--__' },
          { id: 2, label: '__--FILTRE NON CHARGE--__' },
          { id: 3, label: '__--FILTRE NON CHARGE--__' },
          { id: 4, label: '__--FILTRE NON CHARGE--__' },
          { id: 5, label: '__--FILTRE NON CHARGE--__' },
          { id: 6, label: '__--FILTRE NON CHARGE--__' },
        ],
      },
      value: [
        {
          name: 'idcommercantFid',
          field: 'idcommercantFid',
          operator: '=',
          type: 'text',
          value: null,
        },
      ],
      defaultValue: {
        name: 'idcommercantFid',
        field: 'idcommercantFid',
        type: 'text',
        operator: '=',
        value: null,
      },
    },
    num_serie_tpe: {
      active: false,
      quickSearch: true,
      title: 'N° de série',
      name: 'num_serie_tpe',
      operator: {
        type: 'text',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'num_serie_tpe',
        type: 'text',
      },
      value: [
        {
          name: 'num_serie_tpe',
          field: 'num_serie_tpe',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'num_serie_tpe',
        field: 'num_serie_tpe',
        operator: 'like',
        type: 'text',
        value: '',
      },
    },
  },
  data: [],
  listCommerces: [],
  listTimezones: [],
  pageStart: 0,
  pageIndex: 0,
  pageLimit: 50,
  pageTotal: 0,
  pageCount: 0,
  countActiveFilter: 0,
  savedPattern: {},
  savedFilters: [],
  loadedFilter: null,

  initialValuesTpe: {},
  selectedRowTpe: {},
  validationSchemaTpe: myYup.object().shape({
    ModeFonctionnement: myYup.number().required(),
    CECReseauGere: myYup.number().required(),
    GestionRegles: myYup.number().required(),
    QuestionQuantite: myYup.number().required(),
    AInsertionCarteMontantDefaut: myYup.number().required(),
    UserLoginDureeMax: myYup.number().integer().required(),
  }),

  isOpenModalEditTpe: false,
  initialWhere: null,
};

const TpeStore = {
  props: props,
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  async init(idSta, embeded) {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');

    console.log('initfilter TPE');
    await this.getTimezones();

    if (UserStore.isReseau()) {
      if (!idSta) await this.getCommerces();
    } else {
      delete this.props.pattern.idcommercantFid;
    }

    if (idSta) {
      this.props.initialWhere = ' idcommercantFid=' + idSta + ' ';
      delete this.props.pattern['idcommercantFid'];
    }

    if (!idSta && !embeded) await this.getTpes();
    // await this.getSavedFilters()
    // await CardsStore.getClients()
    this.props.savedPattern = toJS(this.props.pattern);

    this.doLoading(false);
  },
  async getTpes() {
    this.props.sortBy = this.props.sortBy || 'etat_dt_hr';
    this.props.sortOrder = this.props.sortOrder || 'ASC';
    const idRes = UserStore.props.user.idRes;
    const idPart = UserStore.props.user.idPart;
    const idCom = UserStore.props.user.idCom;

    let where = '';

    if (this.props.initialWhere) where = this.getWhere(this.props.initialWhere);
    else if (UserStore.isReseau())
      where = this.getWhere(
        'IdPartenaire = ' + idPart + ' AND iDreseauFid = ' + idRes
      );
    else
      where = this.getWhere(
        'IdPartenaire = ' + idPart + ' AND iDreseauFid = ' + idRes
      );

    // let where = this.getWhere(mainWhere)
    let data = {
      objName: 'listeDesTpe',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      sort: this.props.sortBy,
      dir: this.props.sortOrder,
      // 'query': JSON.stringify(where)
    };

    const res = await api.post('/scripts/tpe/tpe.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
    // this.props.loading.status = false
  },
  async getTimezones() {
    // let where = this.getWhere(mainWhere)
    let data = {
      objName: 'time_zone',
      cmd: 'getRows',
      fields: '["name"]',
    };

    const res = await api.post('/scripts/time_zone/time_zone.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.listTimezones = res.data.rows.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      }
    }
  },
  async getFilteredTpes(idSta) {
    this.doLoading(true, 'Chargement en cours ...');
    if (idSta) {
      this.props.initialWhere = ' idcommercantFid=' + idSta + ' ';
      delete this.props.pattern['idcommercantFid'];
    }
    await this.getTpes();
    this.doLoading(false);
  },
  getQuickSerachItems() {
    let results = [];
    Object.keys(this.props.pattern)
      .filter((item) => this.props.pattern[item].quickSearch)
      .map((item) => {
        results.push({
          id: this.props.pattern[item].name,
          label: this.props.pattern[item].title,
        });
      });

    return results;
  },
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTpes();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTpes();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getTpes();
    this.doLoading(false);
  },
  toggleModalEditTpe(row = false) {
    if (!UserStore.isReseau()) return;
    if (row) {
      this.props.initialValuesTpe = row;
      this.props.selectedRowTpe = row;
    }
    this.props.isOpenModalEditTpe = !this.props.isOpenModalEditTpe;
  },
  async submitEditTpe(values) {
    this.doLoading(true, 'Chargement en cours ...');

    delete values['MenuProtection'];

    delete values['NumCommercant'];
    delete values['NomCommercant'];
    delete values['NomReseau'];
    delete values['IdPartenaire'];
    delete values['iDreseauFid'];
    delete values['NumReseau'];
    delete values['idcommercantFid'];
    delete values['resEns'];
    delete values['nbDay'];
    delete values['time_zone'];

    var affMenu = 0;
    var proMenu = 0;
    var optAvancees = 0;
    if (values['AvecMenuCreditPME1']) affMenu = affMenu | valeurBit[0];
    if (values['AvecMenuDebitPME1']) affMenu = affMenu | valeurBit[1];
    if (values['AvecMenuCreditPME2']) affMenu = affMenu | valeurBit[2];
    if (values['AvecMenuDebitPME2']) affMenu = affMenu | valeurBit[3];
    if (values['affMnuInitSTD']) affMenu = affMenu | valeurBit[4];
    if (values['affMnuInitCEC']) affMenu = affMenu | valeurBit[5];
    if (values['affMnuInitID']) affMenu = affMenu | valeurBit[15];
    if (values['affMnuLogInOut']) affMenu = affMenu | valeurBit[13];
    if (values['affMnuMajDateValidite']) affMenu = affMenu | valeurBit[16]; // Rajout du sous-menu "MAJ date validité" au parametrage des tpe | task#1318 - by G.A.
    if (values['affMnuRechargeWeb']) affMenu = affMenu | valeurBit[17]; // Rajout du sous-menu "Recharge Web" au parametrage des tpe | task#1318 - by G.A.
    if (values['affMnuTraiterCmd']) affMenu = affMenu | valeurBit[18]; // Rajout du sous-menu "Traiter commandes" au parametrage des tpe | task#1318 - by G.A.
    if (values['affMnuInitCopieCarte']) affMenu = affMenu | valeurBit[19]; // Rajout du sous-menu "Init Copie Carte" au parametrage des tpe - by G.A.
    if (values['affMnuEmissionCoupon']) affMenu = affMenu | valeurBit[20];
    if (values['affMnuValidationCoupon'])
      // Rajout de loption de protection du sous-menu "Valider Coupons" au parametrage des tpe - by G.A.
      affMenu = affMenu | valeurBit[21];
    if (values['affMnuBonCarburantScan']) affMenu = affMenu | valeurBit[22];

    if (values['affMnuInitSTDFilleul']) affMenu = affMenu | valeurBit[14];

    if (values['proMnuCreditPme1']) proMenu = proMenu | valeurBit[0];
    if (values['proMnuCreditPme2']) proMenu = proMenu | valeurBit[2];
    if (values['proMnuDebitPme1']) proMenu = proMenu | valeurBit[1];
    if (values['proMnuDebitPme2']) proMenu = proMenu | valeurBit[3];
    if (values['proMnuInitSTD']) proMenu = proMenu | valeurBit[4];
    if (values['proMnuInitCEC']) proMenu = proMenu | valeurBit[5];
    if (values['proMnuDuplicata']) proMenu = proMenu | valeurBit[6];
    if (values['proMnuEtatCarte']) proMenu = proMenu | valeurBit[7];
    if (values['proMnuCancelLastTransac']) proMenu = proMenu | valeurBit[8];
    if (values['proMnuJnlTransac']) proMenu = proMenu | valeurBit[9];
    if (values['proMnuTelecollecte']) proMenu = proMenu | valeurBit[10];
    if (values['proMnuPrintStateTelecol']) proMenu = proMenu | valeurBit[11];
    if (values['proMnuParamsTPE']) proMenu = proMenu | valeurBit[12];
    if (values['proMnuLogInOut']) proMenu = proMenu | valeurBit[13];
    if (values['proMnuInitSTDFilleul']) proMenu = proMenu | valeurBit[14];
    if (values['proMnuInitID']) proMenu = proMenu | valeurBit[15];
    if (values['proMnuMajDateValidite']) proMenu = proMenu | valeurBit[16];
    if (values['proMnuRechargeWeb']) proMenu = proMenu | valeurBit[17];
    if (values['proMnuTraiterCmd']) proMenu = proMenu | valeurBit[18];
    if (values['proMnuInitCopieCarte']) proMenu = proMenu | valeurBit[19];
    if (values['proMnuEmissionCoupon'])
      // Rajout de loption de protection du sous-menu "Emettre Coupon" au parametrage des tpe - by G.A.
      proMenu = proMenu | valeurBit[20];
    if (values['proMnuValidationCoupon'])
      // Rajout de loption de protection du sous-menu "Valider Coupons" au parametrage des tpe - by G.A.
      proMenu = proMenu | valeurBit[21];

    // Rajout des options de l'onglet Options avancées - G.A
    if (values['OPTIONS_INIT_ACTIVATION_EN_ATTENTE'])
      optAvancees = optAvancees | valeurBit[0];
    if (values['OPTIONS_REGLE_CARTE_PREINITIALISEE_EN_USINE'])
      optAvancees = optAvancees | valeurBit[8];
    if (values['OPTIONS_INIT_AVEC_DEMANDE_SAISIE_TEL'])
      optAvancees = optAvancees | valeurBit[6];
    if (values['OPTIONS_REINIT_CARTE_AUTORISE'])
      optAvancees = optAvancees | valeurBit[7];
    if (values['OPTIONS_VERIF_ACTIVATION_AUTO'])
      optAvancees = optAvancees | valeurBit[1];
    if (values['OPTIONS_VERIF_ACTIVATION_PAR_SAISIE_CODE'])
      optAvancees = optAvancees | valeurBit[2];
    if (values['OPTIONS_VERIF_AUTORISATION_ONLINE_INIT_STD'])
      optAvancees = optAvancees | valeurBit[3];
    if (values['OPTIONS_VERIF_AUTORISATION_ONLINE_CREDITPME2'])
      optAvancees = optAvancees | valeurBit[4];
    if (values['OPTIONS_VERIF_AUTORISATION_ONLINE_DEBITPME2'])
      optAvancees = optAvancees | valeurBit[5];

    values['MenuAffichage'] = affMenu;
    values['menuProtection'] = proMenu;
    values['optAvancees'] = optAvancees;

    delete values['GenereLaSTDList'];

    // non prise en compte des champs/options spécifiques aux réseaux
    delete values.statutModuleActivation;
    delete values.statutModuleJeu;
    delete values.statutModuleRecharge;
    delete values.statutModuleBonsPlans;
    delete values.bonsPlansCleAPI;
    delete values.bonsPlansPassAPI;
    delete values.statutModuleAnalytics;
    delete values.positionLogo;
    delete values.modeFonctionnementApp;
    delete values.optionAffichageFicheClient;
    delete values.retourFormulaireSecurise;
    delete values.consultationSolde;
    //        delete(values.ModeSmartphone);
    delete values.autoriserSaisieMontant;
    if (parseInt(values['recharge_packsCount'])) {
      for (var index = 1; index <= values['recharge_packsCount']; index++) {
        delete values['prixPack' + index];
        delete values['valeur' + index + 'PME1'];
        delete values['valeur' + index + 'PME2'];
      }
    }
    delete values['recharge_packsCount'];
    delete values.ResNomExpediteur;
    delete values.ComNomExpediteur;

    delete values.refInterneStation;

    // Options Param Gestion Carburant
    delete values['BarcodeMinLength'];
    delete values['BarcodeMaxLength'];
    delete values['BarcodeAmountPosition'];
    delete values['BarcodeAmountLength'];
    delete values['BarcodeValidityDatePosition'];
    delete values['BarcodeValidityDateFormat'];
    delete values['BarcodeTypeTicket'];
    delete values['BarcodeNbDaysGracePeriod'];

    console.log(toJS(values));
    let data = {
      cmd: 'saveRows',
      objName: 'lesTPE',
      ref: 'SaveTpe',
      data: JSON.stringify([toJS(values)]),
    };

    const res = await api.post('/scripts/tpe/tpe.php', data);
    if (res.data.success) {
      // toast.success("Le tpe a bien été modifié");
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'Le tpe a bien été modifié',
      });
      this.toggleModalEditTpe();
      this.doLoading(true, 'Génération .PAR ...');
      await this.generateParamsTpe(values['idparamsTpeFid']);
      this.doLoading(true, 'Génération .RLST ...');
      await this.generateRuleListTpe(values['idparamsTpeFid']);
      this.doLoading(true, 'Génération .PLST ...');
      await this.generatePdtAutorisesListTpe(values['idparamsTpeFid']);
    } else toast.error('Une erreur est survenue<br>' + res.data.error);
    await this.getTpes();
    this.doLoading(false);
  },
  async generateParamsTpe(idTpe) {
    // this.doLoading(true,'Chargement en cours ...')
    let data = {
      cmd: 'exportParams',
      ref: 'expParams',
      idS: idTpe,
      idx: 0,
    };

    const res = await api.post('/scripts/export/ExportParamsTPE.php', data);
    if (res.data.success) {
      toast.success(
        <>
          Export FUELPASS.PAR
          <br />
          {res.data.error}
        </>
      );
    } else
      toast.error(
        'Une erreur est survenue lors de la génération du fichier .PAR'
      );
    // this.doLoading(false)
  },
  async generateRuleListTpe(idTpe) {
    // this.doLoading(true,'Chargement en cours ...')
    let data = {
      cmd: 'ExportRulelist',
      idtpe: idTpe,
    };

    const res = await api.post('/scripts/export/ExportRuleList.php', data);
    if (res.data.success) {
      toast.success(
        <>
          Export FUELPASS.RLST
          <br />
          {res.data.error}
        </>
      );
    } else
      toast.error(
        'Une erreur est survenue lors de la génération du fichier .RLST'
      );
    // this.doLoading(false)
  },
  async generatePdtAutorisesListTpe(idTpe) {
    // this.doLoading(true,'Chargement en cours ...')
    let data = {
      cmd: 'ExportPdtList',
      idtpe: idTpe,
    };

    const res = await api.post(
      '/scripts/export/ExportProduitsAutorisesList.php',
      data
    );
    if (res.data.success) {
      toast.success(
        <>
          Export FUELPASS.PLIST
          <br />
          {res.data.error}
        </>
      );
    } else
      toast.error(
        'Une erreur est survenue lors de la génération du fichier .PLST'
      );
    // this.doLoading(false)
  },
  async updateVersionTpe(idTpe, numSerie) {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Upgrade FUELPASS',
      text: "<p>Voulez-vous mettre à jour l'application FUELPASS sur ce TPE ? </p>",
    });

    if (next) {
      let data = {
        cmd: 'impfid',
        ref: 'impapi',
        ids: numSerie,
        version: '4.0.9',
        type: 'tpe',
        idparamsTpeFid: idTpe,
      };

      const res = await api.post('/scripts/impfid/impapp.php', data);
      if (res.data.success) {
        toast.success(
          <>
            Mise à jour
            <br />
            {res.data.error}
          </>
        );
      } else toast.error('Une erreur est survenue.');
    }
  },
  async updateParamsTpe(idTpe) {
    this.doLoading(true);
    await this.generateParamsTpe(idTpe);
    this.doLoading(false);
  },
  async getCommerces() {
    const idRes = UserStore.props.user.idRes;
    let data = {
      sort: 'NomCommercant',
      dir: 'DESC',
      cmd: 'getRows',
      objName: 'listCommerce',
      where: 'iDreseauFid=' + idRes,
    };

    const res = await api.post('/scripts/commerce/commercant.php', data);
    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;
      this.props.pattern.idcommercantFid.field.list = data.map((item) => ({
        id: item.id,
        label: item.NomCommercant,
      }));
      this.props.pattern.idcommercantFid.field.list.unshift({
        id: '',
        label: 'Tous',
      });

      this.props.listCommerces = this.props.pattern.idcommercantFid.field.list;

      if (!this.props.pattern.idcommercantFid.value.length)
        this.props.pattern.idcommercantFid.value.forEach((item) => {
          item.value = data.map((item) => item.idcommercantFid);
        });
    }
  },
  //Filter
  toggleItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = !this.props.pattern[key].active;
  },
  activateItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = true;
  },
  changeOperator(key, val, index) {
    console.log(val);
    this.props.pattern[key].value[index].operator = val;
    if (val == 'between') {
      this.props.pattern[key].value[index].value = [null, null];
    } else {
      this.props.pattern[key].value[index].value = '';
    }
  },
  changeValue(key, val, index, order) {
    if (this.props.pattern[key].value[index].operator == 'between') {
      this.props.pattern[key].value[index].value[order] = val;
    } else if (this.props.pattern[key].value[index].operator == 'in') {
      if (val != 'all') {
        let position = this.props.pattern[key].value[index].value.findIndex(
          (o) => o == val
        );
        console.log('position :', position);
        if (position === -1)
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value,
            val,
          ];
        else
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value.filter(
              (item) => item != val
            ),
          ];
      } else {
        if (
          this.props.pattern[key].field.list.length !=
          this.props.pattern[key].value[index].value.length
        ) {
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].field.list.map((item) => item.id),
          ];
        } else {
          this.props.pattern[key].value[index].value = [];
        }
      }
    } else {
      this.props.pattern[key].value[index].value = val;
    }
    console.log(toJS(this.props.pattern[key].value[index].value));
  },
  addItemFilter(key, type) {
    let newVal = { ...this.props.pattern[key].defaultValue };
    newVal.logic = type;
    this.props.pattern[key].value = [...this.props.pattern[key].value, newVal];
  },
  removeItemFilter(key, idx) {
    this.props.pattern[key].value = [
      ...this.props.pattern[key].value.filter((item, index) => index != idx),
    ];
  },
  submitFilter() {
    this.props.savedPattern = toJS(this.props.pattern);
    this.getCountActiveFilter();
  },
  resetFilter() {
    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].value = props.pattern[item].value;
      this.props.savedPattern[item].value = props.pattern[item].value;
      this.props.pattern[item].active = false;
      this.props.savedPattern[item].active = false;
    });
  },
  undoPattern() {
    this.props.pattern = toJS(this.props.savedPattern);
  },
  getWhere(where = '') {
    const main = [
      ...Object.keys(this.props.pattern)
        .filter((item) => this.props.pattern[item].active)
        .map((item) => toJS(this.props.pattern[item].value)),
    ];

    console.log(main);
    for (let key in main) {
      if (main[key].isHidden) continue;
      if (where.length) {
        if (main[key].length) where += ' AND ( ';
      } else where += '( ';
      let pass = 0;
      for (let id in main[key]) {
        if (pass) where += ' ' + main[key][id].logic + ' ';
        pass = 1;

        where += this.getClause(main[key][id], main[key][id].custom);
      }

      if (where.length) {
        if (main[key].length) where += ')';
      }
    }

    return where;
  },
  getClause(clause, isCustom = false) {
    let where = '-+--+-+ ERR -++-+-+';
    if (clause.operator == 'in') {
      where = clause.field + ' IN (' + clause.value.join(',') + ') ';
      console.log(where);
    } else if (clause.operator == 'between') {
      if (clause.type == 'date') {
        where =
          clause.field +
          " BETWEEN '" +
          clause.value[0] +
          "' AND '" +
          clause.value[1] +
          "' ";
      } else if (clause.type == 'number') {
        where =
          clause.field +
          ' BETWEEN ' +
          clause.value[0] +
          ' AND ' +
          clause.value[1] +
          ' ';
      } else {
        where = '__ERROR__1';
      }
    } else if (clause.operator == 'like' || clause.operator == 'not like') {
      if (clause.type == 'text') {
        where =
          clause.field + ' ' + clause.operator + " '%" + clause.value + "%' ";
      } else {
        where = '__ERROR__2';
      }
    } else if (
      clause.operator == '>' ||
      clause.operator == '<' ||
      clause.operator == '>=' ||
      clause.operator == '<=' ||
      clause.operator == '<>' ||
      clause.operator == '='
    ) {
      if (clause.type == 'date' || clause.type == 'text') {
        where =
          clause.field + ' ' + clause.operator + " '" + clause.value + "' ";
      } else if (clause.type == 'number') {
        where = clause.field + ' ' + clause.operator + ' ' + clause.value + ' ';
      } else {
        where = '__ERROR__3';
      }
    } else {
      where = '__ERROR__4';
    }
    if (isCustom)
      return clause.field.replace('_$$$_', where.replace(clause.field, ''));
    return where;
  },
  getCountActiveFilter() {
    this.props.countActiveFilter = Object.keys(this.props.pattern).filter(
      (item) => this.props.pattern[item].active
    ).length;
  },
  //FILTER ACTIONS
  getCurrentFilterJson() {
    let filter = {};

    Object.keys(this.props.pattern)
      .filter((item) => this.props.pattern[item].active)
      .map((item) => {
        filter[item] = this.props.pattern[item].value;
      });

    return toJS(filter);
  },
  //END FILTER
  reset() {
    this.props = props;
  },
};

export default observable(TpeStore);
