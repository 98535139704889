import React, { useState, useEffect, useRef } from 'react';
import UserStore from '../../stores/UserStore';
import {
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownItem,
} from 'reactstrap';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import TpeStore from '../../stores/TpeStore';
import MyField from '../../reusable/MyField';
import { useTable } from 'react-table';
import DataTable, {
  ColumnsToggle,
  DropdownPortal,
  Portal,
} from '../../reusable/DataTable';
import {
  faPen,
  faCaretDown,
  faDownload,
  faCodeBranch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { ModalFooter, Tabs, Tab } from 'react-bootstrap';

const QuickSearch = observer((props) => {
  const { onSubmitFilter, listCommerces, className } = props;

  // console.log(toJS(listCommerces))
  const activateItemFilter = (key) => TpeStore.activateItemFilter(key);
  const changeValue = (key, val, index, order) =>
    TpeStore.changeValue(key, val, index, order);
  const resetFilter = () => TpeStore.resetFilter();

  const [station, setStation] = useState('');
  const [numTpe, setNumtpe] = useState('');

  const doQuickSearch = () => {
    resetFilter();

    if (station.length) {
      activateItemFilter('idcommercantFid');
      changeValue('idcommercantFid', station, 0);
    }

    activateItemFilter('num_serie_tpe');
    changeValue('num_serie_tpe', numTpe, 0);

    onSubmitFilter();
  };

  return (
    <div className='d-flex'>
      <div className='mx-2'>
        <label className='text-nowrap mb-0'>Station</label>
        <MyField
          name='idcommercantFid'
          type='select'
          className='form-control form-control-sm form-control form-control-sm-sm'
          list={listCommerces}
          onChange={(e) => {
            setStation(e.target.value);
          }}
          value={station}
        ></MyField>
      </div>
      <div className='mx-2'>
        <label className='text-nowrap mb-0'>N° Tpe</label>
        <MyField
          name='numTpe'
          type='text'
          className='form-control form-control-sm form-control form-control-sm-sm'
          onChange={(e) => {
            setNumtpe(e.target.value);
          }}
          value={numTpe}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              doQuickSearch();
            }
          }}
        ></MyField>
      </div>
      <div className='mx-2 d-flex align-items-end'>
        <button className={className} onClick={() => doQuickSearch()}>
          Rechercher
        </button>
      </div>
    </div>
  );
});

export const ActionsTpe = observer((props) => {
  const {
    row,
    updateVersionTpe,
    updateParamsTpe,
    toggleModalEditTpe,
    canCreationTPE,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const rootSelector = document.getElementById('root');
  const div = document.createElement('div');
  div.className = 'h-0';
  let portalContainer = useRef(div).current;

  useEffect(() => {
    // let portalContainer = document.createElement("div");
    rootSelector.appendChild(portalContainer);
    return () => {
      rootSelector.removeChild(portalContainer);
    };
  }, []);

  return (
    <>
      {canCreationTPE && (
        <DropdownPortal
          id={'edit-drop'}
          isOpen={dropdownOpen}
          toggle={toggle}
          portalContainer={portalContainer}
        >
          <DropdownToggle
            tag='button'
            className='btn btn-xs btn-outline-fuel btn-action'
            data-toggle='dropdown'
            aria-expanded={dropdownOpen}
          >
            {' '}
            Actions
            <FontAwesomeIcon
              className='ml-2'
              icon={faCaretDown}
            ></FontAwesomeIcon>
          </DropdownToggle>
          <Portal target={portalContainer}>
            <DropdownMenu right className='p-0'>
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => toggleModalEditTpe(row)}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  className='mr-2 text-pass'
                ></FontAwesomeIcon>
                Modifier le TPE
              </DropdownItem>
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => updateParamsTpe(row.idparamsTpeFid)}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  className='mr-2 text-pass'
                ></FontAwesomeIcon>
                Mettre à jour le paramétrage
              </DropdownItem>
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() =>
                  updateVersionTpe(row.idparamsTpeFid, row.num_serie_tpe)
                }
              >
                <FontAwesomeIcon
                  icon={faCodeBranch}
                  className='mr-2 text-pass'
                ></FontAwesomeIcon>
                Mettre à jour la version
              </DropdownItem>
            </DropdownMenu>
          </Portal>
        </DropdownPortal>
      )}
    </>
  );
});

const ModalEditTpe = observer((props) => {
  const {
    selectedRowTpe,
    isOpenModalEditTpe,
    initialValuesTpe,
    toggleModalEditTpe,
    validationSchemaTpe,
    submitEditTpe,
    listTimezones,
  } = props;

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValuesTpe,
      validationSchema: validationSchemaTpe,
      onSubmit: submitEditTpe,
      validateOnChange: true,
      validateOnBlur: true,
    });

  const [showWarning, setShowWarning] = useState(false);

  return (
    <Modal
      backdrop='static'
      isOpen={isOpenModalEditTpe}
      className='modal-xxl'
      toggle={() => toggleModalEditTpe()}
    >
      <ModalHeader className='no-border' toggle={() => toggleModalEditTpe()}>
        Paramétrage TPE : {selectedRowTpe.num_serie_tpe}
      </ModalHeader>
      <ModalBody>
        <>
          <div className='row'>
            <div className='col'>
              <Tabs
                defaultActiveKey='modeFonctionnement'
                className='tabs-form no-child '
              >
                <Tab
                  eventKey='modeFonctionnement'
                  tabClassName='text'
                  className='p-0'
                  title='Mode de fonctionnement'
                >
                  <Tabs defaultActiveKey='general' className='tabs-form'>
                    <Tab eventKey='general' title='Général'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Paramètres de base
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Mode de transmission</label>
                                <MyField
                                  type='select'
                                  list={[
                                    { id: 0, label: 'Online' },
                                    { id: 1, label: 'Offline' },
                                  ]}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='ModeTransmission'
                                  value={values['ModeTransmission']}
                                  error={errors['ModeTransmission']}
                                  touched={touched['ModeTransmission']}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Ticket
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-12 mb-3'>
                                <label for=''>
                                  Mode d'impression du ticket
                                </label>
                                <MyField
                                  type='select'
                                  list={[
                                    {
                                      id: '0',
                                      label: 'Impression ticket client',
                                    },
                                    {
                                      id: '1',
                                      label:
                                        'Impression ticket client, puis ticket commercant',
                                    },
                                    {
                                      id: '2',
                                      label:
                                        'Impression ticket client, puis ticket commercant avec affichage du nom utilisateur',
                                    },
                                    {
                                      id: '3',
                                      label: "Pas d'impression de ticket",
                                    },
                                  ]}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='TypeTicket'
                                  value={values['TypeTicket']}
                                  error={errors['TypeTicket']}
                                  touched={touched['TypeTicket']}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Actions par défaut à insertion carte
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Transaction par défaut à insertion carte:
                                </label>
                                <MyField
                                  type='select'
                                  list={[
                                    { id: 0, label: 'Menu transaction' },
                                    { id: 91, label: 'Encodage' },
                                    { id: 4, label: 'Débit Porte-monnaie' },
                                  ]}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='AInsertionCarteTransactionDefaut'
                                  value={
                                    values['AInsertionCarteTransactionDefaut']
                                  }
                                  error={
                                    errors['AInsertionCarteTransactionDefaut']
                                  }
                                  touched={
                                    touched['AInsertionCarteTransactionDefaut']
                                  }
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Demander montant si raccourci (F1 etc..):
                                </label>
                                <MyField
                                  type='select'
                                  list={[
                                    { id: '0', label: 'Non' },
                                    { id: '1', label: 'Oui' },
                                  ]}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='QuestionMontantSiRaccourci'
                                  value={values['QuestionMontantSiRaccourci']}
                                  error={errors['QuestionMontantSiRaccourci']}
                                  touched={
                                    touched['QuestionMontantSiRaccourci']
                                  }
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Activation de la gestion des autorisations
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Autorisation client standard uniquement par
                                  STDList?:
                                </label>
                                <MyField
                                  type='select'
                                  list={[
                                    { id: '0', label: 'Non' },
                                    { id: '1', label: 'Oui' },
                                  ]}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='STDAutorisationParListe'
                                  value={values['STDAutorisationParListe']}
                                  error={errors['STDAutorisationParListe']}
                                  touched={touched['STDAutorisationParListe']}
                                  disabled={true}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Autorisation client en compte uniquement par
                                  CECList?:
                                </label>
                                <MyField
                                  type='select'
                                  list={[
                                    { id: '0', label: 'Non' },
                                    { id: '1', label: 'Oui' },
                                  ]}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='CECAutorisationParListe'
                                  value={values['CECAutorisationParListe']}
                                  error={errors['CECAutorisationParListe']}
                                  touched={touched['CECAutorisationParListe']}
                                  disabled={true}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey='menuSecurite'
                      title='Menu et sécurité'
                      className='position-relative'
                      onEnter={() => setShowWarning(true)}
                    >
                      <>
                        {showWarning && (
                          <div class='overlay-warning-tpe'>
                            <div class='text-center'>
                              <h5>Attention</h5>
                              <p>
                                Ce réseau est un réseau mutualisé dont les
                                paramètres de fidélité sont gérés par le
                                gestionnaire du réseau dans l'onglet
                                Mutualisation.
                              </p>
                              <button
                                className='btn btn-fuel btn-sm'
                                onClick={() => setShowWarning(false)}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )}
                        <div className='row'>
                          <div className='col-md-6'>
                            <fieldset className='p-3 border rounded'>
                              <legend className='text-fid w-auto px-3 text-old'>
                                Quels Menus à afficher sur les TPE?
                              </legend>
                              <div className='row'>
                                <div className='form-group col-6 mb-3'>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Crédit PME2'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='AvecMenuCreditPME2'
                                    value={values['AvecMenuCreditPME2']}
                                    error={errors['AvecMenuCreditPME2']}
                                    touched={touched['AvecMenuCreditPME2']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Débit PME2'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='AvecMenuDebitPME2'
                                    value={values['AvecMenuDebitPME2']}
                                    error={errors['AvecMenuDebitPME2']}
                                    touched={touched['AvecMenuDebitPME2']}
                                  ></MyField>
                                </div>
                                <div className='form-group col-6 mb-3'>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Login/Logout'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='affMnuLogInOut'
                                    value={values['affMnuLogInOut']}
                                    error={errors['affMnuLogInOut']}
                                    touched={touched['affMnuLogInOut']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Mise à jour Carte'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='affMnuRechargeWeb'
                                    value={values['affMnuRechargeWeb']}
                                    error={errors['affMnuRechargeWeb']}
                                    touched={touched['affMnuRechargeWeb']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Encodage'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='affMnuTraiterCmd'
                                    value={values['affMnuTraiterCmd']}
                                    error={errors['affMnuTraiterCmd']}
                                    touched={touched['affMnuTraiterCmd']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Scan Bons Carburants'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='affMnuBonCarburantScan'
                                    value={values['affMnuBonCarburantScan']}
                                    error={errors['affMnuBonCarburantScan']}
                                    touched={touched['affMnuBonCarburantScan']}
                                  ></MyField>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                          <div className='col-md-6'>
                            <fieldset className='p-3 border rounded'>
                              <legend className='text-fid w-auto px-3 text-old'>
                                Quels Menus doivent être protégés par mots de
                                passe sur les TPE?
                              </legend>
                              <div className='row'>
                                <div className='form-group col-6 mb-3'>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Crédit PME2'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='chkBoxproMnuCreditPme2'
                                    value={values['chkBoxproMnuCreditPme2']}
                                    error={errors['chkBoxproMnuCreditPme2']}
                                    touched={touched['chkBoxproMnuCreditPme2']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Débit PME2'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuDebitPme2'
                                    value={values['proMnuDebitPme2']}
                                    error={errors['proMnuDebitPme2']}
                                    touched={touched['proMnuDebitPme2']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Emettre Coupon'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuEmissionCoupon'
                                    value={values['proMnuEmissionCoupon']}
                                    error={errors['proMnuEmissionCoupon']}
                                    touched={touched['proMnuEmissionCoupon']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Valider Coupons'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuEmissionCoupon'
                                    value={values['proMnuEmissionCoupon']}
                                    error={errors['proMnuEmissionCoupon']}
                                    touched={touched['proMnuEmissionCoupon']}
                                  ></MyField>
                                </div>
                                <div className='form-group col-6 mb-3'>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='MAJ date validité'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuMajDateValidite'
                                    value={values['proMnuMajDateValidite']}
                                    error={errors['proMnuMajDateValidite']}
                                    touched={touched['proMnuMajDateValidite']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Mise à jour Carte'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuRechargeWeb'
                                    value={values['proMnuRechargeWeb']}
                                    error={errors['proMnuRechargeWeb']}
                                    touched={touched['proMnuRechargeWeb']}
                                  ></MyField>
                                </div>
                                <div className='form-group col-6 mb-3'>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Duplicata'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuDuplicata'
                                    value={values['proMnuDuplicata']}
                                    error={errors['proMnuDuplicata']}
                                    touched={touched['proMnuDuplicata']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Journal transactions'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuJnlTransac'
                                    value={values['proMnuJnlTransac']}
                                    error={errors['proMnuJnlTransac']}
                                    touched={touched['proMnuJnlTransac']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Paramètrage TPE'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuParamsTPE'
                                    value={values['proMnuParamsTPE']}
                                    error={errors['proMnuParamsTPE']}
                                    touched={touched['proMnuParamsTPE']}
                                  ></MyField>
                                </div>
                                <div className='form-group col-6 mb-3'>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Etat carte'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuEtatCarte'
                                    value={values['proMnuEtatCarte']}
                                    error={errors['proMnuEtatCarte']}
                                    touched={touched['proMnuEtatCarte']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Télécollecte'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuTelecollecte'
                                    value={values['proMnuTelecollecte']}
                                    error={errors['proMnuTelecollecte']}
                                    touched={touched['proMnuTelecollecte']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Annulation dernière transaction'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuCancelLastTransac'
                                    value={values['proMnuCancelLastTransac']}
                                    error={errors['proMnuCancelLastTransac']}
                                    touched={touched['proMnuCancelLastTransac']}
                                  ></MyField>
                                  <MyField
                                    type='checkbox'
                                    checkboxLabel='Impression télécollecte'
                                    className='abc-checkbox-primary'
                                    customOnChange={setFieldValue}
                                    name='proMnuPrintStateTelecol'
                                    value={values['proMnuPrintStateTelecol']}
                                    error={errors['proMnuPrintStateTelecol']}
                                    touched={touched['proMnuPrintStateTelecol']}
                                  ></MyField>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                          <div className='col-md-4'>
                            <fieldset className='p-3 border rounded'>
                              <legend className='text-fid w-auto px-3 text-old'>
                                Paramètres de Login/Logout
                              </legend>
                              <div className='row'>
                                <div className='form-group col-12 mb-3'>
                                  <label for=''>Mode de login/logout</label>
                                  <MyField
                                    type='select'
                                    list={[
                                      {
                                        id: 0,
                                        label: 'Menu et/ou temporisation',
                                      },
                                      {
                                        id: 1,
                                        label:
                                          'Question "Carte suivante ?" après transaction',
                                      },
                                    ]}
                                    className='form-control form-control-sm'
                                    onChange={handleChange}
                                    name='UserLogoutType'
                                    value={values['UserLogoutType']}
                                    error={errors['UserLogoutType']}
                                    touched={touched['UserLogoutType']}
                                  ></MyField>
                                </div>
                                <div className='form-group col-12 mb-3'>
                                  <label for=''>
                                    Temporisation de Logout en minutes
                                    (0=infini)
                                  </label>
                                  <MyField
                                    type='number'
                                    className='form-control form-control-sm'
                                    onChange={handleChange}
                                    name='UserLoginDureeMax'
                                    value={values['UserLoginDureeMax']}
                                    error={errors['UserLoginDureeMax']}
                                    touched={touched['UserLoginDureeMax']}
                                  ></MyField>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </>
                    </Tab>
                    <Tab eventKey='defPme' title='Définitions PME'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              PME2
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Nom de l'unité au singulier:
                                </label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  disabled={true}
                                  name='NomUnitePME2'
                                  value={values['NomUnitePME2']}
                                  error={errors['NomUnitePME2']}
                                  touched={touched['NomUnitePME2']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Nom de l'unité au pluriel:</label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  disabled={true}
                                  name='NomUnitesPME2'
                                  value={values['NomUnitesPME2']}
                                  error={errors['NomUnitesPME2']}
                                  touched={touched['NomUnitesPME2']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Nom de l'unité lors de la saisie d'un montant
                                  (ex: PTS, etc...):
                                </label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  disabled={true}
                                  name='NomCourtUnitePME2'
                                  value={values['NomCourtUnitePME2']}
                                  error={errors['NomCourtUnitePME2']}
                                  touched={touched['NomCourtUnitePME2']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Affichage avec décimales?:</label>
                                <MyField
                                  type='select'
                                  list={[
                                    {
                                      id: 0,
                                      label: 'Unités affichées sans décimales',
                                    },
                                    {
                                      id: 1,
                                      label:
                                        'Unités affichées avec 2 décimales',
                                    },
                                  ]}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  disabled={true}
                                  name='AvecDecimalesPME2'
                                  value={values['AvecDecimalesPME2']}
                                  error={errors['AvecDecimalesPME2']}
                                  touched={touched['AvecDecimalesPME2']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-12 mb-3'>
                                <h5>Seuils</h5>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Montant maximum autorisé sur le PME:
                                </label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  disabled={true}
                                  name='MontantMaxPME2'
                                  value={values['MontantMaxPME2']}
                                  error={errors['MontantMaxPME2']}
                                  touched={touched['MontantMaxPME2']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Montant minimum du PME pour autoriser des
                                  débits:
                                </label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  disabled={true}
                                  name='MontantDeblocPME2'
                                  value={values['MontantDeblocPME2']}
                                  error={errors['MontantDeblocPME2']}
                                  touched={touched['MontantDeblocPME2']}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey='gestBonCar' title='Gestion bons carburants'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Paramétrage du Code Bar
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Longueur Min Code bar:</label>
                                <MyField
                                  type='text'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='BarcodeMinLength'
                                  value={values['BarcodeMinLength']}
                                  error={errors['BarcodeMinLength']}
                                  touched={touched['BarcodeMinLength']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Longueur Max Code bar:</label>
                                <MyField
                                  type='text'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='BarcodeMaxLength'
                                  value={values['BarcodeMaxLength']}
                                  error={errors['BarcodeMaxLength']}
                                  touched={touched['BarcodeMaxLength']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Longueur du montant:</label>
                                <MyField
                                  type='text'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='BarcodeAmountLength'
                                  value={values['BarcodeAmountLength']}
                                  error={errors['BarcodeAmountLength']}
                                  touched={touched['BarcodeAmountLength']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Position du montant:</label>
                                <MyField
                                  type='text'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='BarcodeAmountPosition'
                                  value={values['BarcodeAmountPosition']}
                                  error={errors['BarcodeAmountPosition']}
                                  touched={touched['BarcodeAmountPosition']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Format de Date:</label>
                                <MyField
                                  type='text'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='BarcodeValidityDateFormat'
                                  value={values['BarcodeValidityDateFormat']}
                                  error={errors['BarcodeValidityDateFormat']}
                                  touched={touched['BarcodeValidityDateFormat']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Position de la Date:</label>
                                <MyField
                                  type='text'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='BarcodeValidityDatePosition'
                                  value={values['BarcodeValidityDatePosition']}
                                  error={errors['BarcodeValidityDatePosition']}
                                  touched={
                                    touched['BarcodeValidityDatePosition']
                                  }
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>NB Jour Grâce Période:</label>

                                <MyField
                                  type='text'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='BarcodeNbDaysGracePeriod'
                                  value={values['BarcodeNbDaysGracePeriod']}
                                  error={errors['BarcodeNbDaysGracePeriod']}
                                  touched={touched['BarcodeNbDaysGracePeriod']}
                                ></MyField>
                                <small>
                                  * Nombre de jours supplémentaires autorisés
                                  après la date d'expiration.
                                </small>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Type Ticket:</label>
                                <MyField
                                  type='text'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='BarcodeTypeTicket'
                                  value={values['BarcodeTypeTicket']}
                                  error={errors['BarcodeTypeTicket']}
                                  touched={touched['BarcodeTypeTicket']}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Tab>
                <Tab eventKey='tpe' title='TPE'>
                  <Tabs defaultActiveKey='telecollecte' className='tabs-form'>
                    <Tab eventKey='telecollecte' title='Télécollecte'>
                      <div className='row'>
                        <div className='col-md-10'>
                          <div className='row'>
                            <div className='col-md-4'>
                              <label for=''>Heure de télécollecte:</label>
                              <MyField
                                type='time'
                                className='form-control form-control-sm'
                                customOnChange={setFieldValue}
                                name='HeureTelecollecte'
                                value={values['HeureTelecollecte']}
                                error={errors['HeureTelecollecte']}
                                touched={touched['HeureTelecollecte']}
                              ></MyField>
                            </div>
                            <div className='col-md-4'>
                              <label for=''>Fuseau Horaire:</label>
                              <MyField
                                type='select'
                                list={listTimezones}
                                onChange={handleChange}
                                className='form-control form-control-sm'
                                name='idTimeZone'
                                value={values['idTimeZone']}
                                error={errors['idTimeZone']}
                                touched={touched['idTimeZone']}
                              ></MyField>
                            </div>
                            <div className='col-md-4'>
                              <label for=''>Type de télécollecte:</label>
                              <MyField
                                type='select'
                                list={[
                                  { id: 0, label: 'Aucune Télécollecte' },
                                  { id: 1, label: 'Connexion RTC' },
                                  {
                                    id: 2,
                                    label: 'Connexion en mode IP Ethernet',
                                  },
                                  {
                                    id: 3,
                                    label: 'Connexion en mode IP GPRS interne',
                                  },
                                ]}
                                className='form-control form-control-sm'
                                onChange={handleChange}
                                name='TypeTelecollecte'
                                value={values['TypeTelecollecte']}
                                error={errors['TypeTelecollecte']}
                                touched={touched['TypeTelecollecte']}
                              ></MyField>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Paramètres GPRS
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>APN GPRS:</label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='GprsApn'
                                  value={values['GprsApn']}
                                  error={errors['GprsApn']}
                                  touched={touched['GprsApn']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Login GPRS:</label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='GprsLogin'
                                  value={values['GprsLogin']}
                                  error={errors['GprsLogin']}
                                  touched={touched['GprsLogin']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Mot de passe GPRS:</label>
                                <MyField
                                  type='password'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='GprsMdp'
                                  value={values['GprsMdp']}
                                  error={errors['GprsMdp']}
                                  touched={touched['GprsMdp']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>code pin SIM GPRS:</label>
                                <MyField
                                  type='password'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='GprsPin'
                                  value={values['GprsPin']}
                                  error={errors['GprsPin']}
                                  touched={touched['GprsPin']}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Paramètres RTC
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>N° de tél:</label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='RtcIpNumTelServeur'
                                  value={values['RtcIpNumTelServeur']}
                                  error={errors['RtcIpNumTelServeur']}
                                  touched={touched['RtcIpNumTelServeur']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Login:</label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='RtcIpLogin'
                                  value={values['RtcIpLogin']}
                                  error={errors['RtcIpLogin']}
                                  touched={touched['RtcIpLogin']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Mode de passe:</label>
                                <MyField
                                  type='password'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='RtcIpMdp'
                                  value={values['RtcIpMdp']}
                                  error={errors['RtcIpMdp']}
                                  touched={touched['RtcIpMdp']}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey='infosTpe' title='Informations TPE'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Générales
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>N° TPE:</label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  disabled={true}
                                  name='NumTpe'
                                  value={values['NumTpe']}
                                  error={errors['NumTpe']}
                                  touched={touched['NumTpe']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>N° de série:</label>
                                <MyField
                                  type='number'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='num_serie_tpe'
                                  value={values['num_serie_tpe']}
                                  error={errors['num_serie_tpe']}
                                  touched={touched['num_serie_tpe']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>N° de SIM:</label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='simID'
                                  value={values['simID']}
                                  error={errors['simID']}
                                  touched={touched['simID']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Carte client en compte acceptée:
                                </label>
                                <MyField
                                  type='select'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  list={[
                                    { id: '0', label: 'Non' },
                                    { id: '1', label: 'Oui' },
                                  ]}
                                  name='CECTPEAccepte'
                                  value={values['CECTPEAccepte']}
                                  error={errors['CECTPEAccepte']}
                                  touched={touched['CECTPEAccepte']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Sans contact:</label>
                                <MyField
                                  type='select'
                                  disabled={true}
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  list={[
                                    { id: '0', label: 'Non' },
                                    { id: '1', label: 'Oui' },
                                  ]}
                                  name='AvecContactless'
                                  value={values['AvecContactless']}
                                  error={errors['AvecContactless']}
                                  touched={touched['AvecContactless']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Commentaires:</label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='commentaires'
                                  value={values['commentaires']}
                                  error={errors['commentaires']}
                                  touched={touched['commentaires']}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div className='col-md-6'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Activation
                            </legend>
                            <div className='row'>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>Désactiver ce TPE:</label>
                                <MyField
                                  type='select'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  list={[
                                    { id: '0', label: 'Non' },
                                    { id: '1', label: 'Oui' },
                                  ]}
                                  name='DesactiveTpe'
                                  value={values['DesactiveTpe']}
                                  error={errors['DesactiveTpe']}
                                  touched={touched['DesactiveTpe']}
                                ></MyField>
                              </div>
                              <div className='form-group col-md-6 mb-3'>
                                <label for=''>
                                  Désactiver après N jours sans télécollecte
                                  (0=infini):
                                </label>
                                <MyField
                                  type='text'
                                  className='form-control form-control-sm'
                                  onChange={handleChange}
                                  name='DureeValiditeTpe'
                                  value={values['DureeValiditeTpe']}
                                  error={errors['DureeValiditeTpe']}
                                  touched={touched['DureeValiditeTpe']}
                                ></MyField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <fieldset className='p-3 border rounded'>
                        <legend className='text-fid w-auto px-3 text-old'>
                          Techniques
                        </legend>
                        <div className='form-group col-12 mb-3'>
                          <MyField
                            type='textarea'
                            disabled={true}
                            placeholder='Pas de données à afficher'
                            className='form-control form-control-sm'
                            onChange={handleChange}
                            name='infostech'
                            value={values['infostech']}
                            error={errors['infostech']}
                            touched={touched['infostech']}
                          ></MyField>
                        </div>
                      </fieldset>
                    </Tab>
                    <Tab eventKey='msg_accueil' title="Message d'accueil">
                      <fieldset className='p-3 border rounded'>
                        <legend className='text-fid w-auto px-3 text-old'>
                          Messages
                        </legend>
                        <div className='form-group col-12 mb-3'>
                          <label for=''>Message1:</label>
                          <MyField
                            type='text'
                            className='form-control form-control-sm'
                            onChange={handleChange}
                            name='MessageIdleTPE1'
                            value={values['MessageIdleTPE1']}
                            error={errors['MessageIdleTPE1']}
                            touched={touched['MessageIdleTPE1']}
                          ></MyField>
                        </div>
                        <div className='form-group col-12 mb-3'>
                          <label for=''>Message2:</label>
                          <MyField
                            type='text'
                            className='form-control form-control-sm'
                            onChange={handleChange}
                            name='MessageIdleTPE2'
                            value={values['MessageIdleTPE2']}
                            error={errors['MessageIdleTPE2']}
                            touched={touched['MessageIdleTPE2']}
                          ></MyField>
                        </div>
                        <div className='form-group col-12 mb-3'>
                          <label for=''>Message3:</label>
                          <MyField
                            type='text'
                            className='form-control form-control-sm'
                            onChange={handleChange}
                            name='MessageIdleTPE3'
                            value={values['MessageIdleTPE3']}
                            error={errors['MessageIdleTPE3']}
                            touched={touched['MessageIdleTPE3']}
                          ></MyField>
                        </div>
                        <div className='form-group col-12 mb-3'>
                          <label for=''>Message4:</label>
                          <MyField
                            type='text'
                            className='form-control form-control-sm'
                            onChange={handleChange}
                            name='MessageIdleTPE4'
                            value={values['MessageIdleTPE4']}
                            error={errors['MessageIdleTPE4']}
                            touched={touched['MessageIdleTPE4']}
                          ></MyField>
                        </div>
                      </fieldset>
                    </Tab>
                  </Tabs>
                </Tab>
              </Tabs>
            </div>
          </div>
        </>
      </ModalBody>
      <ModalFooter className='no-border'>
        <button
          type='submit'
          className='btn-success btn'
          onClick={handleSubmit}
        >
          Enregistrer
        </button>
        <button className='btn-danger btn' onClick={() => toggleModalEditTpe()}>
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});

const TpeView = observer((props) => {
  const { idSta, embeded, maxHeight } = props;

  useEffect(() => {
    TpeStore.init(idSta, embeded);
  }, []);

  useEffect(() => {
    console.log(idSta);
    if (idSta && embeded) TpeStore.getFilteredTpes(idSta);
  }, [idSta]);

  const tpesTable = useTable({
    columns: TpeStore.props.columns,
    data: TpeStore.props.data,
    initialState: {
      hiddenColumns: TpeStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: TpeStore.props.pageIndex,
      pageSize: TpeStore.props.pageLimit,
      pageCount: TpeStore.props.pageCount,
      pageTotal: TpeStore.props.pageTotal,
    },
    toggleModalEditTpe: (row) => TpeStore.toggleModalEditTpe(row),
    canCreationTPE: UserStore.canCreationTPE(),
    updateParamsTpe: (idTpe) => TpeStore.updateParamsTpe(idTpe),
    updateVersionTpe: (idTpe, numSerie) =>
      TpeStore.updateVersionTpe(idTpe, numSerie),
    editTpe: (id) => TpeStore.editTpe(id),
  });

  return (
    <div className='animated fadeIn'>
      <div className='row'>
        <div className='col-12'>
          <BlockUi
            tag='div'
            blocking={TpeStore.props.loading.status}
            message={TpeStore.props.loading.msg}
            loader={<BarLoader></BarLoader>}
          >
            <div className={classnames({ 'card-body': !embeded })}>
              <div className='d-flex align-items-end mb-2'>
                <div>
                  {!embeded && (
                    <QuickSearch
                      onSubmitFilter={() => TpeStore.getFilteredTpes()}
                      className='btn btn-fuel btn-sm btn-sm-flex-h'
                      listCommerces={TpeStore.props.listCommerces}
                      idSta={idSta}
                      embeded={embeded}
                    ></QuickSearch>
                  )}
                </div>
                <div className='d-flex ml-auto'>
                  <ColumnsToggle
                    columns={tpesTable.allColumns}
                    className='btn btn-fuel btn-sm mx-1 btn-sm-flex-h'
                    toggleColumn={(col) => TpeStore.toggleColumn(col)}
                  ></ColumnsToggle>
                </div>
              </div>
              <DataTable
                headerGroups={tpesTable.headerGroups}
                getTableBodyProps={tpesTable.getTableBodyProps}
                headerClassName='bg-theme-grey'
                onDoubleClickRow={(id, row) => TpeStore.toggleModalEditTpe(row)}
                rows={tpesTable.rows}
                prepareRow={tpesTable.prepareRow}
                getTableProps={tpesTable.getTableProps}
                columns={TpeStore.props.columns}
                loadingMsg={TpeStore.props.loading.msg}
                loadingStatus={TpeStore.props.loading.status}
                prevPage={() => TpeStore.prevPage()}
                nextPage={() => TpeStore.nextPage()}
                setSort={(sortBy) => TpeStore.setSort(sortBy)}
                sortOrder={TpeStore.props.sortOrder}
                sortBy={TpeStore.props.sortBy}
                goToPage={(page) => TpeStore.goToPage(page)}
                pageCount={TpeStore.props.pageCount}
                pageIndex={TpeStore.props.pageIndex}
                pageLimit={TpeStore.props.pageLimit}
                pageTotal={TpeStore.props.pageTotal}
                maxHeight={maxHeight}
                btnClassName='btn btn-outline-fuel btn-sm'
              ></DataTable>
              <ModalEditTpe
                isOpenModalEditTpe={TpeStore.props.isOpenModalEditTpe}
                listTimezones={TpeStore.props.listTimezones}
                validationSchemaTpe={TpeStore.props.validationSchemaTpe}
                selectedRowTpe={TpeStore.props.selectedRowTpe}
                initialValuesTpe={TpeStore.props.initialValuesTpe}
                toggleModalEditTpe={(row) => TpeStore.toggleModalEditTpe(row)}
                submitEditTpe={(values) => TpeStore.submitEditTpe(values)}
              ></ModalEditTpe>
            </div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
});

export default TpeView;
