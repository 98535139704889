import { observable } from 'mobx';
import api from '../api';
import { toast } from 'react-toastify';
import MainStore from './MainStore';
import LogRocket from 'logrocket';

const props = {
  /***** Props ******/
  access_token: null,
  user: {},
  fail: {
    status: false,
    msg: '',
  },
  loading: {
    status: false,
    msg: 'Connexion en cours ...',
  },
  action: 'login',
  successChangePassword: false,
  forgot: {
    error: '',
    success: false,
  },
  startupWarningActivated: true,
  isLoggedIn: false,
  twoFactorsVerified: false,
  check2FAOPT: false,
  uriConfig: null,
  qrCode: null,
  secret2FA: null,
};

const UserStore = {
  props: props,

  /***** Methods ******/
  async init() {
    this.reset();
    this.props.loading.status = true;
    console.log('init User');
    await this.whoami();
    window.user = this;
    this.props.isLoggedIn = true;
    this.props.loading.status = false;
  },
  setAction(action) {
    this.props.successForgot = false;
    this.props.action = action;
  },
  async whoami() {
    const res = await api.post('/scripts/whoami.php');
    if (res && res.data.success) {
      res.data.user.isAuthenticated = true;
      this.props.user = res.data.user;
      this.props.fullname = res.data.nom;
      if (process.env['NODE_ENV'] === 'production') {
        LogRocket.identify(res.data.user['idUser'], {
          name: res.data.user['nom'],
          email: res.data.user['email'],
          username: res.data.user['login'],
          company: res.data.user['nom_reseau'],
        });
      }
    } else {
      this.props.user = {};
    }
  },
  async login(creds, cb) {
    this.props.loading.msg = 'Connexion en cours ...';
    this.props.loading.status = true;
    let data = {
      loginEuroFid: creds.username,
      passEuroFid: creds.password,
      archF: 'recherche',
    };

    const res = await api.post('/scripts/login.php', data);
    if (res && res.data.success) {
      // console.log('LOGIN_SUCCESS');

      // await this.whoami();
      this.props.fail.status = false;
      this.props.fail.msg = '';
      this.props.loading.status = false;
      // this.props.user = res.data.user

      // this.props.user.isAuthenticated = true;
      cb();
    } else {
      this.props.fail.status = true;
      this.props.fail.msg = res.data.msg;
      setTimeout(() => (this.props.loading.status = false), 3000);
    }
    return res.data;
  },
  async forgot(creds, resetForm) {
    this.props.loading.msg = 'Connexion en cours ...';
    this.props.loading.status = true;
    let data = {
      email: creds.email,
      version: 'v2',
    };
    try {
      const res = await api.post('/scripts/users/forgot_password.php', data);

      // this.setAction('successForgot');
      this.props.loading.status = false;
      this.props.forgot = res.data;
      resetForm();
    } catch (error) {
      this.props.loading.status = false;
      toast.error('Veuillez reessayer plus tard.');
    }
  },
  async resetPassword(creds, cb) {
    this.props.loading.msg = 'Connexion en cours ...';
    this.props.loading.status = true;
    let data = {
      new_password_1: creds.new_password_1,
      new_password_2: creds.new_password_2,
      token: creds.token,
      selector: creds.selector,
      version: 'v2',
    };
    try {
      const res = await api.post(
        '/scripts/users/reset_password_update.php',
        data
      );

      if (res.data.success) {
        this.props.loading.status = false;
        toast.success('Mot de passe modifié avec succès.');
        return cb();
      } else {
        this.props.loading.status = false;
        toast.error(res.data.error);
      }
    } catch (error) {
      this.props.loading.status = false;
      toast.error('Veuillez reessayer plus tard.');
    }
  },
  async verifyEmail(creds) {
    this.props.loading.msg = 'Veuillez patientez ...';
    this.props.loading.status = true;
    let data = {
      token: creds.token,
      selector: creds.selector,
    };
    try {
      const res = await api.post('/scripts/users/verify_email_v2.php', data);

      if (res.data.success) {
        this.props.loading.status = false;
        toast.success(
          'Adresse mail vérifiée ! Vos identifiants de connexion ont été transmis par email.'
        );
      } else {
        this.props.loading.status = false;
        toast.error(res.data.error);
      }
    } catch (error) {
      this.props.loading.status = false;
      toast.error('Erreur. Veuillez reessayer plus tard.');
    }
  },
  setSuccessChangePassword(status) {
    this.props.successChangePassword = status;
  },
  async submitChangePassword(values, resetForm) {
    this.props.loading.msg = 'Connexion en cours ...';
    this.props.loading.status = true;

    let data = {
      cmd: 'changePassword',
      data: JSON.stringify(values),
      version: 'v2',
    };

    const res = await api.post('/scripts/users/users.php', data);

    if (res.data.success) {
      this.props.successChangePassword = true;
    } else {
      toast.error(res.data.error);
    }

    resetForm();

    this.props.loading.status = false;
  },
  async logout() {
    console.log('Logout user');
    this.props.loading.status = true;
    // localStorage.removeItem('fuelpass_api');

    MainStore.reset();

    this.reset();
    this.remove2FAFromLocalStorage();
    await api.get('/scripts/logout.php');
  },
  async verifyIf2FAIsActivated(cb) {
    try {
      this.props.loading.status = true;
      const res = await api.post('/scripts/sess_check_two_factors_auth_v2.php');

      if (res.data.success) {
        this.props.loading.status = false;
        // if (res.data.two_factors_verified !== '1') {
        if (!res.data.check_2fa_otp) {
          this.add2FAToLocalStorage();
          this.props.user.isAuthenticated = true;
          await this.whoami();
          return cb();
        }
        this.props.twoFactorsVerified = res.data.two_factors_verified;
        this.props.check2FAOPT = res.data.check_2fa_otp;
        this.props.user.user_id = res.data.user_id;
      }
    } catch (error) {
      this.props.loading.status = false;
      console.log(error);
    }
  },
  async checkOTP(creeds, cb, shouldAuth = true) {
    try {
      this.props.loading.status = true;
      const res = await api.post(
        '/scripts/users/two_factors_authentication_verify_otp.php',
        creeds
      );

      if (res.data.success) {
        this.props.loading.status = false;
        if (shouldAuth) {
          this.props.user.isAuthenticated = true;
          this.add2FAToLocalStorage();
          await this.whoami();
        }
        return cb();
      } else {
        this.props.loading.status = false;
        toast.error(res.data.error);
      }
    } catch (error) {
      this.props.loading.status = false;
      // toast.error(error.data.error);
      console.log(error);
    }
  },
  async setup2FA(token, selector) {
    try {
      this.props.loading.status = true;

      const res = await api.post(
        '/scripts/users/two_factors_authentication_setup_v2.php',
        { token, selector }
      );

      if (res.data.success && res.data.qrcode_base64) {
        this.props.qrCode = res.data.qrcode_base64;
        this.props.secret2FA = res.data.secret;
        this.props.uriConfig = res.data.uri_config;
      } else {
        toast.error(
          res.data.error ||
            "Erreur lors de la configuration de l'authentification à deux facteurs."
        );
      }
      this.props.loading.status = false;
    } catch (error) {
      this.props.loading.status = false;
      console.log(error);
    }
  },
  toggleFirstTimev2() {
    this.props.user.firstTimeV2 = false;
  },
  // Role utilisateur
  isReseau() {
    return parseInt(this.props.user.scope) === 4;
  },
  isStation() {
    return parseInt(this.props.user.scope) === 5;
  },
  isSaisie() {
    return parseInt(this.props.user.scope) === 6;
  },
  isSociete() {
    return parseInt(this.props.user.scope) === 7;
  },
  /*
    
    //Profil utilisateur
    [1, "Manager"],
    [2, "Opérateur"],
    [3, "Commercial"]

    //Role (leGroupeFid)
    [1, 'Super Administrateur']
    [2, 'Superviseur']
    [3, 'Technicien']
    [4, 'Reseau']
    [5, 'Station']
    [6, 'Saisie']
    [7, 'Societe']

    */
  isManager() {
    return parseInt(this.props.user.profil) === 1;
  },
  isOperator() {
    return parseInt(this.props.user.profil) === 2;
  },
  isCommercial() {
    return parseInt(this.props.user.profil) === 3;
  },
  // Type de fidélité

  //access
  canAnalytics() {
    return parseInt(this.props.user.statutModuleAnalytics) !== 0;
  },
  //Permissions
  canCreateUser() {
    return parseInt(this.props.user.DroitCreation) === 1;
  },
  canRechargementUnique() {
    return parseInt(this.props.user.DroitRechargementUnique) === 1;
  },
  canAdministrationCommandes() {
    return parseInt(this.props.user.DroitAdministrationCommandes) === 1;
  },
  canValidationCredit() {
    return parseInt(this.props.user.DroitValidationCredit) === 1;
  },
  canArreterComptable() {
    return parseInt(this.props.user.DroitArreterComptable) === 1;
  },
  canDroitExport() {
    return parseInt(this.props.user.DroitExport) === 1;
  },
  canCreationTPE() {
    return parseInt(this.props.user.DroitCreationTPE) === 1;
  },
  canCreationSociete() {
    return parseInt(this.props.user.DroitCreationSociete) === 1;
  },
  canGestionBonCarburant() {
    return parseInt(this.props.user.DroitGestionBonCarburant) === 1;
  },
  estAlliance() {
    return parseInt(this.props.user.estAlliance) === 1;
  },
  reset() {
    this.props = props;
  },
  add2FAToLocalStorage() {
    localStorage.setItem('fuelpass_2fa', '1');
  },
  remove2FAFromLocalStorage() {
    localStorage.removeItem('fuelpass_2fa');
  },
};

export default observable(UserStore);
