import React from 'react';

import ActivePrice from './ActivePrice';
import ReferencePrice from './ReferencePrice';
import MarchePrice from './MarchePrice';

const PricePanel = () => {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <fieldset className='p-1 border rounded'>
            <legend>Vos Produits actifs</legend>
            <ActivePrice />
          </fieldset>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col'>
          <fieldset className='p-1 border rounded'>
            <legend>Prix de référence</legend>
            <ReferencePrice />
          </fieldset>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col'>
          <fieldset className='p-1 border rounded'>
            <legend>Prix du marché</legend>
            <MarchePrice />
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default PricePanel;
