import React from 'react';
import { observable, toJS } from 'mobx';
import { Operators } from '../utils/constants';
import moment from 'moment';
import api from '../api';
import UserStore from './UserStore';
import { number } from '../utils/numeral';
import confirmation from '../components/Alerts/Confirmation';
import { toast } from 'react-toastify';
import modalAlert from '../components/Alerts/ModalAlert';

const props = {
  loading: {
    status: false,
    msg: 'Connexion en cours',
  },
  columns: [
    {
      Header: 'Réseau',
      isSortable: true,
      accessor: 'NomReseau',
      field: 'NomReseau',
    },
    {
      Header: 'Sociéte',
      isSortable: true,
      accessor: 'NomSociete',
      field: 'NomSociete',
    },
    {
      Header: 'N° Bon Carburant',
      isSortable: true,
      accessor: 'NumeroBonCarburant',
      field: 'NumeroBonCarburant',
    },
    {
      Header: 'Ajouté par:',
      isSortable: true,
      accessor: 'NP',
      field: 'NP',
    },
    {
      Header: 'Ajouté le:',
      isSortable: true,
      accessor: 'DateInsertion',
      field: 'DateInsertion',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Attribué par:',
      isSortable: true,
      accessor: 'NPAttribution',
      field: 'NPAttribution',
    },
    {
      Header: 'Attribué le:',
      isSortable: true,
      accessor: 'DateAttribution',
      field: 'DateAttribution',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Scanné le:',
      isSortable: true,
      accessor: 'DateScann',
      field: 'DateScann',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Validé le:',
      isSortable: true,
      accessor: 'DateValidation',
      field: 'DateValidation',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Expire le:',
      isSortable: true,
      accessor: 'DateExpiration',
      field: 'DateExpiration',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Statut',
      accessor: 'Statut',
      field: 'Statut',
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        if (value == 0)
          return <div class='bg-light   back-bon'>Disponible</div>;
        else if (value == 1)
          return <div class='bg-success  back-bon'>Validé</div>;
        else if (value == 2)
          return <div class='bg-danger  back-bon'>Périmé'</div>;
        else return value;
      },
    },

    {
      Header: 'Station',
      accessor: 'NomCommercant',
      field: 'NomCommercant',
    },

    {
      Header: 'Montant',
      accessor: 'Montant',
      field: 'Montant',
      width: 140,
      fixed: false,
      isSortable: false,
      hideable: true,
      Cell: ({ nomUnitePME2, cell: { value } }) => {
        if (value != 0) {
          return number(value / 100) + ' ' + nomUnitePME2;
        } else {
          return '';
        }
      },
    },
  ],
  pattern: {
    statut: {
      active: false,
      quickSearch: true,
      title: 'Statut',
      name: 'statut',
      operator: {
        type: 'multiple',
        list: ['in'],
      },
      field: {
        name: 'statut',
        type: 'multiple',
        list: [
          { id: 0, label: 'Disponible' },
          { id: 1, label: 'Validé' },
          { id: 2, label: 'Périmé' },
        ],
      },
      value: [
        {
          name: 'statut',
          field: 'statut',
          type: null,
          operator: 'in',
          value: [],
        },
      ],
      defaultValue: {
        name: 'statut',
        field: 'statut',
        type: null,
        operator: 'in',
        value: [],
      },
    },
    'bc.IdCommercant': {
      active: false,
      title: 'Stations',
      name: 'bc.IdCommercant',
      operator: {
        type: 'select',
        list: ['='].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'bc.IdCommercant',
        type: 'reactSelect',
        list: [
          { id: 1, label: '__--FILTRE NON CHARGE--__' },
          { id: 2, label: '__--FILTRE NON CHARGE--__' },
          { id: 3, label: '__--FILTRE NON CHARGE--__' },
          { id: 4, label: '__--FILTRE NON CHARGE--__' },
          { id: 5, label: '__--FILTRE NON CHARGE--__' },
          { id: 6, label: '__--FILTRE NON CHARGE--__' },
        ],
      },
      value: [
        {
          name: 'bc.IdCommercant',
          field: 'bc.IdCommercant',
          operator: '=',
          type: 'text',
          value: null,
        },
      ],
      defaultValue: {
        name: 'bc.IdCommercant',
        field: 'bc.IdCommercant',
        type: 'text',
        operator: '=',
        value: null,
      },
    },
    'bc.IdSociete': {
      active: false,
      title: 'Sociétés',
      name: 'bc.IdSociete',
      operator: {
        type: 'select',
        list: ['='].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'bc.IdSociete',
        type: 'reactSelect',
        list: [
          { id: 1, label: '__--FILTRE NON CHARGE--__' },
          { id: 2, label: '__--FILTRE NON CHARGE--__' },
          { id: 3, label: '__--FILTRE NON CHARGE--__' },
          { id: 4, label: '__--FILTRE NON CHARGE--__' },
          { id: 5, label: '__--FILTRE NON CHARGE--__' },
          { id: 6, label: '__--FILTRE NON CHARGE--__' },
        ],
      },
      value: [
        {
          name: 'bc.IdSociete',
          field: 'bc.IdSociete',
          operator: '=',
          type: 'text',
          value: null,
        },
      ],
      defaultValue: {
        name: 'bc.IdSociete',
        field: 'bc.IdSociete',
        type: 'text',
        operator: '=',
        value: null,
      },
    },
    DateInsertion: {
      active: false,
      title: "Date de d'ajout",
      canAddOr: false,
      name: 'DateInsertion',
      operator: {
        type: 'select',
        list: ['between'].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'DateInsertion',
        type: 'date',
      },
      value: [
        {
          name: 'DateInsertion',
          field: 'DATE(DateInsertion)',
          type: 'date',
          // custom: true,
          operator: 'between',
          value: [null, null],
        },
      ],
      defaultValue: {
        name: 'DateInsertion',
        field: 'DATE(DateInsertion)',
        type: 'date',
        // custom: true,
        operator: 'between',
        value: [null, null],
      },
    },
    DateAttribution: {
      active: false,
      title: "Date de d'attribution",
      canAddOr: false,
      name: 'DateAttribution',
      operator: {
        type: 'select',
        list: ['between'].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'DateAttribution',
        type: 'date',
      },
      value: [
        {
          name: 'DateAttribution',
          field: 'DATE(DateAttribution)',
          type: 'date',
          // custom: true,
          operator: 'between',
          value: [null, null],
        },
      ],
      defaultValue: {
        name: 'DateAttribution',
        field: 'DATE(DateAttribution)',
        type: 'date',
        // custom: true,
        operator: 'between',
        value: [null, null],
      },
    },
    DateValidation: {
      active: false,
      title: 'Date de validation',
      canAddOr: false,
      name: 'DateValidation',
      operator: {
        type: 'select',
        list: ['between'].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'DateValidation',
        type: 'date',
      },
      value: [
        {
          name: 'DateValidation',
          field: 'DATE(DateValidation)',
          type: 'date',
          // custom: true,
          operator: 'between',
          value: [null, null],
        },
      ],
      defaultValue: {
        name: 'DateValidation',
        field: 'DATE(DateValidation)',
        type: 'date',
        // custom: true,
        operator: 'between',
        value: [null, null],
      },
    },
    DateScann: {
      active: false,
      title: 'Date de Scan',
      canAddOr: false,
      name: 'DateScann',
      operator: {
        type: 'select',
        list: ['between'].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'DateScann',
        type: 'date',
      },
      value: [
        {
          name: 'DateScann',
          field: 'DATE(DateScann)',
          type: 'date',
          // custom: true,
          operator: 'between',
          value: [null, null],
        },
      ],
      defaultValue: {
        name: 'DateScann',
        field: 'DATE(DateScann)',
        type: 'date',
        // custom: true,
        operator: 'between',
        value: [null, null],
      },
    },
  },
  data: [],

  listCommerces: [],
  listSocietes: [],

  savedPattern: {},

  pageStart: 0,
  pageIndex: 0,
  pageLimit: 50,
  pageCount: 0,
  pageTotal: 0,
  sortBy: 'Id',
  sortOrder: 'DESC',

  isOpenModalSetBons: false,
  isOpenModalAddBons: false,
  listeErreurs: [],
};

const MajCardsStore = {
  /***** Props ******/
  props: props,

  /***** Methods ******/
  async init() {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    console.log('init');
    await this.initFilterBons();
    await this.getCommerces();
    await this.getSocietes();
    await this.getBons();
    this.doLoading(false);
  },
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  toggleModalSetBons() {
    this.props.isOpenModalSetBons = !this.props.isOpenModalSetBons;
  },
  toggleModalAddBons() {
    this.props.isOpenModalAddBons = !this.props.isOpenModalAddBons;
  },
  async getFilteredBons() {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.pageStart = 0;
    this.props.pageIndex = 0;
    this.props.pageLimit = 30;
    await this.getBons();
    this.doLoading(false);
  },
  async initFilterBons(pattern) {
    if (pattern) this.props.pattern = pattern;
    this.props.savedPattern = toJS(this.props.pattern);
  },
  async submitSetBons(values) {
    console.log(toJS(values));
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Attribuer des Bons Carburants',
      text:
        'Vous souhaitez ' +
        (values['societe'].label
          ? ' attribuer à la societe <b>' + values['societe'].label + '</b> les'
          : '<b>affecter une société vide </b> aux') +
        ' bons suivants: <br><br>Du <b>' +
        values['numBonCarburantStart'] +
        '</b><br><br>Au <b>' +
        values['numBonCarburantEnd'] +
        '</b><br><br> Confirmez-vous cette action ?</b></p>',
    });

    if (!next) return;

    this.toggleModalSetBons();
    this.doLoading(true, 'Chargement en cours ...');

    const idRes = UserStore.props.user.idRes;
    const idUser = UserStore.props.user.idUser;

    let data = {
      idSociete: values['societe'].label,
      numBonCarburantStart: 'Numéro du 1er bon carburant du lot',
      numBonCarburantEnd: 'Numéro du dernier bon carburant du lot',
      cmd: 'verifier_attribution',
      res: idRes,
      user: idUser,
      societe: values['societe'].id,
      numBonCarburantStart: values['numBonCarburantStart'],
      numBonCarburantEnd: values['numBonCarburantEnd'],
    };

    const res = await api.post(
      '/scripts/bon_carburant/attribution_bons_carburant.php',
      data
    );

    if (res.data.success) {
      const next2 = await confirmation({
        type: 'confirmNoPass',
        title: '',
        text:
          '<p>Total: ' +
          res.data.nbTotal +
          ' bon(s) à attribuer.</p>' +
          (res.data.nbTotalAttribues
            ? '<b>Attention: ' +
              res.data.nbTotalAttribues +
              'bon(s) déjà attribué(s) !<b>'
            : '') +
          '<br><p>Confirmez-vous cette action ?</p>',
      });

      if (!next2) {
        this.doLoading(false);
        return;
      }

      this.attribuer(
        idRes,
        idUser,
        values['societe'].id,
        values['numBonCarburantStart'],
        values['numBonCarburantEnd']
      );
    } else {
      if (res.data.listes_erreurs) {
        const next3 = await confirmation({
          type: 'confirmAffectBons',
          listeErreurs: res.data.listes_erreurs,
        });

        if (!next3) {
          this.doLoading(false);
          return;
        }

        this.attribuer(
          idRes,
          idUser,
          values['societe'].id,
          values['numBonCarburantStart'],
          values['numBonCarburantEnd']
        );
      } else toast.error(<>{'Une erreur est survenue'}</>);
    }
    await this.getBons();

    this.doLoading(false);
  },
  async attribuer(idRes, idUser, idSoc, bonStart, bonEnd) {
    let data = {
      cmd: 'attribuer',
      res: idRes,
      user: idUser,
      societe: idSoc,
      numBonCarburantStart: bonStart,
      numBonCarburantEnd: bonEnd,
    };

    const res = await api.post(
      '/scripts/bon_carburant/attribution_bons_carburant.php',
      data
    );

    if (res.data.success) {
      // toast.success(<>L'attribution a bien été effectué<br /><b>{res.data.nb}</b> bons ont été attribués ! </>)

      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: "L'attribution a bien été effectué<br /><b>{res.data.nb}</b> bons ont été attribués !",
      });

      modalAlert({
        title: 'Bons carburants attribués',
        typeAlert: 'success',
        text:
          "L'attribution a bien été effectué<br><b>" +
          res.data.nb +
          '</b> bons ont été attribués ! ',
      });
    } else {
      toast.error(
        <>
          Une erreur est survenue
          <br />
          {res.data.erreur}
        </>
      );
    }
  },
  async getBons() {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    this.props.sortBy = this.props.sortBy || 'Id';
    this.props.sortOrder = this.props.sortOrder || 'DESC';

    let where = this.getWhere('bc.idReseau=' + idRes);
    let data = {
      objName: 'listeBonCarburant',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post(
      '/scripts/bon_carburant/listeBonCarburant.php',
      data
    );
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
  },
  async getCommerces() {
    const idRes = UserStore.props.user.idRes;
    let data = {
      sort: 'NomCommercant',
      dir: 'DESC',
      cmd: 'getRows',
      objName: 'listCommerce',
      where: 'iDreseauFid=' + idRes,
    };

    const res = await api.post('/scripts/commerce/commercant.php', data);
    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;
      this.props.pattern['bc.IdCommercant'].field.list = data.map((item) => ({
        id: item.id,
        label: item.NomCommercant,
      }));
      // this.props.pattern.idcommercantFid.field.list.unshift({ id: '', label: 'Tous' })

      this.props.listCommerces =
        this.props.pattern['bc.IdCommercant'].field.list;

      if (!this.props.pattern['bc.IdCommercant'].value.length)
        this.props.pattern['bc.IdCommercant'].value.forEach((item) => {
          item.value = data.map((item) => item.idcommercantFid);
        });
    }
  },
  async getSocietes() {
    const idRes = UserStore.props.user.idRes;

    let data = {
      cmd: 'getRows',
      objName: 'listSociete',
      sort: ' nomSociete ASC',
      where: 'idReseau =' + idRes + ' and deleted = 0',
    };

    const res = await api.post('/scripts/societe/societe.php', data);
    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;
      this.props.pattern['bc.IdSociete'].field.list = data.map((item) => ({
        id: item.idsociete,
        label: item.nomSociete,
      }));

      this.props.listSocietes = this.props.pattern['bc.IdSociete'].field.list;

      if (!this.props.pattern['bc.IdSociete'].value.length)
        this.props.pattern['bc.IdSociete'].value.forEach((item) => {
          item.value = data.map((item) => item.idsociete);
        });
    }
  },
  /***** FILTER ******/
  toggleItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = !this.props.pattern[key].active;
  },
  activateItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = true;
  },
  changeOperator(key, val, index) {
    console.log(val);
    this.props.pattern[key].value[index].operator = val;
    if (val == 'between') {
      this.props.pattern[key].value[index].value = [null, null];
    } else {
      this.props.pattern[key].value[index].value = '';
    }
  },
  changeValue(key, val, index, order) {
    if (this.props.pattern[key].value[index].operator == 'between') {
      this.props.pattern[key].value[index].value[order] = val;
    } else if (
      this.props.pattern[key].value[index].operator == 'in' ||
      this.props.pattern[key].value[index].operator == 'inConcat'
    ) {
      if (val != 'all') {
        let position = this.props.pattern[key].value[index].value.findIndex(
          (o) => o == val
        );
        console.log('position :', position);
        if (position === -1)
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value,
            val,
          ];
        else
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value.filter(
              (item) => item != val
            ),
          ];
      } else {
        if (
          this.props.pattern[key].field.list.length !=
          this.props.pattern[key].value[index].value.length
        ) {
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].field.list.map((item) => item.id),
          ];
        } else {
          this.props.pattern[key].value[index].value = [];
        }
      }
    } else {
      this.props.pattern[key].value[index].value = val;
    }
    console.log(toJS(this.props.pattern[key].value[index].value));
  },
  addItemFilter(key, type) {
    let newVal = { ...this.props.pattern[key].defaultValue };
    newVal.logic = type;
    this.props.pattern[key].value = [...this.props.pattern[key].value, newVal];
  },
  removeItemFilter(key, idx) {
    this.props.pattern[key].value = [
      ...this.props.pattern[key].value.filter((item, index) => index != idx),
    ];
  },
  submitFilter() {
    this.props.savedPattern = toJS(this.props.pattern);
    // this.getCountActiveFilter()
  },
  resetFilter() {
    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].value = props.pattern[item].value;
      this.props.savedPattern[item].value = props.pattern[item].value;
      this.props.pattern[item].active = false;
      this.props.savedPattern[item].active = false;
    });
  },
  undoPattern() {
    this.props.pattern = toJS(this.props.savedPattern);
  },
  /***** IMPORT BONS ******/
  async importBons(e) {
    console.log(e);
    const file = e.files[0];
    const filename = file.name;
    const idRes = UserStore.props.user.idRes;
    const idUser = UserStore.props.user.idUser;

    let next = false;
    next = await confirmation({
      title: 'Ajouter des Bons Carburant',
      type: 'ouiNon',
      typeAlert: 'question',
      text:
        '<p>Fichier: ' +
        filename +
        '</p><p><b>Cette action est irreversible, ' +
        'la confirmez-vous ?</b></p>',
    });

    if (!next) return;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    //Envoi du fichier
    console.log('Envoi fichier');
    this.doLoading(true, 'Envoi du fichier en cours ...');

    const formData = new FormData();
    formData.append('cmd', 'upload_fich');
    formData.append('documentURL', file);
    formData.append('res', idRes);
    formData.append('user', idUser);
    formData.append('societe', null);

    const resEnvoi = await api.post(
      '/scripts/bon_carburant/import_bons_carburant.php',
      formData,
      config
    );
    // e.target.files = file[];
    if (!resEnvoi.data.success) {
      modalAlert({
        title: "Erreur d'import",
        typeAlert: 'error',
        text:
          '<br/>' +
          resEnvoi.data.erreur +
          '<br/><br/> NB: La liste des bons carburant doit être au format <b>.csv</b> ou <b>.txt</b>',
      });
      this.props.importLoading = false;
      this.doLoading(false);
      return;
    }

    //Vérifications doublons
    console.log('Vérifications doublons');
    this.doLoading(true, 'Vérification des doublons en cours ...');
    const dataDoublons = {
      cmd: 'verification_doublons',
      res: idRes,
      user: idUser,
      societe: null,
      path: resEnvoi.data.fichier,
    };

    const resDoublons = await api.post(
      '/scripts/bon_carburant/import_bons_carburant.php',
      dataDoublons
    );
    next = false;
    if (!resDoublons.data.success) {
      // toast.error('Une erreur est survenue :<br>' + resDoublons.data.erreur)
      if (resDoublons.data.erreur)
        await modalAlert({
          title: "Erreur d'import",
          text:
            "Une erreur est survenue :<br> Vos données n'ont pas pu être intégrés : " +
            resDoublons.data.erreur,
        });
      else {
        next = await modalAlert({
          type: 'doublons',
          title: "Erreur d'import",
          erreursLignes: resDoublons.data.listes_erreurs,
        });
      }
    } else {
      next = true;
    }

    if (!next) {
      this.doLoading(false);
      return;
    }

    console.log('Importation');
    this.doLoading(true, 'Insertion des données en cours ...');
    const dataImport = {
      cmd: 'importCSV',
      res: idRes,
      user: idUser,
      societe: null,
      path: resDoublons.data.chemin,
    };

    const resImport = await api.post(
      '/scripts/bon_carburant/import_bons_carburant.php',
      dataImport
    );
    if (!resImport.data.success) {
      modalAlert({
        title: "Erreur d'import",
        text:
          "Une erreur est survenue :<br> Vos données n'ont pas pu être intégrés : " +
          resImport.data.erreur,
      });
      this.doLoading(false);
      return;
    } else {
      this.toggleModalAddBons();
      modalAlert({
        title: 'Bon Carburants ajoutés',
        typeAlert: 'success',
        text: resImport.data.nb + ' bons carburants ont été  ajoutés !',
      });

      this.getBons();
      this.doLoading(false);
    }
  },
  toggleModalImport() {
    this.props.ImportIsOpen = !this.props.ImportIsOpen;
  },
  /***** PAGINATION ******/
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getBons();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getBons();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.get();
    this.doLoading(false);
  },
  async setSort(value) {
    console.log('TEST', value);
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortBy != value) {
      this.props.sortBy = value;
      this.props.sortOrder = 'ASC';
    } else {
      if (this.props.sortOrder == 'ASC') this.props.sortOrder = 'DESC';
      else if (this.props.sortOrder == 'DESC') {
        this.props.sortOrder = false;
        this.props.sortBy = null;
      }
    }

    await this.getBons();
    this.doLoading(false);
  },
  /***** WHERE ******/
  getWhere(where = '', type = null) {
    const main = [
      ...Object.keys(this.props.pattern)
        .filter((item) => this.props.pattern[item].active)
        .map((item) => toJS(this.props.pattern[item].value)),
    ];

    console.log(where);
    for (let key in main) {
      if (main[key].isHidden) continue;
      if (where.length) {
        if (main[key].length) where += ' AND ( ';
      } else where += '( ';
      let pass = 0;
      for (let id in main[key]) {
        if (pass) where += ' ' + main[key][id].logic + ' ';
        pass = 1;

        if (type == 'campaign')
          where += this.getClauseCampaign(main[key][id], main[key][id].custom);
        else if (type == 'export')
          where += this.getClauseExport(main[key][id], main[key][id].custom);
        else where += this.getClause(main[key][id], main[key][id].custom);
      }

      if (where.length) {
        if (main[key].length) where += ')';
      }
    }

    return where;
  },
  getClause(clause, isCustom = false) {
    let where = '-+--+-+ ERR -++-+-+';
    if (clause.operator == 'in') {
      where = clause.field + ' IN (' + clause.value.join(',') + ') ';
      console.log(where);
    } else if (clause.operator == 'inConcat') {
      console.log(clause);
      where = '';
      for (let i = 0; i < clause.value.length; i++) {
        where += clause.value[i];
        if (i + 1 != clause.value.length) where += ' OR ';
      }
      console.log(where);
    } else if (clause.operator == 'between') {
      if (clause.type == 'date') {
        where =
          clause.field +
          " BETWEEN '" +
          clause.value[0] +
          "' AND '" +
          clause.value[1] +
          "' ";
      } else if (clause.type == 'number') {
        where =
          clause.field +
          ' BETWEEN ' +
          clause.value[0] +
          ' AND ' +
          clause.value[1] +
          ' ';
      } else {
        where = '__ERROR__1';
      }
    } else if (clause.operator == 'like' || clause.operator == 'not like') {
      if (clause.type == 'text') {
        where =
          clause.field + ' ' + clause.operator + " '%" + clause.value + "%' ";
      } else {
        where = '__ERROR__2';
      }
    } else if (
      clause.operator == '>' ||
      clause.operator == '<' ||
      clause.operator == '>=' ||
      clause.operator == '<=' ||
      clause.operator == '<>' ||
      clause.operator == '='
    ) {
      if (
        clause.type == 'date' ||
        clause.type == 'text' ||
        clause.type == 'select'
      ) {
        where =
          clause.field + ' ' + clause.operator + " '" + clause.value + "' ";
      } else if (clause.type == 'number') {
        where = clause.field + ' ' + clause.operator + ' ' + clause.value + ' ';
      } else {
        where = '__ERROR__3';
      }
    } else {
      where = '__ERROR__4';
    }
    if (isCustom)
      return clause.field.replace('_$$$_', where.replace(clause.field, ''));
    return where;
  },
  reset() {
    this.props = props;
  },
};

export default observable(MajCardsStore);
