export const TypeFid = {
    FID_SIMPLE: 1,
    FID_PORTE_MONNAIE: 2,
    FID_COUPONS: 3,
    COMPTE_SEUL: 4,
    WALLET: 5,
}

// export const labelProfil = [
//     { label: 'Réseau', id: 4 },
//     { label: 'Société', id: 5 },
//     { label: 'Station', id: 7 },
//     { label: '???', id: 6 }
// ]

export const valeurBit = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072, 262144, 524288, 1048576, 2097152, 4194304];


export const Roles = {
    // "1": "Superadmin",
    // "2": "Superviseur",
    // "3": "Technicien",
    "4": "Gestionnaire de réseau",
    "5": "Station",
    "6": "Saisie",
    "7": "Société",
}

export const Roles_With_Id_Label = [
    {id: "4", label: "Réseau"},
    {id: "5", label: "Station"},
    {id: "6", label: "Saisie"},
    {id: "7", label: "Société"},
]

export const StatutCarte = [
    '',
    'Annulée',
    'En Attente',
    'En Cours',
    'Prête',
    'Livrée/Expédiée',
]

export const Civilite = [
    { id: '', label: 'Non renseignée' },
    { id: 1, label: 'Mr.' },
    { id: 2, label: 'Mme' },
    { id: 3, label: 'Mlle.' }
]

export const statutStationList = [
    { id: 0, label: 'Fermée' },
    { id: 1, label: 'Active' },
]

export const statutRecharge = [
    'En attente',
    'Consommée',
    'Périmée'
]

export const Operators = {
    '=': 'Egale à',
    'like': 'Contient',
    'not like': 'Ne contient pas',
    'between': 'Intervalle',
    '<': ' Inférieur à',
    '>': ' Supérieur à',
    '>=': ' Supérieur ou égale à',
    '<=': ' Inférieur ou égale à',
    '<>': ' Différent de',
}

export const PME = {
    0: "Aucune",
    1: "PME1",
    2: "PME2"
}

export const typeCarte = [
    { id: -1, label: 'Pas de carte' },
    { id: 0, label: 'Standard' },
    { id: 1, label: 'Client en compte' },
    { id: 2, label: 'Filleul' },
    { id: 3, label: 'Standard Test' },
    { id: 4, label: 'Client en compte Test' },
    { id: 5, label: 'Filleul Test' },
    { id: 1, label: 'ID' }
]

export const labelTransac = {
    0: "Aucune",
    1: "Crédit PME1",
    2: "Débit PME1",
    3: "Crédit PME2",
    4: "Débit PME2",
    5: "Crédit PME1 + spot PME2",
    6: "Débit CEC PME1",
    7: "Débit CEC PME2",
    8: "Modif Date Val",
    9: "Mise à jour Carte",
    10: "Crédit Smartphone PME1",
    11: "Crédit Smartphone PME2",
    12: "Demande Coupon",
    13: "Validation Coupon",
    14: "Mise en Opposition",	
    15: "Mise à jour Incomplete",	
    16: "Annulation Ticket",
    // Début Bon Carburant
    17: "Bon Scanné",
    18: "Bon Inconnu",
    19: "Bon Doublon",
    20: "Bon Incohérent",
    21: "Bon Périmé",
    22: "Bon Validé",
    23: "Suspicion Fraude",
    24: "Susp. Fraude Traitée",
    // Fin Bon Carburant
    89: "Encodage Batch",
    90: "Init Copie STD",
    91: "Encodage",
    92: "Init ID",
    93: "Init Filleul test",
    94: "Init STD test",
    95: "Init CEC test",
    96: "Init Filleul",
    97: "Init STD",
    98: "Init CEC",
    1000:"Menu principal"
};


export const typeTransac = {
    0: "Aucune",
    1: "Crédit PME1",
    2: "Débit PME1",
    3: "Crédit PME2",
    4: "Débit PME2",
    5: "Crédit PME1 + spot PME2",
    6: "Débit CEC PME1",
    7: "Débit CEC PME2",
    8: "Modif Date Val",
    9: "Mise à jour Carte",
    10: "Crédit Smartphone PME1",
    11: "Crédit Smartphone PME2",
    12: "Demande Coupon",
    13: "Validation Coupon",
    14: "Mise en Opposition",
    15: "Mise à jour Incomplete",
    16: "Annulation Ticket",
    // Début Bon Carburant
    17: "Bon Scanné",
    18: "Bon Inconnu",
    19: "Bon Doublon",
    20: "Bon Incohérent",
    21: "Bon Périmé",
    22: "Bon Validé",
    23: "Suspicion Fraude",
    24: "Susp. Fraude Traitée",
    // Fin Bon Carburant	
    89: "Encodage Batch",
    90: "Init Copie STD",
    91: "Encodage",
    92: "Init ID",
    93: "Init Filleul test",
    94: "Init STD test",
    95: "Init CEC test",
    96: "Init Filleul",
    97: "Init STD",
    98: "Init CEC",
    1000: "Menu principal"
};

export const entityTypes = {
    societe : 'Societe',
    station : 'Station',
    transacClient : 'Client',
    boUser : 'User BO',
  }