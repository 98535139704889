import React, { useState, useEffect } from 'react';
import MultiFactorImg from '../../assets/img/two-factor-authentication-2fa-icon.svg';
import UserStore from '../../stores/UserStore';
import { useHistory, useLocation } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { observer } from 'mobx-react';

import { BarLoader } from '../Loader/Loading';
import { toast } from 'react-toastify';
import OPTInput from '../../reusable/OPTInput';

const ClipboardCopy = ({ value }) => {
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast.success('Texte copié');
      })
      .catch((err) => {
        toast.error('Echec');
      });
  };

  return (
    <div className='mt-3'>
      <p>Configuration Manuelle</p>
      <div>
        <textarea
          disabled
          rows={3}
          id='secret'
          className='form-control'
          style={{
            resize: 'none',
            minWidth: '90%',
            maxWidth: '90%',
          }}
          value={value}
        />
        <button className='btn btn-primary mt-2' onClick={copyToClipboard}>
          Copier
        </button>
      </div>
    </div>
  );
};

const Setup2FAView = () => {
  const history = useHistory();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const token = query.get('token');
  const selector = query.get('selector');

  const [isLoadingInputs, setIsLoadingInputs] = useState(false);

  async function onComplete(OPT) {
    if (!OPT) return toast.error('Veuillez saisir le code de sécurité');

    if (selector && token) {
      setIsLoadingInputs(true);
      await UserStore.checkOTP(
        { one_time_password: OPT, selector, token },
        () => {
          toast.success(
            <>
              <h4>Authentification à 2 facteurs vérifiée !</h4>
              <p>
                Vous devrez entrer le code à usage unique disponible dans
                l'application à chaque nouvelle connexion .
              </p>
            </>
          );
          history.push('/login');
        },
        false
      );
      setIsLoadingInputs(false);
    } else {
      toast.error('Token invalide');
    }
  }

  useEffect(() => {
    if (!token || !selector) {
      return toast.error('Token invalide');
    }

    UserStore.setup2FA(token, selector);
  }, [token, selector]);

  return (
    <div className='app flex-row align-items-center'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-5'>
            {UserStore.props.qrCode && (
              <BlockUi
                tag='div'
                className='card-group rounded login-loader'
                loader={<BarLoader></BarLoader>}
                message={UserStore.props.loading.msg}
                blocking={UserStore.props.loading.status}
              >
                <div className='card p-4'>
                  <div className='card-body'>
                    <div className='text-center'>
                      <img
                        className='login-image'
                        style={{ width: '75px', marginBottom: '20px' }}
                        src={MultiFactorImg}
                        alt='2FA Icon'
                      />
                    </div>
                    <p style={{ textAalign: 'left', fontWeight: 'bold' }}>
                      1. Scannez le QRCode avec une app compatible:{' '}
                    </p>
                    <ul style={{ textAlign: 'left' }}>
                      <li>
                        Google Authenticator:{' '}
                        <a
                          href='https://apps.apple.com/app/google-authenticator/id388497605'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          iOS
                        </a>{' '}
                        ,{' '}
                        <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'>
                          Android
                        </a>{' '}
                      </li>
                      <li>
                        Authy:{' '}
                        <a
                          href='https://authy.com/download/'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          iOS, Android, Chrome et OS X
                        </a>
                      </li>
                      <li>
                        LastPass:{' '}
                        <a
                          href='https://lastpass.com/misc_download2.php'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {' '}
                          iOS, Android, OS X, Linux et Windows{' '}
                        </a>{' '}
                      </li>
                    </ul>
                    {UserStore.props.qrCode && (
                      <div className='d-flex justify-content-center mt-3'>
                        <img
                          src={`data:image/png;base64,${UserStore.props.qrCode}`}
                          alt='QrCode'
                          className=''
                        />
                      </div>
                    )}
                    {UserStore.props.uriConfig && (
                      <p className='mt-3'>
                        Configuration Manuelle: cliquez{' '}
                        <a href={UserStore.props.uriConfig}>ici</a> ou utiliser
                        ce code.
                      </p>
                    )}
                    {!UserStore.props.uriConfig &&
                      UserStore.props.secret2FA && (
                        <ClipboardCopy value={UserStore.props.secret2FA} />
                      )}

                    <div className='mt-5'>
                      <p style={{ textAalign: 'left', fontWeight: 'bold' }}>
                        2. Vérifiez le code à 6 chiffres
                      </p>

                      <div>
                        <OPTInput
                          length={6}
                          onComplete={onComplete}
                          isLoadingInputs={isLoadingInputs}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </BlockUi>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Setup2FAView);
