import React from 'react';
import { observable, toJS } from 'mobx';
import {
  Operators,
  Civilite,
  typeCarte,
  entityTypes,
} from '../utils/constants';
import moment from 'moment';
import api from '../api';
import UserStore from './UserStore';
import { number } from '../utils/numeral';
import { ActionsCards } from '../components/Cards/CardsView';
import { toast } from 'react-toastify';
import myYup from '../utils/myYup';
import { saveAs } from 'file-saver';
import modalAlert from '../components/Alerts/ModalAlert';
import confirmation from '../components/Alerts/Confirmation';

const props = {
  currentRoute: '',
  loading: {
    status: false,
    msg: 'Connexion en cours',
  },
  columnsCards: [
    {
      Header: 'Etat carte',
      accessor: 'cltCardOFF',
      field: 'clientCardDesactive',
      show: false,
      isVisible: false,
      isFilterable: true,
      typeFilter: 'text',
      typeInput: 'text',
      isHidden: false,
      isEditable: false,
      Cell: ({ row, cell: { value } }) =>
        parseInt(value) == 1 ? (
          <span
            style={{ fontSize: '0.8rem' }}
            className='text-danger font-weight-bold'
          >
            Désactivé
          </span>
        ) : row.values.cltNumCard !== '' ? (
          <span
            style={{ fontSize: '0.8rem' }}
            className='text-success font-weight-bold'
          >
            Active
          </span>
        ) : (
          <span
            style={{ fontSize: '0.8rem' }}
            className='text-secondary font-weight-bold'
          >
            Inexistante
          </span>
        ),
    },
    {
      Header: 'Nom',
      accessor: 'cltNom',
      field: 'clientNom',
      isFilterable: true,
      typeFilter: 'text',
      typeInput: 'text',
      isHidden: false,
      isSortable: true,
      isEditable: true,
    },
    {
      Header: 'Prenom',
      accessor: 'cltPrenom',
      field: 'clientPrenom',
      isFilterable: true,
      typeFilter: 'text',
      typeInput: 'text',
      isHidden: false,
      isSortable: true,
      isEditable: true,
    },
    {
      Header: 'Date de naissance',
      accessor: 'cltNeLe',
      field: 'clientNeLe',
      isSortable: true,
      typeInput: 'date',
      isHidden: true,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('L');
        else return '-';
      },
      isEditable: true,
    },
    {
      Header: 'N° de carte',
      accessor: 'cltNumCard',
      field: 'clientNumCard',
      isFilterable: true,
      typeFilter: 'text',
      isEditable: true,
      typeInput: 'label',
      isHidden: false,
      isSortable: true,
      validation: {
        type: String,
        use: {
          carte: (val) => val.length === 0 || val.length >= 17,
        },
      },
    },
    {
      Header: 'Tél Fixe',
      accessor: 'cltTel',
      field: 'clientTel',
      isSortable: true,
      isHidden: true,
      isEditable: true,
      typeInput: 'text',
    },
    {
      Header: 'Tél. mobile',
      accessor: 'cltGsm',
      field: 'clientGsm',
      isSortable: true,
      isHidden: true,
      isEditable: true,
      typeInput: 'text',
    },
    {
      Header: 'Email',
      accessor: 'cltEmail',
      field: 'clientEmail',
      isSortable: true,
      isEditable: true,
      isHidden: true,
      typeInput: 'email',
    },
    {
      Header: 'PIN',
      accessor: 'clientPassword',
      field: 'clientPassword',
      isSortable: true,
      isEditable: true,
      typeInput: 'text',
      isHidden: false,
    },
    {
      Header: 'Société',
      accessor: 'nomSociete',
      field: 'nomSociete',
      isSortable: true,
      isEditable: true,
      typeInput: 'text',
      isHidden: true,
    },
    {
      Header: 'Date activation',
      accessor: 'dateActiv',
      field: 'dateActiv',
      isSortable: true,
      isEditable: true,
      typeInput: 'text',
      isHidden: true,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: '1 er achat',
      accessor: 'firstAchat',
      field: 'firstAchat',
      isSortable: true,
      isEditable: true,
      typeInput: 'text',
      isHidden: true,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Dernier achat',
      accessor: 'dernierAchat',
      field: 'dernierAchat',
      isSortable: true,
      isEditable: true,
      typeInput: 'text',
      isHidden: false,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'Catégorie',
      accessor: 'Label',
      field: 'Label',
      isSortable: true,
      isEditable: true,
      typeInput: 'text',
      isHidden: false,
      Cell: ({ cell: { value } }) => {
        if (value == '') value = 'Aucune';
        return value;
      },
    },
    {
      Header: 'ID Client',
      accessor: 'idcli',
      field: 'idclientFid',
      isFilterable: true,
      typeFilter: 'text',
      typeInput: 'text',
      isHidden: true,
      isEditable: false,
      isSortable: true,
    },
    {
      Header: 'Civilité',
      accessor: 'cltCiv',
      field: 'clientCivilite',
      isFilterable: true,
      typeFilter: 'select',
      typeInput: 'select',
      list: Civilite.map((item, index) => ({ id: index, label: item })),
      isHidden: true,
      isEditable: true,
      Cell: ({ cell: { value } }) => (value ? Civilite[value] : '-'),
    },
    {
      Header: 'Solde',
      accessor: 'Pme2',
      field: 'Pme2',
      isSortable: true,
      isHidden: false,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Crédits',
      accessor: 'cumCredPme1',
      field: 'cumCredPme1',
      isSortable: true,
      isHidden: true,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Débits',
      accessor: 'cumDebPme1',
      field: 'cumDebPme1',
      isSortable: true,
      isHidden: true,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Bonus',
      accessor: 'cumulBonusPme1',
      field: 'cumulBonusPme1',
      isSortable: true,
      isHidden: true,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({
        row,
        toggleModalEditCards,
        toggleModalAffect,
        deleteCards,
        toggleModalLst,
        isReseau,
        isSociete,
      }) => {
        return (
          <ActionsCards
            row={row.original}
            toggleModalEditCards={toggleModalEditCards}
            toggleModalLst={toggleModalLst}
            toggleModalAffect={toggleModalAffect}
            deleteCards={deleteCards}
            isReseau={isReseau}
            isSociete={isSociete}
          ></ActionsCards>
        );
      },
    },
  ],
  pattern: {
    idsociete: {
      active: false,
      title: 'Société',
      name: 'idsociete',
      operator: {
        type: 'select',
        list: ['='].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'clientfid.idsociete',
        type: 'asyncSelect',
        list: [],
      },
      value: [
        {
          name: 'idsociete',
          field: 'clientfid.idsociete',
          operator: '=',
          type: 'number',
          value: '',
        },
      ],
      defaultValue: {
        name: 'idsociete',
        field: 'clientfid.idsociete',
        operator: '=',
        type: 'number',
        value: '',
      },
    },
    ruleId: {
      active: false,
      title: 'Catégorie(s)',
      name: 'ruleId',
      operator: {
        type: 'multiple',
        list: ['in'],
      },
      field: {
        name: 'ruleId',
        type: 'multiple',
        list: [
          { id: 1, label: '__--FILTRE NON CHARGE--__' },
          { id: 2, label: '__--FILTRE NON CHARGE--__' },
          { id: 3, label: '__--FILTRE NON CHARGE--__' },
          { id: 4, label: '__--FILTRE NON CHARGE--__' },
          { id: 5, label: '__--FILTRE NON CHARGE--__' },
          { id: 6, label: '__--FILTRE NON CHARGE--__' },
        ],
      },
      value: [
        {
          name: 'ruleId',
          field: 'clientfid.ruleId',
          campField: 'c.ruleId',
          exportField: 'Ruleid',
          operator: 'in',
          value: [],
        },
      ],
      defaultValue: {
        name: 'ruleId',
        field: 'clientfid.ruleId',
        campField: 'c.ruleId',
        exportField: 'Ruleid',
        type: 'array',
        operator: 'in',
        value: [],
      },
    },
    withCard: {
      active: false,
      title: 'Statut carte',
      canAddOr: false,
      name: 'withCard',
      operator: {
        type: 'multiple',
        list: ['inConcat'],
      },
      field: {
        name: 'withCard',
        type: 'multiple',
        list: [
          { id: 'clientCardDesactive <> 1', label: 'Active' },
          { id: 'clientCardDesactive = 1', label: 'Inactive' },
        ],
      },
      value: [
        {
          name: 'withCard',
          field: null,
          type: 'date',
          operator: 'inConcat',
          value: [],
        },
      ],
      defaultValue: {
        name: 'withCard',
        field: null,
        type: 'date',
        operator: 'inConcat',
        value: [],
      },
    },
    clientNumCard: {
      active: false,
      quickSearch: true,
      title: 'N° de carte',
      name: 'clientNumCard',
      operator: {
        type: 'select',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'clientNumCard',
        type: 'text',
      },
      value: [
        {
          name: 'clientNumCard',
          field: 'clientNumCard',
          campField: 'c.clientNumCard',
          exportField: 'clientNumCard',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'clientNumCard',
        field: 'clientNumCard',
        campField: 'c.clientNumCard',
        exportField: 'clientNumCard',
        operator: 'like',
        type: 'text',
        value: '',
      },
    },
    clientNom: {
      active: false,
      quickSearch: true,
      title: 'Nom',
      name: 'clientNom',
      operator: {
        type: 'select',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'clientNom',
        type: 'text',
      },
      value: [
        {
          name: 'clientNom',
          field: 'clientNom',
          campField: 'c.clientNom',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'clientNom',
        field: 'clientNom',
        campField: 'c.clientNom',
        operator: 'like',
        type: 'text',
        value: '',
      },
    },
    clientPrenom: {
      active: false,
      quickSearch: true,
      title: 'Prénom',
      name: 'clientPrenom',
      operator: {
        type: 'select',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'clientNom',
        type: 'text',
      },
      value: [
        {
          name: 'clientPrenom',
          field: 'clientPrenom',
          campField: 'c.clientPrenom',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'clientPrenom',
        field: 'clientPrenom',
        campField: 'c.clientPrenom',
        type: 'text',
        operator: 'like',
        value: '',
      },
    },
    clientEmail: {
      active: false,
      quickSearch: true,
      title: 'Email',
      canAddOr: false,
      name: 'clientEmail',
      operator: {
        type: 'select',
        list: ['=', 'like'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'clientEmail',
        type: 'text',
      },
      value: [
        {
          name: 'clientEmail',
          field: 'clientEmail',
          campField: 'c.clientEmail',
          type: 'text',
          operator: 'like',
          value: null,
        },
      ],
      defaultValue: {
        name: 'clientEmail',
        field: 'clientEmail',
        campField: 'c.clientEmail',
        type: 'text',
        operator: 'like',
        value: null,
      },
    },
    clientTel: {
      active: false,
      title: 'Tél. fixe',
      canAddOr: false,
      name: 'clientTel',
      operator: {
        type: 'select',
        list: ['like'].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'clientTel',
        type: 'text',
      },
      value: [
        {
          name: 'clientTel',
          field: 'clientTel',
          campField: 'c.clientTel',
          type: 'text',
          operator: 'like',
          value: null,
        },
      ],
      defaultValue: {
        name: 'clientTel',
        field: 'clientTel',
        campField: 'c.clientTel',
        type: 'text',
        operator: 'like',
        value: null,
      },
    },
    clientGsm: {
      active: false,
      quickSearch: true,
      title: 'Tél. mobile',
      canAddOr: false,
      name: 'clientGsm',
      operator: {
        type: 'select',
        list: ['like'].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'clientGsm',
        type: 'text',
      },
      value: [
        {
          name: 'clientGsm',
          field: 'clientGsm',
          campField: 'c.clientGsm',
          type: 'text',
          operator: 'like',
          value: null,
        },
      ],
      defaultValue: {
        name: 'clientGsm',
        field: 'clientGsm',
        campField: 'c.clientGsm',
        type: 'text',
        operator: 'like',
        value: null,
      },
    },
    '1erAchat': {
      active: false,
      title: 'Date 1er achat',
      canAddOr: false,
      name: '1erAchat',
      operator: {
        type: 'select',
        list: ['=', '<>', 'between', '>', '>=', '<', '<='].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: '1erAchat',
        type: 'date',
      },
      value: [
        {
          name: '1erAchat',
          field: '1erAchat',
          campField: 'c.1erAchat',
          type: 'date',
          operator: '=',
          value: moment().format('YYYY-MM-DD'),
        },
      ],
      defaultValue: {
        name: '1erAchat',
        field: '1erAchat',
        campField: 'c.1erAchat',
        type: 'date',
        operator: '=',
        value: moment().format('YYYY-MM-DD'),
      },
    },

    achatEff: {
      active: false,
      title: 'Achat effectué le',
      canAddOr: false,
      name: 'achatEff',
      operator: {
        type: 'select',
        list: ['=', '<>', 'between', '>', '>=', '<', '<='].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'achatEff',
        type: 'date',
      },
      value: [
        {
          name: 'achatEff',
          field:
            'idclientFid in (select `transacfid`.`idClientFid` from `transacfid`  where `transacfid`.`DateTransac`_$$$_)',
          campField:
            'c.idclientFid in (select `transacfid`.`idClientFid` from `transacfid`  where `transacfid`.`DateTransac`_$$$_)',
          type: 'date',
          custom: true,
          operator: '=',
          value: moment().format('YYYY-MM-DD'),
        },
      ],
      defaultValue: {
        name: 'achatEff',
        field:
          'idclientFid in (select `transacfid`.`idClientFid` from `transacfid`  where `transacfid`.`DateTransac`_$$$_)',
        campField:
          'c.idclientFid in (select `transacfid`.`idClientFid` from `transacfid`  where `transacfid`.`DateTransac`_$$$_)',
        type: 'date',
        custom: true,
        operator: '=',
        value: moment().format('YYYY-MM-DD'),
      },
    },
    nbEuro: {
      active: false,
      title: '$$euro$$ sur la carte',
      canAddOr: false,
      name: 'nbEuro',
      operator: {
        type: 'select',
        list: ['=', '<>', 'between', '>', '>=', '<', '<='].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'nbEuro',
        type: 'number',
      },
      value: [
        {
          name: 'nbEuro',
          field: 'CAST((clientPme2 * 0.01) AS UNSIGNED)',
          exportField: 'CAST(Pme2 AS UNSIGNED)',
          campField: 'CAST((c.clientPme2 * 0.01) AS UNSIGNED)',
          type: 'number',
          operator: '=',
          value: null,
        },
      ],
      defaultValue: {
        name: 'nbEuro',
        field: 'CAST((clientPme2 * 0.01) AS UNSIGNED)',
        exportField: 'CAST(Pme2 AS UNSIGNED)',
        campField: 'CAST((c.clientPme2 * 0.01) AS UNSIGNED)',
        type: 'number',
        operator: '=',
        value: null,
      },
    },
    cumulEuro: {
      active: false,
      title: 'Cumul $$euro$$',
      canAddOr: false,
      name: 'cumulEuro',
      operator: {
        type: 'select',
        list: ['=', '<>', 'between', '>', '>=', '<', '<='].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'cumulEuro',
        type: 'number',
      },
      value: [
        {
          name: 'cumulEuro',
          field: 'CAST((cumulCreditPme2 * 0.01) AS UNSIGNED)',
          exportField: 'CAST(cumCredPme2 AS UNSIGNED)',
          campField: 'CAST((c.cumulCreditPme2 * 0.01) AS UNSIGNED)',
          type: 'number',
          operator: '=',
          value: null,
        },
      ],
      defaultValue: {
        name: 'cumulEuro',
        field: 'CAST((cumulCreditPme2 * 0.01) AS UNSIGNED)',
        exportField: 'CAST(cumCredPme2 AS UNSIGNED)',
        campField: 'CAST((c.cumulCreditPme2 * 0.01) AS UNSIGNED)',
        type: 'number',
        operator: '=',
        value: null,
      },
    },
  },
  columnsMaj: [
    {
      Header: 'Créer le',
      accessor: 'dateInsertion',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Consommé le',
      accessor: 'dateTransaction',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'N°',
      accessor: 'NumeroIncrement',
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Date de début',
      accessor: 'DateDebutRecharge',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Date de fin',
      accessor: 'DateFinRecharge',
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Montant',
      accessor: 'MontantRecharge',
      Cell: ({ cell: { value } }) => number(value / 100),
    },
    {
      Header: 'Motif',
      accessor: 'Param_1',
    },
    {
      Header: 'Statut',
      accessor: 'statut',
      Cell: ({ cell: { value } }) => {
        if (value == 0) return <span className='text-warning'>En attente</span>;
        else if (value == 1)
          return <span className='text-success'>Consommée</span>;
        else if (value == 2)
          return <span className='text-danger'>Périmée</span>;
        else return value;
      },
    },
    {
      Header: 'Type',
      accessor: 'idTypeCardUpdate',
      Cell: ({ cell: { value } }) => {
        if (value == 1) return <b>Recharge</b>;
        else if (value == 2) return <b>Mise en opposition</b>;
        else if (value == 3) return <b>Déblocage carte</b>;
        else if (value == 4) return <b>Mise à jour PIN</b>;
        else if (value == 5) return <b>Demande changement PIN</b>;
        else if (value == 6) return <b>Annulation ticket</b>;
        else if (value == 7) return <b>MAJ date validité</b>;
        else return value;
      },
    },
  ],

  dataCards: [],
  dataMaj: [],

  cardsToAffect: [],
  fichesToAffect: [],

  pageStartCards: 0,
  pageIndexCards: 0,
  pageLimitCards: 50,
  pageCountCards: 0,
  pageTotalCards: 0,
  sortByCards: 'clientNom',
  sortOrderCards: 'ASC',

  // listCommerces: [],
  listCardsType: [],
  listCardsUpdate: [],

  countActiveFilter: 0,
  savedPattern: {},
  savedFilters: [],
  loadedFilter: null,

  isOpenModalEditCards: false,
  currentCard: {},
  initialValuesCards: {},
  selectedRow: {},
  typeAffect: 'ficheToCard',
  ModalAffectIsOpen: false,
  initialWhere: null,
  titleAffect: null,
  typeContent: null,
  isOpenModalLst: false,
  modelUpdateCard: {
    cmd: 'saveRows',
    ref: 'saveUsers',
    objName: 'lesClients',
    data: null,
    lignesStdlistesPeriodeAutoPME1ToCreate: JSON.stringify([]),
    lignesStdlistesPeriodeAutoPME1ToUpdate: JSON.stringify([]),
    lignesStdlistesPeriodeAutoPME2ToCreate: JSON.stringify([]),
    lignesStdlistesPeriodeAutoPME2ToUpdate: JSON.stringify([]),
    lignesStdlistesPeriodeAutoPMEToDelete: JSON.stringify([]),
    lignesStdlistesChgtPeriodiquePME1ToCreate: JSON.stringify([]),
    lignesStdlistesChgtPeriodiquePME1ToUpdate: JSON.stringify([]),
    lignesStdlistesChgtPeriodiquePME2ToCreate: JSON.stringify([]),
    lignesStdlistesChgtPeriodiquePME2ToUpdate: JSON.stringify([]),
    lignesStdlistesChgtPeriodiquePMEToDelete: JSON.stringify([]),
    lignesStdlistesChgtUniquePME1ToCreate: JSON.stringify([]),
    lignesStdlistesChgtUniquePME1ToUpdate: JSON.stringify([]),
    lignesStdlistesChgtUniquePME2ToCreate: JSON.stringify([]),
    lignesStdlistesChgtUniquePME2ToUpdate: JSON.stringify([]),
    lignesStdlistesChgtUniquePMEToDelete: JSON.stringify([]),
    lignesCeclistesSeuilCumulPMEToCreate: JSON.stringify([]),
    lignesCeclistesSeuilCumulPMEToUpdate: JSON.stringify([]),
    lignesCeclistesSeuilCumulPMEToDelete: JSON.stringify([]),
    lignesCeclistesAutoPMEToCreate: JSON.stringify([]),
    lignesCeclistesAutoPMEToUpdate: JSON.stringify([]),
    lignesCeclistesAutoPMEToDelete: JSON.stringify([]),
    isModifNumCard: false,
    idRes: null,
    oldNum: null,
  },
  validationAction: [
    null,
    myYup.object().shape({
      MontantRecharge: myYup.number().required(),
      motifRecharge: myYup.string().required(),
    }),
    myYup.object().shape({
      motifBlocage: myYup.string().required(),
    }),
    myYup.object().shape({
      motifDeblocage: myYup.string().required(),
    }),
    myYup.object().shape({
      MotifRegenerate: myYup.string().required(),
    }),
    myYup.object().shape({
      MotifAskChangePin: myYup.string().required(),
    }),
    null,
    myYup.object().shape({
      motifRecharge: myYup.string().required().max(15),
      DateDebutRecharge: myYup.string().required(),
      DateFinRecharge: myYup.string().required().dateIsGreater(),
    }),
  ],
  validationClient: myYup.object().shape({
    clientRIB: myYup.string().rib(),
  }),
};

const CardsStore = {
  /***** Props ******/
  props: props,

  /***** Methods ******/
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  async init(filterCriteria = null) {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    if (typeof filterCriteria !== undefined && filterCriteria !== '')
      this.props.initialWhere = filterCriteria;
    await this.initFilterClient();
    // await this.getListCardUpdate()
    await this.getCards();
    this.doLoading(false);
  },
  toggleModalLst(row, typeContent) {
    if (row) this.props.currentClient = row;
    if (typeContent) this.props.typeContent = typeContent;
    this.props.isOpenModalLst = !this.props.isOpenModalLst;
  },
  setCurrentClient(row) {
    this.props.currentClient = {
      id: row.idcli,
      name: row.clientNP,
      type: entityTypes.clientTransac,
    };
  },
  async getCards() {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;
    const idSoc = UserStore.props.user.idSoc;
    let where = '';

    this.props.sortBy = this.props.sortBy || 'clientNom';
    this.props.sortOrder = this.props.sortOrder || 'ASC';

    if (this.props.initialWhere)
      where = this.getWhere(
        this.props.initialWhere + ' AND clientTypeCarte <> 6 '
      );
    else if (UserStore.isReseau())
      where = this.getWhere(
        'reseaufid.iDreseauFid=' + idRes + ' AND clientTypeCarte <> 6 '
      );
    else if (UserStore.isSociete())
      where = this.getWhere(
        'clientfid.idSociete=' + idSoc + ' AND clientTypeCarte <> 6 '
      );
    else
      where = this.getWhere(
        'reseaufid.idcommercantFid=' + idCom + ' AND clientTypeCarte <> 6 '
      );

    let data = {
      objName: 'gridCartes',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStartCards,
      limit: this.props.pageLimitCards,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortByCards) data['sort'] = this.props.sortByCards;

    if (this.props.sortOrderCards) data['dir'] = this.props.sortOrderCards;

    const res = await api.post('/scripts/clients/clients.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.dataCards = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotalCards = res.data.totalCount;
        this.props.pageCountCards = Math.ceil(
          res.data.totalCount / this.props.pageLimitCards
        );
      } else {
        this.props.dataCards = [];
      }
    }
    // this.props.loading.status = false
  },
  async getFilteredCards() {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.pageStartCards = 0;
    this.props.pageIndexCards = 0;
    this.props.pageLimitCards = 30;
    await this.getCards();
    this.doLoading(false);
  },
  //ACTIONS
  async ajouterRecharge(values) {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Ajout recharge unique',
      text: 'Voulez-vous ajouter cette recharge?',
    });

    if (!next) return;

    this.doLoading(true, 'Chargement en cours');
    const periode = 0;
    const cumul = 1;
    const rattrapage = 0;

    let data = {
      cmd: 'GetValeursPeriodeRechargeByCriteria',
      where:
        ' TypeRecharge = 1 AND ValeurPeriode = ' +
        periode +
        ' AND ValeurCumul = ' +
        cumul +
        ' AND ValeurRattrapage = ' +
        rattrapage,
      json: 'true',
      callFromClient: 'true', //précise au script Services.Recharge.php s'il doit appeler la méthode présente dans 'cmd'
    };

    const res = await api.post(
      '/scripts/RepositoryPattern/Services.Recharge.php',
      data
    );

    if (res.data.success) {
      const periode = res.data.rows[0]['Valeur'];

      const toAdd = {
        NumeroIncrement: '0',
        IdReseau: values['iDres'],
        IdCommercant: values['idcom'],
        IdClient: values['idclientFid'],
        NumeroPME: '2',
        NumeroCarte: values['clientNumCard'],
        DateDebutRecharge: values['DateDebutRecharge'],
        DateFinRecharge: values['DateFinRecharge'],
        PeriodeRecharge: periode,
        MontantRecharge: values['MontantRecharge'] * 100,
        Param_1: values['motifRecharge'],
        idTypeCardUpdate: 1,
        Param_2: '',
        Param_3: '',
        Param_4: '',
        Param_5: '',
      };

      const user = {
        idclientFid: values['idclientFid'],
        clientNumCard: values['clientNumCard'],
      };

      let data = props.modelUpdateCard;

      data['idRes'] = values['iDres'];
      data['oldNum'] = values['clientNumCard'];
      data['data'] = JSON.stringify([user]);
      data['lignesStdlistesChgtUniquePME2ToCreate'] = JSON.stringify([toAdd]);

      console.log(data);
      const res2 = await api.post('/scripts/clients/regclients.php', data);

      if (res2.data.success) {
        // toast.success('La recharge a bien été enregistré')
        modalAlert({
          title: 'Succès',
          typeAlert: 'success',
          text: 'La recharge a bien été enregistré',
        });
      } else {
        toast.error(
          <>
            {'Une erreur est survenue'}
            <br />
            {"La recharge n'a pas été enregistré"}
          </>
        );
      }

      await this.getCardsMaj(values['clientNumCard']);
    } else {
      toast.error('Une erreur est survenue.');
    }
    this.doLoading(false);
  },
  async changeValidite(values) {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Modification date validité',
      text: 'Etes-vous sûr(e) de vouloir modifier la date de validité de cette carte ?',
    });

    if (!next) return;

    const toAdd = {
      NumeroIncrement: '0',
      IdReseau: values['iDres'],
      IdCommercant: values['idcom'],
      IdClient: values['idclientFid'],
      NumeroPME: '2',
      NumeroCarte: values['clientNumCard'],
      DateDebutRecharge: values['DateDebutRecharge'],
      DateFinRecharge: values['DateFinRecharge'],
      MontantRecharge: values['MontantRecharge'] * 100,
      Param_1: values['motifRecharge'],
      idTypeCardUpdate: 7,
      Param_2: '',
      Param_3: '',
      Param_4: '',
      Param_5: '',
    };

    const user = {
      idclientFid: values['idclientFid'],
      clientNumCard: values['clientNumCard'],
    };

    let data = props.modelUpdateCard;

    data['idRes'] = values['iDres'];
    data['oldNum'] = values['clientNumCard'];
    data['data'] = JSON.stringify([user]);
    data['lignesStdlistesChgtUniquePME2ToCreate'] = JSON.stringify([toAdd]);

    console.log(data);
    const res2 = await api.post('/scripts/clients/regclients.php', data);

    if (res2.data.success) {
      // toast.success('La recharge a bien été enregistré')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La recharge a bien été enregistré',
      });
    } else {
      toast.error(
        <>
          {'Une erreur est survenue'}
          <br />
          {"La recharge n'a pas été enregistré"}
        </>
      );
    }

    await this.getCardsMaj(values['clientNumCard']);
    await this.editBlackList();
    await this.editStdListe();
    await this.editUpdListe();

    this.doLoading(false);
  },
  async regeneratePin(values) {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Regénération du code PIN',
      text: 'Voulez vous regénérer le code PIN ?',
    });

    if (!next) return;

    this.doLoading(true, 'Chargement en cours');
    const toAdd = {
      NumeroIncrement: '0',
      IdReseau: values['iDres'],
      IdCommercant: values['idcom'],
      IdClient: values['idclientFid'],
      NumeroPME: '2',
      NumeroCarte: values['clientNumCard'],
      DateDebutRecharge: '',
      DateFinRecharge: '',
      PeriodeRecharge: 101,
      MontantRecharge: 0,
      Param_1: values['MotifRegenerate'],
      idTypeCardUpdate: 4,
      Param_2: '',
      Param_3: '',
      Param_4: '',
      Param_5: '',
      NouveauPin:
        Math.floor(Math.random() * 10) +
        '' +
        Math.floor(Math.random() * 10) +
        '' +
        Math.floor(Math.random() * 10) +
        '' +
        Math.floor(Math.random() * 10),
    };

    const user = {
      idclientFid: values['idclientFid'],
      clientNumCard: values['clientNumCard'],
    };

    let data = props.modelUpdateCard;

    data['idRes'] = values['iDres'];
    data['oldNum'] = values['clientNumCard'];
    data['data'] = JSON.stringify([user]);
    data['lignesStdlistesChgtUniquePME2ToCreate'] = JSON.stringify([toAdd]);

    console.log(data);
    const res2 = await api.post('/scripts/clients/regclients.php', data);

    if (res2.data.success) {
      // toast.success('La modification a bien été effectué')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La modification a bien été effectué',
      });
    } else {
      toast.error(<>{'Une erreur est survenue'}</>);
    }

    await this.getCardsMaj(values['clientNumCard']);
    this.doLoading(false);
  },
  async askChangePin(values) {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Demande de nouveau code PIN',
      text: 'Voulez vous envoyer une demande de nouveau code PIN ?',
    });

    if (!next) return;

    this.doLoading(true, 'Chargement en cours');
    const toAdd = {
      NumeroIncrement: '0',
      IdReseau: values['iDres'],
      IdCommercant: values['idcom'],
      IdClient: values['idclientFid'],
      NumeroPME: '2',
      NumeroCarte: values['clientNumCard'],
      DateDebutRecharge: '',
      DateFinRecharge: '',
      PeriodeRecharge: 101,
      MontantRecharge: 0,
      idTypeCardUpdate: 5,
      Param_1: values['MotifAskChangePin'],
      Param_2: '',
      Param_3: '',
      Param_4: '',
      Param_5: '',
    };

    const user = {
      idclientFid: values['idclientFid'],
      clientNumCard: values['clientNumCard'],
    };

    let data = props.modelUpdateCard;

    data['idRes'] = values['iDres'];
    data['oldNum'] = values['clientNumCard'];
    data['data'] = JSON.stringify([user]);
    data['lignesStdlistesChgtUniquePME2ToCreate'] = JSON.stringify([toAdd]);

    console.log(data);
    const res2 = await api.post('/scripts/clients/regclients.php', data);

    if (res2.data.success) {
      // toast.success('La modification a bien été effectué')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La modification a bien été effectué',
      });
    } else {
      toast.error(<>{'Une erreur est survenue'}</>);
    }

    await this.getCardsMaj(values['clientNumCard']);
    this.doLoading(false);
  },
  async disableCard(values) {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Désactivation de carte',
      text: 'Voulez-vous désactiver cette carte ?',
    });

    if (!next) return;

    this.doLoading(true, 'Chargement en cours');
    const toAdd = {
      NumeroIncrement: '0',
      IdReseau: values['iDres'],
      IdCommercant: values['idcom'],
      IdClient: values['idclientFid'],
      NumeroPME: '2',
      NumeroCarte: values['clientNumCard'],
      DateDebutRecharge: '',
      DateFinRecharge: '',
      PeriodeRecharge: 101,
      MontantRecharge: 0,
      idTypeCardUpdate: 2,
      Param_1: values['motifBlocage'],
      Param_2: '',
      Param_3: '',
      Param_4: '',
      Param_5: '',
    };

    const user = {
      idclientFid: values['idclientFid'],
      clientNumCard: values['clientNumCard'],
    };

    let data = props.modelUpdateCard;

    data['idRes'] = values['iDres'];
    data['oldNum'] = values['clientNumCard'];
    data['data'] = JSON.stringify([user]);
    data['lignesStdlistesChgtUniquePME2ToCreate'] = JSON.stringify([toAdd]);

    console.log(data);
    const res2 = await api.post('/scripts/clients/regclients.php', data);

    if (res2.data.success) {
      // toast.success('La modification a bien été effectué')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La modification a bien été effectué',
      });
    } else {
      toast.error(<>{'Une erreur est survenue'}</>);
    }

    await this.getCardsMaj(values['clientNumCard']);
    this.doLoading(false);
  },
  async enableCard(values) {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Ré-activation de carte',
      text: 'Voulez-vous ré-activer cette carte ?',
    });

    if (!next) return;

    this.doLoading(true, 'Chargement en cours');
    const toAdd = {
      NumeroIncrement: '0',
      IdReseau: values['iDres'],
      IdCommercant: values['idcom'],
      IdClient: values['idclientFid'],
      NumeroPME: '2',
      NumeroCarte: values['clientNumCard'],
      DateDebutRecharge: '',
      DateFinRecharge: '',
      PeriodeRecharge: 101,
      MontantRecharge: 0,
      idTypeCardUpdate: 3,
      Param_1: values['motifDeblocage'],
      Param_2: '',
      Param_3: '',
      Param_4: '',
      Param_5: '',
    };

    const user = {
      idclientFid: values['idclientFid'],
      clientNumCard: values['clientNumCard'],
    };

    let data = props.modelUpdateCard;

    data['idRes'] = values['iDres'];
    data['oldNum'] = values['clientNumCard'];
    data['data'] = JSON.stringify([user]);
    data['lignesStdlistesChgtUniquePME2ToCreate'] = JSON.stringify([toAdd]);

    console.log(data);
    const res2 = await api.post('/scripts/clients/regclients.php', data);

    if (res2.data.success) {
      // toast.success('La modification a bien été effectué')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La modification a bien été effectué',
      });
    } else {
      toast.error(<>{'Une erreur est survenue'}</>);
    }

    await this.getCardsMaj(values['clientNumCard']);
    this.doLoading(false);
  },
  async editInfosClient(values) {
    this.doLoading(true, 'Chargement en cours');

    const user = {
      idclientFid: values['idclientFid'],
      clientNumCard: values['clientNumCard'],
      clientNom: values['clientNom'],
      clientPrenom: values['clientPrenom'],
      clientCivilite: values['clientCivilite'],
      idSociete: values['idSociete'].id,
      clientGsm: values['clientGsm'],
      clientTel: values['clientTel'],
      clientEmail: values['clientEmail'],
      clientRIB: values['clientRIB'],
      clientAdresse1: values['clientAdresse1'],
      clientAdress2: values['clientAdress2'],
      clientCP: values['clientCP'],
      clientVille: values['clientVille'],
      clientNeLe: values['clientNeLe'],
      clientCardDesactive: values['clientCardDesactive'],
      clientAdresseIncorrect: values['clientAdresseIncorrect'],
      clientCarteTest: values['clientCarteTest'],
    };

    let data = props.modelUpdateCard;

    data['idRes'] = values['iDres'];
    data['oldNum'] = values['clientNumCard'];
    data['data'] = JSON.stringify([user]);

    console.log(data);
    const res2 = await api.post('/scripts/clients/regclients.php', data);

    if (res2.data.success) {
      // toast.success('La modification a bien été effectué')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La modification a bien été effectué',
      });
    } else {
      toast.error(<>{'Une erreur est survenue'}</>);
    }

    // await this.getCardsMaj(values['clientNumCard'])
    this.doLoading(false);
  },
  async getDetailsClients(idCli) {
    const data = {
      cmd: 'getRows',
      objName: 'detailsCartes',
      where: 'clientfid.idclientFid=' + idCli,
    };

    const res = await api.post('/scripts/clients/clients.php', data);

    if (res.data.success) {
      return res.data.rows[0].infos;
    }

    return '';
  },
  //END ACTIONS
  async getListCardUpdate(idClient) {
    const idRes = UserStore.props.user.idRes;

    let data = {
      cmd: 'getRows',
      objName: 'listCardUpdate',
      idReseau: idRes,
      origine: 'clientRecharge',
      idClient: idClient,
      query: '',
    };

    const res = await api.post('/scripts/recharge/listeRecharge.php', data);

    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;
      this.props.listCardsUpdate = data.map((item) => ({
        id: item.id,
        label: item.libelle_type_card_update,
      }));
    }
  },
  async getCardsMaj(numCard) {
    let data = {
      cmd: 'GetLignesStdlistChgtDirectByCriteria',
      where: 'NumeroPME = 2 AND NumeroCarte=' + numCard,
      json: true,
      // 'limit': 4,
      sort: 'NumeroIncrement DESC LIMIT 4',
      callFromClient: true,
    };

    const res = await api.post(
      '/scripts/RepositoryPattern/Services.Recharge.php',
      data
    );

    if (res.data.success) {
      if (res.data.rows) {
        this.props.dataMaj = !res.data.rows ? [] : res.data.rows;
      }
    }
  },
  getQuickSerachItems() {
    let results = [];
    Object.keys(this.props.pattern)
      .filter((item) => this.props.pattern[item].quickSearch)
      .map((item) => {
        results.push({
          id: this.props.pattern[item].name,
          label: this.props.pattern[item].title,
        });
      });

    return results;
  },
  async toggleModalEditCards(row = null) {
    if (!UserStore.isReseau()) return;
    this.doLoading(true, 'Chargement en cours');
    this.props.currentCard = {};
    if (row) {
      await this.getListCardUpdate(row.idcli);
      this.props.currentCard = row;

      this.props.currentCard['clientTypeCarte'] = typeCarte.find(
        (item) => item.id == row.cltTypeCarte
      ).label;
      this.props.currentCard['cltCardOFF'] = parseInt(row.cltCardOFF);
      this.props.currentCard['ctrlList_joursem'] =
        parseInt(row.ctrlList_joursem) > 0 ? true : false;
      this.props.currentCard['ctrlList_joursem1'] = parseInt(
        row.ctrlList_joursem1
      );
      this.props.currentCard['ctrlList_joursem2'] = parseInt(
        row.ctrlList_joursem2
      );
      this.props.currentCard['ctrlList_joursem3'] = parseInt(
        row.ctrlList_joursem3
      );
      this.props.currentCard['ctrlList_joursem4'] = parseInt(
        row.ctrlList_joursem4
      );
      this.props.currentCard['ctrlList_joursem5'] = parseInt(
        row.ctrlList_joursem5
      );
      this.props.currentCard['ctrlList_joursem6'] = parseInt(
        row.ctrlList_joursem6
      );
      this.props.currentCard['ctrlList_joursem7'] = parseInt(
        row.ctrlList_joursem7
      );
      this.props.currentCard['ctrlList_km'] =
        parseInt(row.ctrlList_km) > 0 ? true : false;
      this.props.currentCard['ctrlList_pdt'] =
        parseInt(row.ctrlList_pdt) > 0 ? true : false;
      this.props.currentCard['prdctList_0'] = row.prdctList_0;
      this.props.currentCard['prdctList_1'] = row.prdctList_1;
      this.props.currentCard['prdctList_2'] = row.prdctList_2;
      this.props.currentCard['prdctList_3'] = row.prdctList_3;
      this.props.currentCard['prdctList_4'] = row.prdctList_4;
      this.props.currentCard['prdctList_5'] = row.prdctList_5;
      this.props.currentCard['stats'] = await this.getDetailsClients(row.idcli);

      this.props.initialValuesCards = {
        clientNom: row.cltNom,
        clientPrenom: row.clientPrenom,
        clientCivilite: row.cltCivilite,
        idSociete:
          parseInt(row.idSociete) > 0
            ? { value: row.idSociete, label: row.nomSociete }
            : { value: '', label: 'Aucune' },
        clientGsm: row.cltGsm,
        clientTel: row.cltTel,
        clientEmail: row.cltEmail,
        clientRIB: row.cltRIB,
        clientAdresse1: row.cltAdresse1,
        clientAdress2: row.cltAdress2,
        clientCP: row.cltCP,
        clientVille: row.cltVille,
        clientNeLe: row.cltNeLe,
        clientNumCard: row.cltNumCard,
        clientCardDesactive: parseInt(row.cltCardOFF),
        clientAdresseIncorrect: parseInt(row.cltAdrssKO),
        clientCarteTest: parseInt(row.carteTest),
        idclientFid: row.idcli,
        iDres: row.iDres,
        idcom: row.idcom,
        //Recharge
        Param_1: '',
        DateDebutRecharge: moment(row.DtFin).format('YYYY-MM-DD'),
        DateFinRecharge: null,
        MontantRecharge: '',
        motifRecharge: '',
        motifDeblocage: '',
        motifBlocage: '',
        //REgenerer Pin
        clientPassword: row.clientPassword,
        MotifRegenerate: '',
        MotifAskChangePin: '',
      };
    }
    if (!this.props.isOpenModalEditCards)
      await this.getCardsMaj(row.cltNumCard);
    this.doLoading(false);

    this.props.isOpenModalEditCards = !this.props.isOpenModalEditCards;
  },
  async initFilterClient(pattern) {
    if (pattern) this.props.pattern = pattern;
    console.log('initfilter');
    if (UserStore.isReseau()) {
      this.props.pattern.idsociete.fetch = (value) => this.fetchSociete(value);
      this.props.pattern.idsociete.field.list = await this.fetchSociete();
      this.props.pattern.idsociete.field.list.unshift({
        id: '',
        label: 'Aucune',
      });
      // await this.getSocietes()
    } else {
      delete this.props.pattern.idsociete;
    }

    await this.getCardsType();
    // await this.getSavedFilters()

    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].title = this.props.pattern[item].title.replace(
        '$$point$$',
        UserStore.props.user.unitesPME1
      );
      this.props.pattern[item].title = this.props.pattern[item].title.replace(
        '$$euro$$',
        UserStore.props.user.unitesPME2
      );
    });
    // await CardsStore.getCards()
    this.props.savedPattern = toJS(this.props.pattern);
  },
  async fetchSociete(value = '') {
    const idRes = UserStore.props.user.idRes;
    // const idPart = UserStore.props.user.idPart

    let data = {
      sort: 'nomSociete',
      json: true,
      dir: 'ASC',
      cmd: 'GetByCriteria',
      callFromClient: true,
      objName: 'listCommerce',
      where: ' idReseau =' + idRes + ' and deleted = 0',
      fields: JSON.stringify(['nomSociete']),
      query: value,
    };

    const res = await api.post('/scripts/societe/Services.Societes.php', data);
    let listSocietes = !res.data.rows ? [] : res.data.rows;
    return new Promise((resolve) =>
      resolve(
        listSocietes.map((item) => ({
          value: item.idsociete,
          label: item.nomSociete,
        }))
      )
    );
  },
  /************* EXPORT *************/
  async exportClients() {
    this.doLoading(true, "Génération de l'export en cours ...");

    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';
    if (UserStore.isReseau()) where = this.getWhere('iDreseauFid = ' + idRes);
    else where = this.getWhere('idcommercantFid = ' + idCom);

    const data = {
      cmd: 'exportCsv',
      wheres: JSON.stringify({
        objName: 'exportCsvAFNOR',
        where: where,
        modeF: 2,
      }),
      isCsv: 'true',
      data: '',
    };

    const res = await api.post('/scripts/export/ExportCsvAFNOR.php', data, {
      responseType: 'arraybuffer',
    });

    const file = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
    saveAs(file, 'export_clients.csv');

    this.doLoading(false);
  },
  /*********** AFFECTATION ***********/
  async affect(item, type) {
    this.doLoading(true, 'Affectation en cours ...');
    let next = false;
    let data = {
      cmd: 'affectation',
      idclient: this.props.selectedRow.idcli,
      idLigneChoisie: item.idclientFid,
      temp: item.DateDinsertionEnBase == '' ? 0 : item.DateDinsertionEnBase,
    };

    if (type == 'cardToFiche') {
      next = await confirmation({
        type: 'confirmAffect',
        typeAffect: 'cardToFiche',
        selectedRow: this.props.selectedRow,
        choice: item,
      });
    } else if (type == 'ficheToCard')
      next = await confirmation({
        type: 'confirmAffect',
        typeAffect: 'ficheToCard',
        selectedRow: this.props.selectedRow,
        choice: item,
      });
    else {
      toast.error('Erreur type action');
      return;
    }
    if (!next) {
      this.doLoading(false);
      return;
    }

    const res = await api.post('/scripts/clients/import_clients.php', data);
    console.log(res);
    if (res.status == 200) {
      // toast.success("Affectation réussie");
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'Affectation réussie',
      });
    } else toast.error('Une erreur est survenue');
    this.props.ModalAffectIsOpen = false;
    this.getCards();
    this.doLoading(false);
  },
  async getFichesToAffect() {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = false;
    if (UserStore.isReseau())
      where =
        'iDreseauFid= ' +
        idRes +
        ' and clientNumCard is null and clientPme1=0 and clientPme2=0 and 1erAchat is null and dernierAchat is null and dateValidPME1 is null and dateValidPME2 is null and cumulCreditPme1=0 and cumulCreditPme2=0 and cumulDebitPme1=0 and cumulDebitPme2=0 and PeriodeRechargePME1=0 and PeriodeRechargePME2=0 and RechargePME1=0 and RechargePME2=0 and cumulBonusPme1=0 and cumulBonusPme2=0 and cumulRechargePme1=0 and cumulRechargePme2=0 and cumulSpot=0 and cumulParrainagePme1=0 and cumulParrainagePme2=0';
    else
      where =
        'iDreseauFid= ' +
        idRes +
        ' and idcommercantFid=' +
        idCom +
        ' and clientNumCard is null and clientPme1=0 and clientPme2=0 and 1erAchat is null and dernierAchat is null and dateValidPME1 is null and dateValidPME2 is null and cumulCreditPme1=0 and cumulCreditPme2=0 and cumulDebitPme1=0 and cumulDebitPme2=0 and PeriodeRechargePME1=0 and PeriodeRechargePME2=0 and RechargePME1=0 and RechargePME2=0 and cumulBonusPme1=0 and cumulBonusPme2=0 and cumulRechargePme1=0 and cumulRechargePme2=0 and cumulSpot=0 and cumulParrainagePme1=0 and cumulParrainagePme2=0';

    let data = {
      cmd: 'getRows_bi_table',
      objName: null,
      table_where: 'clientfid',
      table: 'clientfid_temporaire',
      res: UserStore.isReseau() ? idRes : -1,
      where: where,
    };

    const res = await api.post(
      '/scripts/clients/union_client_temporaire.php',
      data
    );
    console.log(res);
    if (res.data.success) {
      this.props.fichesToAffect = res.data.rows;
    } else {
      toast.error('Une erreur est survenue');
    }
  },
  async getCardsToAffect() {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = false;
    if (UserStore.isReseau())
      where =
        'iDreseauFid= ' +
        idRes +
        " and clientNumCard is not null and (clientNom is null or clientNom='') and (clientPrenom is null or clientPrenom='') and (clientEmail is null or clientEmail='') and (clientGsm is null or clientGsm='') and (clientAdresse1 is null or clientAdresse1='') and (clientAdress2 is null or clientAdress2='') and (clientCP is null or clientCP='') and (clientVille is null or clientVille='') and (clientTel is null or clientTel='') and (clientRIB is null or clientRIB='') and (clientCivilite is null or clientCivilite='')";
    else
      where =
        'iDreseauFid= ' +
        idRes +
        ' and idcommercantFid=' +
        idCom +
        " and clientNumCard is not null and (clientNom is null or clientNom='') and (clientPrenom is null or clientPrenom='') and (clientEmail is null or clientEmail='') and (clientGsm is null or clientGsm='') and (clientAdresse1 is null or clientAdresse1='') and (clientAdress2 is null or clientAdress2='') and (clientCP is null or clientCP='') and (clientVille is null or clientVille='') and (clientTel is null or clientTel='') and (clientRIB is null or clientRIB='') and (clientCivilite is null or clientCivilite='')";

    let data = {
      cmd: 'getRows',
      objName: 'lesClients',
      table_where: 'clientfid',
      table: 'clientfid_temporaire',
      res: -1,
      where: where,
    };

    const res = await api.post('/scripts/clients/clients.php', data);
    console.log(res);
    if (res.data.success) {
      this.props.cardsToAffect = res.data.rows;
    } else {
      toast.error('Une erreur est survenue');
    }
  },
  async searchFiche(value, type) {
    this.doLoading(true, 'Recherche en cours ...');
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;
    let where = '';

    if (UserStore.isReseau())
      where =
        type +
        " like '%" +
        value +
        "%' and iDreseauFid= " +
        idRes +
        ' and clientNumCard is null and clientPme1=0 and clientPme2=0 and 1erAchat is null and dernierAchat is null and dateValidPME1 is null and dateValidPME2 is null and cumulCreditPme1=0 and cumulCreditPme2=0 and cumulDebitPme1=0 and cumulDebitPme2=0 and PeriodeRechargePME1=0 and PeriodeRechargePME2=0 and RechargePME1=0 and RechargePME2=0 and cumulBonusPme1=0 and cumulBonusPme2=0 and cumulRechargePme1=0 and cumulRechargePme2=0 and cumulSpot=0 and cumulParrainagePme1=0 and cumulParrainagePme2=0';
    else
      where =
        type +
        " like '%" +
        value +
        "%' and iDreseauFid= " +
        idRes +
        ' and idcommercantFid=' +
        idCom +
        ' and clientNumCard is null and clientPme1=0 and clientPme2=0 and 1erAchat is null and dernierAchat is null and dateValidPME1 is null and dateValidPME2 is null and cumulCreditPme1=0 and cumulCreditPme2=0 and cumulDebitPme1=0 and cumulDebitPme2=0 and PeriodeRechargePME1=0 and PeriodeRechargePME2=0 and RechargePME1=0 and RechargePME2=0 and cumulBonusPme1=0 and cumulBonusPme2=0 and cumulRechargePme1=0 and cumulRechargePme2=0 and cumulSpot=0 and cumulParrainagePme1=0 and cumulParrainagePme2=0';

    let data = {
      cmd: 'getRows_bi_table',
      objName: '',
      table_where: 'clientfid',
      table: 'clientfid_temporaire',
      res: idRes + ' and ' + type + " like '%" + value + "%'",
      where: where,
    };

    const res = await api.post(
      '/scripts/clients/union_client_temporaire.php',
      data
    );
    console.log(res);
    if (res.data.success) {
      this.props.fichesToAffect = res.data.rows ? res.data.rows : [];
    } else {
      toast.error('Une erreur est survenue');
    }

    this.doLoading(false);
  },
  async searchCard(value) {
    this.doLoading(true, 'Recherche en cours ...');
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;
    let where = false;

    if (UserStore.isReseau())
      where =
        "clientNumCard like '%" +
        value +
        "%' and iDreseauFid= " +
        idRes +
        " and clientNumCard is not null and (clientNom is null or clientNom='') and (clientPrenom is null or clientPrenom='') and (clientEmail is null or clientEmail='') and (clientGsm is null or clientGsm='') and (clientAdresse1 is null or clientAdresse1='') and (clientAdress2 is null or clientAdress2='') and (clientCP is null or clientCP='') and (clientVille is null or clientVille='') and (clientTel is null or clientTel='') and (clientRIB is null or clientRIB='') and (clientCivilite is null or clientCivilite='')";
    else
      where =
        "clientNumCard like '%" +
        value +
        "%' and iDreseauFid= " +
        idRes +
        ' and idcommercantFid=' +
        idCom +
        " and clientNumCard is not null and (clientNom is null or clientNom='') and (clientPrenom is null or clientPrenom='') and (clientEmail is null or clientEmail='') and (clientGsm is null or clientGsm='') and (clientAdresse1 is null or clientAdresse1='') and (clientAdress2 is null or clientAdress2='') and (clientCP is null or clientCP='') and (clientVille is null or clientVille='') and (clientTel is null or clientTel='') and (clientRIB is null or clientRIB='') and (clientCivilite is null or clientCivilite='')";

    let data = {
      cmd: 'getRows',
      objName: 'lesClients',
      table_where: 'clientfid',
      table: 'clientfid_temporaire',
      res: "467 and clientNumCard like '%gh%' ",
      where: where,
    };

    const res = await api.post('/scripts/clients/clients.php', data);
    console.log(res);
    if (res.data.success) {
      this.props.cardsToAffect = res.data.rows ? res.data.rows : [];
    } else {
      toast.error('Une erreur est survenue');
    }

    this.doLoading(false);
  },
  async toggleModalAffect(type = false, row) {
    this.props.ModalAffectIsOpen = !this.props.ModalAffectIsOpen;
    this.doLoading(true, 'Chargement ...');
    if (this.props.ModalAffectIsOpen) this.props.selectedRow = row;
    if (type) {
      if (type == 'ficheToCard')
        this.props.titleAffect = "Affectation d'une carte";
      else if (type == 'cardToFiche')
        this.props.titleAffect = "Affectation d'une fiche";
      this.props.typeAffect = type;
    }
    if (this.props.ModalAffectIsOpen) {
      if (type == 'ficheToCard') await this.getFichesToAffect();
      if (type == 'cardToFiche') await this.getCardsToAffect();
    }
    this.doLoading(false);
  },
  //FILTRES
  toggleItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = !this.props.pattern[key].active;
  },
  activateItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = true;
  },
  changeOperator(key, val, index) {
    console.log(val);
    this.props.pattern[key].value[index].operator = val;
    if (val == 'between') {
      this.props.pattern[key].value[index].value = [null, null];
    } else {
      this.props.pattern[key].value[index].value = '';
    }
  },
  changeValue(key, val, index, order) {
    if (this.props.pattern[key].value[index].operator == 'between') {
      this.props.pattern[key].value[index].value[order] = val;
    } else if (
      this.props.pattern[key].value[index].operator == 'in' ||
      this.props.pattern[key].value[index].operator == 'inConcat'
    ) {
      if (val != 'all') {
        let position = this.props.pattern[key].value[index].value.findIndex(
          (o) => o == val
        );
        console.log('position :', position);
        if (position === -1)
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value,
            val,
          ];
        else
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value.filter(
              (item) => item != val
            ),
          ];
      } else {
        if (
          this.props.pattern[key].field.list.length !=
          this.props.pattern[key].value[index].value.length
        ) {
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].field.list.map((item) => item.id),
          ];
        } else {
          this.props.pattern[key].value[index].value = [];
        }
      }
    } else {
      this.props.pattern[key].value[index].value = val;
    }
    console.log(toJS(this.props.pattern[key].value[index].value));
  },
  addItemFilter(key, type) {
    let newVal = { ...this.props.pattern[key].defaultValue };
    newVal.logic = type;
    this.props.pattern[key].value = [...this.props.pattern[key].value, newVal];
  },
  removeItemFilter(key, idx) {
    this.props.pattern[key].value = [
      ...this.props.pattern[key].value.filter((item, index) => index != idx),
    ];
  },
  submitFilter() {
    this.props.savedPattern = toJS(this.props.pattern);
    // this.getCountActiveFilter()
  },
  resetFilter() {
    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].value = props.pattern[item].value;
      this.props.savedPattern[item].value = props.pattern[item].value;
      this.props.pattern[item].active = false;
      this.props.savedPattern[item].active = false;
    });
  },
  undoPattern() {
    this.props.pattern = toJS(this.props.savedPattern);
  },
  //PAGINATION
  async nextPageCards() {
    this.props.pageIndexCards = this.props.pageIndexCards + 1;
    this.props.pageStartCards =
      this.props.pageIndexCards * this.props.pageLimitCards;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getCards();
    this.doLoading(false);
  },
  async prevPageCards() {
    this.props.pageIndexCards = this.props.pageIndexCards - 1;
    this.props.pageStartCards =
      this.props.pageIndexCards * this.props.pageLimitCards;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getCards();
    this.doLoading(false);
  },
  async goToPageCards(page) {
    this.props.pageIndexCards = page;
    this.props.pageStartCards =
      this.props.pageIndexCards * this.props.pageLimitCards;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getCards();
    this.doLoading(false);
  },
  async setSortCards(value) {
    console.log('TEST', value);
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortByCards != value) {
      this.props.sortByCards = value;
      this.props.sortOrderCards = 'ASC';
    } else {
      if (this.props.sortOrderCards == 'ASC')
        this.props.sortOrderCards = 'DESC';
      else if (this.props.sortOrderCards == 'DESC') {
        this.props.sortOrderCards = false;
        this.props.sortByCards = null;
      }
    }

    await this.getCards();
    this.doLoading(false);
    // console.log(value, this.props.sortOrder)
  },
  //
  getWhere(where = '', type = null) {
    const main = [
      ...Object.keys(this.props.pattern)
        .filter((item) => this.props.pattern[item].active)
        .map((item) => toJS(this.props.pattern[item].value)),
    ];

    console.log(where);
    for (let key in main) {
      if (main[key].isHidden) continue;
      if (where.length) {
        if (main[key].length) where += ' AND ( ';
      } else where += '( ';
      let pass = 0;
      for (let id in main[key]) {
        if (pass) where += ' ' + main[key][id].logic + ' ';
        pass = 1;

        if (type == 'campaign')
          where += this.getClauseCampaign(main[key][id], main[key][id].custom);
        else if (type == 'export')
          where += this.getClauseExport(main[key][id], main[key][id].custom);
        else where += this.getClause(main[key][id], main[key][id].custom);
      }

      if (where.length) {
        if (main[key].length) where += ')';
      }
    }

    return where;
  },
  getClause(clause, isCustom = false) {
    let where = '-+--+-+ ERR -++-+-+';
    if (clause.operator == 'in') {
      where = clause.field + ' IN (' + clause.value.join(',') + ') ';
      console.log(where);
    } else if (clause.operator == 'inConcat') {
      console.log(clause);
      where = '';
      for (let i = 0; i < clause.value.length; i++) {
        where += clause.value[i];
        if (i + 1 != clause.value.length) where += ' OR ';
      }
      console.log(where);
    } else if (clause.operator == 'between') {
      if (clause.type == 'date') {
        where =
          clause.field +
          " BETWEEN '" +
          clause.value[0] +
          "' AND '" +
          clause.value[1] +
          "' ";
      } else if (clause.type == 'number') {
        where =
          clause.field +
          ' BETWEEN ' +
          clause.value[0] +
          ' AND ' +
          clause.value[1] +
          ' ';
      } else {
        where = '__ERROR__1';
      }
    } else if (clause.operator == 'like' || clause.operator == 'not like') {
      if (clause.type == 'text') {
        where =
          clause.field + ' ' + clause.operator + " '%" + clause.value + "%' ";
      } else {
        where = '__ERROR__2';
      }
    } else if (
      clause.operator == '>' ||
      clause.operator == '<' ||
      clause.operator == '>=' ||
      clause.operator == '<=' ||
      clause.operator == '<>' ||
      clause.operator == '='
    ) {
      if (
        clause.type == 'date' ||
        clause.type == 'text' ||
        clause.type == 'select'
      ) {
        where =
          clause.field + ' ' + clause.operator + " '" + clause.value + "' ";
      } else if (clause.type == 'number') {
        where = clause.field + ' ' + clause.operator + ' ' + clause.value + ' ';
      } else {
        where = '__ERROR__3';
      }
    } else {
      where = '__ERROR__4';
    }
    if (isCustom)
      return clause.field.replace('_$$$_', where.replace(clause.field, ''));
    return where;
  },
  async getCardsType() {
    const idRes = UserStore.props.user.idRes;
    let data = {
      cmd: 'getRows',
      objName: 'listeRegleRes',
      fields: '["Label"]',
      where: 'reglereseau.InitCarteTransactionDefaut=97 and ReseauId=' + idRes,
    };

    const res = await api.post('/scripts/reseau/reseau.php', data);

    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;

      this.props.pattern.ruleId.field.list = data.map((item) => ({
        id: item.RuleKey,
        label: item.Label,
      }));

      if (!this.props.pattern.ruleId.value.length)
        this.props.pattern.ruleId.value.forEach((item) => {
          item.value = data.map((item) => item.RuleKey);
        });
    }
  },
  /************* BLACKLIST *************/
  async editBlackList() {
    this.doLoading(true, 'Génération de la blakclist en cours ...');
    const idRes = UserStore.props.user.idRes;
    let data = {
      cmd: 'exporterLaBlackListe',
      ref: 'expBlkLst',
      idS: idRes,
      idx: 1,
    };

    const res = await api.post('scripts/export/ExportBlackList.php', data);
    this.doLoading(false);
    if (res.data.error) {
      toast.success(
        <>
          Génération de la Blklist vers les TPE <br /> {res.data.error}
        </>
      );
    } else {
      toast.error(
        <>
          Génération de la Blklist vers les TPE <br /> Une erreur est survenue
        </>
      );
    }
  },
  async editUpdListe() {
    // this.doLoading(true, 'Génération de la blakclist en cours ...')
    const idRes = UserStore.props.user.idRes;
    let data = {
      cmd: 'exporterLaUpdListe',
      idS: idRes,
    };

    const res = await api.post('scripts/export/ExportUpdlist.php', data);
    // this.doLoading(false)
    if (res.data.error) {
      toast.success(
        <>
          Génération de la Updlist vers les TPE <br /> {res.data.error}
        </>
      );
    } else {
      toast.error(
        <>
          Génération de la Updlist vers les TPE <br /> Une erreur est survenue
        </>
      );
    }
  },
  async editStdListe() {
    // this.doLoading(true, 'Génération de la blakclist en cours ...')
    const idRes = UserStore.props.user.idRes;
    let data = {
      cmd: 'ExportStdlist',
      idReseau: idRes,
    };

    const res = await api.post('scripts/export/ExportStdlist.php', data);
    // this.doLoading(false)
    if (res.data.error) {
      toast.success(
        <>
          Génération de la Updlist vers les TPE <br /> {res.data.error}
        </>
      );
    } else {
      toast.error(
        <>
          Génération de la Updlist vers les TPE <br /> Une erreur est survenue
        </>
      );
    }
  },
  reset() {
    this.props = props;
  },
};

export default observable(CardsStore);
