import axios from 'axios';
import qs from 'qs';

const instance = axios.create({
  baseURL: process.env['REACT_APP_API_URL'],
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  transformRequest: (data, headers) => {
    // transform the data
    if (headers['Content-Type'] !== 'multipart/form-data')
      return qs.stringify(data);
    return data;
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    config.headers['Api_version'] = 'v2';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
