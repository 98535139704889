import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundView = () => {
  return (
    <div class='c-app c-default-layout flex-row align-items-center'>
      <div class='container'>
        <div class='justify-content-center row'>
          <div class='col-md-6'>
            <div class='clearfix'>
              <h1 class='float-left display-3 mr-4'>404</h1>
              <h4 class='pt-3'>Oops!</h4>
              <p class='text-muted float-left'>La page n'a pas été trouvé.</p>
            </div>
            <div class='col-md-12 d-flex'>
              <Link to='/dashboard' className='btn rounded btn-lg btn-pass'>
                Tableau de bord
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundView;
