import React, { useState, useEffect, useRef } from 'react';
import UserStore from '../../stores/UserStore';
import {
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownItem,
  Dropdown,
} from 'reactstrap';
import { observer } from 'mobx-react';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import MyField from '../../reusable/MyField';
import moment from 'moment';
import { useTable } from 'react-table';
import DataTable, {
  ColumnsToggle,
  DropdownPortal,
  Portal,
} from '../../reusable/DataTable';
import {
  faCaretDown,
  faFilter,
  faPaperPlane,
  faPlus,
  faIdCard,
  faStream,
  faEye,
  faCircle,
  faEnvelopeOpenText,
  faCheck,
  faCode,
  faSave,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import myYup from '../../utils/myYup';
import { useFormik } from 'formik';
import { ModalFooter } from 'react-bootstrap';
import OrdersStore from '../../stores/OrdersStore';
import OrdersFilter from '../../reusable/OrdersFilter';
import { StatutCarte } from '../../utils/constants';
import confirmation from '../Alerts/Confirmation';

const QuickSearch = observer((props) => {
  const { onSubmitFilter, className } = props;
  const listSearch = [
    {
      id: 'numCard',
      label: 'N° carte',
    },
    {
      id: 'NumTag',
      label: 'N° tag',
    },
    {
      id: 'Immatriculation',
      label: 'Immatriculation',
    },
    {
      id: 'c_t.idReferenceExterne',
      label: 'ID Véhicule',
    },
  ];

  // console.log(toJS(listCommerces))
  // const activateItemFilter = (key) => OrdersStore.activateItemFilter(key);
  // const changeValue = (key, val, index, order) =>
  //   OrdersStore.changeValue(key, val, index, order);
  const resetFilter = () => OrdersStore.resetFilter();

  const [type, setType] = useState('numCard');
  const [value, setValue] = useState('');

  const doQuickSearch = () => {
    resetFilter();

    if (!type || !value) {
      return;
    }

    onSubmitFilter(type, value);
  };

  return (
    <div className='d-flex'>
      <div className='mx-2'>
        <label className='text-nowrap mb-0'>Recherche</label>
      </div>
      <div className='mx-2'>
        <MyField
          name='type'
          type='select'
          className='form-control form-control-sm form-control form-control-sm-sm'
          list={listSearch}
          onChange={(e) => {
            setType(e.target.value);
          }}
          value={type}
        ></MyField>
      </div>
      <div className='mx-2'>
        {/* <label className="text-nowrap mb-0">N° Tpe</label> */}
        <MyField
          name='value'
          type='text'
          placeholder=''
          className='form-control form-control-sm form-control form-control-sm-sm'
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              doQuickSearch();
            }
          }}
        ></MyField>
      </div>
      <div className='mx-2 d-flex align-items-end'>
        <button className={className} onClick={() => doQuickSearch()}>
          Rechercher
        </button>
      </div>
    </div>
  );
});

export const ActionsOrders = observer((props) => {
  const { row, toggleModalDetailsOrders } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const rootSelector = document.getElementById('root');
  const div = document.createElement('div');
  div.className = 'h-0';
  let portalContainer = useRef(div).current;

  useEffect(() => {
    // let portalContainer = document.createElement("div");
    rootSelector.appendChild(portalContainer);
    return () => {
      rootSelector.removeChild(portalContainer);
    };
  }, []);

  return (
    <DropdownPortal
      id={'edit-drop'}
      isOpen={dropdownOpen}
      toggle={toggle}
      portalContainer={portalContainer}
    >
      <DropdownToggle
        tag='button'
        className='btn btn-xs btn-outline-fuel btn-action'
        data-toggle='dropdown'
        aria-expanded={dropdownOpen}
      >
        {' '}
        Actions
        <FontAwesomeIcon className='ml-2' icon={faCaretDown}></FontAwesomeIcon>
      </DropdownToggle>
      <Portal target={portalContainer}>
        <DropdownMenu right className='p-0'>
          <DropdownItem
            tag='a'
            className='dropdown-item'
            onClick={() => toggleModalDetailsOrders(row)}
          >
            <FontAwesomeIcon
              icon={faEye}
              className='mr-2 text-pass'
            ></FontAwesomeIcon>
            Visualiser / traiter
          </DropdownItem>
          {(row.statut == 4 || row.statut == 5) && (
            <DropdownItem
              tag='a'
              className='dropdown-item'
              target='_blank'
              href={
                process.env['REACT_APP_API_URL'] +
                'scripts/pdf/makePDF.php?action=livraisonCommande&orderId=' +
                row.idcommande_article
              }
            >
              <FontAwesomeIcon
                icon={faEnvelopeOpenText}
                className='mr-2 text-pass'
              ></FontAwesomeIcon>
              Regénérer le courrier de livraison
            </DropdownItem>
          )}
        </DropdownMenu>
      </Portal>
    </DropdownPortal>
  );
});

const ModalDetailsOrders = observer((props) => {
  const {
    selectedRowOrders,
    isOpenModalDetailsOrders,
    toggleModalDetailsOrders,
    treatCards,
    currentUser,
  } = props;

  const showTreatButton =
    selectedRowOrders.responsableTraitement &&
    currentUser.toUpperCase() ==
      selectedRowOrders.responsableTraitement.toUpperCase() &&
    selectedRowOrders.statut == 3;

  return (
    <Modal
      backdrop='static'
      isOpen={isOpenModalDetailsOrders}
      toggle={() => toggleModalDetailsOrders()}
    >
      <ModalHeader className='no-border'>
        Visualisation / Traitement commande n°{' '}
        {selectedRowOrders.idcommande_article}
      </ModalHeader>
      <ModalBody className='px-5 py-3'>
        <div className='row'>
          <div className='col-md-12'>
            <table className='w-100'>
              <tbody>
                <tr className='border-bottom'>
                  <td>Type Carte</td>
                  <td>
                    <b>{selectedRowOrders.typeCarte_lbl}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Valeur</td>
                  <td>
                    <b>{selectedRowOrders.valeurCarte}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Civilité destinataire</td>
                  <td>
                    <b>{selectedRowOrders.destinataireCivilite}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Nom dest.</td>
                  <td>
                    <b>{selectedRowOrders.nom}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Prénom dest.</td>
                  <td>
                    <b>{selectedRowOrders.prenom}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Tél dest.</td>
                  <td>
                    <b>{selectedRowOrders.destinataireTel}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>CP dest.</td>
                  <td>
                    <b>{selectedRowOrders.destinataireCP}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Adresse 1 dest.</td>
                  <td>
                    <b>{selectedRowOrders.destinataireAdresse1}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Email dest.</td>
                  <td>
                    <b>{selectedRowOrders.destinataireEmail}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Réseau:</td>
                  <td>
                    <b>{selectedRowOrders.idReseauFid_lbl}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Station</td>
                  <td>
                    <b>{selectedRowOrders.idCommercantFid_lbl}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Date commande</td>
                  <td>
                    <b>
                      {moment(selectedRowOrders.dateHeureCmdmoment).isValid()
                        ? moment(selectedRowOrders.dateHeureCmdmoment).format(
                            'DD/MM/YYYY [à] HH:mm:ss'
                          )
                        : '-'}
                    </b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Date d'envoi souhaitée</td>
                  <td>
                    <b>
                      {moment(selectedRowOrders.dateEnvoiSouhaitee).isValid()
                        ? moment(selectedRowOrders.dateEnvoiSouhaitee).format(
                            'DD/MM/YYYY [à] HH:mm:ss'
                          )
                        : '-'}
                    </b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Date de Mise à jour:</td>
                  <td>
                    <b>
                      {moment(selectedRowOrders.dateTraitement).isValid()
                        ? moment(selectedRowOrders.dateTraitement).format(
                            'DD/MM/YYYY [à] HH:mm:ss'
                          )
                        : '-'}
                    </b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Traité par:</td>
                  <td>
                    <b>{selectedRowOrders.responsableTraitement}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Société</td>
                  <td>
                    <b>{selectedRowOrders.idsociete_lbl}</b>
                  </td>
                </tr>
                <tr className='border-bottom'>
                  <td>Statut</td>
                  <td>
                    <b>{selectedRowOrders.statut}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='no-border'>
        {showTreatButton && (
          <button
            type='submit'
            className='btn-success btn'
            onClick={() => treatCards(selectedRowOrders)}
          >
            {selectedRowOrders.responsableTraitement.length > 0 &&
              !selectedRowOrders.responsableTraitement == '' && (
                <>Continuer le traitement</>
              )}
            {selectedRowOrders.responsableTraitement == '' && (
              <>Lancer le traitement</>
            )}
          </button>
        )}
        <button
          className='btn-danger btn'
          onClick={() => toggleModalDetailsOrders()}
        >
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});

const ModalHowTo = observer((props) => {
  const { selectedRowOrders, isOpenModalHowTo, toggleModalHowTo } = props;

  return (
    <Modal
      backdrop='static'
      isOpen={isOpenModalHowTo}
      className='modal-lg'
      toggle={() => toggleModalHowTo()}
    >
      <ModalHeader className='no-border'>
        Pour satisfaire la commande n° {selectedRowOrders.idcommande_article}
      </ModalHeader>
      <ModalBody className='px-5 py-3'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12 d-flex align-items-center mb-2'>
                <FontAwesomeIcon
                  icon={faCircle}
                  size={'2x'}
                  className='text-pass mr-3'
                ></FontAwesomeIcon>
                Choisir une carte : <b>{selectedRowOrders.typeCarte_lbl}</b>
              </div>
              <div className='col-md-12 d-flex align-items-center mb-2'>
                <FontAwesomeIcon
                  icon={faCircle}
                  size={'2x'}
                  className='text-pass mr-3'
                ></FontAwesomeIcon>
                Sur le TPE, accéder au menu "Traiter commande" puis insérer la
                carte
              </div>
              <div className='col-md-12 d-flex align-items-center mb-2'>
                <FontAwesomeIcon
                  icon={faCircle}
                  size={'2x'}
                  className='text-pass mr-3'
                ></FontAwesomeIcon>
                Saisir l'id : <b>{selectedRowOrders.idcommande_article}</b>
              </div>
              <div className='col-md-12 d-flex align-items-center mb-2'>
                <FontAwesomeIcon
                  icon={faCircle}
                  size={'2x'}
                  className='text-pass mr-3'
                ></FontAwesomeIcon>
                Spécifier le montant initial :{' '}
                <b>{selectedRowOrders.valeurCarte}</b>
              </div>
              <div className='col-md-12 d-flex align-items-center mb-2'>
                <FontAwesomeIcon
                  icon={faCircle}
                  size={'2x'}
                  className='text-pass mr-3'
                ></FontAwesomeIcon>
                S'assurer d'une connexion en ligne du TPE
              </div>
              <div className='col-md-12 d-flex align-items-center mb-2'>
                <FontAwesomeIcon
                  icon={faCircle}
                  size={'2x'}
                  className='text-pass mr-3'
                ></FontAwesomeIcon>
                Valider la transaction
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='no-border'>
        <button className='btn-success btn' onClick={() => toggleModalHowTo()}>
          Ok
        </button>
      </ModalFooter>
    </Modal>
  );
});

const ModalScan = observer((props) => {
  const { cardNotFound, isOpenModalScan, toggleModalScan, submitNewCard } =
    props;

  const textInput = useRef(null);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: myYup.object().shape({
      numCarte: myYup.string().required(),
    }),
    onSubmit: submitNewCard,
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    console.log(textInput);
    if (isOpenModalScan)
      setTimeout(() => {
        if (textInput.current) textInput.current.focus();
      }, 500);
  }, [isOpenModalScan]);

  return (
    <Modal
      backdrop='static'
      autoFocus={true}
      centered={true}
      isOpen={isOpenModalScan}
      toggle={() => toggleModalScan()}
    >
      <ModalHeader toggle={() => toggleModalScan()} className='no-border'>
        Nouvelle Carte
      </ModalHeader>
      <ModalBody className='px-3 py-3'>
        <div className='row'>
          <div className='col-md-12 d-flex flex-column align-items-center'>
            <span className='mb-2'>
              Scannez le QRcode de la carte avec un lecteur
            </span>
            <span className='mb-2'>Ou</span>
            <span className='mb-2'>Saisissez le numéro de la carte</span>
          </div>
          {cardNotFound && (
            <div className='col-md-12 d-flex justify-content-center'>
              <h5 className='text-danger'>Carte introuvable</h5>
            </div>
          )}
          <div className='col-md-12 d-flex justify-content-center mb-3'>
            <div className='col-md-6'>
              <MyField
                refer={textInput}
                type='text'
                className='form-control'
                onChange={handleChange}
                name='numCarte'
                value={values['numCarte']}
                error={errors['numCarte']}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                touched={touched['numCarte']}
              ></MyField>
            </div>
          </div>
          <div className='col-md-12 d-flex justify-content-center mb-3'>
            <button className='btn btn-fuel' onClick={handleSubmit}>
              <FontAwesomeIcon
                icon={faCheck}
                className='mr-2'
              ></FontAwesomeIcon>
              Valider
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
});

const ModalNewCard = observer((props) => {
  const {
    currentCard,
    isOpenModalNewCard,
    toggleModalNewCard,
    submitEditCard,
    fetchSociete,
    listCardsType,
  } = props;

  let initialValuesCard = {};

  if (currentCard) {
    initialValuesCard = currentCard;
  }

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValuesCard,
      validationSchema: myYup.object().shape({
        idSociete: myYup.object({
          value: myYup.string().ensure().required(),
        }),
        RuleId: myYup.string().ensure().required(),
        expiration: myYup.string().ensure().required(),
        Pme2: myYup.number().required(),
      }),
      onSubmit: submitEditCard,
      validateOnChange: false,
      validateOnBlur: false,
    });

  const [disabled, setDisabled] = useState(true);
  const [disableDays, setDisabledDays] = useState(false);
  const [disableProducts, setDisabledProducts] = useState(false);

  const reEncode = async () => {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Carte déjà existante',
      text: '<p>Vous vous apprétez à réencoder une carte déjà initialisée, êtes vous sûr(e) ?</p>',
    });

    if (next) {
      setDisabled(false);
    }
  };

  const access_token = UserStore.props.access_token;

  const printCard = () => {
    if (values['statut_commande'] == 3)
      window.open(
        process.env['REACT_APP_API_URL'] +
          'cards/' +
          values['cltNumCard'] +
          '.pdf?access_token=' +
          access_token,
        'mywindow',
        'menubar=1,resizable=1,width=440,height=290,toolbar=0,scrollbars=0,resizable=0,status=0,location=0,menubar=0'
      );
    else
      window.open(
        process.env['REACT_APP_API_URL'] +
          'cards/0' +
          values['cltNumCard'] +
          '.pdf?access_token=' +
          access_token,
        'mywindow',
        'menubar=1,resizable=1,width=440,height=290,toolbar=0,scrollbars=0,resizable=0,status=0,location=0,menubar=0'
      );
  };

  const printCourrier = () => {
    window.open(
      process.env['REACT_APP_API_URL'] +
        'scripts/pdf/makePDF.php?action=livraisonCommande&orderId=' +
        currentCard.idcommande_article +
        '&access_token=' +
        access_token
    );
  };

  useEffect(() => {
    // console.log(toJS(values),isOpenModalNewCard && values['statut_commande'])
    if (isOpenModalNewCard && !values['statut_commande']) {
      console.log('DISBALED');
      setDisabled(false);
    }
  }, [values['statut_commande']]);

  useEffect(() => {
    // console.log('useEFfect CtrlJourSem')
    let found = listCardsType.find((item) => item.id == values['RuleId']);
    //    console.log(listCardsType,found,values['RuleId'])
    if (found && isOpenModalNewCard) {
      setFieldValue('expiration', found.expiration);
    }
  }, [values['RuleId']]);

  useEffect(() => {
    // console.log('useEFfect CtrlJourSem')
    if (values['ctrlList_joursem']) {
      setDisabledDays(false);
    } else {
      setDisabledDays(true);
      // setFieldValue('ctrlList_joursem0', 0)
      setFieldValue('ctrlList_joursem1', 0);
      setFieldValue('ctrlList_joursem2', 0);
      setFieldValue('ctrlList_joursem3', 0);
      setFieldValue('ctrlList_joursem4', 0);
      setFieldValue('ctrlList_joursem5', 0);
      setFieldValue('ctrlList_joursem6', 0);
      setFieldValue('ctrlList_joursem7', 0);
    }
  }, [values['ctrlList_joursem']]);

  useEffect(() => {
    // console.log('useEFfect CtrlJourSem')
    if (values['ctrlList_pdt']) {
      setDisabledProducts(false);
    } else {
      setDisabledProducts(true);
      setFieldValue('prdctList_0', 0);
      setFieldValue('prdctList_1', 0);
      setFieldValue('prdctList_2', 0);
      setFieldValue('prdctList_3', 0);
      setFieldValue('prdctList_4', 0);
      setFieldValue('prdctList_5', 0);
    }
  }, [values['ctrlList_pdt']]);

  return (
    <Modal
      backdrop='static'
      centered={true}
      isOpen={isOpenModalNewCard}
      className='modal-xl'
      toggle={() => toggleModalNewCard()}
    >
      <ModalHeader className='no-border'>Nouvelle carte</ModalHeader>
      <ModalBody className='px-3 py-3'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Carte:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='cltNumCard'
                  value={values['cltNumCard']}
                  error={errors['cltNumCard']}
                  touched={touched['cltNumCard']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Nom:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='text'
                  disabled={disabled}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='cltNom'
                  value={values['cltNom']}
                  error={errors['cltNom']}
                  touched={touched['cltNom']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Prenom:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='text'
                  disabled={disabled}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='cltPrenom'
                  value={values['cltPrenom']}
                  error={errors['cltPrenom']}
                  touched={touched['cltPrenom']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Email:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='text'
                  disabled={disabled}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='cltEmail'
                  value={values['cltEmail']}
                  error={errors['cltEmail']}
                  touched={touched['cltEmail']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Société:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='asyncSelect'
                  disabled={disabled}
                  defaultValue={values['idSociete']}
                  className='async-select'
                  fetch={fetchSociete}
                  customOnChange={setFieldValue}
                  defaultOptions={true}
                  name='idSociete'
                  value={values['idSociete']}
                  error={
                    errors['idSociete']
                      ? errors['idSociete'].value
                      : errors['idSociete']
                  }
                  touched={touched['idSociete']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Type de carte:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='select'
                  disabled={disabled}
                  list={listCardsType}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='RuleId'
                  value={values['RuleId']}
                  error={errors['RuleId']}
                  touched={touched['RuleId']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Expire le:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='date'
                  disabled={true}
                  className='form-control form-control-sm'
                  customOnChange={setFieldValue}
                  name='expiration'
                  value={values['expiration']}
                  error={errors['expiration']}
                  touched={touched['expiration']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Montant:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='number'
                  disabled={disabled}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='Pme2'
                  value={values['Pme2']}
                  error={errors['Pme2']}
                  touched={touched['Pme2']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Immatriculation:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='text'
                  disabled={disabled}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='Immatriculation'
                  value={values['Immatriculation']}
                  error={errors['Immatriculation']}
                  touched={touched['Immatriculation']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Statut:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='statut_commande'
                  value={StatutCarte[values['statut_commande']]}
                ></MyField>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Contrôle Jour Semaine
              </legend>
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Oui'
                  disabled={disabled}
                  className='abc-checkbox-primary col-md-12'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem'
                  value={values['ctrlList_joursem']}
                  error={errors['ctrlList_joursem']}
                  touched={touched['ctrlList_joursem']}
                ></MyField>
              </div>
              {/* <AnimateHeight
                                duration={500}
                                className="px-0"
                                height={values['ctrlList_joursem'] ? 'auto' : 0}
                            > */}
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Lundi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem1'
                  value={values['ctrlList_joursem1']}
                  error={errors['ctrlList_joursem1']}
                  touched={touched['ctrlList_joursem1']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Mardi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem2'
                  value={values['ctrlList_joursem2']}
                  error={errors['ctrlList_joursem2']}
                  touched={touched['ctrlList_joursem2']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Mercredi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem3'
                  value={values['ctrlList_joursem3']}
                  error={errors['ctrlList_joursem3']}
                  touched={touched['ctrlList_joursem3']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Jeudi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem4'
                  value={values['ctrlList_joursem4']}
                  error={errors['ctrlList_joursem4']}
                  touched={touched['ctrlList_joursem4']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Vendredi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem5'
                  value={values['ctrlList_joursem5']}
                  error={errors['ctrlList_joursem5']}
                  touched={touched['ctrlList_joursem5']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Samedi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem6'
                  value={values['ctrlList_joursem6']}
                  error={errors['ctrlList_joursem6']}
                  touched={touched['ctrlList_joursem6']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Dimanche autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem7'
                  value={values['ctrlList_joursem7']}
                  error={errors['ctrlList_joursem7']}
                  touched={touched['ctrlList_joursem7']}
                ></MyField>
              </div>
              {/* </AnimateHeight> */}
            </fieldset>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Contrôle Kilométrage
              </legend>
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Oui'
                  disabled={disabled}
                  className='abc-checkbox-primary col-md-12'
                  customOnChange={setFieldValue}
                  name='ctrlList_km'
                  value={values['ctrlList_km']}
                  error={errors['ctrlList_km']}
                  touched={touched['ctrlList_km']}
                ></MyField>
              </div>
            </fieldset>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Contrôle Produits
              </legend>
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Oui'
                  disabled={disabled}
                  className='abc-checkbox-primary col-md-12'
                  customOnChange={setFieldValue}
                  name='ctrlList_pdt'
                  value={values['ctrlList_pdt']}
                  error={errors['ctrlList_pdt']}
                  touched={touched['ctrlList_pdt']}
                ></MyField>
              </div>
              {/* <AnimateHeight
                                duration={500}
                                className="px-0"
                                height={values['ctrlList_pdt'] ? 'auto' : 0}
                            > */}
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Gazoline'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_0'
                  value={values['prdctList_0']}
                  error={errors['prdctList_0']}
                  touched={touched['prdctList_0']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Diésel'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_1'
                  value={values['prdctList_1']}
                  error={errors['prdctList_1']}
                  touched={touched['prdctList_1']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Kérosène'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_2'
                  value={values['prdctList_2']}
                  error={errors['prdctList_2']}
                  touched={touched['prdctList_2']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='GPL'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_3'
                  value={values['prdctList_3']}
                  error={errors['prdctList_3']}
                  touched={touched['prdctList_3']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Lubrifiants'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_4'
                  value={values['prdctList_4']}
                  error={errors['prdctList_4']}
                  touched={touched['prdctList_4']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Autres'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_5'
                  value={values['prdctList_5']}
                  error={errors['prdctList_5']}
                  touched={touched['prdctList_5']}
                ></MyField>
              </div>
              {/* </AnimateHeight> */}
            </fieldset>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='no-border'>
        {disabled && (
          <button className='btn-fuel btn' onClick={() => reEncode()}>
            <FontAwesomeIcon icon={faCode} className='mr-2'></FontAwesomeIcon>
            Ré-encoder
          </button>
        )}
        {!disabled && (
          <button className='btn-fuel btn' onClick={handleSubmit}>
            <FontAwesomeIcon icon={faSave} className='mr-2'></FontAwesomeIcon>
            Enregistrer
          </button>
        )}
        <button className='btn-fuel btn' onClick={() => printCard()}>
          <FontAwesomeIcon icon={faIdCard} className='mr-2'></FontAwesomeIcon>
          Imprimer carte
        </button>
        <button className='btn-fuel btn' onClick={() => printCourrier()}>
          <FontAwesomeIcon icon={faPrint} className='mr-2'></FontAwesomeIcon>
          Imprimer courrier
        </button>
        <button className='btn-danger btn' onClick={() => toggleModalNewCard()}>
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});

const ModalNewBatch = observer((props) => {
  const {
    currentBatch,
    isOpenModalNewBatch,
    toggleModalNewBatch,
    submitNewBatch,
    fetchSociete,
    listCardsType,
  } = props;

  let initialValuesBatch = {};

  if (currentBatch) {
    initialValuesBatch = currentBatch;
  }

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValuesBatch,
      onSubmit: (values) => submitNewBatch(values),
      validationSchema: myYup.object().shape({
        idSociete: myYup.object({
          value: myYup.string().ensure().required(),
        }),
        RuleId: myYup.string().ensure().required(),
        expiration: myYup.string().ensure().required(),
        Pme2: myYup.number().required(),
        NbCartes: myYup.number().required().min(1),
      }),
      validateOnChange: false,
      validateOnBlur: false,
    });

  const disabled = currentBatch.numbatch ? true : false;

  // const [disabled, setDisabled] = useState(false)
  const [disableDays, setDisabledDays] = useState(true);
  const [disableProducts, setDisabledProducts] = useState(true);

  const access_token = UserStore.props.access_token;

  const printCard = () => {
    window.open(
      process.env['REACT_APP_API_URL'] +
        'cards/' +
        values['idSociete'].value +
        '_' +
        values['numbatch'] +
        '.pdf?access_token=' +
        access_token,
      'mywindow',
      'menubar=1,resizable=1,width=440,height=290,toolbar=0,scrollbars=0,resizable=0,status=0,location=0,menubar=0'
    );
  };

  const printCourrier = () => {
    window.open(
      process.env['REACT_APP_API_URL'] +
        'scripts/pdf/makePDF.php?action=livraisonCommande&orderId=' +
        currentBatch.idcommande_article +
        '&access_token=' +
        access_token
    );
  };

  useEffect(() => {
    // console.log('useEFfect CtrlJourSem')
    if (values['ctrlList_joursem']) {
      setDisabledDays(false);
    } else {
      setDisabledDays(true);
      // setFieldValue('ctrlList_joursem0', 0)
      setFieldValue('ctrlList_joursem1', 0);
      setFieldValue('ctrlList_joursem2', 0);
      setFieldValue('ctrlList_joursem3', 0);
      setFieldValue('ctrlList_joursem4', 0);
      setFieldValue('ctrlList_joursem5', 0);
      setFieldValue('ctrlList_joursem6', 0);
      setFieldValue('ctrlList_joursem7', 0);
    }
  }, [values['ctrlList_joursem']]);

  useEffect(() => {
    // console.log('useEFfect CtrlJourSem')
    if (values['ctrlList_pdt']) {
      setDisabledProducts(false);
    } else {
      setDisabledProducts(true);
      setFieldValue('prdctList_0', 0);
      setFieldValue('prdctList_1', 0);
      setFieldValue('prdctList_2', 0);
      setFieldValue('prdctList_3', 0);
      setFieldValue('prdctList_4', 0);
      setFieldValue('prdctList_5', 0);
    }
  }, [values['ctrlList_pdt']]);

  useEffect(() => {
    // console.log('useEFfect CtrlJourSem')
    let found = listCardsType.find(
      (item) => item.id == initialValuesBatch.RuleId
    );
    //    console.log(listCardsType,found,initialValuesBatch.RuleId)
    if (found && isOpenModalNewBatch) {
      setFieldValue('expiration', found.expiration);
    }
  }, [isOpenModalNewBatch]);

  return (
    <Modal
      backdrop='static'
      centered={true}
      isOpen={isOpenModalNewBatch}
      className='modal-xl'
      toggle={() => toggleModalNewBatch()}
    >
      <ModalHeader className='no-border'>Nouveau batch</ModalHeader>
      <ModalBody className='px-3 py-3'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Num Batch:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='numbatch'
                  value={values['numbatch']}
                  error={errors['numbatch']}
                  touched={touched['numbatch']}
                ></MyField>
              </div>
            </div>
            {!currentBatch.newRecord && (
              <div className='row form-group col-md-12 mb-3'>
                <label className='col-md-5' for=''>
                  Carte:
                </label>
                <div className='col-md-7'>
                  <MyField
                    type='text'
                    disabled={true}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    name='cltNumCard'
                    value={values['cltNumCard']}
                    error={errors['cltNumCard']}
                    touched={touched['cltNumCard']}
                  ></MyField>
                </div>
              </div>
            )}
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Société:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='asyncSelect'
                  disabled={disabled}
                  customOnChange={setFieldValue}
                  defaultValue={values['idSociete']}
                  className='async-select'
                  fetch={fetchSociete}
                  defaultOptions={true}
                  name='idSociete'
                  value={values['idSociete']}
                  error={
                    errors['idSociete']
                      ? errors['idSociete'].value
                      : errors['idSociete']
                  }
                  touched={touched['idSociete']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Type de carte:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='select'
                  disabled={disabled}
                  list={listCardsType}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='RuleId'
                  value={values['RuleId']}
                  error={errors['RuleId']}
                  touched={touched['RuleId']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Expire le:{' '}
              </label>
              <div className='col-md-7'>
                <MyField
                  type='date'
                  disabled={true}
                  className='form-control form-control-sm'
                  customOnChange={setFieldValue}
                  name='expiration'
                  value={values['expiration']}
                  error={errors['expiration']}
                  touched={touched['expiration']}
                ></MyField>
              </div>
            </div>
            <div className='row form-group col-md-12 mb-3'>
              <label className='col-md-5' for=''>
                Montant:
              </label>
              <div className='col-md-7'>
                <MyField
                  type='number'
                  disabled={disabled}
                  className='form-control form-control-sm'
                  onChange={handleChange}
                  name='Pme2'
                  value={values['Pme2']}
                  error={errors['Pme2']}
                  touched={touched['Pme2']}
                ></MyField>
              </div>
            </div>
            {currentBatch.newRecord && (
              <div className='row form-group col-md-12 mb-3'>
                <label className='col-md-5' for=''>
                  Nombre de cartes:
                </label>
                <div className='col-md-7'>
                  <MyField
                    type='number'
                    disabled={disabled}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    name='NbCartes'
                    value={values['NbCartes']}
                    error={errors['NbCartes']}
                    touched={touched['NbCartes']}
                  ></MyField>
                </div>
              </div>
            )}
            {!currentBatch.newRecord && (
              <div className='row form-group col-md-12 mb-3'>
                <label className='col-md-5' for=''>
                  Statut:
                </label>
                <div className='col-md-7'>
                  <MyField
                    type='text'
                    disabled={true}
                    className='form-control form-control-sm'
                    onChange={handleChange}
                    name='statut_commande'
                    value={StatutCarte[values['statut_commande']]}
                  ></MyField>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-6'>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Contrôle Jour Semaine
              </legend>
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Oui'
                  disabled={disabled}
                  className='abc-checkbox-primary col-md-12'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem'
                  value={values['ctrlList_joursem']}
                  error={errors['ctrlList_joursem']}
                  touched={touched['ctrlList_joursem']}
                ></MyField>
              </div>
              {/* <AnimateHeight
                                duration={500}
                                className="px-0"
                                height={values['ctrlList_joursem'] ? 'auto' : 0}
                            > */}
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Lundi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem1'
                  value={values['ctrlList_joursem1']}
                  error={errors['ctrlList_joursem1']}
                  touched={touched['ctrlList_joursem1']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Mardi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem2'
                  value={values['ctrlList_joursem2']}
                  error={errors['ctrlList_joursem2']}
                  touched={touched['ctrlList_joursem2']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Mercredi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem3'
                  value={values['ctrlList_joursem3']}
                  error={errors['ctrlList_joursem3']}
                  touched={touched['ctrlList_joursem3']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Jeudi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem4'
                  value={values['ctrlList_joursem4']}
                  error={errors['ctrlList_joursem4']}
                  touched={touched['ctrlList_joursem4']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Vendredi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem5'
                  value={values['ctrlList_joursem5']}
                  error={errors['ctrlList_joursem5']}
                  touched={touched['ctrlList_joursem5']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Samedi autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem6'
                  value={values['ctrlList_joursem6']}
                  error={errors['ctrlList_joursem6']}
                  touched={touched['ctrlList_joursem6']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Dimanche autorisé'
                  disabled={disableDays || disabled}
                  className='abc-checkbox-primary col-md-6'
                  customOnChange={setFieldValue}
                  name='ctrlList_joursem7'
                  value={values['ctrlList_joursem7']}
                  error={errors['ctrlList_joursem7']}
                  touched={touched['ctrlList_joursem7']}
                ></MyField>
              </div>
              {/* </AnimateHeight> */}
            </fieldset>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Contrôle Kilométrage
              </legend>
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Oui'
                  disabled={disabled}
                  className='abc-checkbox-primary col-md-12'
                  customOnChange={setFieldValue}
                  name='ctrlList_km'
                  value={values['ctrlList_km']}
                  error={errors['ctrlList_km']}
                  touched={touched['ctrlList_km']}
                ></MyField>
              </div>
            </fieldset>
            <fieldset className='p-3 border rounded'>
              <legend className='text-fid w-auto px-3 text-old'>
                Contrôle Produits
              </legend>
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Oui'
                  disabled={disabled}
                  className='abc-checkbox-primary col-md-12'
                  customOnChange={setFieldValue}
                  name='ctrlList_pdt'
                  value={values['ctrlList_pdt']}
                  error={errors['ctrlList_pdt']}
                  touched={touched['ctrlList_pdt']}
                ></MyField>
              </div>
              {/* <AnimateHeight
                                duration={500}
                                className="px-0"
                                height={values['ctrlList_pdt'] ? 'auto' : 0}
                            > */}
              <div className='row'>
                <MyField
                  type='checkbox'
                  checkboxLabel='Gazoline'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_0'
                  value={values['prdctList_0']}
                  error={errors['prdctList_0']}
                  touched={touched['prdctList_0']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Diésel'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_1'
                  value={values['prdctList_1']}
                  error={errors['prdctList_1']}
                  touched={touched['prdctList_1']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Kérosène'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_2'
                  value={values['prdctList_2']}
                  error={errors['prdctList_2']}
                  touched={touched['prdctList_2']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='GPL'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_3'
                  value={values['prdctList_3']}
                  error={errors['prdctList_3']}
                  touched={touched['prdctList_3']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Lubrifiants'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_4'
                  value={values['prdctList_4']}
                  error={errors['prdctList_4']}
                  touched={touched['prdctList_4']}
                ></MyField>
                <MyField
                  type='checkbox'
                  checkboxLabel='Autres'
                  disabled={disableProducts || disabled}
                  className='abc-checkbox-primary col-md-4'
                  customOnChange={setFieldValue}
                  name='prdctList_5'
                  value={values['prdctList_5']}
                  error={errors['prdctList_5']}
                  touched={touched['prdctList_5']}
                ></MyField>
              </div>
              {/* </AnimateHeight> */}
            </fieldset>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='no-border'>
        {currentBatch.newRecord && (
          <button className='btn-fuel btn' onClick={handleSubmit}>
            <FontAwesomeIcon icon={faSave} className='mr-2'></FontAwesomeIcon>
            Enregistrer
          </button>
        )}
        {!currentBatch.newRecord && (
          <>
            <button className='btn-fuel btn' onClick={() => printCard()}>
              <FontAwesomeIcon
                icon={faIdCard}
                className='mr-2'
              ></FontAwesomeIcon>
              Imprimer carte
            </button>
            <button className='btn-fuel btn' onClick={() => printCourrier()}>
              <FontAwesomeIcon
                icon={faPrint}
                className='mr-2'
              ></FontAwesomeIcon>
              Imprimer courrier
            </button>
          </>
        )}
        <button
          className='btn-danger btn'
          onClick={() => toggleModalNewBatch()}
        >
          Fermer
        </button>
      </ModalFooter>
    </Modal>
  );
});

const OrdersView = observer((props) => {
  useEffect(() => {
    OrdersStore.init();
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const CommandesTable = useTable({
    columns: OrdersStore.props.columns,
    data: OrdersStore.props.data,
    initialState: {
      hiddenColumns: OrdersStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: OrdersStore.props.pageIndex,
      pageSize: OrdersStore.props.pageLimit,
      pageCount: OrdersStore.props.pageCount,
      pageTotal: OrdersStore.props.pageTotal,
    },
    toggleModalDetailsOrders: (row) =>
      OrdersStore.toggleModalDetailsOrders(row),
    treatCards: (row) => OrdersStore.treatCards(row),
    updateParamsTpe: (idTpe) => OrdersStore.updateParamsTpe(idTpe),
    updateVersionTpe: (idTpe, numSerie) =>
      OrdersStore.updateVersionTpe(idTpe, numSerie),
    toggleCheckboxAll: () => OrdersStore.toggleCheckboxAll(),
    selectionnes: OrdersStore.props.selectionnes.slice(),
    toggleCheckbox: (index, smsLength) =>
      OrdersStore.toggleCheckbox(index, smsLength),
    getBadgeColor: (value) => OrdersStore.getBadgeColor(value),
  });

  return (
    <div className='container-fluid mt-3'>
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={OrdersStore.props.loading.status}
              message={OrdersStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className='card card-transparent'
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <div className='row mb-2'>
                  <div className='col-md-auto'>
                    <Dropdown
                      isOpen={dropdownOpen}
                      className=' ml-auto'
                      toggle={toggle}
                    >
                      <DropdownToggle
                        tag='button'
                        className='btn btn-sm btn-fuel'
                        data-toggle='dropdown'
                        aria-expanded={dropdownOpen}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='mr-2'
                        ></FontAwesomeIcon>
                        Carte
                        <FontAwesomeIcon
                          className='ml-2'
                          icon={faCaretDown}
                        ></FontAwesomeIcon>
                      </DropdownToggle>
                      <DropdownMenu right className='p-0'>
                        <DropdownItem
                          tag='a'
                          onClick={() => OrdersStore.toggleModalScan()}
                          className='dropdown-item'
                        >
                          <FontAwesomeIcon
                            icon={faIdCard}
                            className='mr-2 text-success'
                          ></FontAwesomeIcon>
                          Nouvelle carte
                        </DropdownItem>
                        <DropdownItem
                          tag='a'
                          onClick={() => OrdersStore.toggleModalNewBatch()}
                          className='dropdown-item'
                        >
                          <FontAwesomeIcon
                            icon={faStream}
                            className='mr-2 text-success'
                          ></FontAwesomeIcon>
                          Nouveau Batch
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className='col-md-auto'>
                    <button
                      className='btn btn-sm btn-fuel'
                      onClick={() => OrdersStore.setCardsSent()}
                    >
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className='mr-2'
                      ></FontAwesomeIcon>
                      Passer en livré / Expédié
                    </button>
                  </div>
                  {/* <div className='col-md-auto'>
                                        <QuickSearch
                                            onSubmitFilter={() => OrdersStore.getFilteredOrders()}
                                            className="btn btn-fuel btn-sm btn-sm-flex-h"
                                            listCommerces={OrdersStore.props.listCommerces}
                                        ></QuickSearch>
                                    </div> */}
                  <div className='col-md-auto'>
                    <div className='row'>
                      <div className='col-md-auto'>
                        <ColumnsToggle
                          columns={CommandesTable.allColumns}
                          className='btn btn-fuel btn-sm mx-1 btn-sm-flex-h'
                          toggleColumn={(col) => OrdersStore.toggleColumn(col)}
                        ></ColumnsToggle>
                      </div>
                      <div className='col-md-auto'>
                        <OrdersFilter
                          onSubmitFilter={() => OrdersStore.getFilteredOrders()}
                          className='btn btn-fuel btn-sm  mx-1'
                          innerButton={
                            <>
                              <FontAwesomeIcon
                                icon={faFilter}
                                className='mr-2'
                              ></FontAwesomeIcon>
                              Recherche avancée
                            </>
                          }
                        ></OrdersFilter>
                      </div>
                      <div className='col-md-auto'>
                        <QuickSearch
                          className='btn btn-fuel btn-sm  mx-1'
                          onSubmitFilter={(type, value) =>
                            OrdersStore.getOrdersBySearch(type, value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <DataTable
                  headerGroups={CommandesTable.headerGroups}
                  getTableBodyProps={CommandesTable.getTableBodyProps}
                  headerClassName='bg-theme-grey'
                  onDoubleClickRow={(index, row) =>
                    OrdersStore.showCardBatchDetails(row)
                  }
                  rows={CommandesTable.rows}
                  prepareRow={CommandesTable.prepareRow}
                  getTableProps={CommandesTable.getTableProps}
                  columns={OrdersStore.props.columns}
                  loadingMsg={OrdersStore.props.loading.msg}
                  loadingStatus={OrdersStore.props.loading.status}
                  prevPage={() => OrdersStore.prevPage()}
                  nextPage={() => OrdersStore.nextPage()}
                  setSort={(sortBy) => OrdersStore.setSort(sortBy)}
                  sortOrder={OrdersStore.props.sortOrder}
                  sortBy={OrdersStore.props.sortBy}
                  goToPage={(page) => OrdersStore.goToPage(page)}
                  pageCount={OrdersStore.props.pageCount}
                  pageIndex={OrdersStore.props.pageIndex}
                  pageLimit={OrdersStore.props.pageLimit}
                  pageTotal={OrdersStore.props.pageTotal}
                  maxHeight='calc(100vh - 260px)'
                  btnClassName='btn btn-outline-fuel btn-sm'
                ></DataTable>
              </div>
              <ModalDetailsOrders
                isOpenModalDetailsOrders={
                  OrdersStore.props.isOpenModalDetailsOrders
                }
                currentUser={UserStore.props.user.nom}
                listTimezones={OrdersStore.props.listTimezones}
                validationSchemaTpe={OrdersStore.props.validationSchemaTpe}
                selectedRowOrders={OrdersStore.props.selectedRowOrders}
                initialValuesTpe={OrdersStore.props.initialValuesTpe}
                toggleModalDetailsOrders={(row) =>
                  OrdersStore.toggleModalDetailsOrders(row)
                }
                treatCards={(row) => OrdersStore.treatCards(row)}
                submitDetailsOrders={(values) =>
                  OrdersStore.submitDetailsOrders(values)
                }
              ></ModalDetailsOrders>
              <ModalHowTo
                isOpenModalHowTo={OrdersStore.props.isOpenModalHowTo}
                selectedRowOrders={OrdersStore.props.selectedRowOrders}
                toggleModalHowTo={(row) => OrdersStore.toggleModalHowTo(row)}
              ></ModalHowTo>
              <ModalNewCard
                currentCard={OrdersStore.props.currentCard}
                isOpenModalNewCard={OrdersStore.props.isOpenModalNewCard}
                toggleModalNewCard={(row) =>
                  OrdersStore.toggleModalNewCard(row)
                }
                submitEditCard={(values) => OrdersStore.submitEditCard(values)}
                validationSchemaCard={OrdersStore.props.validationSchemaCard}
                fetchSociete={(value) => OrdersStore.fetchSociete(value)}
                listCardsType={OrdersStore.props.listCardsType}
              ></ModalNewCard>
              <ModalNewBatch
                currentBatch={OrdersStore.props.currentBatch}
                isOpenModalNewBatch={OrdersStore.props.isOpenModalNewBatch}
                submitNewBatch={(values) => OrdersStore.submitNewBatch(values)}
                toggleModalNewBatch={(row) =>
                  OrdersStore.toggleModalNewBatch(row)
                }
                fetchSociete={(value) => OrdersStore.fetchSociete(value)}
                listCardsType={OrdersStore.props.listCardsType}
              ></ModalNewBatch>
              <ModalScan
                cardNotFound={OrdersStore.props.cardNotFound}
                isOpenModalScan={OrdersStore.props.isOpenModalScan}
                toggleModalScan={() => OrdersStore.toggleModalScan()}
                submitNewCard={(values) => OrdersStore.submitNewCard(values)}
              ></ModalScan>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default OrdersView;
