import React, { useState } from 'react';
import Logo from '../../assets/img/logo_black.png';
import UserStore from '../../stores/UserStore';
import { useLocation, useHistory } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import { useFormik } from 'formik';
import myYup from '../../utils/myYup';
import MyField from '../../reusable/MyField';
import { BarLoader } from '../Loader/Loading';
import { toast } from 'react-toastify';

const ResetPasswordView = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const token = query.get('token');
  const selector = query.get('selector');

  const resetPassword = async (creds) => {
    if (!token || !selector) return toast.error('Invalid token');
    UserStore.props.loading.status = true;
    setIsLoading(true);
    await UserStore.resetPassword({ ...creds, token, selector }, () =>
      history.push('/login')
    );
    UserStore.props.loading.status = false;
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      new_password_1: '',
      new_password_2: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await resetPassword(values);
      resetForm();
    },
    validationSchema: myYup.object().shape({
      new_password_1: myYup
        .string()
        .required()
        .min(8, 'Ton mot de pase doit contenir au moins 8 caratctères.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&*#!@$%^&:;,.?()\[\]{}~_+\-=|<>])[A-Za-z\d@$!%*?&*#!@$%^&:;,.?()\[\]{}~_+\-=|<>]{8,}$/,
          'Ton mot de passe doit contenir au moins un chiffre - au moins une lettre majuscule - au moins une lettre minuscule et un caractère spécial.'
        ),
      new_password_2: myYup
        .string()
        .required()
        .oneOf(
          [myYup.ref('new_password_1'), null],
          'Les mots de passe ne sont pas identiques.'
        ),
    }),
  });

  return (
    <div className='app flex-row align-items-center'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-5'>
            <BlockUi
              tag='div'
              className='card-group rounded login-loader'
              loader={<BarLoader></BarLoader>}
              message={UserStore.props.loading.msg}
              blocking={UserStore.props.loading.status}
            >
              <div className='card p-4'>
                <div className='card-body'>
                  <div className='text-center'>
                    <img
                      className='login-image'
                      src={Logo}
                      alt='Fidlink Logo'
                    />
                  </div>
                  <p className='text-muted text-center'>
                    Réinitialiser votre mot de passe
                  </p>
                  <form onSubmit={formik.handleSubmit} method='post'>
                    <div className='input-group mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <i className='icon-lock'></i>
                        </span>
                      </div>
                      <MyField
                        name='new_password_1'
                        type='password'
                        className='form-control'
                        placeholder='Mot de passe'
                        onChange={formik.handleChange}
                        value={formik.values.new_password_1}
                        error={formik.errors.new_password_1}
                        highlight={UserStore.props.fail.status}
                        touched={formik.touched.new_password_1}
                      ></MyField>
                    </div>
                    <div className='input-group mb-4'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <i className='icon-lock'></i>
                        </span>
                      </div>
                      <MyField
                        name='new_password_2'
                        type='password'
                        className='form-control'
                        placeholder='Confirmer le mot de passe'
                        onChange={formik.handleChange}
                        value={formik.values.new_password_2}
                        error={formik.errors.new_password_2}
                        highlight={UserStore.props.fail.status}
                        touched={formik.touched.new_password_2}
                      ></MyField>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <button
                          type='submit'
                          className='btn btn-fuel px-4'
                          disabled={isLoading}
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordView;
