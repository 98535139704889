import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import BoUserStore from '../../stores/BoUserStore';
import SocietesStore from '../../stores/SocietesStore';
import StationsStore from '../../stores/StationsStore';
import { useTable } from 'react-table';
import { DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faPlus,
  faTrash,
  faPen,
  faPaperPlane,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import 'simplebar/dist/simplebar.min.css';
import MyField from '../../reusable/MyField';
import DataTable, {
  ColumnsToggle,
  DropdownPortal,
  Portal,
} from '../../reusable/DataTable';
import { Roles_With_Id_Label } from '../../utils/constants';
import { ModalEditBoUser } from '../Common/Modals';
import UserStore from '../../stores/UserStore';

export const ActionsBoUser = observer((props) => {
  const {
    row,
    toggleModalEditBoUser,
    isManager,
    isOperator,
    isReseau,
    deleteUser,
    resend2FAMail,
    resendActionMAil,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const rootSelector = document.getElementById('root');
  const div = document.createElement('div');
  div.className = 'h-0';
  let portalContainer = useRef(div).current;

  useEffect(() => {
    rootSelector.appendChild(portalContainer);
    return () => {
      rootSelector.removeChild(portalContainer);
    };
  }, []);

  return (
    <>
      <DropdownPortal
        id={'edit-drop'}
        isOpen={dropdownOpen}
        toggle={toggle}
        portalContainer={portalContainer}
      >
        <DropdownToggle
          tag='button'
          className='btn btn-xs btn-outline-fuel btn-action'
          data-toggle='dropdown'
          aria-expanded={dropdownOpen}
        >
          {' '}
          Actions
          <FontAwesomeIcon
            className='ml-2'
            icon={faCaretDown}
          ></FontAwesomeIcon>
        </DropdownToggle>
        <Portal target={portalContainer}>
          <DropdownMenu right className='p-0'>
            {/* Edit User */}
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => toggleModalEditBoUser(row)}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Modifier cet utilisateur
              </DropdownItem>
            )}
            {/* Resend Activation Mail */}
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => resendActionMAil(row)}
              >
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Renvoyer email de vérification
              </DropdownItem>
            )}
            {/* Resend 2FA Mail */}
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => resend2FAMail(row)}
              >
                <FontAwesomeIcon
                  icon={faLock}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Réinitialiser email de configuration 2FA
              </DropdownItem>
            )}
            {/* {(isOperator || isManager) && isReseau && 0 === 1 && ( */}
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => deleteUser(row)}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Supprimer cet utilisateur
              </DropdownItem>
            )}
          </DropdownMenu>
        </Portal>
      </DropdownPortal>
    </>
  );
});

const BoUsersView = observer((props) => {
  useEffect(() => {
    BoUserStore.init();
    SocietesStore.init();
    StationsStore.init();
  }, []);

  const boUserTable = useTable({
    columns: BoUserStore.props.columns,
    data: BoUserStore.props.data.map((d) => {
      return {
        ...d,
        ...{
          usersDroit: Roles_With_Id_Label.filter((r) => r.id === d.usersDroit)
            ? Roles_With_Id_Label.filter((r) => r.id === d.usersDroit)[0]
            : {},
          compteActif: parseInt(d.compteActif) === 1,
          verified: parseInt(d.verified) === 1,
          two_factors_method: parseInt(d.two_factors_method) === 1,
          two_factors_verified: parseInt(d.two_factors_verified) === 1,
          DroitCreation: parseInt(d.DroitCreation) === 1,
          DroitAdministrationCommandes:
            parseInt(d.DroitAdministrationCommandes) === 1,
          DroitValidationCredit: parseInt(d.DroitValidationCredit) === 1,
          DroitRechargementPeriodique:
            parseInt(d.DroitRechargementPeriodique) === 1,
          DroitRechargementUnique: parseInt(d.DroitRechargementUnique) === 1,
          DroitArreterComptable: parseInt(d.DroitArreterComptable) === 1,
          DroitExport: parseInt(d.DroitExport) === 1,
          DroitCreationTPE: parseInt(d.DroitCreationTPE) === 1,
          DroitCreationSociete: parseInt(d.DroitCreationSociete) === 1,
          DroitGestionBonCarburant: parseInt(d.DroitGestionBonCarburant) === 1,
        },
      };
    }),
    initialState: {
      hiddenColumns: BoUserStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: BoUserStore.props.pageIndex,
      pageSize: BoUserStore.props.pageLimit,
      pageCount: BoUserStore.props.pageCount,
      pageTotal: BoUserStore.props.pageTotal,
    },
    deleteUser: (row) => BoUserStore.deleteUser(row),
    resend2FAMail: (row) => BoUserStore.resend2FAMail(row),
    resendActionMAil: (row) => BoUserStore.resendActionMAil(row),
    toggleModalCreateBoUser: () => BoUserStore.toggleModalCreateBoUser(),
    toggleModalEditBoUser: (row) => BoUserStore.toggleModalEditBoUser(row),
    isOperator: UserStore.isOperator(),
    isManager: UserStore.isManager(),
    isReseau: UserStore.isReseau(),
  });

  return (
    <div className='container-fluid mt-3'>
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={BoUserStore.props.loading.status}
              message={BoUserStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className='card'
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col-md-2 d-flex align-items-end'>
                    <ColumnsToggle
                      columns={boUserTable.allColumns}
                      className='btn btn-old btn-sm mx-1'
                      toggleColumn={(col) => BoUserStore.toggleColumn(col)}
                    ></ColumnsToggle>
                  </div>
                  <div className='col-md-10'>
                    <div className='row'>
                      <div className='col-md-2 d-flex align-items-end'>
                        {(UserStore.isOperator() || UserStore.isManager()) &&
                          UserStore.isReseau() && (
                            <button
                              className='btn btn-sm btn-fuel'
                              onClick={() =>
                                BoUserStore.toggleModalCreateBoUser()
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className='mr-2'
                              ></FontAwesomeIcon>
                              Ajout Utilisateur
                            </button>
                          )}
                      </div>
                      <div className='col-md-5'>
                        <label
                          className='text-right mr-2'
                          for='filtre-nom-utiliateur'
                        >
                          Rechercher par nom :
                        </label>
                        <MyField
                          type='reactSelect'
                          list={BoUserStore.props.listBoUsers}
                          className='async-select'
                          onChange={(e) => BoUserStore.setSearchBoUser(e)}
                          name='boUser'
                          id='filtre-nom-utilisateur'
                          value={BoUserStore.props.searchBoUser}
                        ></MyField>
                      </div>
                      <div className='col-md-5'>
                        <label
                          className='text-right mr-2'
                          for='filtre-nom-societe'
                        >
                          Rechercher par société :
                        </label>
                        <MyField
                          type='reactSelect'
                          list={SocietesStore.props.listSocietes}
                          className='async-select'
                          onChange={(e) =>
                            BoUserStore.setSearchBoUserSociete(e)
                          }
                          name='societe'
                          id='filtre-nom-societe'
                          value={SocietesStore.props.setSearchBoUserSociete}
                        ></MyField>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    {/* <fieldset className="p-3 border rounded">
                      <legend className="text-fid w-auto px-3 text-old">
                        Liste des Utilisateurs BO
                      </legend> */}
                    <DataTable
                      headerGroups={boUserTable.headerGroups}
                      getTableBodyProps={boUserTable.getTableBodyProps}
                      onDoubleClickRow={(index, row) =>
                        BoUserStore.toggleModalEditBoUser(row)
                      }
                      onClickRow={(index, row) =>
                        BoUserStore.setCurrentBoUser(row)
                      }
                      rows={boUserTable.rows}
                      tableClassName='font-xs'
                      prepareRow={boUserTable.prepareRow}
                      getTableProps={boUserTable.getTableProps}
                      columns={BoUserStore.props.columns}
                      loadingMsg={BoUserStore.props.loading.msg}
                      loadingStatus={BoUserStore.props.loading.status}
                      prevPage={() => BoUserStore.prevPage()}
                      nextPage={() => BoUserStore.nextPage()}
                      setSort={(sortBy) => BoUserStore.setSort(sortBy)}
                      sortOrder={BoUserStore.props.sortOrder}
                      sortBy={BoUserStore.props.sortBy}
                      goToPage={(page) => BoUserStore.goToPage(page)}
                      pageCount={BoUserStore.props.pageCount}
                      pageIndex={BoUserStore.props.pageIndex}
                      pageLimit={BoUserStore.props.pageLimit}
                      pageTotal={BoUserStore.props.pageTotal}
                      maxHeight='calc(100vh - 260px)'
                      btnClassName='btn btn-outline-fuel btn-sm'
                    ></DataTable>
                    {/* </fieldset> */}
                  </div>
                </div>

                <ModalEditBoUser
                  initialValuesBoUser={BoUserStore.props.initialValuesBoUser}
                  currentBoUser={BoUserStore.props.currentBoUser}
                  toggleModalCreateBoUser={() =>
                    BoUserStore.toggleModalCreateBoUser()
                  }
                  toggleModalEditBoUser={() =>
                    BoUserStore.toggleModalEditBoUser()
                  }
                  submitEditBoUser={(values) =>
                    BoUserStore.submitEditBoUser(values)
                  }
                  isOpenModalEditBoUser={
                    BoUserStore.props.isOpenModalEditBoUser
                  }
                  listSocietes={SocietesStore.props.listSocietes}
                  listCommerces={StationsStore.props.listCommerces}
                ></ModalEditBoUser>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BoUsersView;
