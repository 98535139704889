import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTable } from 'react-table';
import moment from 'moment';

import DataTable, { ColumnsToggle } from '../../reusable/DataTable';
import PrixProduitFilterStore from '../../stores/PrixProduitFilterStore';
import MyField from '../../reusable/MyField';
import { BarLoader } from '../Loader/Loading';

export const QuickSearchDate = observer((props) => {
  const { onSubmitFilter, className } = props;

  const activateItemFilter = (key) =>
    PrixProduitFilterStore.activateItemFilter(key);
  const changeValue = (key, val, index, order) =>
    PrixProduitFilterStore.changeValue(key, val, index, order);

  const [startDate, setStartDate] = useState(
    moment().subtract('1', 'months').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const doQuickSearch = () => {
    activateItemFilter('DateAction');

    changeValue('DateAction', startDate, 0, 0);
    changeValue('DateAction', endDate, 0, 1);
    onSubmitFilter();
  };

  return (
    <div className='d-flex'>
      <div className='mx-2'>
        <label className='text-nowrap mb-0'>Date début</label>
        <MyField
          name='startDate'
          type='date'
          className='form-control form-control-sm'
          onChange={(date) => {
            setStartDate(date);
          }}
          value={startDate}
        ></MyField>
      </div>
      <div className='mx-2'>
        <label className='text-nowrap mb-0'>Date fin</label>
        <MyField
          name='endDate'
          type='date'
          className='form-control form-control-sm'
          onChange={(date) => {
            setEndDate(date);
          }}
          value={endDate}
        ></MyField>
      </div>
      <div className='mx-2 d-flex align-items-end'>
        <button className={className} onClick={() => doQuickSearch()}>
          Rechercher
        </button>
      </div>
    </div>
  );
});

const HistoricPrice = observer(() => {
  useEffect(() => {
    PrixProduitFilterStore.init();
  }, []);

  const prixProduitTable = useTable({
    columns: PrixProduitFilterStore.props.columns,
    data: PrixProduitFilterStore.props.data,
    initialState: {
      hiddenColumns: PrixProduitFilterStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: PrixProduitFilterStore.props.pageIndex,
      pageSize: PrixProduitFilterStore.props.pageLimit,
      pageCount: PrixProduitFilterStore.props.pageCount,
      pageTotal: PrixProduitFilterStore.props.pageTotal,
    },
    setUnitsHeader: PrixProduitFilterStore.setUnitsHeader,
  });
  return PrixProduitFilterStore.props.loading.status ? (
    <BarLoader />
  ) : (
    <>
      <div className='d-flex  align-items-end mb-2 mt-4'>
        {/* search */}
        <div>
          <QuickSearchDate
            className='btn btn-old btn-sm btn-sm-flex-h'
            onSubmitFilter={() => PrixProduitFilterStore.getFilteredPrices()}
          />
        </div>
        <div className='d-flex ml-auto '>
          <ColumnsToggle
            columns={prixProduitTable.allColumns}
            className='btn btn-old btn-sm mx-1 btn-sm-flex-h'
            toggleColumn={(col) => PrixProduitFilterStore.toggleColumn(col)}
          />
        </div>
      </div>
      <DataTable
        headerGroups={prixProduitTable.headerGroups}
        getTableBodyProps={prixProduitTable.getTableBodyProps}
        rows={prixProduitTable.rows}
        prepareRow={prixProduitTable.prepareRow}
        getTableProps={prixProduitTable.getTableProps}
        columns={PrixProduitFilterStore.props.columns}
        loadingMsg={PrixProduitFilterStore.props.loading.msg}
        loadingStatus={PrixProduitFilterStore.props.loading.status}
        prevPage={() => PrixProduitFilterStore.prevPage()}
        nextPage={() => PrixProduitFilterStore.nextPage()}
        setSort={(sortBy) => PrixProduitFilterStore.setSort(sortBy)}
        sortOrder={PrixProduitFilterStore.props.sortOrder}
        sortBy={PrixProduitFilterStore.props.sortBy}
        goToPage={(page) => PrixProduitFilterStore.goToPage(page)}
        pageCount={PrixProduitFilterStore.props.pageCount}
        pageIndex={PrixProduitFilterStore.props.pageIndex}
        pageLimit={PrixProduitFilterStore.props.pageLimit}
        pageTotal={PrixProduitFilterStore.props.pageTotal}
        maxHeight='calc(100vh - 260px)'
        btnClassName='btn btn-outline-success btn-sm'
      ></DataTable>
    </>
  );
});

export default HistoricPrice;
