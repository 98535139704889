import React, { useState, useEffect } from 'react';
import UserStore from '../../stores/UserStore';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import FinancialStore from '../../stores/FinancialStore';
import { useTable } from 'react-table';
import DataTable, { ColumnsToggle } from '../../reusable/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faRedo,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { number } from '../../utils/numeral';
import SimpleBar from 'simplebar-react';
import MyField from '../../reusable/MyField';
import moment from 'moment';

const DashboardView = observer((props) => {
  useEffect(() => {
    FinancialStore.init();
  }, []);

  const [activeTab, setActiveTab] = useState('1');
  const [dateArrete, setDateArrete] = useState(
    moment().subtract(1, 'days').format('YYYY-MM-DD')
  );

  const onChandeDateArrete = (date) => {
    setDateArrete(date);
    FinancialStore.getOperationsEnCours(date);
  };

  const refreshData = () => {
    FinancialStore.getOperationsEnCours(dateArrete);
  };

  const toggleTab = (tab) => {
    console.log(tab);
    if (activeTab !== tab) setActiveTab(tab);
  };

  const callbackArrete = (lot) => {
    toggleTab('2');
    FinancialStore.showNewArreteDetails(lot);
  };

  const OperationsEnCoursTable = useTable({
    columns: FinancialStore.props.operationsEnCoursColumns,
    data: FinancialStore.props.dataOperationsEnCours,
    initialState: {
      hiddenColumns: FinancialStore.props.dataOperationsEnCours
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: FinancialStore.props.pageIndexOperationEnCours,
      pageSize: FinancialStore.props.pageLimitOperationEnCours,
      pageCount: FinancialStore.props.pageCountOperationEnCours,
      pageTotal: FinancialStore.props.pageTotalOperationEnCours,
    },
  });

  const LotsTable = useTable({
    columns: FinancialStore.props.lotsColumns,
    data: FinancialStore.props.dataLots,
    initialState: {
      hiddenColumns: FinancialStore.props.lotsColumns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: FinancialStore.props.pageIndexLots,
      pageSize: FinancialStore.props.pageLimitLots,
      pageCount: FinancialStore.props.pageCountLots,
      pageTotal: FinancialStore.props.pageTotalLots,
    },
    getDetailsLot: (lot) => FinancialStore.getDetailsLot(lot),
  });

  const OperationsByLotsTable = useTable({
    columns: FinancialStore.props.operationsEnCoursColumns,
    data: FinancialStore.props.dataOperationsByLots,
    initialState: {
      hiddenColumns: FinancialStore.props.operationsEnCoursColumns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: FinancialStore.props.pageIndexOperations,
      pageSize: FinancialStore.props.pageLimitOperations,
      pageCount: FinancialStore.props.pageCountOperations,
      pageTotal: FinancialStore.props.pageTotalOperations,
    },
  });

  return (
    <div className='container-fluid mt-3'>
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={FinancialStore.props.loading.status}
              message={FinancialStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className='card'
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggleTab('1');
                        }}
                      >
                        <span className='h6'>Relevé d'opérations en cours</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggleTab('2');
                        }}
                      >
                        <span className='h6'>Historique des lots</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent className='p-0 ' activeTab={activeTab}>
                    <TabPane tabId='1' className='animated fadeIn p-0'>
                      <div className='d-flex align-items-end mb-2 pt-3 center-v flex-md-row flex-column'>
                        <div className='d-flex center-v center-h mx-1'>
                          <label className='my-0 mx-1'>Jusqu'au : </label>
                          <MyField
                            type='date'
                            className='form-control form-control-sm mx-1'
                            onChange={onChandeDateArrete}
                            name='dateArrete'
                            filterDate={(date) => {
                              return moment()
                                .subtract(1, 'days')
                                .isAfter(moment(date));
                            }}
                            value={dateArrete}
                          ></MyField>
                        </div>
                        <div className='d-flex center-v center-h mx-1'>
                          Prochain arrêté automatique le :{' '}
                          {moment(FinancialStore.props.dateProchain).isValid()
                            ? moment(FinancialStore.props.dateProchain).format(
                                'DD/MM/YYYY'
                              )
                            : '-'}
                        </div>
                        <div className='d-flex ml-auto  flex-md-row flex-column'>
                          {!UserStore.isStation() && (
                            <>
                              <button
                                className='btn btn-sm btn-success mx-1'
                                onClick={() =>
                                  FinancialStore.doArreteComptable(
                                    dateArrete,
                                    (lot) => callbackArrete(lot)
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faRedo}
                                  className='mr-2'
                                ></FontAwesomeIcon>
                                Arrêté comptable
                              </button>
                            </>
                          )}
                          <button
                            className='btn btn-sm btn-fuel mx-1'
                            onClick={() => refreshData()}
                          >
                            <FontAwesomeIcon
                              icon={faRedo}
                              className='mr-2'
                            ></FontAwesomeIcon>
                            Rafraîchir
                          </button>
                          <ColumnsToggle
                            id={'operations'}
                            columns={OperationsEnCoursTable.allColumns}
                            className='btn btn-fuel btn-sm mx-1'
                            toggleColumn={(col) =>
                              FinancialStore.toggleColumn(col)
                            }
                          ></ColumnsToggle>
                        </div>
                      </div>
                      <DataTable
                        headerGroups={OperationsEnCoursTable.headerGroups}
                        getTableBodyProps={
                          OperationsEnCoursTable.getTableBodyProps
                        }
                        headerClassName='bg-theme-grey'
                        tableClassName='font-xs'
                        // onDoubleClickRow={(id) => TransacFilterStore.onDoubleClickRow(id)}
                        rows={OperationsEnCoursTable.rows}
                        prepareRow={OperationsEnCoursTable.prepareRow}
                        getTableProps={OperationsEnCoursTable.getTableProps}
                        columns={FinancialStore.props.columns}
                        loadingMsg={FinancialStore.props.loading.msg}
                        loadingStatus={FinancialStore.props.loading.status}
                        prevPage={() => FinancialStore.prevPage()}
                        nextPage={() => FinancialStore.nextPage()}
                        setSort={(sortBy) =>
                          FinancialStore.setSortOperation(sortBy)
                        }
                        sortOrder={FinancialStore.props.sortOrderOperation}
                        sortBy={FinancialStore.props.sortByOperation}
                        // goToPage={(page) => FinancialStore.goToPage(page)}
                        // pageCount={FinancialStore.props.pageCount}
                        // pageIndex={FinancialStore.props.pageIndex}
                        // pageLimit={FinancialStore.props.pageLimit}
                        // pageTotal={FinancialStore.props.pageTotal}
                        hidePagination={true}
                        maxHeight='calc(100vh - 260px)'
                        btnClassName='btn btn-outline-fuel btn-sm'
                      ></DataTable>
                    </TabPane>
                    <TabPane tabId='2' className='animated fadeIn p-0'>
                      <div className='row'>
                        <div className='col-md-4'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Lots précédents
                            </legend>
                            <BlockUi
                              tag='div'
                              blocking={FinancialStore.props.loading.statusLots}
                              message={FinancialStore.props.loading.msg}
                              loader={<BarLoader></BarLoader>}
                              style={{ minHeight: '60vh' }}
                            >
                              <div className='d-flex align-items-end mb-2'>
                                <div className='d-flex ml-auto'>
                                  <ColumnsToggle
                                    id='lots'
                                    columns={LotsTable.allColumns}
                                    className='btn btn-fuel btn-sm mx-1 btn-sm-flex-h'
                                    toggleColumn={(col) =>
                                      FinancialStore.toggleColumn(col)
                                    }
                                  ></ColumnsToggle>
                                </div>
                              </div>
                              <DataTable
                                headerGroups={LotsTable.headerGroups}
                                getTableBodyProps={LotsTable.getTableBodyProps}
                                headerClassName='bg-theme-grey'
                                // tableClassName="font-xs"
                                // onDoubleClickRow={(id) => TransacFilterStore.onDoubleClickRow(id)}
                                rows={LotsTable.rows}
                                prepareRow={LotsTable.prepareRow}
                                getTableProps={LotsTable.getTableProps}
                                columns={FinancialStore.props.columns}
                                loadingMsg={FinancialStore.props.loading.msg}
                                loadingStatus={
                                  FinancialStore.props.loading.status
                                }
                                prevPage={() => FinancialStore.prevPage()}
                                nextPage={() => FinancialStore.nextPage()}
                                setSort={(sortBy) =>
                                  FinancialStore.setSortLot(sortBy)
                                }
                                sortOrder={FinancialStore.props.sortOrderLot}
                                sortBy={FinancialStore.props.sortByLot}
                                goToPage={(page) =>
                                  FinancialStore.goToPage(page)
                                }
                                pageCount={FinancialStore.props.pageCount}
                                pageIndex={FinancialStore.props.pageIndex}
                                pageLimit={FinancialStore.props.pageLimit}
                                pageTotal={FinancialStore.props.pageTotal}
                                hidePagination={true}
                                maxHeight='calc(50vh)'
                                btnClassName='btn btn-outline-fuel btn-sm'
                              ></DataTable>
                            </BlockUi>
                          </fieldset>
                        </div>
                        <div className='col-md-4'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Sociétés
                            </legend>
                            <BlockUi
                              tag='div'
                              blocking={FinancialStore.props.loading.statusLots}
                              message={FinancialStore.props.loading.msg}
                              loader={<BarLoader></BarLoader>}
                              style={{ minHeight: '60vh' }}
                            >
                              <SimpleBar style={{ maxHeight: '60vh' }}>
                                <div className='row m-0'>
                                  {FinancialStore.props.totaux_soc && (
                                    <div className='col-12 mb-2'>
                                      <div className='col-12 border rounded p-3 border-2'>
                                        <h5>Totaux</h5>
                                        <div className='row'>
                                          <div className='col d-flex flex-column'>
                                            <span>Crédits</span>
                                            <div className='font-weight-bold'>
                                              <FontAwesomeIcon
                                                icon={faPlus}
                                                className='text-success mr-2'
                                              ></FontAwesomeIcon>
                                              {number(
                                                FinancialStore.props.totaux_soc
                                                  .credit
                                              ) + ' HTG'}
                                            </div>
                                          </div>

                                          <div className='col d-flex flex-column'>
                                            <span>Débits</span>
                                            <div className='font-weight-bold'>
                                              <FontAwesomeIcon
                                                icon={faMinus}
                                                className='text-danger mr-2'
                                              ></FontAwesomeIcon>
                                              {number(
                                                FinancialStore.props.totaux_soc
                                                  .debit
                                              ) + ' HTG'}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {Object.keys(
                                    FinancialStore.props.dataSociete
                                  ).map((item) => {
                                    let current =
                                      FinancialStore.props.dataSociete[item];
                                    return (
                                      <div className='col-12 mb-2'>
                                        <div className='col-12 border rounded p-3'>
                                          <h6>{current.nomSociete}</h6>
                                          <div className='row'>
                                            <div className='col d-flex flex-column'>
                                              <span>Crédits</span>
                                              <div className='font-weight-bold'>
                                                <FontAwesomeIcon
                                                  icon={faPlus}
                                                  className='text-success mr-2'
                                                ></FontAwesomeIcon>
                                                {number(current.credit) +
                                                  ' HTG'}
                                              </div>
                                            </div>

                                            <div className='col d-flex flex-column'>
                                              <span>Débits</span>
                                              <div className='font-weight-bold'>
                                                <FontAwesomeIcon
                                                  icon={faMinus}
                                                  className='text-danger mr-2'
                                                ></FontAwesomeIcon>
                                                {number(current.debit) + ' HTG'}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </SimpleBar>
                            </BlockUi>
                          </fieldset>
                        </div>
                        <div className='col-md-4'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Stations
                            </legend>
                            <BlockUi
                              tag='div'
                              blocking={FinancialStore.props.loading.statusLots}
                              message={FinancialStore.props.loading.msg}
                              loader={<BarLoader></BarLoader>}
                              style={{ minHeight: '60vh' }}
                            >
                              <SimpleBar style={{ maxHeight: '60vh' }}>
                                <div className='row m-0'>
                                  {FinancialStore.props.totaux_sta && (
                                    <div className='col-12 mb-2'>
                                      <div className='col-12 border rounded p-3 border-2'>
                                        <h5>Totaux</h5>
                                        <div className='row'>
                                          <div className='col d-flex flex-column'>
                                            <span>Crédits</span>
                                            <div className='font-weight-bold'>
                                              <FontAwesomeIcon
                                                icon={faPlus}
                                                className='text-success mr-2'
                                              ></FontAwesomeIcon>
                                              {number(
                                                FinancialStore.props.totaux_sta
                                                  .credit
                                              ) + ' HTG'}
                                            </div>
                                          </div>

                                          <div className='col d-flex flex-column'>
                                            <span>Débits</span>
                                            <div className='font-weight-bold'>
                                              <FontAwesomeIcon
                                                icon={faMinus}
                                                className='text-danger mr-2'
                                              ></FontAwesomeIcon>
                                              {number(
                                                FinancialStore.props.totaux_sta
                                                  .debit
                                              ) + ' HTG'}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {Object.keys(
                                    FinancialStore.props.dataStation
                                  ).map((item) => {
                                    let current =
                                      FinancialStore.props.dataStation[item];
                                    return (
                                      <div className='col-12 mb-2'>
                                        <div className='col-12 border rounded p-3'>
                                          <h6>{current.NomCommercant}</h6>
                                          <div className='row'>
                                            <div className='col d-flex flex-column'>
                                              <span>Crédits</span>
                                              <div className='font-weight-bold'>
                                                <FontAwesomeIcon
                                                  icon={faPlus}
                                                  className='text-success mr-2'
                                                ></FontAwesomeIcon>
                                                {number(current.credit) +
                                                  ' HTG'}
                                              </div>
                                            </div>

                                            <div className='col d-flex flex-column'>
                                              <span>Débits</span>
                                              <div className='font-weight-bold'>
                                                <FontAwesomeIcon
                                                  icon={faMinus}
                                                  className='text-danger mr-2'
                                                ></FontAwesomeIcon>
                                                {number(current.debit) + ' HTG'}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </SimpleBar>
                            </BlockUi>
                          </fieldset>
                        </div>
                        <div className='col-md-12'>
                          <fieldset className='p-3 border rounded'>
                            <legend className='text-fid w-auto px-3 text-old'>
                              Liste des transactions associées
                            </legend>
                            <BlockUi
                              tag='div'
                              blocking={FinancialStore.props.loading.statusLots}
                              message={FinancialStore.props.loading.msg}
                              loader={<BarLoader></BarLoader>}
                              style={{ minHeight: '50vh' }}
                            >
                              <div className='d-flex align-items-end mb-2'>
                                {FinancialStore.props.currentLot && (
                                  <>
                                    <div className='d-flex'>
                                      {UserStore.canDroitExport() && (
                                        <button
                                          onClick={() =>
                                            FinancialStore.exportCsvListTransac()
                                          }
                                          className='btn btn-old btn-sm  mx-1 btn-sm-flex-h'
                                        >
                                          <FontAwesomeIcon
                                            icon={faFileExcel}
                                            className='mr-2'
                                          ></FontAwesomeIcon>
                                          Exporter ce lot
                                        </button>
                                      )}
                                    </div>
                                    <div className='d-flex'>
                                      {UserStore.canDroitExport() && (
                                        <>
                                          <label className='mb-0'>
                                            Export comptable :{' '}
                                          </label>
                                          <button
                                            onClick={() =>
                                              FinancialStore.exportComptableCsv(
                                                'reg'
                                              )
                                            }
                                            className='btn btn-old btn-sm  mx-1 btn-sm-flex-h'
                                          >
                                            <FontAwesomeIcon
                                              icon={faFileExcel}
                                              className='mr-2'
                                            ></FontAwesomeIcon>
                                            Export régulier
                                          </button>
                                          <button
                                            onClick={() =>
                                              FinancialStore.exportComptableCsv(
                                                'sol'
                                              )
                                            }
                                            className='btn btn-old btn-sm  mx-1 btn-sm-flex-h'
                                          >
                                            <FontAwesomeIcon
                                              icon={faFileExcel}
                                              className='mr-2'
                                            ></FontAwesomeIcon>
                                            Export SOL
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                                <div className='d-flex ml-auto'>
                                  <ColumnsToggle
                                    id={'operations'}
                                    columns={OperationsByLotsTable.allColumns}
                                    className='btn btn-fuel btn-sm mx-1 btn-sm-flex-h'
                                    toggleColumn={(col) =>
                                      FinancialStore.toggleColumn(col)
                                    }
                                  ></ColumnsToggle>
                                </div>
                              </div>
                              <DataTable
                                headerGroups={
                                  OperationsByLotsTable.headerGroups
                                }
                                getTableBodyProps={
                                  OperationsByLotsTable.getTableBodyProps
                                }
                                headerClassName='bg-theme-grey'
                                tableClassName='font-xs'
                                // onDoubleClickRow={(id) => TransacFilterStore.onDoubleClickRow(id)}
                                rows={OperationsByLotsTable.rows}
                                prepareRow={OperationsByLotsTable.prepareRow}
                                getTableProps={
                                  OperationsByLotsTable.getTableProps
                                }
                                columns={FinancialStore.props.columns}
                                loadingMsg={FinancialStore.props.loading.msg}
                                loadingStatus={
                                  FinancialStore.props.loading.status
                                }
                                prevPage={() => FinancialStore.prevPage()}
                                nextPage={() => FinancialStore.nextPage()}
                                setSort={(sortBy) =>
                                  FinancialStore.setSortOperation(sortBy)
                                }
                                sortOrder={
                                  FinancialStore.props.sortOrderOperation
                                }
                                sortBy={FinancialStore.props.sortByOperation}
                                // goToPage={(page) => FinancialStore.goToPage(page)}
                                // pageCount={FinancialStore.props.pageCount}
                                // pageIndex={FinancialStore.props.pageIndex}
                                // pageLimit={FinancialStore.props.pageLimit}
                                // pageTotal={FinancialStore.props.pageTotal}
                                hidePagination={true}
                                maxHeight='calc(100vh - 260px)'
                                btnClassName='btn btn-outline-fuel btn-sm'
                              ></DataTable>
                            </BlockUi>
                          </fieldset>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default DashboardView;
