import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';
import BlockUi from 'react-block-ui';

import { BarLoader } from '../Loader/Loading';
import PrixProduitFilter from '../../stores/PrixProduitFilterStore';
import ActivePriceStore from '../../stores/ActivePriceStore';
import ReferencePriceStore from '../../stores/ReferencePriceStore';
import MarchePriceStore from '../../stores/MarchePriceStore';
import HistoricPrice from './HistoricPrice';
import PricePanel from './Prices';

const GestionProduit = () => {
  const [activeTab, setActiveTab] = useState('1');
  const loadingStatus = PrixProduitFilter.props.loading.status;
  const loadingMsg = PrixProduitFilter.props.loading.msg;
  const ActivePriceLoadingStatus = ActivePriceStore.props.loading.status;
  const ActivePriceLoadingMsg = ActivePriceStore.props.loading.msg;
  const ReferencePriceLoadingStatus = ReferencePriceStore.props.loading.status;
  const ReferencePriceLoadingMsg = ReferencePriceStore.props.loading.msg;
  const MarchePriceLoadingStatus = MarchePriceStore.props.loading.status;
  const MarchePriceLoadingMsg = MarchePriceStore.props.loading.msg;

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className='container-fluid mt-3'>
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={
                loadingStatus ||
                ActivePriceLoadingStatus ||
                ReferencePriceLoadingStatus ||
                MarchePriceLoadingStatus
              }
              message={
                loadingMsg ||
                ActivePriceLoadingMsg ||
                ReferencePriceLoadingMsg ||
                MarchePriceLoadingMsg
              }
              loader={<BarLoader />}
              className='card'
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => toggleTab('1')}
                    >
                      <span className='h6'>
                        Historique des changements de prix produits
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => toggleTab('2')}
                    >
                      <span className='h6'>Gestion des produits</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className='p-0' activeTab={activeTab}>
                  <TabPane tabId='1' className='animated fadeIn p-0'>
                    {/* <HistoricPrice /> */}
                    {activeTab === '1' && <HistoricPrice />}
                  </TabPane>
                  <TabPane tabId='2' className='animated fadeIn p-0'>
                    <div className='mt-4'>
                      {/* <PricePanel /> */}
                      {activeTab === '2' && <PricePanel />}
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionProduit;
