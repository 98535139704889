import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import LoginLayout from './components/Layouts/LoginLayout';
import LoginView from './components/Login/LoginView';
import IsLogged from './components/IsLogged/IsLogged';
import NotFoundView from './components/NotFound/NotFoundView';
import DashboardView from './components/Dashboard/DashboardView';
import LogoutView from './components/Logout/LogoutView';
import MainLayout from './components/Layouts/MainLayout';
import OrdersView from './components/Orders/OrdersView';
import FinancialView from './components/Financial/FinancialView';
import CardsView from './components/Cards/CardsView';
import TransacView from './components/Transacs/TransacView';
import BoUsersView from './components/BoUsers/BoUsersView';
import MajCardsView from './components/MajCards/MajCardsView';
import BonsView from './components/Bons/BonsView';
import StationsView from './components/Stations/StationsView';
import SocietesView from './components/Societes/SocietesView';
import GestionProduit from './components/Produits/GestionProduit';
import ResetPasswordView from './components/ResetPassword/ResetPasswordView';
import ActivateEmailView from './components/ActivateEmail/ActivateEmailView';
import MultiFactorView from './components/2FA/MultiFactorView';
import Setup2FAView from './components/Setup2FA/Setup2FAView';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/login'>
          <IsLogged>
            <LoginLayout component={LoginView} />
          </IsLogged>
        </Route>
        <Route path='/2fa'>
          <LoginLayout component={MultiFactorView} />
        </Route>
        <Route path='/two_factors_authentication_setup'>
          <LoginLayout component={Setup2FAView} />
        </Route>
        <Route path='/reset_password_verify_link'>
          <LoginLayout component={ResetPasswordView} />
        </Route>
        <Route path='/verify_email'>
          <LoginLayout component={ActivateEmailView} />
        </Route>
        <Route path='/dashboard'>
          <IsLogged>
            <MainLayout component={DashboardView} />
          </IsLogged>
        </Route>
        <Route path='/orders'>
          <IsLogged>
            <MainLayout component={OrdersView} />
          </IsLogged>
        </Route>
        <Route path='/financial'>
          <IsLogged>
            <MainLayout component={FinancialView} />
          </IsLogged>
        </Route>
        <Route path='/gestion-products'>
          <IsLogged>
            <MainLayout component={GestionProduit} />
          </IsLogged>
        </Route>
        <Route path='/cards'>
          <IsLogged>
            <MainLayout component={CardsView} />
          </IsLogged>
        </Route>
        <Route path='/stations'>
          <IsLogged>
            <MainLayout component={StationsView} />
          </IsLogged>
        </Route>
        <Route path='/societes'>
          <IsLogged>
            <MainLayout component={SocietesView} />
          </IsLogged>
        </Route>
        <Route path='/transactions'>
          <IsLogged>
            <MainLayout component={TransacView} />
          </IsLogged>
        </Route>
        <Route path='/majs'>
          <IsLogged>
            <MainLayout component={MajCardsView} />
          </IsLogged>
        </Route>
        <Route path='/bons'>
          <IsLogged>
            <MainLayout component={BonsView} />
          </IsLogged>
        </Route>
        <Route path='/logout'>
          <IsLogged>
            <MainLayout component={LogoutView}></MainLayout>
          </IsLogged>
        </Route>
        <Route path='/bo-users'>
          <IsLogged>
            <MainLayout component={BoUsersView}></MainLayout>
          </IsLogged>
        </Route>
        <Route exact path='/'>
          <IsLogged>
            <Redirect to='/logout' />
          </IsLogged>
        </Route>
        <Route component={NotFoundView} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
