import React, { useEffect, useState } from 'react'
import TransacStore from '../../stores/TransacStore'
import { observer } from 'mobx-react'
import { useTable } from 'react-table'
import { BarLoader } from '../Loader/Loading'
import DataTable, { ColumnsToggle, DropdownPortal, Portal } from '../../reusable/DataTable'
import BlockUi from 'react-block-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyField from '../../reusable/MyField'
import moment from 'moment'
import TransacFilter, { QuickSearchDate } from '../../reusable/TransacFilter'
import { faArrowRight, faCheck, faFileExcel, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'
import UserStore from '../../stores/UserStore'
import { number } from '../../utils/numeral'
import ReactTooltip from 'react-tooltip'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import classnames from 'classnames'

export const ActionsTransac = observer((props) => {

    const {
        row,
        toggleModalCancelTransac,
        isManager,
        toggleModalTreatFraude
    } = props

    return (
        <>
            {
                row.NumTicket !== '' && isManager &&
                <>
                    {
                        parseInt(row.TransactionAnnulee) === 0 && (parseInt(row.TypeTransac) === 3 || parseInt(row.TypeTransac) === 4) &&
                        <>
                            <button data-tip="Annuler la transaction" onClick={() => toggleModalCancelTransac(row)} className="btn btn-xs btn-outline-danger py-0">
                                <FontAwesomeIcon icon={faTimes} className='mx-1'></FontAwesomeIcon>
                            </button>
                            <ReactTooltip />
                        </>
                    }
                    {
                        parseInt(row.TypeTransac) === 23 &&
                        <>
                            <button data-tip="Traiter cette transaction BonCarburant" onClick={() => toggleModalTreatFraude(row)} className="btn btn-xs btn-outline-pass py-0">
                                <FontAwesomeIcon icon={faArrowRight} className='mx-1'></FontAwesomeIcon>
                            </button>
                            <ReactTooltip />
                        </>
                    }
                </>
            }
        </>
    )
})

const ModalCancelTransac = observer((props) => {

    const {
        selectedRow,
        cancelTransac,
        isOpenModalCancelTransac,
        toggleModalCancelTransac
    } = props
    const [motif, setMotif] = useState('')
    const [error, setError] = useState(false)
    const submit = () => {
        if (motif.length == 0)
            return setError('Ce champ est requis')

        cancelTransac(selectedRow, motif)
        toggleModalCancelTransac()
    }

    return (

        <Modal backdrop="static" centered={true} isOpen={isOpenModalCancelTransac} toggle={() => toggleModalCancelTransac()}>
            <ModalHeader className="no-border" toggle={() => toggleModalCancelTransac()}>
                Annulation ticket #{selectedRow.NumTicket}
            </ModalHeader>
            <ModalBody className="px-3 py-3">
                <div className="form-group">
                    <label>Saisir un motif :</label>
                    <MyField
                        type="text"
                        className="form-control form-control-sm"
                        onChange={e => setMotif(e.target.value)}
                        name="cltNumCard"
                        value={motif}
                        error={error}>
                    </MyField>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn-danger btn" onClick={() => toggleModalCancelTransac()}>Annuler</button>
                <button className="btn-success btn" onClick={() => submit()}>Valider</button>
            </ModalFooter>
        </Modal>
    )
})

const ModalTreatFraude = observer((props) => {

    const {
        selectedRowFraude,
        changeBonStatut,
        isOpenModalTreatFraude,
        toggleModalTreatFraude
    } = props

    return (

        <Modal backdrop="static" centered={true} isOpen={isOpenModalTreatFraude} toggle={() => toggleModalTreatFraude()}>
            <ModalHeader className="no-border" toggle={() => toggleModalTreatFraude()}>
                Traitement suspicion fraude
            </ModalHeader>
            <ModalBody className="px-3 py-3">
                <div className="form-group">
                    <table className="table borderless">
                        <tbody>
                            <tr>
                                <td className="border-0">Ticket</td>
                                <td className="border-0 font-weight-bold">{selectedRowFraude.NumTicket}</td>
                            </tr>
                            <tr>
                                <td className="border-0">Bon carburant</td>
                                <td className="border-0 font-weight-bold">{selectedRowFraude.NumCarte}</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="border-0 font-weight-bold">Ce bon a déjà été validé par une autre station.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ModalBody>
            <ModalFooter className="d-flex center-h">
                <button className="btn-success btn" onClick={() => changeBonStatut('validate', selectedRowFraude)}>
                    <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                    Valider ce Bon Carburant
                </button>
                <button className="btn-danger btn" onClick={() => changeBonStatut('reject', selectedRowFraude)}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
                    Rejeter ce Bon Carburant
                </button>
            </ModalFooter>
        </Modal>
    )
})

const TransacView = observer((props) => {

    const { filterCriteria } = props
    
    useEffect(() => {
        TransacStore.init(filterCriteria)
    }, [])

    // const cols = toJS(CardsStore.props.columns).filter(item => !item.isNotDisplay)
    const transacTable = useTable(
        {
            columns: TransacStore.props.columns,
            data: TransacStore.props.data,
            initialState: {
                hiddenColumns: TransacStore.props.columns.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: TransacStore.props.pageIndex,
                pageSize: TransacStore.props.pageLimit,
                pageCount: TransacStore.props.pageCount,
                pageTotal: TransacStore.props.pageTotal,
            },
            setUnitsHeader: TransacStore.setUnitsHeader,
            toggleModalCancelTransac: (row) => TransacStore.toggleModalCancelTransac(row),
            toggleModalTreatFraude: (row) => TransacStore.toggleModalTreatFraude(row),
            isManager: UserStore.isManager()
        }
    )


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);


    return (
        <div className="container-fluid mt-3">

            {/* <h2 className="card-title">Liste des cartes</h2> */}
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={TransacStore.props.loading.status}
                            message={TransacStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            className={classnames({ 'card': !filterCriteria })} style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <div className="card-body">
                                <div className="d-flex  align-items-end mb-2">
                                    <div>
                                        <QuickSearchDate
                                            onSubmitFilter={() => TransacStore.getFilteredTransacs()}
                                            className="btn btn-old btn-sm btn-sm-flex-h"
                                        ></QuickSearchDate>
                                    </div>
                                    <div className="d-flex ml-auto">
                                        {
                                            UserStore.canDroitExport() &&
                                            <button onClick={() => TransacStore.exportCsvListTransac()} className="btn btn-old btn-sm  mx-1 btn-sm-flex-h">
                                                <FontAwesomeIcon icon={faFileExcel} className="mr-2"></FontAwesomeIcon>
                                            Exporter
                                            </button>
                                        }
                                        <ColumnsToggle
                                            columns={transacTable.allColumns}
                                            className="btn btn-old btn-sm mx-1 btn-sm-flex-h"
                                            toggleColumn={(col) => TransacStore.toggleColumn(col)}
                                        // columnsFromTable={CardsStore.props.columnsFromTable}
                                        >
                                        </ColumnsToggle>
                                        <TransacFilter
                                            onSubmitFilter={() => TransacStore.getFilteredTransacs()}
                                            className="btn btn-old btn-sm  mx-1 btn-sm-flex-h"
                                            innerButton={
                                                <>
                                                    <FontAwesomeIcon icon={faFilter} className="mr-2"></FontAwesomeIcon>
                                            Recherche avancée
                                        </>
                                            }
                                        >

                                        </TransacFilter>
                                    </div>
                                </div>
                                <div className="d-flex  justify-content-end">

                                    <span data-tip="Annuler la transaction" className="mx-2">Total crédit : <b>{number(TransacStore.props.total_credit_pme2)} {' '} {UserStore.props.user.unitesPME2court}</b></span>
                                    <span className="mx-2">Total débit :  <b>{number(TransacStore.props.total_debit_pme2)} {' '} {UserStore.props.user.unitesPME2court}</b></span>

                                </div>
                                <DataTable
                                    headerGroups={transacTable.headerGroups}
                                    getTableBodyProps={transacTable.getTableBodyProps}
                                    tableClassName="font-xs"
                                    // onDoubleClickRow={(id) => TransacStore.onDoubleClickRow(id)}
                                    rows={transacTable.rows}
                                    prepareRow={transacTable.prepareRow}
                                    getTableProps={transacTable.getTableProps}
                                    columns={TransacStore.props.columns}
                                    loadingMsg={TransacStore.props.loading.msg}
                                    loadingStatus={TransacStore.props.loading.status}
                                    prevPage={() => TransacStore.prevPage()}
                                    nextPage={() => TransacStore.nextPage()}
                                    setSort={(sortBy) => TransacStore.setSort(sortBy)}
                                    sortOrder={TransacStore.props.sortOrder}
                                    sortBy={TransacStore.props.sortBy}
                                    goToPage={(page) => TransacStore.goToPage(page)}
                                    pageCount={TransacStore.props.pageCount}
                                    pageIndex={TransacStore.props.pageIndex}
                                    pageLimit={TransacStore.props.pageLimit}
                                    pageTotal={TransacStore.props.pageTotal}
                                    maxHeight="calc(100vh - 260px)"
                                    btnClassName="btn btn-outline-success btn-sm"
                                ></DataTable>
                                <ModalCancelTransac
                                    selectedRow={TransacStore.props.selectedRow}
                                    cancelTransac={(row, motif) => TransacStore.cancelTransac(row, motif)}
                                    isOpenModalCancelTransac={TransacStore.props.isOpenModalCancelTransac}
                                    toggleModalCancelTransac={() => TransacStore.toggleModalCancelTransac()}
                                ></ModalCancelTransac>
                                <ModalTreatFraude
                                    selectedRowFraude={TransacStore.props.selectedRowFraude}
                                    changeBonStatut={(action, row) => TransacStore.changeBonStatut(action, row)}
                                    isOpenModalTreatFraude={TransacStore.props.isOpenModalTreatFraude}
                                    toggleModalTreatFraude={() => TransacStore.toggleModalTreatFraude()}
                                ></ModalTreatFraude>
                            </div>
                        </BlockUi>
                    </div >
                </div>
            </div>

        </div >

    )
})

export default TransacView