import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import SocietesStore from '../../stores/SocietesStore';
import { useTable } from 'react-table';
import {
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Dropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faCaretDown,
  faPlus,
  faList,
  faPen,
  faUsers,
  faExpandArrowsAlt,
} from '@fortawesome/free-solid-svg-icons';
import BlockUi from 'react-block-ui';
import { BarLoader } from '../Loader/Loading';
import 'simplebar/dist/simplebar.min.css';
import UserStore from '../../stores/UserStore';
import MyField from '../../reusable/MyField';
import DataTable, {
  ColumnsToggle,
  DropdownPortal,
  Portal,
} from '../../reusable/DataTable';
import { entityTypes } from '../../utils/constants';
import { ModalLst, ModalEditSociete } from '../Common/Modals';

export const ActionsSociete = observer((props) => {
  const {
    row,
    toggleModalEditSociete,
    toggleModalLst,
    isManager,
    isOperator,
    isReseau,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const rootSelector = document.getElementById('root');
  const div = document.createElement('div');
  div.className = 'h-0';
  let portalContainer = useRef(div).current;

  useEffect(() => {
    rootSelector.appendChild(portalContainer);
    return () => {
      rootSelector.removeChild(portalContainer);
    };
  }, []);

  return (
    <>
      <DropdownPortal
        id={'edit-drop'}
        isOpen={dropdownOpen}
        toggle={toggle}
        portalContainer={portalContainer}
      >
        <DropdownToggle
          tag='button'
          className='btn btn-xs btn-outline-fuel btn-action'
          data-toggle='dropdown'
          aria-expanded={dropdownOpen}
        >
          {' '}
          Actions
          <FontAwesomeIcon
            className='ml-2'
            icon={faCaretDown}
          ></FontAwesomeIcon>
        </DropdownToggle>
        <Portal target={portalContainer}>
          <DropdownMenu right className='p-0'>
            {(isOperator || isManager) && isReseau && (
              <DropdownItem
                tag='a'
                className='dropdown-item'
                onClick={() => toggleModalEditSociete(row)}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  className='mr-2 text-fuel'
                ></FontAwesomeIcon>
                Modifier cette société
              </DropdownItem>
            )}
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() =>
                toggleModalLst(
                  {
                    id: row.idsociete,
                    name: row.nomSociete,
                    type: entityTypes.societe,
                  },
                  'clients'
                )
              }
            >
              <FontAwesomeIcon
                icon={faUsers}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Liste des clients
            </DropdownItem>
            <DropdownItem
              tag='a'
              className='dropdown-item'
              onClick={() =>
                toggleModalLst(
                  {
                    id: row.idsociete,
                    name: row.nomSociete,
                    type: entityTypes.societe,
                  },
                  'transacs'
                )
              }
            >
              <FontAwesomeIcon
                icon={faExpandArrowsAlt}
                className='mr-2 text-fuel'
              ></FontAwesomeIcon>
              Liste des transactions
            </DropdownItem>
          </DropdownMenu>
        </Portal>
      </DropdownPortal>
    </>
  );
});

const SocietesView = observer((props) => {
  useEffect(() => {
    SocietesStore.init();
  }, []);

  const societeTable = useTable({
    columns: SocietesStore.props.columns,
    data: SocietesStore.props.data.map((d) => {
      d.deleted = d.deleted ? 'true' : 'false';
      return d;
    }),
    initialState: {
      hiddenColumns: SocietesStore.props.columns
        .filter((item) => item.isHidden)
        .map((item) => item.accessor),
      manualPagination: true,
      pageIndex: SocietesStore.props.pageIndex,
      pageSize: SocietesStore.props.pageLimit,
      pageCount: SocietesStore.props.pageCount,
      pageTotal: SocietesStore.props.pageTotal,
    },
    delete: (row) => SocietesStore.delete(row),
    toggleModalCreateSociete: () => SocietesStore.toggleModalCreateSociete(),
    toggleModalEditSociete: (row) => SocietesStore.toggleModalEditSociete(row),
    toggleModalLst: (row, typeContent) =>
      SocietesStore.toggleModalLst(row, typeContent),
    toggleModalLstUsers: (row) => SocietesStore.toggleModalLstUsers(row),
    isOperator: UserStore.isOperator(),
    isManager: UserStore.isManager(),
    isReseau: UserStore.isReseau(),
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className='container-fluid mt-3'>
      {/* <h2 className="card-title">Liste des cartes</h2> */}
      <div className='animated fadeIn'>
        <div className='row'>
          <div className='col-12'>
            <BlockUi
              tag='div'
              blocking={SocietesStore.props.loading.status}
              message={SocietesStore.props.loading.msg}
              loader={<BarLoader></BarLoader>}
              className='card'
              style={{ minHeight: 'calc(100vh - 150px)' }}
            >
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col-md-2 d-flex align-items-end'>
                    <div className='row flex-grow-1'>
                      <div className='col-auto'>
                        <ColumnsToggle
                          columns={societeTable.allColumns}
                          className='btn btn-old btn-sm mx-1'
                          toggleColumn={(col) =>
                            SocietesStore.toggleColumn(col)
                          }
                          // columnsFromTable={SocietesStore.props.columnsFromTable}
                        ></ColumnsToggle>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-8'>
                    <div className='row'>
                      <div className='col-md-2  d-flex align-items-end'>
                        {(UserStore.isOperator() || UserStore.isManager()) &&
                          UserStore.isReseau() && (
                            <button
                              className='btn btn-sm btn-fuel'
                              onClick={() =>
                                SocietesStore.toggleModalCreateSociete()
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className='mr-2'
                              ></FontAwesomeIcon>
                              Ajout société
                            </button>
                          )}
                      </div>
                      <div className='col-md-5'>
                        <label
                          className='text-right mr-2'
                          for='filtre-nom-societe'
                        >
                          Rechercher par nom :
                        </label>
                        <MyField
                          type='reactSelect'
                          list={SocietesStore.props.listSocietes}
                          className='async-select'
                          onChange={(e) => SocietesStore.setSearchSociete(e)}
                          name='societe'
                          id='filtre-nom-societe'
                          value={SocietesStore.props.searchSociete}
                        ></MyField>
                      </div>
                      <div className='col-md-5'>
                        <label
                          className='text-right mr-2'
                          for='filtre-nom-societe'
                        >
                          Rechercher par gérant :
                        </label>
                        <MyField
                          type='reactSelect'
                          list={SocietesStore.props.listGerants}
                          className='async-select'
                          onChange={(e) =>
                            SocietesStore.setSearchSocieteByGerant(e)
                          }
                          name='gerant'
                          id='filtre-gerant-societe'
                          value={SocietesStore.props.searchSocieteByGerant}
                        ></MyField>
                      </div>
                    </div>
                  </div>
                  {UserStore.canDroitExport() && (
                    <div className='col-md-2 d-flex align-items-end'>
                      <Dropdown
                        isOpen={dropdownOpen}
                        className=' ml-auto'
                        toggle={toggle}
                      >
                        <DropdownToggle
                          tag='button'
                          className='btn btn-sm btn-old'
                          data-toggle='dropdown'
                          aria-expanded={dropdownOpen}
                        >
                          Actions
                          <FontAwesomeIcon
                            className='ml-2'
                            icon={faCaretDown}
                          ></FontAwesomeIcon>
                        </DropdownToggle>
                        <DropdownMenu right className='p-0'>
                          <DropdownItem
                            className='dropdown-item btn'
                            onClick={() => SocietesStore.exportClients()}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className='mr-2 text-info'
                            ></FontAwesomeIcon>
                            Exporter
                          </DropdownItem>

                          <DropdownItem
                            className='dropdown-item'
                            onClick={() => {
                              SocietesStore.editBlackList();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faList}
                              className='mr-2 text-secondary'
                            ></FontAwesomeIcon>
                            Générer la blacklist
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                </div>
                <div className='row'>
                  <div className='col'>
                    {/* <fieldset className="p-3 border rounded">
                      <legend className="text-fid w-auto px-3 text-old">
                        Liste des Sociétés
                      </legend> */}
                    <DataTable
                      headerGroups={societeTable.headerGroups}
                      tableClassName='font-xs'
                      getTableBodyProps={societeTable.getTableBodyProps}
                      onDoubleClickRow={(index, row) =>
                        SocietesStore.toggleModalEditSociete(row)
                      }
                      onClickRow={(index, row) =>
                        SocietesStore.setCurrentSociete(row)
                      }
                      rows={societeTable.rows}
                      prepareRow={societeTable.prepareRow}
                      getTableProps={societeTable.getTableProps}
                      columns={SocietesStore.props.columns}
                      loadingMsg={SocietesStore.props.loading.msg}
                      loadingStatus={SocietesStore.props.loading.status}
                      prevPage={() => SocietesStore.prevPage()}
                      nextPage={() => SocietesStore.nextPage()}
                      setSort={(sortBy) => SocietesStore.setSort(sortBy)}
                      sortOrder={SocietesStore.props.sortOrder}
                      sortBy={SocietesStore.props.sortBy}
                      goToPage={(page) => SocietesStore.goToPage(page)}
                      pageCount={SocietesStore.props.pageCount}
                      pageIndex={SocietesStore.props.pageIndex}
                      pageLimit={SocietesStore.props.pageLimit}
                      pageTotal={SocietesStore.props.pageTotal}
                      maxHeight='calc(100vh - 260px)'
                      btnClassName='btn btn-outline-fuel btn-sm'
                    ></DataTable>
                    {/* </fieldset> */}
                  </div>
                </div>

                <ModalEditSociete
                  initialValuesSociete={
                    SocietesStore.props.initialValuesSociete
                  }
                  currentSociete={SocietesStore.props.currentSociete}
                  toggleModalCreateSociete={() =>
                    SocietesStore.toggleModalCreateSociete()
                  }
                  toggleModalEditSociete={() =>
                    SocietesStore.toggleModalEditSociete()
                  }
                  submitEditSociete={(values) =>
                    SocietesStore.submitEditSociete(values)
                  }
                  isOpenModalEditSociete={
                    SocietesStore.props.isOpenModalEditSociete
                  }
                ></ModalEditSociete>

                <ModalLst
                  currentEntity={SocietesStore.props.currentSociete}
                  typeContent={SocietesStore.props.typeContent}
                  toggleModalLst={() => SocietesStore.toggleModalLst()}
                  isOpenModalLst={SocietesStore.props.isOpenModalLst}
                ></ModalLst>
              </div>
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SocietesView;
