import React from 'react';
import { observable, toJS } from 'mobx';
import moment from 'moment';
import api from '../api';
import UserStore from './UserStore';
import { Operators, StatutCarte, valeurBit } from '../utils/constants';
import { number } from '../utils/numeral';
import myYup from '../utils/myYup';
import { toast } from 'react-toastify';
import confirmation from '../components/Alerts/Confirmation';
import modalAlert from '../components/Alerts/ModalAlert';
import { ActionsOrders } from '../components/Orders/OrdersView';

const props = {
  loading: {
    status: false,
    msg: 'Chargement en cours',
  },
  columns: [
    {
      id: 'selection',
      isNotDisplay: true,
      Header: ({ selectionnes, data, toggleCheckboxAll }) => (
        <div className='form-check abc-checkbox abc-checkbox-primary'>
          <input
            id='item_all'
            className='form-check-input'
            onChange={() => toggleCheckboxAll()}
            checked={data
              .filter((item) => item.statut == 2 || item.statut == 4)
              .every((r) =>
                selectionnes
                  .map((item) => item.idcommande_article)
                  .includes(r.idcommande_article)
              )}
            type='checkbox'
          />
          <label for='item_all' className='form-check-label'></label>
        </div>
      ),
      Cell: ({ row, toggleCheckbox, selectionnes }) => {
        return (
          <>
            {row.values.statut == 2 ||
              (row.values.statut == 4 && (
                <div className='form-check abc-checkbox abc-checkbox-primary'>
                  <input
                    id={'item_' + row.id}
                    checked={selectionnes
                      .map((item) => item.idcommande_article)
                      .includes(row.values.idcommande_article)}
                    onChange={() =>
                      toggleCheckbox(row.values.idcommande_article)
                    }
                    className='form-check-input'
                    type='checkbox'
                  />
                  <label
                    for={'item_' + row.id}
                    className='form-check-label'
                  ></label>
                </div>
              ))}
          </>
        );
      },
    },
    {
      Header: 'Type commande',
      isSortable: true,
      accessor: 'type_commande',
      field: 'type_commande',
      Cell: ({ row, cell: { value } }) =>
        value == 2 ? (
          <span
            style={{ fontSize: '0.8rem' }}
            className='text-success font-weight-bold'
          >
            Batch
          </span>
        ) : (
          <span
            style={{ fontSize: '0.8rem' }}
            className='text-primary font-weight-bold'
          >
            Unique
          </span>
        ),
    },
    {
      Header: 'Num. Carte',
      isSortable: true,
      accessor: 'numCard',
      field: 'numCard',
    },
    {
      Header: 'Num. Batch',
      accessor: 'idcommande',
      field: 'idcommande',
      isSortable: true,
    },
    {
      Header: 'ID',
      accessor: 'idcommande_article',
      field: 'idcommande_article',
      isSortable: true,
    },
    {
      Header: 'Type Carte',
      accessor: 'typeCarte_lbl',
      field: 'typeCarte_lbl',
      isSortable: true,
    },
    {
      Header: 'Valeur',
      accessor: 'valeurCarte',
      field: 'valeurCarte',
      isSortable: true,
    },
    {
      Header: 'Civilité Client',
      accessor: 'clientCivilite',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Nom Client',
      accessor: 'clientNom',
      field: 'clientNom',
      isSortable: true,
    },
    {
      Header: 'Prénom Client',
      accessor: 'clientPrenom',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Immatriculation',
      accessor: 'Immatriculation',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Tél Client',
      accessor: 'clientTel',
      isSortable: true,
    },
    {
      Header: 'CP Client',
      accessor: 'clientCP',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Adresse 1 Client',
      accessor: 'clientAdresse1',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Adresse 2 Client',
      accessor: 'clientAdresse2',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Email Client',
      accessor: 'clientEmail',
      isSortable: true,
      isHidden: false,
    },
    {
      Header: 'Civilité dest.',
      accessor: 'destinataireCivilite',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Nom dest.',
      accessor: 'nom',
      isSortable: true,
      isHidden: true,
      Cell: ({ cell: { value } }) => {
        return number(value);
      },
    },
    {
      Header: 'Prénom dest.',
      accessor: 'prenom',
      isSortable: true,
      isHidden: true,
      Cell: ({ cell: { value } }) => {
        return number(value);
      },
    },
    {
      Header: 'Tél dest.',
      accessor: 'destinataireTel',
      isSortable: true,
      isHidden: true,
      Cell: ({ cell: { value } }) => {
        return number(value);
      },
    },
    {
      Header: 'CP dest.',
      accessor: 'destinataireCP',
      isSortable: true,
      isHidden: true,
      Cell: ({ cell: { value } }) => {
        return number(value);
      },
    },
    {
      Header: 'Adresse 2 dest.',
      accessor: 'destinataireAdresse2',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Email dest.',
      accessor: 'destinataireEmail',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Réseau',
      accessor: 'idReseauFid_lbl',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Station',
      accessor: 'idCommercantFid_lbl',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Emise le',
      accessor: 'dateExpedition',
      isSortable: true,
      isHidden: false,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH[h]mm');
        else return '-';
      },
    },
    {
      Header: "Date d'envoi souhaitée",
      accessor: 'dateEnvoiSouhaitee',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Livrée/Expédiée ou annulée ou débutée le',
      accessor: 'dateTraitement',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Traité par',
      accessor: 'responsableTraitement',
      isSortable: true,
      isHidden: false,
    },
    {
      Header: 'Commentaire',
      accessor: 'commentaire',
      isSortable: true,
      isHidden: true,
    },
    {
      Header: 'Statut',
      accessor: 'statut',
      isSortable: true,
      isHidden: false,
      Cell: ({ getBadgeColor, cell: { value } }) => (
        <span
          style={{ fontSize: '85%' }}
          className={
            'font-weight-bold badge ' + getBadgeColor(parseFloat(value))
          }
        >
          {StatutCarte[value]}
        </span>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({
        row,
        toggleModalDetailsOrders,
        updateParamsTpe,
        updateVersionTpe,
      }) => {
        return (
          <ActionsOrders
            row={row.original}
            toggleModalDetailsOrders={toggleModalDetailsOrders}
            updateParamsTpe={updateParamsTpe}
            updateVersionTpe={updateVersionTpe}
          ></ActionsOrders>
        );
      },
    },
  ],
  pattern: {
    statut: {
      active: false,
      title: 'statut',
      name: 'statut',
      operator: {
        type: 'multiple',
        list: ['in'],
      },
      field: {
        name: 'statut',
        type: 'multiple',
        list: [
          { id: 1, label: 'Annulée' },
          { id: 2, label: 'En Attente' },
          { id: 3, label: 'En Cours' },
          { id: 4, label: 'Prête' },
          { id: 5, label: 'Livrée/Expédiée' },
        ],
      },
      value: [
        {
          name: 'statut',
          field: 'statut',
          operator: 'in',
          type: 'array',
          value: [],
        },
      ],
      defaultValue: {
        name: 'statut',
        field: 'statut',
        type: 'array',
        operator: 'in',
        value: [],
      },
    },
    idsociete: {
      active: false,
      quickSearch: true,
      title: 'Société',
      name: 'idsociete',
      operator: {
        type: 'select',
        list: ['='].map((item) => ({ id: item, label: Operators[item] })),
      },
      field: {
        name: 'c_t.idsociete',
        type: 'asyncSelect',
      },
      value: [
        {
          name: 'idsociete',
          field: 'c_t.idsociete',
          operator: '=',
          type: 'asyncSelect',
          value: '',
        },
      ],
      defaultValue: {
        name: 'idsociete',
        field: 'c_t.idsociete',
        operator: '=',
        type: 'asyncSelect',
        value: '',
      },
    },
    dateExpedition: {
      active: false,
      title: "Période d'émission",
      canAddOr: false,
      name: 'dateExpedition',
      operator: {
        type: 'select',
        list: ['=', '<>', 'between', '>', '>=', '<', '<='].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'dateExpedition',
        type: 'date',
      },
      value: [
        {
          name: 'dateExpedition',
          field: 'date(dateExpedition)',
          type: 'date',
          operator: '=',
          value: moment().format('YYYY-MM-DD'),
        },
      ],
      defaultValue: {
        name: 'dateExpedition',
        field: 'date(dateExpedition)',
        type: 'date',
        operator: '=',
        value: moment().format('YYYY-MM-DD'),
      },
    },
    responsableTraitement: {
      active: false,
      quickSearch: true,
      title: 'Traiter par',
      name: 'responsableTraitement',
      operator: {
        type: 'select',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'responsableTraitement',
        type: 'text',
      },
      value: [
        {
          name: 'responsableTraitement',
          field: 'responsableTraitement',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'responsableTraitement',
        field: 'responsableTraitement',
        operator: 'like',
        type: 'text',
        value: '',
      },
    },
    clientNom: {
      active: false,
      quickSearch: true,
      title: 'Nom Client',
      name: 'clientNom',
      operator: {
        type: 'select',
        list: ['like', 'not like', '=', '<>'].map((item) => ({
          id: item,
          label: Operators[item],
        })),
      },
      field: {
        name: 'clientNom',
        type: 'text',
      },
      value: [
        {
          name: 'clientNom',
          field: 'c_t.clientNom',
          operator: 'like',
          type: 'text',
          value: '',
        },
      ],
      defaultValue: {
        name: 'clientNom',
        field: 'c_t.clientNom',
        operator: 'like',
        type: 'text',
        value: '',
      },
    },
  },
  data: [],
  listCommerces: [],
  listTimezones: [],
  pageStart: 0,
  pageIndex: 0,
  pageLimit: 50,
  pageTotal: 0,
  pageCount: 0,
  sortBy: 'idcommande_article',
  sortOrder: 'DESC',
  countActiveFilter: 0,
  savedPattern: {},
  savedFilters: [],
  listCardsType: [],
  loadedFilter: null,

  initialValuesCard: {},
  selectedRowOrders: {},
  isOpenModalDetailsOrders: false,
  isOpenModalHowTo: false,
  isOpenModalNewCard: false,
  isOpenModalNewBatch: false,
  isOpenModalScan: false,
  selectionnes: [],
  cardNotFound: false,
  currentCard: {},
  currentBatch: {},
  mode: null,

  validationSchemaCard: myYup.object().shape({
    RuleId: myYup.string().required(),
  }),
  validationSchemaBatch: myYup.object().shape({
    RuleId: myYup.string().required(),
  }),
};

const OrdersStore = {
  props: props,
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  async init(pattern) {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    if (pattern) this.props.pattern = pattern;
    this.props.pattern.idsociete.fetch = (value) => this.fetchSociete(value);
    console.log('initfilter TPE');
    await this.getCardsType();
    await this.getSocietes();

    await this.getOrders();
    // await this.getSavedFilters()
    // await CardsStore.getClients()
    this.props.savedPattern = toJS(this.props.pattern);

    this.doLoading(false);
  },
  async getOrders() {
    const idRes = UserStore.props.user.idRes;

    let where = '';

    if (UserStore.isReseau())
      where = this.getWhere(
        'c.iDreseauFid = ' + idRes + ' AND c_s.deleted = 0 '
      );
    else where = this.getWhere();

    // let where = this.getWhere(mainWhere)
    let data = {
      objName: 'listCommandes',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortBy) data['sort'] = this.props.sortBy;

    if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/commandes/commandes.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
    // this.props.loading.status = false
  },
  async getOrdersBySearch(type, value) {
    this.doLoading(true, 'Chargement en cours ...');
    const idRes = UserStore.props.user.idRes;

    let where = `c.iDreseauFid = ${idRes} AND c_s.deleted = 0 AND ${type} LIKE '%${value}%'`;

    // let where = this.getWhere(mainWhere)
    let data = {
      objName: 'listCommandes',
      cmd: 'getRows',
      where: where,
      start: this.props.pageStart,
      limit: this.props.pageLimit,
      sort: 'idcommande_article',
      dir: 'DESC',
      // 'query': JSON.stringify(where)
    };

    // if (this.props.sortBy) data['sort'] = this.props.sortBy;

    // if (this.props.sortOrder) data['dir'] = this.props.sortOrder;

    const res = await api.post('/scripts/commandes/commandes.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.data = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.data = [];
      }
    }
    this.props.loading.status = false;
  },
  async fetchSociete(value = '') {
    const idRes = UserStore.props.user.idRes;

    let data = {
      sort: 'nomSociete',
      json: true,
      dir: 'DESC',
      cmd: 'GetByCriteria',
      callFromClient: true,
      objName: 'listCommerce',
      where: 'idReseau=' + idRes + ' and deleted = 0 ',
      fields: JSON.stringify(['nomSociete']),
      query: value,
    };

    const res = await api.post('/scripts/societe/Services.Societes.php', data);
    let listSocietes = !res.data.rows ? [] : res.data.rows;
    return new Promise((resolve) =>
      resolve(
        listSocietes.map((item) => ({
          value: item.idsociete,
          label: item.nomSociete,
        }))
      )
    );
  },
  async getCardsType() {
    const idRes = UserStore.props.user.idRes;
    let data = {
      cmd: 'getRows',
      objName: 'listeRegleRes',
      fields: '["Label"]',
      where: 'reglereseau.InitCarteTransactionDefaut=97 and ReseauId=' + idRes,
    };

    const res = await api.post('/scripts/reseau/reseau.php', data);

    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;
      this.props.listCardsType = data.map((item) => ({
        id: item.RuleKey,
        label: item.Label,
        expiration: item.expiration,
      }));
      // this.props.listCardsType.unshift({ id: '', label: 'Aucun' })
    }
  },
  async submitEditCard(values) {
    // console.log(toJS(values))

    const idRes = UserStore.props.user.idRes;
    const auteur = UserStore.props.user.nom;

    let ProduitsAutorises = 0;
    let Restrictions = 0;

    //Jours autorisés
    if (parseInt(values['ctrlList_joursem']))
      Restrictions = Restrictions | valeurBit[0];
    if (parseInt(values['ctrlList_joursem1']))
      Restrictions = Restrictions | valeurBit[1];
    if (parseInt(values['ctrlList_joursem2']))
      Restrictions = Restrictions | valeurBit[2];
    if (parseInt(values['ctrlList_joursem3']))
      Restrictions = Restrictions | valeurBit[3];
    if (parseInt(values['ctrlList_joursem4']))
      Restrictions = Restrictions | valeurBit[4];
    if (parseInt(values['ctrlList_joursem5']))
      Restrictions = Restrictions | valeurBit[5];
    if (parseInt(values['ctrlList_joursem6']))
      Restrictions = Restrictions | valeurBit[6];
    if (parseInt(values['ctrlList_joursem7']))
      Restrictions = Restrictions | valeurBit[7];

    //Controle Kilométrage
    if (parseInt(values['ctrlList_km']))
      Restrictions = Restrictions | valeurBit[8];

    //Controle produits
    var PRODUITS = [];
    if (parseInt(values['ctrlList_pdt']))
      Restrictions = Restrictions | valeurBit[9];
    if (parseInt(values['prdctList_0'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[0];
      PRODUITS.push('Gaz.');
    }
    if (parseInt(values['prdctList_1'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[1];
      PRODUITS.push('Die.');
    }
    if (parseInt(values['prdctList_2'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[2];
      PRODUITS.push('Kér.');
    }
    if (parseInt(values['prdctList_3'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[3];
      PRODUITS.push('GPL.');
    }
    if (parseInt(values['prdctList_4'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[4];
      PRODUITS.push('Lub.');
    }
    if (parseInt(values['prdctList_5'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[5];
      PRODUITS.push('Autres');
    }
    if (!parseInt(values['ctrlList_pdt'])) PRODUITS = ['Tous produits'];

    values['ProduitsAutorises'] = ProduitsAutorises;
    values['Restrictions'] = Restrictions;

    const typeCarteLabel = this.props.listCardsType.find(
      (item) => item.id == values['RuleId']
    ).label;

    let data = {
      cmd: 'saveCardTemporaire',
      idreseau: idRes,
      objName: 'gridCartes_temporaire',
      auteur: JSON.stringify(auteur),
      data: JSON.stringify({
        cltNumCard: values['cltNumCard'],
        id_cmd_artcl: values['id_cmd_artcl'],
        cltNom: values['cltNom'],
        cltPrenom: values['cltPrenom'],
        cltEmail: values['cltEmail'],
        idSociete: values['idSociete'].label,
        RuleId: typeCarteLabel,
        expiration: values['expiration'],
        Pme2: values['Pme2'] ? values['Pme2'] : 0,
        Immatriculation: values['Immatriculation'],
        ProduitsAutorises: values['ProduitsAutorises'],
        Restrictions: values['Restrictions'],
      }),
      ref: 'printCard',
      printedName: values['idSociete'].value,
      nomprenom: values['cltPrenom'] + ' ' + values['cltNom'],
      immat: values['Immatriculation'] ? values['Immatriculation'] : '-',
      card: values['cltNumCard'],
      montant: values['Pme2'] ? values['Pme2'] : 0,
      expiration: values['expiration'],
      produits: PRODUITS.length ? PRODUITS.join(' | ') : 'Tous produits',
    };

    const res = await api.post('/scripts/clients/clients.php', data);
    if (res.data.success) {
      // toast.success('La carte a bien été enregistré')
      modalAlert({
        title: 'Succès',
        typeAlert: 'success',
        text: 'La carte a bien été enregistré',
      });
      api.get(
        process.env['REACT_APP_API_URL'] +
          'printCard.php?type=UserCard&printedName=' +
          data.printedName +
          '&immat=' +
          data.immat +
          '&card=' +
          data.card +
          '&expiration=' +
          data.expiration +
          '&produits=' +
          data.produits +
          '&nomprenom=' +
          data.nomprenom +
          (typeCarteLabel.toLowerCase() === 'carte jetable'
            ? '&montant=' + data.montant
            : '')
      );

      this.toggleModalNewCard();
      await this.getOrders();
    } else {
      toast.error('Une erreur est survenue.');
    }
  },
  async submitNewBatch(values) {
    console.log(toJS(values));

    const idRes = UserStore.props.user.idRes;
    const auteur = UserStore.props.user.nom;

    let ProduitsAutorises = 0;
    let Restrictions = 0;

    //Jours autorisés
    if (parseInt(values['ctrlList_joursem']))
      Restrictions = Restrictions | valeurBit[0];
    if (parseInt(values['ctrlList_joursem1']))
      Restrictions = Restrictions | valeurBit[1];
    if (parseInt(values['ctrlList_joursem2']))
      Restrictions = Restrictions | valeurBit[2];
    if (parseInt(values['ctrlList_joursem3']))
      Restrictions = Restrictions | valeurBit[3];
    if (parseInt(values['ctrlList_joursem4']))
      Restrictions = Restrictions | valeurBit[4];
    if (parseInt(values['ctrlList_joursem5']))
      Restrictions = Restrictions | valeurBit[5];
    if (parseInt(values['ctrlList_joursem6']))
      Restrictions = Restrictions | valeurBit[6];
    if (parseInt(values['ctrlList_joursem7']))
      Restrictions = Restrictions | valeurBit[7];

    //Controle Kilométrage
    if (parseInt(values['ctrlList_km']))
      Restrictions = Restrictions | valeurBit[8];

    //Controle produits
    var PRODUITS = [];
    if (parseInt(values['ctrlList_pdt']))
      Restrictions = Restrictions | valeurBit[9];
    if (parseInt(values['prdctList_0'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[0];
      PRODUITS.push('Gaz.');
    }
    if (parseInt(values['prdctList_1'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[1];
      PRODUITS.push('Die.');
    }
    if (parseInt(values['prdctList_2'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[2];
      PRODUITS.push('Kér.');
    }
    if (parseInt(values['prdctList_3'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[3];
      PRODUITS.push('GPL.');
    }
    if (parseInt(values['prdctList_4'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[4];
      PRODUITS.push('Lub.');
    }
    if (parseInt(values['prdctList_5'])) {
      ProduitsAutorises = ProduitsAutorises | valeurBit[5];
      PRODUITS.push('Autres');
    }
    if (!parseInt(values['ctrlList_pdt'])) PRODUITS = ['Tous produits'];

    values['ProduitsAutorises'] = ProduitsAutorises;
    values['Restrictions'] = Restrictions;

    const typeCarteLabel = this.props.listCardsType.find(
      (item) => item.id == values['RuleId']
    ).label;

    let data = {
      cmd: 'saveCardTemporaireBatch',
      idreseau: idRes,
      objName: 'gridCartes_temporaire',
      auteur: JSON.stringify(auteur),
      data: JSON.stringify({
        id_cmd_artclBatch: values['id_cmd_artclBatch'],
        idSociete: values['idSociete'].label,
        RuleId: typeCarteLabel,
        expiration: values['expiration'],
        Pme2: values['Pme2'] ? values['Pme2'] : 0,
        NbCartes: values['NbCartes'],
        ProduitsAutorises: values['ProduitsAutorises'],
        Restrictions: values['Restrictions'],
      }),
      ref: 'printCardBatch',
      printedName: values['idSociete'].value,
      nomprenom: values['cltPrenom'] + ' ' + values['cltNom'],
      immat: values['Immatriculation'] ? values['Immatriculation'] : '-',
      card: values['cltNumCard'],
      NbCartes: values['NbCartes'],
      montant: values['Pme2'] ? values['Pme2'] : 0,
      expiration: values['expiration'],
      produits: PRODUITS.length ? PRODUITS.join(' | ') : 'Tous produits',
    };

    const res = await api.post('/scripts/clients/clients.php', data);
    if (res.data.success) {
      toast.success('La carte a bien été enregistré');

      const idBatch = res.data.idbatch;
      const nbCartes = values['NbCartes'];
      api.get(
        process.env['REACT_APP_API_URL'] +
          'printCard.php?type=BatchCard&printedName=' +
          data.printedName +
          '&expiration=' +
          data.expiration +
          '&produits=' +
          data.produits +
          '&montant=' +
          data.montant +
          '&nb_card=' +
          nbCartes +
          '&idcommande=' +
          idBatch
      );

      this.toggleModalNewBatch();
      await this.getOrders();
    } else {
      modalAlert({
        title: 'Erreur',
        text: 'Veuillez vérifiez vos saisies<br>' + res.data.raison,
      });
      toast.error('Une erreur est survenue.');
    }
  },
  async submitNewCard(values) {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.cardNotFound = false;
    const idRes = UserStore.props.user.idRes;
    let data = {
      cmd: 'VerifyFormatNumCard',
      numCard: values['numCarte'],
    };

    const res = await api.post('/scripts/clients/clients.php', data);
    if (res.data.success) {
      let data = {
        cmd: 'getRows',
        start: 0,
        limit: 1,
        where:
          'clientfid_temporaire.iDreseauFid=' +
          idRes +
          ' AND  clientNumCard=' +
          values['numCarte'],
        objName: 'gridCartes_temporaire',
      };
      const res2 = await api.post('/scripts/clients/clients.php', data);

      if (res2.data.rows && res2.data.rows.length) {
        let data = res2.data.rows[0];

        this.toggleModalScan();
        this.toggleModalNewCard(data, true);
      } else {
        this.props.cardNotFound = true;
      }
    } else toast.error('Format Num Carte incorrect.');

    this.doLoading(false);
  },
  async showCardBatchDetails(row) {
    this.doLoading(true, 'Chargement en cours ...');
    this.props.cardNotFound = false;
    const idRes = UserStore.props.user.idRes;
    const idcommande_article = row.idcommande_article;

    let data = {
      cmd: 'getRows',
      start: 0,
      limit: 1,
      where:
        'idcommande_article=' +
        idcommande_article +
        ' AND clientfid_temporaire.iDreseauFid=' +
        idRes,
      objName: 'gridCartes_temporaire',
    };

    const res = await api.post('/scripts/clients/clients.php', data);

    if (res.data.rows && res.data.rows.length) {
      let data = res.data.rows[0];

      // data.idSociete = { value: data.idSociete, label: data.nomSociete }

      if (data['type_commande'] == 2) {
        // this.props.currentBatch = data
        this.toggleModalNewBatch(data);
      } else {
        // this.props.currentCard = data
        this.toggleModalNewCard(data);
      }
    } else {
      this.props.cardNotFound = true;
    }

    this.doLoading(false);
  },
  async treatCards(row) {
    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Confirmation de traitement',
      text: 'Souhaitez-vous lancer/continuer le processus de traitement?',
    });

    if (next) {
      this.toggleModalDetailsOrders();
      if (
        moment(row.dateTraitement).isSame(moment('0000-00-00 00:00:00')) ||
        !row.dateTraitement
      ) {
        let data = {
          cmd: 'saveRows',
          ref: 'setMyStatus',
          objName: 'listCommandes',
          data: JSON.stringify([
            {
              statut: 3,
              responsableTraitement: UserStore.props.user.nom,
              idcommande_article: row.idcommande_article,
            },
          ]),
        };

        const res = await api.post('/scripts/commandes/commandes.php', data);

        if (res.data.success) {
          await this.getOrders();
          this.toggleModalHowTo(row);
          // toast.success('Passage réussi')
        } else
          toast.error(
            <>
              Une erreur est survenue. <br />
              {res.data.error}
            </>
          );

        this.doLoading(false);
      }
    }
  },
  async getFilteredOrders() {
    this.doLoading(true, 'Chargement en cours ...');
    await this.getOrders();
    this.doLoading(false);
  },
  getQuickSerachItems() {
    let results = [];
    Object.keys(this.props.pattern)
      .filter((item) => this.props.pattern[item].quickSearch)
      .map((item) => {
        results.push({
          id: this.props.pattern[item].name,
          label: this.props.pattern[item].title,
        });
      });

    return results;
  },
  async setCardsSent() {
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.selectionnes.length == 0) {
      modalAlert({
        title: 'Attention',
        text: 'Veuillez cocher les lignes des commandes à passer en Livrées/Expédiées',
      });
      this.doLoading(false);
      return;
    }

    const next = await confirmation({
      type: 'confirmNoPass',
      title: 'Passer les commandes sélectionnées en Livrées/Expédiées',
      text:
        'Vous allez passer <b>' +
        this.props.selectionnes.length +
        ' commandes</b> en Livrées/Expédiées.<br/>Etes-vous sûr(e) de vouloir continuer ?',
    });
    if (next) {
      let data = {
        cmd: 'multiExpedier',
        auteur: JSON.stringify(UserStore.props.user.nom),
        lines: JSON.stringify(toJS(this.props.selectionnes)),
      };

      const res = await api.post('/scripts/commandes/commandes.php', data);

      if (res.data.success) {
        await this.getOrders();
        toast.success('Passage réussi');
      } else
        toast.error(
          <>
            Une erreur est survenue. <br />
            {res.data.error}
          </>
        );
    }
    this.doLoading(false);
  },
  async nextPage() {
    this.props.pageIndex = this.props.pageIndex + 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getOrders();
    this.doLoading(false);
  },
  async prevPage() {
    this.props.pageIndex = this.props.pageIndex - 1;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getOrders();
    this.doLoading(false);
  },
  async goToPage(page) {
    this.props.pageIndex = page;
    this.props.pageStart = this.props.pageIndex * this.props.pageLimit;
    this.doLoading(true, 'Chargement en cours ...');
    await this.getOrders();
    this.doLoading(false);
  },
  async setSort(value) {
    // console.log("TEST", value);
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortBy != value) {
      this.props.sortBy = value;
      this.props.sortOrder = 'ASC';
    } else {
      if (this.props.sortOrder == 'ASC') this.props.sortOrder = 'DESC';
      else if (this.props.sortOrder == 'DESC') {
        this.props.sortOrder = false;
        this.props.sortBy = null;
      }
    }

    await this.getOrders();
    this.doLoading(false);
  },
  toggleModalDetailsOrders(row = false) {
    if (row) {
      this.props.selectedRowOrders = row;
    }
    this.props.isOpenModalDetailsOrders = !this.props.isOpenModalDetailsOrders;
  },
  toggleModalHowTo(row = false) {
    if (row) {
      this.props.selectedRowOrders = row;
    }
    this.props.isOpenModalHowTo = !this.props.isOpenModalHowTo;
  },
  toggleModalNewCard(row = false, create = false) {
    console.log('Card');
    if (row) {
      this.props.currentCard = {
        id_cmd_artcl: row.idcommande_article,
        cltNumCard: row.cltNumCard,
        cltNom: row.cltNom,
        cltPrenom: row.cltPrenom,
        cltEmail: row.cltEmail,
        idSociete:
          parseInt(row.idSociete) > 0
            ? { value: row.idSociete, label: row.nomSociete }
            : { value: '', label: 'Aucune' },
        RuleId: parseInt(row.RuleId)
          ? row.RuleId
          : this.props.listCardsType[0].id,
        expiration: row.expiration,
        Pme2: row.valeurCarte,
        Immatriculation: row.Immatriculation,
        statut_commande:
          row.statut_commande.length > 0 ? row.statut_commande : false,
        ctrlList_joursem: parseInt(row.ctrlList_joursem) > 0 ? true : false,
        ctrlList_joursem1: parseInt(row.ctrlList_joursem1),
        ctrlList_joursem2: parseInt(row.ctrlList_joursem2),
        ctrlList_joursem3: parseInt(row.ctrlList_joursem3),
        ctrlList_joursem4: parseInt(row.ctrlList_joursem4),
        ctrlList_joursem5: parseInt(row.ctrlList_joursem5),
        ctrlList_joursem6: parseInt(row.ctrlList_joursem6),
        ctrlList_joursem7: parseInt(row.ctrlList_joursem7),
        ctrlList_km: parseInt(row.ctrlList_km) > 0 ? true : false,
        ctrlList_pdt: parseInt(row.ctrlList_pdt) > 0 ? true : false,
        prdctList_0: row.prdctList_0,
        prdctList_1: row.prdctList_1,
        prdctList_2: row.prdctList_2,
        prdctList_3: row.prdctList_3,
        prdctList_4: row.prdctList_4,
        prdctList_5: row.prdctList_5,
      };
    }

    this.props.isOpenModalNewCard = !this.props.isOpenModalNewCard;
  },
  toggleModalNewBatch(row = false) {
    console.log('Batch');
    if (row) {
      this.props.currentBatch = {
        numbatch: row.numbatch,
        cltNumCard: row.cltNumCard,
        idSociete:
          parseInt(row.idSociete) > 0
            ? { value: row.idSociete, label: row.nomSociete }
            : { value: '', label: 'Aucune' },
        RuleId: parseInt(row.RuleId)
          ? row.RuleId
          : this.props.listCardsType[0].id,
        expiration: row.expiration,
        Pme2: row.valeurCarte,
        NbCartes: row.NbCartes,
        statut_commande:
          row.statut_commande.length > 0 ? row.statut_commande : false,
        ctrlList_joursem: parseInt(row.ctrlList_joursem) > 0 ? true : false,
        ctrlList_joursem1: parseInt(row.ctrlList_joursem1),
        ctrlList_joursem2: parseInt(row.ctrlList_joursem2),
        ctrlList_joursem3: parseInt(row.ctrlList_joursem3),
        ctrlList_joursem4: parseInt(row.ctrlList_joursem4),
        ctrlList_joursem5: parseInt(row.ctrlList_joursem5),
        ctrlList_joursem6: parseInt(row.ctrlList_joursem6),
        ctrlList_joursem7: parseInt(row.ctrlList_joursem7),
        ctrlList_km: parseInt(row.ctrlList_km) > 0 ? true : false,
        ctrlList_pdt: parseInt(row.ctrlList_pdt) > 0 ? true : false,
        prdctList_0: row.prdctList_0,
        prdctList_1: row.prdctList_1,
        prdctList_2: row.prdctList_2,
        prdctList_3: row.prdctList_3,
        prdctList_4: row.prdctList_4,
        prdctList_5: row.prdctList_5,
      };
    } else {
      this.props.currentBatch = {
        newRecord: true,
        RuleId: this.props.listCardsType[0].id,
        idSociete: { value: '', label: 'Aucune' },
        NbCartes: 1,
        Pme2: 0,
      };
    }

    this.props.isOpenModalNewBatch = !this.props.isOpenModalNewBatch;
  },
  toggleModalScan() {
    this.props.isOpenModalScan = !this.props.isOpenModalScan;
  },
  async getSocietes() {
    const idRes = UserStore.props.user.idRes;
    const idPart = UserStore.props.user.idPart;

    let data = {
      start: 0,
      sort: 'nomSociete, raisonSociale, Credit',
      dir: 'DESC',
      cmd: 'getRows',
      objName: 'listeSocieteDuReseau',
      where: 's.idPartenaire = ' + idPart + ' and s.idReseau =' + idRes,
    };

    const res = await api.post('/scripts/societe/societe.php', data);
    if (res.data.success) {
      const data = !res.data.rows ? [] : res.data.rows;
      this.props.pattern.idsociete.field.list = data.map((item) => ({
        id: item.id,
        label: item.nomSociete,
      }));
      this.props.pattern.idsociete.field.list.unshift({
        id: '',
        label: 'Toutes les sociétés',
      });

      this.props.listCommerces = this.props.pattern.idsociete.field.list;

      if (!this.props.pattern.idsociete.value.length)
        this.props.pattern.idsociete.value.forEach((item) => {
          item.value = data.map((item) => item.idsociete);
        });
    }
  },
  getBadgeColor(value) {
    const bg_colors = [
      'bg-danger',
      'bg-warning',
      'bg-lightblue',
      'bg-info',
      'bg-success',
      'bg-secondary',
    ];

    if (value == 1) return bg_colors[0];
    if (value == 2) return bg_colors[1];
    if (value == 3) return bg_colors[2];
    if (value == 4) return bg_colors[3];
    if (value == 5) return bg_colors[4];

    return bg_colors[5];
  },
  toggleCheckboxAll() {
    const data = this.props.data.filter(
      (item) => item.statut == 2 || item.statut == 4
    );
    console.log('hasAllSelected');
    const hasAllSelected = data.every((r) =>
      this.props.selectionnes
        .map((item) => item.idcommande_article)
        .includes(r.idcommande_article)
    );
    if (hasAllSelected) {
      data.map((item) => {
        const position = this.props.selectionnes.findIndex(
          (value) => value.idcommande_article == item.idcommande_article
        );
        if (position > -1) {
          this.props.selectionnes.splice(position, 1);
        }
      });
    } else {
      console.log('NothingExclue');
      data.map((item) => {
        const position = this.props.selectionnes.findIndex(
          (value) => value.idcommande_article == item.idcommande_article
        );
        if (position < 0)
          this.props.selectionnes.push({
            idcommande_article: item.idcommande_article,
          });
      });
    }
    console.log(toJS(this.props.selectionnes));
  },
  toggleCheckbox(idcommande_article) {
    const position = this.props.selectionnes.findIndex(
      (item) => item.idcommande_article == idcommande_article
    );
    console.log(idcommande_article, position);
    if (position > -1) {
      this.props.selectionnes.splice(position, 1);
    } else {
      this.props.selectionnes.push({ idcommande_article: idcommande_article });
    }
  },
  allIsSelected() {
    const data = this.props.data.filter(
      (item) => item.statut == 2 || item.statut == 4
    );
    return this.props.selectionnes.length == data.length;
  },
  //Filter
  toggleItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = !this.props.pattern[key].active;
  },
  activateItemFilter(key) {
    if (!this.props.pattern[key].isNotToggable)
      this.props.pattern[key].active = true;
  },
  changeOperator(key, val, index) {
    console.log(val);
    this.props.pattern[key].value[index].operator = val;
    if (val == 'between') {
      this.props.pattern[key].value[index].value = [null, null];
    } else {
      this.props.pattern[key].value[index].value = '';
    }
  },
  changeValue(key, val, index, order) {
    if (this.props.pattern[key].value[index].operator == 'between') {
      this.props.pattern[key].value[index].value[order] = val;
    } else if (this.props.pattern[key].value[index].operator == 'in') {
      if (val != 'all') {
        let position = this.props.pattern[key].value[index].value.findIndex(
          (o) => o == val
        );
        console.log('position :', position);
        if (position === -1)
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value,
            val,
          ];
        else
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].value[index].value.filter(
              (item) => item != val
            ),
          ];
      } else {
        if (
          this.props.pattern[key].field.list.length !=
          this.props.pattern[key].value[index].value.length
        ) {
          this.props.pattern[key].value[index].value = [
            ...this.props.pattern[key].field.list.map((item) => item.id),
          ];
        } else {
          this.props.pattern[key].value[index].value = [];
        }
      }
    } else {
      this.props.pattern[key].value[index].value = val;
    }
    console.log(toJS(this.props.pattern[key].value[index].value));
  },
  addItemFilter(key, type) {
    let newVal = { ...this.props.pattern[key].defaultValue };
    newVal.logic = type;
    this.props.pattern[key].value = [...this.props.pattern[key].value, newVal];
  },
  removeItemFilter(key, idx) {
    this.props.pattern[key].value = [
      ...this.props.pattern[key].value.filter((item, index) => index != idx),
    ];
  },
  submitFilter() {
    this.props.savedPattern = toJS(this.props.pattern);
    this.getCountActiveFilter();
  },
  resetFilter() {
    Object.keys(this.props.pattern).map((item) => {
      this.props.pattern[item].value = props.pattern[item].value;
      this.props.savedPattern[item].value = props.pattern[item].value;
      this.props.pattern[item].active = false;
      this.props.savedPattern[item].active = false;
    });
  },
  undoPattern() {
    this.props.pattern = toJS(this.props.savedPattern);
  },
  getWhere(where = '') {
    const main = [
      ...Object.keys(this.props.pattern)
        .filter((item) => this.props.pattern[item].active)
        .map((item) => toJS(this.props.pattern[item].value)),
    ];

    console.log(main);
    for (let key in main) {
      if (main[key].isHidden) continue;
      if (where.length) {
        if (main[key].length) where += ' AND ( ';
      } else where += '( ';
      let pass = 0;
      for (let id in main[key]) {
        if (pass) where += ' ' + main[key][id].logic + ' ';
        pass = 1;

        where += this.getClause(main[key][id], main[key][id].custom);
      }

      if (where.length) {
        if (main[key].length) where += ')';
      }
    }

    return where;
  },
  getClause(clause, isCustom = false) {
    let where = '-+--+-+ ERR -++-+-+';
    if (clause.operator == 'in') {
      where = clause.field + ' IN (' + clause.value.join(',') + ') ';
      console.log(where);
    } else if (clause.operator == 'between') {
      if (clause.type == 'date') {
        where =
          clause.field +
          " BETWEEN '" +
          clause.value[0] +
          "' AND '" +
          clause.value[1] +
          "' ";
      } else if (clause.type == 'number') {
        where =
          clause.field +
          ' BETWEEN ' +
          clause.value[0] +
          ' AND ' +
          clause.value[1] +
          ' ';
      } else {
        where = '__ERROR__1';
      }
    } else if (clause.operator == 'like' || clause.operator == 'not like') {
      if (clause.type == 'text') {
        where =
          clause.field + ' ' + clause.operator + " '%" + clause.value + "%' ";
      } else {
        where = '__ERROR__2';
      }
    } else if (
      clause.operator == '>' ||
      clause.operator == '<' ||
      clause.operator == '>=' ||
      clause.operator == '<=' ||
      clause.operator == '<>' ||
      clause.operator == '='
    ) {
      if (clause.type == 'date' || clause.type == 'text') {
        where =
          clause.field + ' ' + clause.operator + " '" + clause.value + "' ";
      } else if (clause.type == 'number') {
        where = clause.field + ' ' + clause.operator + ' ' + clause.value + ' ';
      } else {
        where = '__ERROR__3';
      }
    } else {
      where = '__ERROR__4';
    }
    if (isCustom)
      return clause.field.replace('_$$$_', where.replace(clause.field, ''));
    return where;
  },
  getCountActiveFilter() {
    this.props.countActiveFilter = Object.keys(this.props.pattern).filter(
      (item) => this.props.pattern[item].active
    ).length;
  },
  //FILTER ACTIONS
  getCurrentFilterJson() {
    let filter = {};

    Object.keys(this.props.pattern)
      .filter((item) => this.props.pattern[item].active)
      .map((item) => {
        filter[item] = this.props.pattern[item].value;
      });

    return toJS(filter);
  },
  //END FILTER
  reset() {
    this.props = props;
  },
};

export default observable(OrdersStore);
