import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faExchangeAlt,
  faWindowMaximize,
  faCoins,
  faTicketAlt,
  faCreditCard,
  faGasPump,
  faBuilding,
  faNewspaper,
  faSync,
  faMoneyBillWave,
  faLock,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import UserStore from '../../stores/UserStore';
import { Roles } from '../../utils/constants';
import PrivateLink from '../../reusable/PrivateLink';
import classnames from 'classnames';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';

const SidebarView = () => {
  // Role
  const isReseau = UserStore.isReseau();
  const isSociete = UserStore.isSociete();
  const isStation = UserStore.isStation();

  //Permissions
  const canCreateUser = UserStore.canCreateUser();
  const canAdministrationCommandes = UserStore.canAdministrationCommandes();
  const canValidationCredit = UserStore.canValidationCredit();
  const canArreterComptable = UserStore.canArreterComptable();
  const canGestionBonCarburant = UserStore.canGestionBonCarburant();
  const estAlliance = UserStore.estAlliance();

  const nom = isReseau
    ? UserStore.props.user.nom_reseau
    : isSociete
    ? UserStore.props.user.nom_societe
    : isStation
    ? UserStore.props.user.nom_station
    : '';

  const menu = [
    {
      // header: isCampagne ? 'CLIENTS' : 'PILOTER',
      children: [
        {
          name: 'Tableau de bord',
          icon: faTachometerAlt,
          link: {
            pathname: '/dashboard',
            state: 'Tableau de bord',
          },
          hide: false || isSociete,
        },
        {
          name: 'Production de cartes',
          icon: faCreditCard,
          link: {
            pathname: '/orders',
            state: 'Production de cartes',
          },
          hide: !canAdministrationCommandes || isStation || isSociete,
        },
        {
          name: 'Etat financier',
          icon: faMoneyBillWave,
          link: {
            pathname: '/financial',
            state: 'Etat financier',
          },
          hide: !canArreterComptable || !estAlliance || isSociete,
        },
        {
          name: 'Gestion des Produits',
          icon: faTag,
          link: {
            pathname: '/gestion-products',
            state: 'Gestion des Produits',
          },
          hide: !canArreterComptable || !estAlliance || isSociete,
        },
        {
          name: 'Stations / TPE',
          icon: faGasPump,
          link: {
            pathname: '/stations',
            state: 'Stations / TPE',
          },
          hide: isStation || isSociete,
        },
        {
          name: 'Gestion des sociétés',
          icon: faBuilding,
          link: {
            pathname: '/societes',
            state: 'Liste des sociétés',
          },
          hide: false || isStation || isSociete,
        },
        {
          name: 'Gestion des accès',
          icon: faLock,
          link: {
            pathname: '/bo-users',
            state: 'Liste des utilisateurs',
          },
          hide: false || (isStation && !canCreateUser) || isSociete,
        },
        {
          name: 'Liste des cartes',
          icon: faNewspaper,
          link: {
            pathname: '/cards',
            state: 'Liste des cartes',
          },
          hide: false || isStation,
        },
        {
          name: 'Liste des transactions',
          icon: faExchangeAlt,
          link: {
            pathname: '/transactions',
            state: 'Liste des transactions',
          },
          hide: false || isStation,
        },
        {
          name: 'Bons carburants',
          icon: faTicketAlt,
          link: {
            pathname: '/bons',
            state: 'Bons carburants',
          },
          hide: !canGestionBonCarburant || isSociete,
        },
        {
          name: 'Mise à jour Cartes',
          icon: faSync,
          link: {
            pathname: '/majs',
            state: 'Mise à jour Cartes',
          },
          hide: false || isStation || isSociete,
        },
        {
          name: 'Demande de crédits',
          icon: faCoins,
          link: {
            pathname: '/credits',
            state: 'Demande de crédits',
          },
          hide: !canValidationCredit || isSociete,
        },
        {
          name: 'ANCIENNE INTERFACE',
          _blank: true,
          className: 'text-fuel',
          icon: faWindowMaximize,
          link: process.env['REACT_APP_OLD_ADMIN_URL'],
        },
      ],
    },
  ];

  return (
    <div className='sidebar'>
      <div className='sidebar-header'>
        <div className='avatar-first-letter'>
          {UserStore.props.user.firstLetter}
        </div>
        <div className='mt-2'>
          <strong>{nom}</strong>
        </div>
        <small>{Roles[UserStore.props.user.scope]}</small>
        {/* <div className="text-muted" style={{ lineHeight: '1' }}><small>{labelFid.find(x => x.id == UserStore.props.user.mode).label}</small></div> */}
      </div>
      <SimpleBar
        style={{ height: '100%' }}
        autoHide={false}
        className='sidebar-nav'
      >
        <ul className='nav'>
          {menu
            .filter((item) => !item.hide)
            .map((item) => (
              <>
                {item.header && <li className='nav-title'>{item.header}</li>}
                {item.children
                  .filter((item) => !item.hide)
                  .map((child) => (
                    <MenuItem {...child}></MenuItem>
                  ))}
              </>
            ))}
        </ul>
      </SimpleBar>
    </div>
  );
};

const MenuItem = (props) => {
  const { name, link, children, icon, _blank, className } = props;

  return (
    <>
      {children && <SubMenuItem {...props}></SubMenuItem>}
      {!children && (
        <>
          {!_blank && (
            <li className={`nav-item ${className ? ' ' + className : ''}`}>
              <PrivateLink to={link} className='nav-link'>
                <FontAwesomeIcon
                  icon={icon}
                  className='mr-2 nav-icon'
                ></FontAwesomeIcon>
                {name}
              </PrivateLink>
            </li>
          )}
          {_blank && (
            <Link
              target='_blank'
              href={link}
              className={`nav-link ${className ? ' ' + className : ''}`}
            >
              <FontAwesomeIcon
                icon={icon}
                className='mr-2 nav-icon'
              ></FontAwesomeIcon>
              {/* <FontAwesomeIcon icon={item.icon} className="mr-2"></FontAwesomeIcon> */}
              {name}
            </Link>
          )}
        </>
      )}
    </>
  );
};

const SubMenuItem = (props) => {
  const { name, children, icon } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <li className={classnames('nav-item nav-dropdown', { open: menuOpen })}>
      <a
        class='nav-link nav-dropdown-toggle'
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <FontAwesomeIcon
          icon={icon}
          className='mr-2 nav-icon'
        ></FontAwesomeIcon>
        {name}
      </a>
      <ul class='nav-dropdown-items'>
        {children.map((item) => (
          <li class='nav-item'>
            <PrivateLink to={item.link} className='nav-link'>
              {/* <FontAwesomeIcon icon={item.icon} className="mr-2"></FontAwesomeIcon> */}
              {item.name}
            </PrivateLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default observer(SidebarView);
