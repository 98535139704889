import React from 'react';
import { observable } from 'mobx';
import moment from 'moment';
import UserStore from './UserStore';
import api from '../api';
import { typeTransac } from '../utils/constants';
import { number } from '../utils/numeral';
import { toast } from 'react-toastify';
import confirmation from '../components/Alerts/Confirmation';
import { saveAs } from 'file-saver';

const props = {
  currentRoute: '',
  loading: {
    status: false,
    msg: 'Connexion en cours',
    statusLots: false,
  },
  operationsEnCoursColumns: [
    {
      Header: 'Date/heure',
      accessor: 'DateTransaction',
      field: 'DateTransaction',
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid())
          return moment(value).format('DD/MM/YYYY [à] HH:mm:ss');
        else return '-';
      },
    },
    {
      Header: 'N° Incrément',
      accessor: 'NouveauNumListe',
      field: 'NouveauNumListe',
      isHidden: true,
      isSortable: true,
    },
    {
      Header: 'Client',
      accessor: 'NP',
      field: 'NP',
      isSortable: true,
    },
    {
      Header: 'Catégorie',
      accessor: 'Label',
      field: 'Label',
      isSortable: false,
    },
    {
      Header: 'Type transaction',
      accessor: 'TypeTransac',
      field: 'TypeTransac',
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        if (typeTransac.hasOwnProperty(value)) return typeTransac[value];

        return value;
      },
    },
    {
      Header: 'Utilisateur',
      accessor: 'NomUser',
      field: 'NomUser',
      isSortable: true,
    },
    {
      Header: 'Société',
      accessor: 'nomSociete',
      field: 'nomSociete',
      isSortable: true,
    },
    {
      Header: 'Station',
      accessor: 'NomCommercant',
      field: 'NomCommercant',
      isSortable: true,
    },
    {
      Header: 'N° carte',
      accessor: 'NumCarte',
      field: 'NumCarte',
      isSortable: true,
    },
    {
      Header: '#Ticket',
      accessor: 'NumTicket',
      field: 'NumTicket',
      isSortable: true,
    },
    {
      Header: '#Ticket Annulé',
      accessor: 'NumTicketAnnule',
      field: 'NumTicketAnnule',
      isHidden: true,
      isSortable: true,
    },
    {
      Header: 'Bonus',
      accessor: 'bonus2',
      field: 'bonus2',
      isSortable: true,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Crédits PME2',
      accessor: 'creditPme2',
      field: 'creditPme2',
      isSortable: true,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Débits PME2',
      accessor: 'debitPme2',
      field: 'debitPme2',
      isSortable: true,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Gain',
      accessor: 'SPOT',
      field: 'SPOT',
      isSortable: true,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Date début valid.',
      accessor: 'DateDebut',
      field: 'DateDebut',
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Date fin valid.',
      accessor: 'DateFin',
      field: 'DateFin',
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        if (moment(value).isValid()) return moment(value).format('DD/MM/YYYY');
        else return '-';
      },
    },
    {
      Header: 'Transaction test',
      accessor: 'carteTest',
      field: 'carteTest',
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        if (parseInt(value)) return 'Oui';

        return 'Non';
      },
    },
    {
      Header: 'Mode',
      accessor: 'ModeTransmission',
      field: 'ModeTransmission',
      isSortable: true,
      Cell: ({ cell: { value } }) => {
        // console.log(value)
        if (value == 0)
          return (
            <span
              style={{ fontSize: '0.8rem', color: '#229046' }}
              className='font-weight-bold'
            >
              Offline
            </span>
          );
        else
          return (
            <span
              style={{ fontSize: '0.8rem' }}
              className='text-success font-weight-bold'
            >
              Online
            </span>
          );
      },
    },
  ],
  lotsColumns: [
    {
      Header: 'N° de lot',
      accessor: 'lot',
      field: 'lot',
      isSortable: false,
    },
    {
      Header: 'Montant (HTG)',
      accessor: 'montant',
      field: 'montant',
      isSortable: true,
      isHidden: true,
      Cell: ({ cell: { value } }) => number(value),
    },
    {
      Header: 'Date',
      accessor: 'date',
      field: 'montant',
      isSortable: true,
      Cell: ({ row: { values } }) => {
        const value = values.lot;

        var val = String(value);
        var dt = new Date();
        dt.setDate(val.substr(4, 2));
        dt.setMonth(val.substr(2, 2) - 1);
        dt.setFullYear(2000 + parseInt(val.substr(0, 2)));
        return dt.toLocaleDateString();

        // const date = (2000 + parseInt(value.substr(0, 2))) + '-' + (value.substr(2, 2) - 1) + '-' + (value.substr(4, 2))

        // if (moment(date).isValid())
        //     return moment(date).format('DD/MM/YYYY')
        // else
        //     return "-"
      },
    },
    {
      Header: '',
      isNotDisplay: true,
      accessor: 'button',
      Cell: ({ row, getDetailsLot }) => {
        return (
          <button
            onClick={() => getDetailsLot(row.values.lot)}
            className='btn btn-xs btn-success'
          >
            Détails
          </button>
        );
      },
    },
  ],
  dataStation: [],
  dataSociete: [],
  totaux_sta: null,
  totaux_soc: null,
  currentLot: null,
  //OperationsEnCours
  dataOperationsEnCours: [],
  pageStartOperationEnCours: 0,
  pageIndexOperationEnCours: 0,
  pageLimitOperationEnCours: 20000,
  pageTotalOperationEnCours: 20000,
  sortByOperationEnCours: '',
  sortOrderOperationEnCours: '',
  //Lots
  dataLots: [],
  pageStartLots: 0,
  pageIndexLots: 0,
  pageLimitLots: 20000,
  pageTotalLots: 0,
  sortByLots: '',
  sortOrderLots: '',
  //OperationsByLots
  dataOperationsByLots: [],
  pageStartOperationsByLots: 0,
  pageIndexOperationsByLots: 0,
  pageLimitOperationsByLots: 12,
  pageTotalOperationsByLots: 12,
  sortByOperationsByLots: 'DateTransaction',
  sortOrderOperationsByLots: 'DESC',

  //Historique
  dataHistorique: [],
  pageStartHistorique: 0,
  pageIndexHistorique: 0,
  pageLimitHistorique: 20000,
  sortByHistorique: null,
  sortOrderHistorique: null,
  dateProchain: '',

  pageStartLot: 0,
  pageIndexLot: 0,
  pageLimitLot: 20000,
  sortByLot: '',
  sortOrderLot: '',
};

const FinancialStore = {
  /***** Props ******/
  props: props,

  /***** Methods ******/
  doLoading(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.status = state;
  },
  doLoadingLots(state, msg = '') {
    this.props.loading.msg = msg;
    this.props.loading.statusLots = state;
  },
  setCurrentLot(lot) {
    this.props.currentLot = lot;
  },
  async exportCsvListTransac() {
    this.doLoading(true, "Génération de l'export en cours ...");

    // const modeFid = UserStore.props.user.mode
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';
    if (UserStore.isReseau())
      where =
        'iDreseauFid = ' +
        idRes +
        ' AND carteTest not in (1) AND lot=' +
        this.props.currentLot;
    else
      where =
        'idcommercantFid = ' +
        idCom +
        ' AND carteTest not in (1) AND lot=' +
        this.props.currentLot;

    const data = {
      cmd: 'exportCsvLstTransac',
      wheres: JSON.stringify({
        objName: 'expCsvLsttransac',
        where: where,
        modeF: 2,
        typeBonusPME1: 0,
        typeBonusPME2: 0,
        NomUnitePME1: UserStore.props.user.unitesPME1court || '',
        NomUnitePME2: UserStore.props.user.unitesPME2court || '',
      }),
      isCsv: true,
      data: undefined,
    };

    const res = await api.post('/scripts/export/exportscsv.php', data, {
      responseType: 'arraybuffer',
    });

    const file = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
    saveAs(file, 'export_lot_' + this.props.currentLot + '.csv');

    this.doLoading(false);
  },
  async exportComptableCsv(stat) {
    let objN = null;
    if (stat === 'sol')
      //SOL
      objN = 'expTransaComptableSOL';
    else objN = 'expCsvLsttransaComptable';

    this.doLoading(true, "Génération de l'export en cours ...");

    // const modeFid = UserStore.props.user.mode
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;
    const idSoc = UserStore.props.user.idSoc;

    let where = '';
    if (UserStore.isReseau())
      where = 'carteTest not in (1) AND lot=' + this.props.currentLot;
    else if (UserStore.isSociete())
      where =
        'idSociete = ' +
        idSoc +
        ' AND carteTest not in (1) AND lot=' +
        this.props.currentLot;
    else
      where =
        'idcommercantFid = ' +
        idCom +
        ' AND carteTest not in (1) AND lot=' +
        this.props.currentLot;

    const data = {
      cmd: 'exportCsvLstTransac',
      wheres: JSON.stringify({
        objName: objN,
        where: where,
        modeF: 2,
        typeBonusPME1: 0,
        typeBonusPME2: 0,
        NomUnitePME1: UserStore.props.user.unitesPME1court || '',
        NomUnitePME2: UserStore.props.user.unitesPME2court || '',
        reseauID: idRes,
      }),
      isCsv: true,
      data: undefined,
    };

    const res = await api.post('/scripts/export/exportscsv.php', data, {
      responseType: 'arraybuffer',
    });

    const file = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
    saveAs(file, 'export_lot_' + stat + '_' + this.props.currentLot + '.csv');

    this.doLoading(false);
  },
  async init() {
    this.reset();
    this.doLoading(true, 'Chargement en cours ...');
    console.log('INIT');
    await this.getOperationsEnCours();
    await this.getLots();
    await this.getDateProchain();
    this.doLoading(false);
  },
  async doArreteComptable(au, callback) {
    const listeTransac = this.quelleTransac(this.props.dataOperationsEnCours);
    const dateLimite = moment(au);

    let data = {
      cmd: 'flagTransac',
      records: JSON.stringify(listeTransac),
      date: dateLimite.isValid()
        ? dateLimite.format('MMDD')
        : moment().subtract(1, 'days').format('MMDD'),
    };

    const res = await api.post('/scripts/transac/transac.php', data);
    if (res.data.success) {
      const next = await confirmation({
        type: 'ouiNon',
        title: 'Opération réussie',
        text:
          '<p>Création du lot <b>' +
          res.data.numlot +
          "</b> réussie.</p><p>Souhaitez-vous afficher les détails de l'arrêté ?</p>",
      });

      if (next) callback(res.data.numlot);
    } else toast.error('Une erreur est survenue');
  },
  quelleTransac(rows) {
    let listeTransac = [];
    // console.log(rows)
    for (let key in rows) {
      console.log(rows[key]);
      var idTransac = rows[key].idTransac.split('#');
      var idRes = rows[key].iDreseauFid;
      var transac = {
        iDreseauFid: idRes,
        NumReseau: idTransac[0],
        NumCommercant: idTransac[1],
        NumTpe: idTransac[2],
        DateTransac: idTransac[3],
        HeureTransac: idTransac[4],
        NumCarte: idTransac[5],
      };
      listeTransac.push(transac);
    }

    return listeTransac;
  },
  async getDetailsSocietestations(lot) {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';

    if (UserStore.isReseau())
      where = ' lot=' + lot + ' AND iDreseauFid=' + idRes;
    else where = ' lot=' + lot + ' AND idcommercantFid=' + idCom;

    let data = {
      cmd: 'renderArrete',
      id: idRes,
      where: where,
    };

    const res = await api.post('/scripts/transac/transac.php', data);
    if (res.data.success) {
      this.props.dataStation = res.data.raw.rows;
      this.props.totaux_sta = res.data.totaux_sta;

      this.props.dataSociete = res.data.raw.rowsSoc;
      this.props.totaux_soc = res.data.totaux_soc;
    }
  },
  async getDetailsTransacs(lot) {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';

    if (UserStore.isReseau())
      where = ' lot=' + lot + ' AND iDreseauFid=' + idRes;
    else where = ' lot=' + lot + ' AND idcommercantFid=' + idCom;

    let data = {
      cmd: 'renderArrete',
      id: idRes,
      where: where,
    };

    const res = await api.post('/scripts/transac/transac.php', data);
    if (res.data.success) {
      this.props.dataStation = res.data.raw.rows;
      this.props.dataSociete = res.data.raw.rowsSoc;
    }
  },
  async getDetailsLot(lot) {
    this.setCurrentLot(lot);
    this.doLoadingLots(true, 'Chargement en cours ...');
    await this.getDetailsSocietestations(lot);
    await this.getOperationsByLot(lot);
    this.doLoadingLots(false);
  },
  async showNewArreteDetails(lot) {
    this.doLoadingLots(true, 'Chargement en cours ...');
    await this.getLots();
    await this.getDetailsSocietestations(lot);
    await this.getOperationsByLot(lot);
    this.doLoadingLots(false);
  },
  async getOperationsEnCours(date = null) {
    if (date) this.doLoading(true, 'Chargement en cours ...');
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';

    if (UserStore.isReseau())
      where =
        ' lot=0 AND TypeTransac in(1,2,3,4,5,9,16,22,96,97,91,89) AND iDreseauFid=' +
        idRes;
    else
      where =
        ' lot=0 AND TypeTransac in(1,2,3,4,5,9,16,22,96,97,91,89) AND idcommercantFid=' +
        idCom;

    if (date) where += " AND DATE(DateTransaction) <= '" + date + "'";

    let data = {
      objName: 'listeTransac',
      cmd: 'getRowsP',
      where: where,
      start: 0,
      limit: 20000,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortByOperationsEnCours)
      data['sort'] = this.props.sortByOperationsEnCours;

    if (this.props.sortOrderOperationsEnCours)
      data['dir'] = this.props.sortOrderOperationsEnCours;

    const res = await api.post('/scripts/transac/transac.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.dataOperationsEnCours = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotalEnCours = res.data.totalCount;
        this.props.pageCountEnCours = Math.ceil(
          res.data.totalCount / this.props.pageLimitEnCours
        );
      } else {
        this.props.dataOperationsEnCours = [];
      }
    }
    if (date) this.doLoading(false);
  },
  async getOperationsByLot(lot) {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';

    if (UserStore.isReseau())
      where =
        ' lot=' +
        lot +
        ' AND TypeTransac in(1,2,3,4,5,9,16,22,96,97,91,89) AND iDreseauFid=' +
        idRes;
    else
      where =
        ' lot=' +
        lot +
        ' AND TypeTransac in(1,2,3,4,5,9,16,22,96,97,91,89) AND idcommercantFid=' +
        idCom;

    let data = {
      objName: 'listeTransac',
      cmd: 'getRowsP',
      id: idRes,
      where: where,
      start: this.props.pageStartOperationsByLots,
      limit: this.props.pageLimitOperationsByLots,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortByOperationsByLots)
      data['sort'] = this.props.sortByOperationsByLots;

    if (this.props.sortOrderOperationsByLots)
      data['dir'] = this.props.sortOrderOperationsByLots;

    const res = await api.post('/scripts/transac/transac.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.dataOperationsByLots = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotalOperationsByLots = res.data.totalCount;
        this.props.pageCountOperationsByLots = Math.ceil(
          res.data.totalCount / this.props.pageLimitOperationsByLots
        );
      } else {
        this.props.dataOperationsByLot = [];
      }
    }
  },
  async getLots() {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';

    if (UserStore.isReseau()) where = 'iDreseauFid=' + idRes;
    else where = 'idcommercantFid=' + idCom;

    let data = {
      cmd: 'historiqueRM',
      whereAnd: where,
      start: this.props.pageStartLot,
      limit: this.props.pageLimitLot,
      // 'query': JSON.stringify(where)
    };

    if (this.props.sortByLots) data['sort'] = this.props.sortByLots;

    if (this.props.sortOrderLot) data['dir'] = this.props.sortOrderLots;

    const res = await api.post('/scripts/transac/transac.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.dataLots = !res.data.rows ? [] : res.data.rows;
        this.props.pageTotal = res.data.totalCount;
        this.props.pageCount = Math.ceil(
          res.data.totalCount / this.props.pageLimit
        );
      } else {
        this.props.dataLots = [];
      }
    }
  },
  async getHistorique() {
    const idRes = UserStore.props.user.idRes;
    const idCom = UserStore.props.user.idCom;

    let where = '';

    if (UserStore.isReseau()) where = 'iDreseauFid=' + idRes;
    else where = 'idcommercantFid=' + idCom;

    let data = {
      objName: 'listeTransac',
      cmd: 'historiqueRM',
      whereAnd: where,
      start: this.props.pageStartHistorique,
      limit: this.props.pageLimitHistorique,
      sort: this.props.sortByHistorique,
      dir: this.props.sortOrderHistorique,
    };

    const res = await api.post('/scripts/transac/transac.php', data);
    if (res.data.success) {
      if (res.data.rows) {
        this.props.dataHistorique = !res.data.rows ? [] : res.data.rows;
        // this.props.pageTotal = res.data.totalCount
        // this.props.pageCount = Math.ceil(res.data.totalCount / this.props.pageLimit)
      } else {
        this.props.dataHistorique = [];
      }
    }
  },
  async getDateProchain() {
    let data = {
      cmd: 'getDateProchain',
    };

    const res = await api.post('/scripts/reseau/reseau.php', data);
    if (res.data) {
      this.props.dateProchain = res.data;
    }
  },
  async setSortOperation(value) {
    console.log('TEST', value);
    this.doLoading(true, 'Chargement en cours ...');
    if (this.props.sortByOperation != value) {
      this.props.sortByOperation = value;
      this.props.sortOrderOperation = 'ASC';
    } else {
      if (this.props.sortOrderOperation == 'ASC')
        this.props.sortOrderOperation = 'DESC';
      else if (this.props.sortOrderOperation == 'DESC') {
        this.props.sortOrderOperation = false;
        this.props.sortByOperation = null;
      }
    }

    await this.getOperations();
    this.doLoading(false);
    // console.log(value, this.props.sortOrder)
  },
  reset() {
    this.props = props;
  },
};

export default observable(FinancialStore);
